import type { ReactNode } from 'react'
import { useState, useContext, createContext, useCallback, useMemo } from 'react'

interface ShareModalContent {
	code: string
	title: string
	element?: string
	isDirty?: boolean
}

interface ShareModalProps {
	code: string
	title: string
	element: string
	isDirty: boolean
	onClose: () => void
}

interface ShareModalContextType {
	isModalOpen: boolean
	openModal: () => void

	setModalContent: (content: ShareModalContent) => void
	modalProps: ShareModalProps | null
}

const ShareModalContext = createContext<ShareModalContextType | null>(null)

interface ShareModalProviderProps {
	children: ReactNode
}

export const ShareModalProvider = ({ children }: ShareModalProviderProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalProps, setModalProps] = useState<ShareModalProps | null>(null)

	const openModal = useCallback(() => {
		setIsModalOpen(true)
	}, [])

	const closeModal = useCallback(() => {
		setIsModalOpen(false)
	}, [])

	const setModalContent = useCallback(
		(content: ShareModalContent) => {
			setModalProps({
				code: content.code,
				title: content.title,
				element: content.element ?? 'textarea',
				isDirty: content.isDirty ?? false,
				onClose: closeModal,
			})
		},
		[setModalProps, closeModal],
	)

	const modalValue = useMemo(
		() => ({ isModalOpen, openModal, setModalContent, modalProps }),
		[isModalOpen, openModal, setModalContent, modalProps],
	)

	return <ShareModalContext.Provider value={modalValue}>{children}</ShareModalContext.Provider>
}

export const useShareModal = () => {
	const context = useContext(ShareModalContext)
	if (context === null) {
		throw new Error('useShareModal must be used within a ShareModalProvider')
	}
	return context
}
