import { omit } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Auth } from 'aws-amplify'
import { useState } from 'react'

import Button from '@/components/Button'
import FieldCheckbox from '@/components/FieldCheckbox'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import PropTypes from '@/lib/propTypes'
import { useFinalizeUser } from '@/api/videobot'
import { useReAuth } from '@/modules/session/cognito.component'
import { ON_LOGIN_EMAIL_KEY } from '@/modules/session/auth.store'

import AuthForm from '../AuthForm'

const JoinTeamHeader = () => {
	const { t } = useTranslation(['auth'])
	return <h1>{t('auth:createAnAccount')}</h1>
}

const JoinTeamFooter = () => {
	const { t } = useTranslation(['auth'])
	return (
		<p>
			{t('auth:alreadyHaveAnAccount')} <Link to="/login">{t('auth:logIn')}</Link>
		</p>
	)
}

const JoinTeamForm = ({ email, token, accountId }) => {
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})
	const { t } = useTranslation(['auth', 'account', 'validation', 'errors'])
	const navigate = useNavigate()
	const { mutate, isLoading } = useFinalizeUser()
	const [isAuthenticating, setIsAuthenticating] = useState(false)
	const reAuth = useReAuth()

	const submitForm = async (data) => {
		const body = omit(data, ['repeatPassword', 'policy', 'offers'])
		setIsAuthenticating(true)
		try {
			const user = await Auth.signIn(email, token)
			await Auth.completeNewPassword(user, data.password)
		} catch (e) {
			// TODO: handle error
			setIsAuthenticating(false)
			toast.error(e.code ? t(`errors:${e.code}`) : t('errors:errorOccured'))
			return
		}
		mutate(
			{ data: { ...body, token, accountId } },
			{
				onSuccess: () => {
					toast.success(t('auth:accountHasBeenCreated'))
					reAuth()
					navigate('/')
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
				},
			},
		)
	}

	const googleAuth = () => {
		sessionStorage.setItem(ON_LOGIN_EMAIL_KEY, email)
		Auth.federatedSignIn({ provider: 'Google' })
		setIsAuthenticating(true)
	}

	return (
		<AuthForm header={<JoinTeamHeader />} footer={<JoinTeamFooter />}>
			<div className="mb-4 flex">
				<Button
					variant="secondary"
					block
					size="large"
					onClick={googleAuth}
					disabled={isAuthenticating}
				>
					<div className="flex items-center gap-4">
						<img src="/assets/icons/google.svg" className="aspect-square h-6" />
						Log in with Google
					</div>
				</Button>
			</div>
			<form onSubmit={handleSubmit(submitForm)}>
				<Row>
					<Col md={6} xxs={12}>
						<FormField label={t('account:fields.firstName.label')}>
							<FieldText
								register={register}
								placeholder={t('account:fields.firstName.placeholder')}
								name="firstName"
								type="text"
								error={errors.firstName}
								required={t('validation:required')}
								size="large"
							/>
						</FormField>
					</Col>
					<Col md={6} xxs={12}>
						<FormField label={t('account:fields.lastName.label')}>
							<FieldText
								register={register}
								placeholder={t('account:fields.lastName.placeholder')}
								name="lastName"
								type="text"
								error={errors.lastName}
								required={t('validation:required')}
								size="large"
							/>
						</FormField>
					</Col>
				</Row>
				<FormField label={t('account:fields.email.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.email.placeholder')}
						name="email"
						type="email"
						defaultValue={email}
						error={errors.email}
						readonly
						size="large"
					/>
				</FormField>
				<FormField label={t('account:fields.password.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.password.placeholder')}
						name="password"
						type="password"
						error={errors.password}
						required
						size="large"
					/>
					<span className="mt-6 block text-muted">
						Password should be at least 8 characters length, includes at least 1 number,
						1 uppercase character.
					</span>
				</FormField>
				<FormField label={t('account:fields.repeatPassword.label')}>
					<FieldText
						register={register}
						rules={{
							validate: (value) => value === getValues('password'),
						}}
						placeholder={t('account:fields.repeatPassword.placeholder')}
						name="repeatPassword"
						type="password"
						error={errors.repeatPassword}
						required
						size="large"
					/>
				</FormField>
				<FormField>
					<FieldCheckbox
						name="policy"
						error={errors.policy}
						register={register}
						setValue={setValue}
						required
					>
						<Trans i18nKey="account:fields.signUpPolicy.label">
							I agree with the <Link to="/terms">Terms</Link> and{' '}
							<Link to="/privacy-policy">Privacy Policy</Link>
						</Trans>
					</FieldCheckbox>
					<FieldCheckbox
						name="offers"
						error={errors.offers}
						register={register}
						setValue={setValue}
					>
						{t('account:fields.receiveOffersMailing.label')}
					</FieldCheckbox>
				</FormField>
				<Button block isLoading={isLoading || isAuthenticating}>
					{t('auth:signUp')}
				</Button>
			</form>
		</AuthForm>
	)
}

JoinTeamForm.propTypes = {
	email: PropTypes.string,
	token: PropTypes.string,
}

export default JoinTeamForm
