import styled, { css } from 'styled-components'

import { Cover, ImgCover } from '../../lib/mixins'

export const AvatarWrapper = styled.div`
	position: relative;
	border-radius: 50%;
	background-color: ${(props) => props.color || props.theme.color.global};
	${ImgCover()}
	flex-shrink: 0;
	color: white;

	${(props) =>
		props.size === 'md' &&
		css`
			width: 2.3rem;
			height: 2.3rem;
		`};

	${(props) =>
		props.size === 'lg' &&
		css`
			width: 3.3rem;
			height: 3.3rem;
		`};

	${(props) =>
		props.variant === 'purple' &&
		css`
			background-color: #ccbbff;
			color: ${(props) => props.theme.color.global};
		`};
	${(props) =>
		props.variant === 'orange' &&
		css`
			background-color: #ffddc9;
			color: ${(props) => props.theme.color.global};
		`};
	${(props) =>
		props.variant === 'primary' &&
		css`
			background-color: ${(props) => props.theme.color.primary};
			color: white;
		`};
	${(props) =>
		props.variant === 'dark' &&
		css`
			background-color: ${(props) => props.theme.color.global};
			color: white;
		`};
	${(props) =>
		props.variant === 'muted' &&
		css`
			background-color: ${(props) => props.theme.color.line};
			color: ${(props) => props.theme.color.muted};
		`};
	${(props) =>
		props.variant === 'default' &&
		css`
			border: 1px solid ${(props) => props.theme.color.line};
			background-color: ${(props) => props.theme.color.body};
			color: ${(props) => props.theme.color.muted};
		`};

	> img {
		border-radius: 50%;
		border: 2px solid ${(props) => props.theme.color.global};
		display: block;
	}

	> span {
		${Cover()}
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.1rem;
		text-transform: uppercase;
		font-weight: 600;
		color: inherit;
	}
`
