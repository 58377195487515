import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Error from '@/layout/Error'

export const Error500 = () => {
	const { t } = useTranslation(['errors', 'common'])
	return (
		<Error
			title={t('errors:internalServerError')}
			img={'/assets/img/error-500.svg'}
			actions={<Button to="/dashboard">{t('backToDashboard')}</Button>}
		/>
	)
}
