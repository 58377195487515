import React, { useState } from 'react'
import { motion, Reorder, useDragControls } from 'framer-motion'

import PropTypes from '@/lib/propTypes'

import Icon from '../Icon'
import {
	ListTableAction,
	ListTableBody,
	ListTableCell,
	ListTableDrag,
	ListTableRow,
	ListTableWrapper,
} from './ListTable.styles'

const ListTableItem = ({ columns, row, onClick, clickable }) => {
	const controls = useDragControls()
	const [isDragging, setIsDragging] = useState(false)

	return (
		<Reorder.Item
			as={ListTableRow}
			value={row}
			dragElastic={0.5}
			dragListener={false}
			dragControls={controls}
			clickable={clickable}
			isDragging={isDragging}
			onClick={(e) => onClick(row, e)}
			onDragStart={() => setIsDragging(true)}
			onDragEnd={() => setIsDragging(false)}
		>
			<ListTableCell isButton>
				<ListTableDrag
					as={motion.div}
					key={row.id}
					onPointerDown={(e) => controls.start(e)}
					onClick={(e) => e.stopPropagation()}
				/>
			</ListTableCell>
			{columns.map((cell, cellIndex) => (
				<ListTableCell key={cellIndex} isButton={cell.button} isThumbnail={cell.thumbnail}>
					{typeof cell.cell === 'function' ? cell.cell(row) : cell.selector(row)}
				</ListTableCell>
			))}
		</Reorder.Item>
	)
}

const ListTable = ({
	columns,
	data,
	dragEnabled,
	onReorder,
	addAction,
	onAdd,
	addComponent,
	onRowClicked,
}) => {
	if (!data) {
		return <div>No data...</div>
	}

	if (typeof data !== typeof []) return <div>Wrong data...</div>

	return (
		<ListTableWrapper hasFooter={addAction}>
			<ListTableRow head>
				{dragEnabled && <ListTableCell isButton></ListTableCell>}
				{columns.map((cell, index) => (
					<ListTableCell
						key={`th_${index}`}
						isButton={cell.button}
						isThumbnail={cell.thumbnail}
					>
						{cell.name}
					</ListTableCell>
				))}
			</ListTableRow>
			{dragEnabled && (
				<Reorder.Group as={ListTableBody} axis="y" values={data} onReorder={onReorder}>
					{data.map((row, _rowIndex) => (
						<ListTableItem
							key={row.id}
							columns={columns}
							row={row}
							onClick={onRowClicked}
							clickable={onRowClicked}
						/>
					))}
				</Reorder.Group>
			)}
			{!dragEnabled && (
				<ListTableBody>
					{data.map((row, rowIndex) => (
						<ListTableRow
							key={`tr_${rowIndex}`}
							onClick={(e) => onRowClicked && onRowClicked(row, e)}
							clickable={onRowClicked}
						>
							{columns.map((cell, cellIndex) => (
								<ListTableCell
									key={cellIndex}
									isButton={cell.button}
									isThumbnail={cell.thumbnail}
								>
									{typeof cell.cell === 'function'
										? cell.cell(row)
										: cell.selector(row)}
								</ListTableCell>
							))}
						</ListTableRow>
					))}
				</ListTableBody>
			)}
			{(addAction || addComponent) && (
				<ListTableRow footer>
					<ListTableCell>
						<ListTableAction onClick={onAdd} type="button">
							{addComponent || (
								<React.Fragment>
									<Icon name="plus" variant="square" size="medium"></Icon>{' '}
									{addAction}
								</React.Fragment>
							)}
						</ListTableAction>
					</ListTableCell>
				</ListTableRow>
			)}
		</ListTableWrapper>
	)
}

ListTable.propTypes = {
	selectableRows: PropTypes.bool,
}

export default ListTable
