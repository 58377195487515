import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { useSetUserPassword } from '@/api/videobot'
import FormField from '@/components/FormField'
import FieldText from '@/components/FieldText'

export const SetPasswordModal = ({ onClose, user }) => {
	const { t } = useTranslation(['common', 'user'])
	const navigate = useNavigate()
	const { mutate, isLoading: isSetPasswordLoading } = useSetUserPassword()
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm()

	const ModalHeader = <h3>{t('user:setUserPassword')}</h3>

	const onSubmit = (data) => {
		mutate(
			{ userId: user.id, data: { password: data.password } },
			{
				onSuccess: () => {
					toast.success(t('user:setPasswordSuccess'))
					navigate('/dashboard/users')
					onClose()
				},
				onError: (err) => {
					toast.error(t(err.message))
					onClose()
				},
			},
		)
	}

	const getUserDisplayName = (user) => {
		if (user.firstName || user.lastName) {
			return `${user.firstName || ''} ${user.lastName || ''}`.trim()
		}
		return user.email
	}

	return (
		<Modal header={ModalHeader} onClose={onClose} variant="confirmation">
			<form autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data))}>
				<p className="text-balance">
					<Trans
						i18nKey="user:setUserPasswordDescription"
						values={{ displayName: getUserDisplayName(user) }}
					>
						Do you want to set a new password for &nbsp;
						<strong>{getUserDisplayName(user)}</strong>? This will allow them to log in
						immediately.
					</Trans>
				</p>
				<FormField label="Password">
					<FieldText
						register={register}
						placeholder="Password"
						name="password"
						type="password"
						error={errors.password}
					/>
				</FormField>
				<Button variant="secondary" onClick={onClose} wide>
					{t('cancel')}
				</Button>
				<Button variant="primary" isLoading={isSetPasswordLoading} wide type="submit">
					Set Password
				</Button>
			</form>
		</Modal>
	)
}
