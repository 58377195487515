import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useAtomValue, useSetAtom } from 'jotai'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import ProgressBar from '@/components/ProgressBar'
import PropTypes from '@/lib/propTypes'
import {
	cancelUploadMediaAtom,
	uploadingMediasAtom,
} from '@/features/MediaLibrary/store/media_library.store'

import {
	MediaUploadItemActions,
	MediaUploadItemCheck,
	MediaUploadItemName,
	MediaUploadItemProgress,
	MediaUploadItemWrapper,
	MediaUploadListWrapper,
} from './MediaUploadList.styles'

export const MediaUploadItem = ({ uploadAtom }) => {
	const { t } = useTranslation()
	const uploadingMedia = useAtomValue(uploadAtom)
	const cancelUpload = useSetAtom(cancelUploadMediaAtom)

	const handleCancel = () => {
		cancelUpload(uploadAtom)
	}

	return (
		<MediaUploadItemWrapper uploaded={uploadingMedia.progress >= 1}>
			<MediaUploadItemName>
				<Icon name="video" variant="square" />
				<span>{uploadingMedia.filename}</span>
			</MediaUploadItemName>
			<MediaUploadItemProgress>
				<ProgressBar value={uploadingMedia.progress * 100} />
			</MediaUploadItemProgress>
			<MediaUploadItemActions>
				<Button variant="secondary" size="small" onClick={handleCancel}>
					{t('cancel')}
				</Button>
				<AnimatePresence mode="wait">
					{uploadingMedia.progress >= 1 && (
						<MediaUploadItemCheck key={uploadAtom.toString()}>
							<svg x="0" y="0" viewBox="0 0 32 32">
								<motion.path
									fill="none"
									strokeLinecap="round"
									d="M6,16l7,8L27,8"
									initial={{ strokeDashoffset: 36 }}
									animate={{ strokeDashoffset: 0 }}
								/>
							</svg>
						</MediaUploadItemCheck>
					)}
				</AnimatePresence>
			</MediaUploadItemActions>
		</MediaUploadItemWrapper>
	)
}

MediaUploadItem.propTypes = {
	file: PropTypes.object,
	progress: PropTypes.number,
	uuid: PropTypes.string,
	upload: PropTypes.object,
}

const MediaUploadList = () => {
	const uploadingAtom = useAtomValue(uploadingMediasAtom)

	return (
		<MediaUploadListWrapper>
			{uploadingAtom.map((atom) => (
				<MediaUploadItem uploadAtom={atom} key={atom.toString()} />
			))}
		</MediaUploadListWrapper>
	)
}

MediaUploadList.propTypes = {
	upload: PropTypes.object,
}

export default MediaUploadList
