import { startCase, upperFirst } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import Icon from '@/components/Icon'
import Thumbnail from '@/components/Thumbnail'
import { useCurrentAccount, usePowerUserMode } from '@/modules/session/auth.store'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'

import {
	CardContent,
	CardDetails,
	CardField,
	CardHeader,
	CardOption,
	CardUtils,
	CardWrapper,
} from './Card.styles'

const CardSlide = ({ item, dragEnabled, onEdit, isOverlay }) => {
	const { name, filename, duration, options, posterUrl, id } = item

	const activeAccount = useCurrentAccount()
	const { watch } = useVideobotEditor()
	const theme = watch('theme')
	const { powerUserMode } = usePowerUserMode()

	const buttonBackgroundColor = theme?.backgroundColor || activeAccount?.buttonBackgroundColor
	const buttonTextColor = theme?.textColor || activeAccount?.buttonTextColor

	return (
		<CardWrapper dragEnabled={dragEnabled} background="dark" inverted isOverlay={isOverlay}>
			<CardHeader>
				<Thumbnail size="large" url={posterUrl} fallbackIcon="no-video" />

				{/* Slide */}
				<CardDetails>
					<h5>
						{powerUserMode && `[${id}]`} {name}
					</h5>
					<span>{filename}</span>
					<span>
						{format(
							new Date('January 1, 2000 00:00:00').setSeconds(duration || 0),
							'HH:mm:ss',
						)}
					</span>
				</CardDetails>
				<CardUtils>
					<button onClick={onEdit} type="button">
						<Icon name="edit" />
					</button>
				</CardUtils>
			</CardHeader>
			{options?.length > 0 && (
				<CardContent>
					{options?.map((option) => (
						<CardOption
							key={option.id}
							featured={option.featured}
							buttonBackgroundColor={buttonBackgroundColor}
							buttonTextColor={buttonTextColor}
						>
							{option.name}
						</CardOption>
					))}
				</CardContent>
			)}
		</CardWrapper>
	)
}

const CardRegularForm = ({ item, onEdit }) => {
	const { name, type, fields, email, subject } = item
	const { t } = useTranslation()

	return (
		<CardWrapper background="primary" inverted>
			<CardHeader>
				<Icon name="mail-solid" variant="square" size="medium" color="transparent" />
				<CardDetails>
					<h5>{name}</h5>
					<span>{upperFirst(startCase(type).toLowerCase())}</span>
				</CardDetails>
				<CardUtils>
					<button onClick={onEdit} type="button">
						<Icon name="edit" />
					</button>
				</CardUtils>
			</CardHeader>
			<CardContent>
				<CardField>
					<strong>{t('videobot:formFields.email.label')}</strong>
					{email}
				</CardField>
				<CardField>
					<strong>{t('videobot:formFields.subject.label')}</strong>
					{subject}
				</CardField>
				<CardField>
					<strong>{t('fields')}</strong>
					{fields
						?.filter((o) => o.type !== 'checkbox')
						.map((option) => `${upperFirst(option.label)}${option.required ? '*' : ''}`)
						.join(', ')}
				</CardField>
			</CardContent>
		</CardWrapper>
	)
}

const CardExternalLink = ({ item, onEdit }) => {
	const { name, type, url, newTab, sameTab, embed, linkType, externalLinkType } = item
	const { t } = useTranslation()
	const getTargetLabel = (sameTab, newTab, embed, linkType) => {
		if (linkType !== null) {
			switch (linkType) {
				case 'same_tab':
					return t('videobot:linkFields.target.sameTab')
				case 'new_tab':
					return t('videobot:linkFields.target.newTab')
				case 'embed':
					return t('videobot:linkFields.target.sameTabVideobot')
			}
		}

		if (sameTab) return t('videobot:linkFields.target.sameTab')
		if (newTab) return t('videobot:linkFields.target.newTab')
		if (embed) return t('videobot:linkFields.target.sameTabVideobot')
	}

	return (
		<CardWrapper background="purpleLight">
			<CardHeader>
				<Icon name="external-link" variant="square" size="medium" color="purple" />
				<CardDetails>
					<h5>{name}</h5>
					<span>{upperFirst(startCase(type).toLowerCase())}</span>
				</CardDetails>
				<CardUtils>
					<button onClick={onEdit} type="button">
						<Icon name="edit" />
					</button>
				</CardUtils>
			</CardHeader>
			<CardContent>
				<CardField className="break-words">
					{externalLinkType === 'tel' ? (
						<strong>{t('videobot:linkFields.tel.label')}</strong>
					) : externalLinkType === 'mailto' ? (
						<strong>{t('videobot:linkFields.mailto.label')}</strong>
					) : (
						<strong>{t('videobot:linkFields.url.label')}</strong>
					)}
					{url}
				</CardField>
				<CardField>
					<strong>{t('videobot:linkFields.target.label')}</strong>
					{getTargetLabel(sameTab, newTab, embed, linkType)}
				</CardField>
			</CardContent>
		</CardWrapper>
	)
}

const CardPostMessage = ({ item, onEdit }) => {
	const { name, type, value } = item
	const { t } = useTranslation()

	return (
		<CardWrapper background="purpleLight">
			<CardHeader>
				<Icon name="external-link" variant="square" size="medium" color="purple" />
				<CardDetails>
					<h5>{name}</h5>
					<span>{upperFirst(startCase(type).toLowerCase())}</span>
				</CardDetails>
				<CardUtils>
					<button onClick={onEdit} type="button">
						<Icon name="edit" />
					</button>
				</CardUtils>
			</CardHeader>
			<CardContent>
				<CardField className="break-words">
					<strong>{t('videobot:postMessageFields.message.label')}</strong>
					{value}
				</CardField>
			</CardContent>
		</CardWrapper>
	)
}

const CardCloseVideobot = ({ item, onEdit }) => {
	const { name, type } = item
	const { t } = useTranslation()

	return (
		<CardWrapper background="purpleLight">
			<CardHeader>
				<Icon name="cross" variant="square" size="medium" color="purple" />
				<CardDetails>
					<h5>{name}</h5>
					<span>{upperFirst(startCase(type).toLowerCase())}</span>
				</CardDetails>
				<CardUtils>
					<button onClick={onEdit} type="button">
						<Icon name="edit" />
					</button>
				</CardUtils>
			</CardHeader>
			<CardContent>
				<CardField className="break-words">
					<strong>{t('videobot:closeVideobotFields.name.label')}</strong>
					{name}
				</CardField>
			</CardContent>
		</CardWrapper>
	)
}

const CardAddSlide = ({ onAdd, variant }) => {
	const { t } = useTranslation()

	return (
		<CardWrapper variant={variant} onClick={onAdd} role="button">
			<CardHeader center>
				<Icon name="add-video" size="large" variant="square" />
				<CardDetails>
					<h5>{t('videobot:addSlide')}</h5>
				</CardDetails>
			</CardHeader>
		</CardWrapper>
	)
}

const CardAddAction = ({ variant, addComponent }) => {
	const { t } = useTranslation(['videobot'])

	return (
		<CardWrapper variant={variant}>
			<CardHeader center>
				<Icon name="plus" size="medium" variant="square" />
				<CardDetails>
					<h5>{t('videobot:addAction')}</h5>
				</CardDetails>
			</CardHeader>
			<CardContent>{addComponent}</CardContent>
		</CardWrapper>
	)
}

const Card = (props) => {
	const { variant, item } = props

	if (variant === 'SLIDE') return <CardSlide {...props} />
	if (variant === 'ADD_SLIDE') return <CardAddSlide {...props} />
	if (variant === 'ADD_ACTION') return <CardAddAction {...props} />
	if (item?.type === 'REGULAR_FORM') return <CardRegularForm {...props} />
	if (item?.type === 'EXTERNAL_LINK') return <CardExternalLink {...props} />
	if (item?.type === 'POST_MESSAGE') return <CardPostMessage {...props} />
	if (item?.type === 'CLOSE_VIDEOBOT') return <CardCloseVideobot {...props} />
}

export default Card
