import styled, { css } from 'styled-components'

export const InfoIconWrapper = styled.span`
	display: inline-block;
	padding: 0.1rem;
	vertical-align: middle;
	cursor: pointer;
	> svg {
		fill: ${(props) =>
			props.color ? props.theme.color[props.color] : props.theme.color.global};
		opacity: 0.5;
		width: 0.9rem;
		height: 0.9rem;
		display: block;
	}

	${(props) =>
		props.open &&
		css`
			> svg {
				opacity: 1;
			}
		`};

	${(props) =>
		props.variant === 'square' &&
		css`
			width: 1.5rem;
			height: 1.5rem;
			border: 1px solid ${(props) => props.theme.color.line};
			border-radius: 0.25rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			> svg {
				fill: ${(props) => props.theme.color.global};
				width: 0.8rem;
				height: 0.8rem;
			}
		`};
`

export const InfoIconTooltip = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.md};
	box-shadow: ${(props) => props.theme.shadow.sm};
	padding: 0.25rem 0.5rem;
	font-size: 0.75rem;
	font-weight: 600;
	width: max-content;
	max-width: 14rem;
	text-align: center;

	${(props) =>
		props.wide &&
		css`
			min-width: 10rem;
		`};

	${(props) =>
		props.noWrap &&
		css`
			white-space: nowrap;
		`};

	&:before {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 6px 0px 5px;
		border-color: ${(props) => props.theme.color.body} transparent transparent transparent;
		content: ' ';
	}
`
