import React, { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'

import Badge from '@/components/Badge'
import Popover from '@/components/Popover'
import { useCloneBotById, useUpdateBotById } from '@/api/videobot'
import DataTable from '@/components/DataTable'
import More, { MoreButton } from '@/components/More'
import Thumbnail from '@/components/Thumbnail'
import { useWindowSize } from '@/hooks/useWindowSize'
import PropTypes from '@/lib/propTypes'
import { breakpoints } from '@/lib/theme'
import { BotStatusType } from '@/api/videobot.schemas'
import { DeactivateVideobotModal } from '@/features/Videobot/components/DeactivateVideobotModal/DeactivateVideobotModal'
import { ReactivateVideobotModal } from '@/features/Videobot/components/ReactivateVideobotModal/ReactivateVideobotModal'
import { allBotsKey } from '@/api/videobot.keys'
import { useCurrentUser, usePowerUserMode } from '@/modules/session/auth.store'

import DeleteVideobotModal from '../DeleteVideobotModal'

const VideobotMenu = ({ row, onEditClick, onDeleteClick, onDuplicateClick }) => {
	const { t } = useTranslation(['videobot'])
	const [showDeactivateModal, setShowDeactivateModal] = useState(false)
	const [showReactivateModal, setShowReactivateModal] = useState(false)
	const { mutate, isLoading } = useUpdateBotById()
	const queryClient = useQueryClient()
	const currentUser = useCurrentUser()

	const handleDeactivate = () => {
		mutate(
			{ botId: row.id, data: { status: BotStatusType.Deactivated } },
			{
				onSettled: () => {
					setShowDeactivateModal(false)
					queryClient.invalidateQueries({ queryKey: allBotsKey() })
				},
			},
		)
	}

	const handleReactivate = () => {
		mutate(
			{ botId: row.id, data: { status: BotStatusType.Published } },
			{
				onSettled: () => {
					setShowReactivateModal(false)
					queryClient.invalidateQueries({ queryKey: allBotsKey() })
				},
			},
		)
	}

	const isDeactivated = row.status === BotStatusType.Deactivated

	return (
		<Fragment>
			{showDeactivateModal && (
				<DeactivateVideobotModal
					videobot={row}
					onClose={() => setShowDeactivateModal(false)}
					isLoading={isLoading}
					onSubmit={handleDeactivate}
				/>
			)}
			{showReactivateModal && (
				<ReactivateVideobotModal
					videobot={row}
					onClose={() => setShowReactivateModal(false)}
					isLoading={isLoading}
					onSubmit={handleReactivate}
				/>
			)}
			<More>
				<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
				<MoreButton disabled={isDeactivated} onClick={() => onDuplicateClick(row)}>
					{t('duplicate')}
				</MoreButton>
				{!isDeactivated && (
					<MoreButton
						disabled={isDeactivated}
						onClick={() => setShowDeactivateModal(true)}
						variant="red"
					>
						{t('deactivate')}
					</MoreButton>
				)}
				{isDeactivated && (
					<MoreButton
						disabled={!(currentUser.isSuperuser || currentUser.isCustomerSuccess)}
						onClick={() => setShowReactivateModal(true)}
						variant="green"
					>
						{t('reactivate')}
					</MoreButton>
				)}
				<MoreButton onClick={() => onDeleteClick(row)} variant="red">
					{t('remove')}
				</MoreButton>
			</More>
		</Fragment>
	)
}

VideobotMenu.propTypes = {
	row: PropTypes.object,
	onResetPasswordClick: PropTypes.func,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
}

const VideobotsList = (props) => {
	const { videobots } = props
	const { t } = useTranslation(['account', 'videobot'])
	const navigate = useNavigate()
	const [videobotToDelete, setVideobotToDelete] = useState(false)
	const queryClient = useQueryClient()
	const { search: urlQuery } = useLocation()
	const { powerUserMode } = usePowerUserMode()
	const { mutate: cloneBot } = useCloneBotById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: allBotsKey() })
				toast.success(t('videobot:botCloneSuccess'))
			},
			onError: () => {
				toast.error(t('videobot:botCloneError'))
			},
		},
	})

	const { windowWidth } = useWindowSize()

	const handleEdit = (row) => {
		navigate(`/dashboard/videobots/${row.id}${urlQuery}`)
	}

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: windowWidth > breakpoints.lg,
			button: true,
			cell: (row) => {
				return (
					<VideobotMenu
						row={row}
						onEditClick={() => handleEdit(row)}
						onDuplicateClick={() => cloneBot({ botId: row.id })}
						onDeleteClick={() => setVideobotToDelete(row)}
					/>
				)
			},
		},
		{
			width: '4rem',
			cell: (row) => {
				return <Thumbnail url={row.posterUrl} fallbackIcon="no-video" />
			},
		},
		{
			name: 'ID',
			width: '4rem',
			cell: (row) => {
				return row.id
			},
			omit: !powerUserMode,
		},
		{
			id: 'name',
			name: t('videobot:botFields.name.label'),
			selector: (row) => row.name,
			sortable: true,
			width: '10rem',
			cell: (row) => {
				return (
					<div onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }}>
						<Popover
							toggler={
								<span
									style={{
										maxWidth: '9rem',
										display: 'inline-block',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{row.name}
								</span>
							}
							triggerOnHover
						>
							{row.name}
						</Popover>
					</div>
				)
			},
		},
		{
			id: 'status',
			name: t('videobot:botFields.status.label'),
			selector: (row) => row.status,
			cell: (row) => {
				if (row.status === BotStatusType.Published) {
					return <Badge variant="green">{t(row.status.toLowerCase())}</Badge>
				}
				if (row.status === BotStatusType.Draft) {
					return <Badge variant="default">{t(row.status.toLowerCase())}</Badge>
				}
				if (row.status === BotStatusType.Deactivated) {
					return <Badge variant="red">{t(row.status.toLowerCase())}</Badge>
				}
			},
		},
		{
			id: 'author_id',
			name: t('videobot:botFields.createdBy.label'),
			sortable: true,
			selector: (row) => row.author?.email,
		},
		{
			id: 'id',
			name: t('videobot:botFields.createdAt.label'),
			sortable: true,
			selector: (row) => row.createdAt,
			cell: (row) => {
				return row.createdAt
					? format(new Date(`${row.createdAt}Z`), 'dd/MM/yyyy HH:mm')
					: '-'
			},
		},
		{
			id: 'published_at',
			name: t('videobot:botFields.currentRevision.label'),
			sortable: true,
			selector: (row) => row.publishedAt || row.createdAt,
			cell: (row) => {
				const revisionDate = row.publishedAt || row.createdAt
				return revisionDate ? format(new Date(`${revisionDate}Z`), 'dd/MM/yyyy HH:mm') : '-'
			},
		},
		{
			id: 'privacy',
			name: t('videobot:botFields.privacy.label'),
			sortable: true,
			selector: (row) =>
				row.privacy === 'Public'
					? t('videobot:botFields.privacy.public')
					: t('videobot:botFields.privacy.private'),
		},
		{
			id: 'slideCount',
			name: t('videobot:botFields.slides.label'),
			sortable: false,
			selector: (row) => row.slideCount || '-',
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<VideobotMenu
						row={row}
						onEditClick={handleEdit}
						onDuplicateClick={() => cloneBot({ botId: row.id })}
						onDeleteClick={() => setVideobotToDelete(row)}
					/>
				)
			},
		},
	]

	return (
		<React.Fragment>
			{videobots && (
				<DataTable
					columns={columns}
					data={videobots}
					onRowClicked={handleEdit}
					{...props}
				/>
			)}
			<AnimatePresence>
				{videobotToDelete && (
					<DeleteVideobotModal
						videobot={videobotToDelete}
						onClose={() => setVideobotToDelete(false)}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

VideobotsList.propTypes = {
	videobots: PropTypes.array,
	onDelete: PropTypes.func,
}

VideobotsList.propTypes = {}

export default VideobotsList
