import { useCallback, useContext, useEffect, useState } from 'react'
import {
	UNSAFE_NavigationContext as NavigationContext,
	useLocation,
	useNavigate,
} from 'react-router-dom'

export const useBlocker = (blocker, when = true) => {
	const { navigator } = useContext(NavigationContext)
	const location = useLocation()

	useEffect(() => {
		if (!when) return

		const unblock = navigator.block((nextLocation) => {
			const autoUnblockingTx = {
				...nextLocation,
				retry() {
					unblock()
					nextLocation.retry()
				},
			}

			blocker(autoUnblockingTx)
		})

		return unblock
	}, [navigator, blocker, when, location])
}

const usePrompt = ({ when = true, condition }) => {
	const navigate = useNavigate()
	const [showPrompt, setShowPrompt] = useState(false)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)
	const [nextLocation, setNextLocation] = useState(false)

	const blocker = useCallback(
		(nextLocation) => {
			const path = nextLocation.location.pathname

			if (condition(path)) {
				setShowPrompt(true)
				setNextLocation(nextLocation)
			} else {
				nextLocation.retry()
			}
		},
		[confirmedNavigation],
	)

	const acceptPrompt = useCallback(() => {
		setShowPrompt(false)
		setConfirmedNavigation(true)
	}, [])

	const cancelPrompt = useCallback(() => {
		setShowPrompt(false)
	}, [])

	useEffect(() => {
		if (confirmedNavigation && nextLocation) {
			navigate(nextLocation.location.pathname)
		}
	}, [confirmedNavigation, showPrompt])

	useBlocker(blocker, when)

	return { showPrompt, acceptPrompt, cancelPrompt }
}

export default usePrompt
