import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '../../lib/mixins'
import { ButtonWrapper } from '../Button/Button.styles'

export const AccountLinkIcon = styled.span`
	position: relative;
	width: 2.75rem;
	height: 2.75rem;
	flex-shrink: 0;
	background-color: ${(props) => props.color || props.theme.color.light};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	> svg {
		width: 37%;
		height: 37%;
		fill: currentColor;
	}

	> span {
		${Cover()}
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.1rem;
		text-transform: uppercase;
		font-weight: 600;
	}
`
export const AccountLinkLabel = styled.span`
	flex: 1 1 auto;
	padding: 0 1.25rem;
`

export const AccountLinkWrapper = styled.div`
	${Reset('button')}
	display: block;
	width: 100%;
	padding: 1rem 0;
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	display: flex;
	align-items: center;
	text-align: left;

	${(props) =>
		!props.clickable &&
		css`
			cursor: initial;
		`};

	${(props) =>
		!props.other &&
		css`
			${AccountLinkLabel} {
				font-weight: 700;
			}
		`};

	${(props) =>
		props.other &&
		css`
			${AccountLinkIcon} {
				${TransitionPrimary('background,color,border')}
				background-color: white;
				border: 1px solid ${(props) => props.theme.color.line};
			}

			&:hover {
				${AccountLinkIcon} {
					border-color: ${(props) => props.theme.color.global};
					background-color: ${(props) => props.theme.color.global};
					color: ${(props) => props.theme.color.inverted};
				}
			}
		`};

	${ButtonWrapper} {
		min-width: 5rem;
	}
`
