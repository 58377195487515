import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'

import FormRow from '@/components/FormRow'
import { Row, Col } from '@/components/Grid'
import ListTable from '@/components/ListTable/ListTable'
import PropTypes from '@/lib/propTypes'
import More, { MoreButton } from '@/components/More'
import { breakpoints } from '@/lib/theme'
import Icon from '@/components/Icon'
import Badge from '@/components/Badge'

import AddActionButton from './AddActionButtonModal'
import EditExternaLinkActionPanel from './EditExternalLinkActionPanel'
import EditContactFormActionPanel from './EditContactFormActionPanel'

const RowButtonsMenu = ({ row, onEditClick, onDeleteClick }) => {
	const { t } = useTranslation(['user'])
	return (
		<More>
			<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

const AccountSettingsActionButtons = ({ initialActionButtons, onActionButtonsChange }) => {
	const { t } = useTranslation(['user'])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const initializedActionButtons = initialActionButtons.map((button) => ({
		...button,
		id: nanoid(),
	}))
	const [actionButtons, setActionButtons] = useState(initializedActionButtons)
	const [linkToEdit, setLinkToEdit] = useState(null)
	const [formToEdit, setFormToEdit] = useState(null)

	const handleAddAction = () => {
		setIsModalOpen(true)
	}

	const handleBackClick = () => {
		setLinkToEdit(null)
		setFormToEdit(null)
	}

	const handleNewActionButtonSubmit = (actionData) => {
		if (actionData.type === 'external_link') {
			const newLink = {
				...actionData,
				id: nanoid(),
				url: '',
				style: 'standard',
				target: 'new_tab',
			}
			onActionButtonsChange([...actionButtons, newLink])
			setLinkToEdit(newLink)
		} else if (actionData.type === 'contact_form') {
			const newForm = {
				...actionData,
				id: nanoid(),
				buttonText: '',
				buttonStyle: 'standard',
				title: '',
				subject: '',
				description: '',
				emailTo: '',
				emailBcc: '',
				fields: [],
			}
			onActionButtonsChange([...actionButtons, newForm])
			setFormToEdit(newForm)
		}
		setIsModalOpen(false)
	}

	const handleEditClick = (row) => {
		if (row.type === 'external_link') {
			setLinkToEdit(row)
		} else if (row.type === 'contact_form') {
			setFormToEdit(row)
		}
	}

	const handleSaveEdit = (editedAction) => {
		let updatedActions
		if (
			editedAction.id &&
			actionButtons.some((actionButton) => actionButton.id === editedAction.id)
		) {
			updatedActions = actionButtons.map((action) =>
				action.id === editedAction.id ? editedAction : action,
			)
		} else {
			updatedActions = [...actionButtons, { ...editedAction, id: nanoid() }]
		}
		setActionButtons(updatedActions)
		onActionButtonsChange(updatedActions)

		if (editedAction.type === 'external_link') {
			setLinkToEdit(null)
		} else if (editedAction.type === 'contact_form') {
			setFormToEdit(null)
		}
	}

	const handleDeleteAction = (actionId) => {
		const actionToDelete = actionButtons.find((action) => action.id === actionId)
		const updatedActions = actionButtons.filter((action) => action.id !== actionId)

		setActionButtons(updatedActions)
		onActionButtonsChange(updatedActions)

		if (actionToDelete.type === 'external_link') {
			setLinkToEdit(null)
		} else if (actionToDelete.type === 'contact_form') {
			setFormToEdit(null)
		}
	}

	const handleReorder = (newOrder) => {
		setActionButtons(newOrder)
		onActionButtonsChange(newOrder)
	}

	const buttonColumns = [
		{
			name: t('videobot:slideFields.type.label'),
			selector: (row) => (
				<React.Fragment>
					{row.type === 'external_link' && (
						<React.Fragment>
							<Icon
								variant="square"
								size="small"
								color="purple"
								name="external-link"
							/>
							<span>{row.name}</span>
						</React.Fragment>
					)}
					{row.type === 'contact_form' && (
						<React.Fragment>
							<Icon
								variant="square"
								size="small"
								color="primary"
								inverted
								name="mail-solid"
							/>
							<span> {row.name}</span>
						</React.Fragment>
					)}
				</React.Fragment>
			),
		},
		{
			name: t('videobot:slideFields.target.label'),
			selector: (row) => (row.type === 'external_link' ? row.url : row.emailTo),
		},
		{
			name: t('videobot:slideFields.style.label'),
			selector: (row) => (row.type === 'external_link' ? row.style : row.buttonStyle),
			cell: (row) => {
				const style = row.type === 'external_link' ? row.style : row.buttonStyle
				switch (style) {
					case 'highlight':
						return <Badge variant="primary">{t('highlight')}</Badge>
					case 'standard':
						return <Badge variant="primary-inverted">{t('standard')}</Badge>
					default:
						return <span>{style}</span>
				}
			},
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<RowButtonsMenu
						row={row}
						onEditClick={handleEditClick}
						onDeleteClick={() => handleDeleteAction(row.id)}
					/>
				)
			},
		},
	]

	return (
		<FormRow
			title={t('account:group.actionButtons.label')}
			description={t('account:group.actionButtons.description')}
		>
			<Row>
				<Col sm={12} xxs={12}>
					<ListTable
						dragEnabled
						columns={buttonColumns}
						data={actionButtons}
						addAction={t('videobot:addButton')}
						onAdd={handleAddAction}
						onReorder={handleReorder}
					/>
					{linkToEdit && (
						<EditExternaLinkActionPanel
							linkData={linkToEdit}
							onSave={handleSaveEdit}
							onDelete={handleDeleteAction}
							onBack={handleBackClick}
						/>
					)}
					{formToEdit && (
						<EditContactFormActionPanel
							formData={formToEdit}
							onSave={handleSaveEdit}
							onDelete={handleDeleteAction}
							onBack={handleBackClick}
						/>
					)}
					{isModalOpen && (
						<AddActionButton
							type="button"
							show={isModalOpen}
							onClose={() => setIsModalOpen(false)}
							onSubmit={handleNewActionButtonSubmit}
						/>
					)}
				</Col>
			</Row>
		</FormRow>
	)
}

AccountSettingsActionButtons.propTypes = {
	initialActionButtons: PropTypes.array,
	onActionButtonsChange: PropTypes.func.isRequired,
}

export default AccountSettingsActionButtons
