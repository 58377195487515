import styled, { css, keyframes } from 'styled-components'

import { Cover } from '../../lib/mixins'

export const LoaderWrapper = styled.div`
	${Cover()}
	background-color: rgba(255,255,255, 0.85);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;

	${(props) =>
		props.fixed &&
		css`
			position: fixed;
			height: 100vh;
			z-index: ${(props) => props.theme.layer.header - 5};
		`};

	${(props) =>
		props.transparent &&
		css`
			background-color: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(10px);
		`};

	${(props) =>
		props.noOverlay &&
		css`
			background-color: transparent;
		`};
`

export const LoaderOuter = styled.div``

const loadingBar = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300%);
  }
`
export const LoaderBar = styled.div`
	position: fixed;
	top: 0;
	height: 3px;
	left: -50vw;
	width: 50vw;
	background: ${(props) => props.theme.color.secondary};
	z-index: ${(props) => props.theme.layer.appLoader};
	animation: linear ${loadingBar} 1.5s infinite;
`

const loaderRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loaderTurn = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`

export const LoaderObject = styled.svg`
	stroke: ${(props) => props.theme.color.primary};
	animation: ${loaderRotate} 1.4s linear infinite;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	display: block;
`

export const LoaderCircle = styled.circle`
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke-width: 2;
	transform-origin: center;
	animation: ${loaderTurn} 1.4s ease-in-out infinite;
`
