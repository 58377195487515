import styled, { css } from 'styled-components'

import media from '@/lib/media'
import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

export const IconSquare = styled.span`
	position: relative;
	${Reset('button')}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: ${(props) => props.theme.radius.sm};
	${TransitionPrimary('background')}
	cursor: inherit;
	flex-shrink: 0;

	&:before {
		${Cover()}
		background-color: ${(props) => props.theme.color[props.color]};
		border-radius: ${(props) => props.theme.radius.sm};
		${TransitionPrimary()}
	}

	${(props) =>
		props.hoverable &&
		css`
			cursor: pointer;

			&:before {
				transform: scale(0.65);
				opacity: 0;
			}

			&:hover {
				&:before {
					transform: scale(1);
					opacity: 1;
				}
			}
		`};

	${(props) =>
		props.color === 'transparent' &&
		css`
			&:before {
				background-color: rgba(255, 255, 255, 0.15);
			}
		`};

	${(props) =>
		props.size === 'large' &&
		css`
			width: 4rem;
			height: 4rem;
			min-width: 4rem;
		`}

	${(props) =>
		props.size === 'default' &&
		css`
			${media('>md')} {
				width: ${(props) => props.theme.size.thumbnail.desktop};
				height: ${(props) => props.theme.size.thumbnail.desktop};
				min-width: ${(props) => props.theme.size.thumbnail.desktop};
			}

			${media('<=md')} {
				width: ${(props) => props.theme.size.thumbnail.mobile};
				height: ${(props) => props.theme.size.thumbnail.mobile};
				min-width: ${(props) => props.theme.size.thumbnail.mobile};
			}
		`}

  svg {
		position: relative;
		${(props) =>
			props.inverted &&
			css`
				fill: ${(props) => props.theme.color.inverted};
			`};
		${(props) =>
			!props.inverted &&
			css`
				fill: ${(props) => props.theme.color.global};
			`};
		width: 32%;
		height: 32%;
		display: block;
		${TransitionPrimary('fill')}
	}

	${(props) =>
		props.size === 'small' &&
		css`
			border-radius: ${(props) => props.theme.radius.md};
			width: 1.7rem;
			height: 1.7rem;
			min-width: 1rem;

			&:before {
				border-radius: ${(props) => props.theme.radius.md};
			}

			svg {
				width: 55%;
				height: 55%;
			}
		`};

	${(props) =>
		props.size === 'medium' &&
		css`
			border-radius: ${(props) => props.theme.radius.md};
			width: 2.3rem;
			height: 2.3rem;
			min-width: 1rem;

			&:before {
				border-radius: ${(props) => props.theme.radius.md};
			}

			svg {
				width: 45%;
				height: 45%;
			}
		`};
`
export const IconRound = styled.span`
	position: relative;
	${Reset('button')}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	${TransitionPrimary('background')}
	cursor: inherit;
	flex-shrink: 0;

	&:before {
		${Cover()}
		background-color: ${(props) => props.theme.color[props.color]};
		border-radius: 50%;
		${TransitionPrimary()}
	}

	${(props) =>
		props.hoverable &&
		css`
			cursor: pointer;

			&:before {
				transform: scale(0.65);
				opacity: 0;
			}

			&:hover {
				&:before {
					transform: scale(1);
					opacity: 1;
				}
			}
		`};

	${(props) =>
		props.color === 'transparent' &&
		css`
			&:before {
				background-color: rgba(255, 255, 255, 0.15);
			}
		`};

	${(props) =>
		props.size === 'large' &&
		css`
			width: 4rem;
			height: 4rem;
			min-width: 4rem;
		`}

	${(props) =>
		props.size === 'default' &&
		css`
			${media('>md')} {
				width: ${(props) => props.theme.size.thumbnail.desktop};
				height: ${(props) => props.theme.size.thumbnail.desktop};
				min-width: ${(props) => props.theme.size.thumbnail.desktop};
			}

			${media('<=md')} {
				width: ${(props) => props.theme.size.thumbnail.mobile};
				height: ${(props) => props.theme.size.thumbnail.mobile};
				min-width: ${(props) => props.theme.size.thumbnail.mobile};
			}
		`}

  svg {
		position: relative;
		${(props) =>
			props.inverted &&
			css`
				fill: ${(props) => props.theme.color.inverted};
			`};
		${(props) =>
			!props.inverted &&
			css`
				fill: ${(props) => props.theme.color.global};
			`};
		width: 32%;
		height: 32%;
		display: block;
		${TransitionPrimary('fill')}
	}

	${(props) =>
		props.size === 'small' &&
		css`
			border-radius: 50%;
			width: 1.7rem;
			height: 1.7rem;
			min-width: 1rem;

			&:before {
				border-radius: 50%;
			}

			svg {
				width: 55%;
				height: 55%;
			}
		`};

	${(props) =>
		props.size === 'medium' &&
		css`
			border-radius: 50%;
			width: 2.3rem;
			height: 2.3rem;
			min-width: 1rem;

			&:before {
				border-radius: 50%;
			}

			svg {
				width: 55%;
				height: 55%;
			}
		`};
`
