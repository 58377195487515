import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Auth } from 'aws-amplify'

import Alert from '@/components/Alert'
import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'

import AuthForm from '../AuthForm'

const ResetPasswordForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})
	const { t } = useTranslation(['auth', 'common', 'account', 'errors'])
	const [state, setState] = useState('initial')
	const isLoading = state === 'loading'
	const isSuccess = state === 'success'

	const submitForm = async (data) => {
		setState('loading')
		try {
			await Auth.forgotPassword(data.email)
			setState('success')
		} catch (e) {
			toast.error(t('errors:errorOccured'))
			setState('initial')
			throw e
		}
	}

	const ResetPasswordHeader = () => (
		<React.Fragment>
			<h1>{t('auth:forgotPassword')}</h1>
			<p>{t('auth:forgotPasswordDescription')}</p>
		</React.Fragment>
	)

	const ResetPasswordFooter = () => (
		<div className="text-muted">
			<p>
				{t('backTo')} <Link to="/login">{t('auth:logIn')}</Link>
			</p>
			<p>
				{t('auth:enterNewPassowrd')} <Link to="/new-password">{t('auth:newPassword')}</Link>
			</p>
		</div>
	)

	return (
		<AuthForm header={<ResetPasswordHeader />} footer={<ResetPasswordFooter />}>
			{!isSuccess && (
				<form onSubmit={handleSubmit(submitForm)}>
					<FormField label={t('account:fields.email.label')}>
						<FieldText
							register={register}
							placeholder={t('account:fields.email.placeholder')}
							name="email"
							type="email"
							error={errors.email}
							required
							size="large"
						/>
					</FormField>
					<Button block isLoading={isLoading}>
						{t('auth:resetPassword')}
					</Button>
				</form>
			)}
			{isSuccess && (
				<React.Fragment>
					<Alert variant="green" icon="exclamation-mark">
						{t('auth:forgotPasswordEmailSent')}
					</Alert>
				</React.Fragment>
			)}
		</AuthForm>
	)
}
export default ResetPasswordForm
