import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary, TransitionSecondary } from '../../../../lib/mixins'

export const TeamSelectWrapper = styled.div`
	max-width: 100%;
`

export const TeamSelectToggler = styled.button`
	position: relative;
	${Reset('button')}
	display: flex;
	height: 2.5rem;
	align-items: center;
	font-family: ${(props) => props.theme.font.secondary};
	font-weight: 600;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;

	> span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	> svg:first-child {
		margin-right: 1rem;
		width: 1.25rem;
		display: block;
		fill: currentColor;
		flex-shrink: 0;
	}

	> svg:last-child {
		margin-left: 0.75rem;
		opacity: 0.5;
		fill: currentColor;
	}

	&:hover {
		> svg:last-child {
			opacity: 1;
		}
	}

	${(props) =>
		props.open &&
		css`
			> svg:last-child {
				transform: rotate(180deg);
				opacity: 1;
			}
		`};
`

/* Dropdown */

export const TeamSelectDropdownBody = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.lg};
	box-shadow: ${(props) => props.theme.shadow.sm};
	padding: 0.5rem 0.25rem;
	opacity: 0;
	transform: translateY(0.5rem);
	max-height: calc(100vh - 5rem);
	overflow-y: auto;

	${TransitionPrimary('opacity, transform, visibility')}
`

export const TeamSelectDropdownWrapper = styled.div`
	width: 22rem;

	${(props) =>
		props.open &&
		css`
			${TeamSelectDropdownBody} {
				opacity: 1;
				transform: translateY(0);
			}
		`};
`

export const TeamSelectDropdownItem = styled.button`
	${Reset('button')}
	display: block;
	width: calc(100% + 0.5rem);
	text-align: left;
	padding: 0.5rem 1.75rem;
	${TransitionPrimary('background')}
	margin: 0 -0.25rem;
	font-size: 0.95rem;

	&:hover {
		background-color: ${(props) => props.theme.color.light};
	}
	${(props) =>
		props.variant === 'red' &&
		css`
			color: ${(props) => props.theme.color.red};
		`};

	${(props) =>
		props.isActive &&
		css`
			color: ${(props) => props.theme.color.primary};
			background-color: ${(props) => props.theme.color.light};
			font-weight: 600;
		`};
`

/* Popup */
export const TeamSelectPopupBody = styled.div`
	position: relative;
	z-index: 2;
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.lg} ${(props) => props.theme.radius.lg} 0 0;
	box-shadow: ${(props) => props.theme.shadow.sm};
	transform: translateY(0.5rem);
	margin-top: auto;
	height: 15rem;
	min-height: 60vh;
	max-height: 90vh;
	width: 100%;

	&:after {
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: 0;
		height: 20vh;
		background-color: ${(props) => props.theme.color.body};
		content: ' ';
	}
`

export const TeamSelectPopupInner = styled.div`
	max-height: 100%;
	overflow: auto;
	padding: 1rem 0.25rem 1.5rem;
	margin-top: -1rem;
	mask-image: linear-gradient(to bottom, transparent, black 1rem);
`

export const TeamSelectPopupBackdrop = styled.div`
	${Cover()}
	background: #000329;
	opacity: 0;
	${TransitionSecondary('opacity')}
`

export const TeamSelectPopupWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	bottom: 0;
	display: flex;
	align-items: flex-end;
	z-index: 200;
	overflow: hidden;
	visibility: hidden;
	${TransitionSecondary(' visibility')}

	${(props) =>
		props.open &&
		css`
			visibility: visible;
			opacity: 1;
			${TeamSelectPopupBackdrop} {
				opacity: 0.4;
			}
		`};
`

export const TeamSelectPopupDragger = styled.div`
	position: relative;
	height: 2rem;
	display: flex;
	justify-content: center;
	padding-top: 0.4rem;

	&:before {
		height: 4px;
		width: 60px;
		background-color: ${(props) => props.theme.color.line};
		border-radius: 2px;
		content: ' ';
	}
`

export const TeamSelectPopupItem = styled.button`
	${Reset('button')}
	display: block;
	width: calc(100% + 0.5rem);
	text-align: left;
	padding: 0.75rem 1.75rem;
	${TransitionPrimary('background')}
	margin: 0 -0.25rem;
	font-size: 0.95rem;

	&:hover {
		background-color: ${(props) => props.theme.color.light};
	}
	${(props) =>
		props.variant === 'red' &&
		css`
			color: ${(props) => props.theme.color.red};
		`};
`
