import { RoleType } from '@/api/videobot.schemas'
import ShareVideobot from '@/features/Videobot/components/ShareVideobot'
import { RoleCheck } from '@/modules/session/auth.component'

const VideobotShareContents = () => {
	return <ShareVideobot />
}

const VideobotShare = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Owner,
				RoleType.Super_Admin,
				RoleType.Admin,
				RoleType.Reseller,
				RoleType.Member,
			]}
		>
			<VideobotShareContents />
		</RoleCheck>
	)
}

export default VideobotShare
