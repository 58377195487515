import type { FC } from 'react'
import { useNavigate } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import type { ErrorBoundaryProps, FallbackRender } from '@sentry/react'

import Alert from '@/components/Alert'
import Button from '@/components/Button'

const AnyButton = Button as any // TODO: Fix this

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
	const { t } = useTranslation(['common', 'errors'])
	const navigate = useNavigate()

	const fallback: FallbackRender = ({ error, componentStack, resetError }) => {
		const handleCopy = () => navigator.clipboard.writeText(`${error}\n${componentStack}`)
		return (
			<div className="static w-full overflow-y-auto">
				<div className="flex min-h-screen items-center justify-center bg-light p-16">
					<div className="shadow-2xl6 flex max-w-3xl flex-col items-center justify-center rounded-lg border border-line bg-body px-16 py-8">
						<p className="mb-0 text-6xl text-muted">:(</p>
						<p className="mb-0 mt-4 text-xl font-bold text-global">
							{t('errors:somethingWentWrong')}
						</p>
						<p className="text-center text-base text-global">
							<Trans i18nKey="errors:contactSupport">
								If the error persists, please contact{' '}
								<a href="mailto:support@videobot.com">support@videobot.com</a> and
								include the error message and details shown below.
							</Trans>
						</p>
						<Alert icon="exclamation-mark" variant="red">
							{error.toString()}
						</Alert>
						<code className="max-h-72 space-x-4 overflow-y-auto whitespace-pre-line rounded-lg bg-light p-4 pl-6 text-left text-sm">
							{componentStack}
						</code>
						<div className="mt-8">
							<AnyButton variant="secondary" onClick={handleCopy}>
								{t('common:copyClipboard')}
							</AnyButton>
							<AnyButton
								variant="primary"
								onClick={() => {
									navigate('/dashboard')
									resetError()
								}}
							>
								{t('common:reload')}
							</AnyButton>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
}
