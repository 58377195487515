import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Panel from '@/components/Panel'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'

import useEditorFormPrompt from '../../hooks/useEditorPrompt'
import ExitEditorModal from '../ExitEditorModal'

const EditCloseVideobotActions = ({ onSave, onDelete }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onDelete} variant="secondary">
				{t('delete')}
			</Button>
			<Button onClick={onSave}>{t('save')}</Button>
		</React.Fragment>
	)
}

export const EditCloseVideobotPanel = () => {
	const { actionId } = useParams()

	const { t } = useTranslation()
	const {
		watch: watchEditor,
		actions: { deleteAction, updateAction },
	} = useVideobotEditor()
	const actions = watchEditor('actions')
	const actionData = actions.find((o) => o.id.toString() === actionId.toString())
	const navigate = useNavigate()
	const formRef = useRef()
	const {
		register,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		reset,
		watch,
	} = useForm({
		mode: 'onBlur',
		values: actionData,
	})
	const currentAction = watch()

	const { isSubmitted, setIsSubmitted, showPrompt, acceptPrompt, cancelPrompt } =
		useEditorFormPrompt({
			message: t('leavePageQuestion'),
			when: isDirty,
			condition: (url) => url.match(/.*videobots\/\d.\/content\//g),
		})

	const handleSave = (data) => {
		updateAction(actionId, data)
		reset()
		setIsSubmitted(true)
	}

	const handleDelete = () => {
		deleteAction(actionId)
		navigate('../')
	}

	useEffect(() => {
		if (isSubmitted) {
			navigate('../')
		}
	}, [isSubmitted, navigate])

	return (
		<Panel
			title={t('videobot:editCloseVideobot')}
			onBack={() => navigate('../')}
			footer={
				<EditCloseVideobotActions
					onSave={() =>
						formRef.current?.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
					}
					onDelete={() => handleDelete()}
					saveDisabled={!isValid || !isDirty}
				/>
			}
		>
			{currentAction && (
				<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
					{/* Title */}
					<FormField label={t('videobot:closeVideobotFields.name.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:closeVideobotFields.name.placeholder')}
							name="name"
							error={errors.name}
							defaultValue={currentAction?.name}
							required
						/>
					</FormField>
				</form>
			)}
			{showPrompt && (
				<ExitEditorModal
					onSave={() => {
						formRef.current.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
						acceptPrompt()
					}}
					onExit={acceptPrompt}
					onClose={cancelPrompt}
				/>
			)}
		</Panel>
	)
}
