import styled from 'styled-components'

export const LazyImageWrapper = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	transition: opacity 0.2s;
	opacity: ${(props) => (props.loaded ? 1 : 0)};

	img {
		display: block;
	}
`
