import { useForm } from 'react-hook-form'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

import View from '@/layout/View'
import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'

// TODO: Fix this
const AnyButton = Button as any

interface FormData {
	oldPassword: string
	newPassword: string
	confirmPassword: string
}

// TODO: Refactor this
export const ChangePaswordView = () => {
	const { register, handleSubmit, formState, setError, reset } = useForm<FormData>()
	const changePasswordHandler = async (data: FormData) => {
		if (data.newPassword !== data.confirmPassword) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords do not match',
			})
			return
		}

		try {
			const user = await Auth.currentAuthenticatedUser()
			await Auth.changePassword(user, data.oldPassword, data.newPassword)
			toast.success('Password changed successfully')
			reset()
		} catch (e) {
			toast.error('Failed to change password')
			if (e instanceof Error) {
				setError('newPassword', {
					type: 'manual',
					message: e.message,
				})
			} else {
				throw e
			}
		}
	}

	return (
		<View header title="Change password" mobileTopBar isLoading={false}>
			<div className="flex max-w-xl flex-col gap-4">
				<div className="hyphens-auto break-words">
					<p className="text-lg">
						<span className="block text-muted">
							Password should be at least 8 characters length, includes at least 1
							number, 1 uppercase character.
						</span>
					</p>

					<form onSubmit={handleSubmit(changePasswordHandler)}>
						<FormField label="Old password" required>
							<FieldText
								placeholder="Password..."
								name="oldPassword"
								type="password"
								required
								register={register}
								error={formState.errors.oldPassword}
							/>
						</FormField>

						<FormField label="New password" required>
							<FieldText
								placeholder="Password..."
								name="newPassword"
								type="password"
								required
								register={register}
								error={formState.errors.newPassword}
							/>
						</FormField>

						<FormField label="Confirm new password" required>
							<FieldText
								placeholder="Password..."
								name="confirmPassword"
								type="password"
								required
								register={register}
								error={formState.errors.confirmPassword}
							/>
						</FormField>

						<div>
							<AnyButton type="submit" loading={formState.isSubmitting}>
								Confirm
							</AnyButton>
						</div>
					</form>
				</div>
			</div>
		</View>
	)
}
