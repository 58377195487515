import React from 'react'
import { CustomPicker, EditableInput, Hue, Saturation, Alpha } from '@hello-pangea/color-picker'

import PropTypes from '@/lib/propTypes'
import { hexWithAlpha } from '@/lib/utils'

import {
	FieldColorDropdownContent,
	FieldColorInput,
	FieldColorPointer,
	FieldColorPreview,
	FieldColorSaturation,
	FieldColorSlider,
} from './FieldColor.styles'

const FieldColorPickerContents = (props) => {
	const { hex, onChange, rgb, withAlpha = false } = props

	const hexAlpha = withAlpha ? hexWithAlpha(hex, rgb.a) : hex

	const handleChange = (color, _e) => {
		onChange(color)
	}

	return (
		<React.Fragment>
			<FieldColorSaturation>
				<Saturation
					{...props}
					onChange={handleChange}
					pointer={<FieldColorPointer color={hexAlpha} />}
				/>
			</FieldColorSaturation>
			<FieldColorDropdownContent>
				<FieldColorSlider>
					<Hue
						{...props}
						onChange={handleChange}
						pointer={() => <FieldColorPointer color={hexAlpha} />}
					/>
				</FieldColorSlider>
				{withAlpha && (
					<FieldColorSlider>
						<Alpha
							{...props}
							onChange={handleChange}
							pointer={() => <FieldColorPointer color={hexAlpha} />}
						/>
					</FieldColorSlider>
				)}
				<FieldColorInput>
					<FieldColorPreview color={hexAlpha}></FieldColorPreview>
					<EditableInput value={hexAlpha} onChange={handleChange} />
				</FieldColorInput>
			</FieldColorDropdownContent>
		</React.Fragment>
	)
}

FieldColorPickerContents.propTypes = {
	hex: PropTypes.string,
	rgb: PropTypes.object,
	color: PropTypes.string,
	setValue: PropTypes.func,
	name: PropTypes.string,
	onChange: PropTypes.func,
}

const FieldColorPickerContentsWithAlpha = (props) => {
	return <FieldColorPickerContents {...props} withAlpha />
}

const FieldColorPicker = CustomPicker(FieldColorPickerContents)
export const FieldColorPickerWithAlpha = CustomPicker(FieldColorPickerContentsWithAlpha)

export default FieldColorPicker
