import PropTypes from '@/lib/propTypes'

import {
	ProgressBarFill,
	ProgressBarIndicator,
	ProgressBarValue,
	ProgressBarWrapper,
} from './ProgressBar.styles'

const ProgressBar = (props) => {
	const { value, variant = 'default' } = props
	const roundedValue = Math.ceil(value)

	return (
		<ProgressBarWrapper variant={variant}>
			<ProgressBarIndicator>
				<ProgressBarFill width={value}></ProgressBarFill>
			</ProgressBarIndicator>
			<ProgressBarValue>{roundedValue}%</ProgressBarValue>
		</ProgressBarWrapper>
	)
}

ProgressBar.propTypes = {
	value: PropTypes.number,
	variant: PropTypes.oneOf(['default', 'purple', 'orange', 'primary', 'dark', 'muted', 'green']),
}

export default ProgressBar
