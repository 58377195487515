import styled from 'styled-components'

import media from '../../lib/media'
import { LinkUnderlineOnHover } from '../../lib/mixins'
import { FormFieldWrapper } from '../FormField/FormField.styles'
import { Row } from '../Grid'

export const FormRowWrapper = styled.div`
	position: relative;
	display: flex;
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	${media('>md')} {
		margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
	}

	${media('<=md')} {
		flex-wrap: wrap;
		margin-bottom: ${(props) => props.theme.spacing.mobile.sm};
	}
`

export const FormRowHeader = styled.div`
	${media('>md')} {
		flex: 0 0 30%;
		margin-right: 3rem;
		margin-bottom: 2rem;
	}
	${media('<=md')} {
		flex: 0 0 100%;
		margin-bottom: 1.25rem;
	}

	h5 {
		font-size: 0.95rem;
		margin-bottom: 0;
		font-weight: 600;
	}

	div {
		margin-bottom: 0;
		margin-top: 0.25rem;
		font-size: 0.95rem;
		color: ${(props) => props.theme.color.muted};

		a {
			display: block;
			/* ${LinkUnderlineOnHover()} */
			color: ${(props) => props.theme.color.primary};
			/* font-weight: 600; */
		}
	}
`
export const FormRowContent = styled.div`
	flex: 1 1 auto;
	margin-bottom: 2rem;
	${media('<=md')} {
		margin-bottom: 1.35rem;
	}

	${FormFieldWrapper} {
		&:last-child {
			${media('>sm')} {
				padding-bottom: 0;
			}
		}
	}

	${Row} {
		&:not(:last-child) {
			${media('>sm')} {
				padding-bottom: 1.25rem;
			}
			${media('<=sm')} {
				padding-bottom: 1rem;
			}
		}
	}

	> * > *:last-child {
		${FormFieldWrapper} {
			&:last-child {
				${media('<=sm')} {
					padding-bottom: 0;
				}
			}
		}
	}
`
