import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { Slider as Widget } from '@videobotcom/embed'
import { keyBy } from 'lodash-es'

import { parseCss } from '@/lib/utils'
import View from '@/layout/View'
import { EditWidgetSettings } from '@/features/widget/edit_widget_settings.component'
import {
	getReadAllWidgetsQueryKey,
	useReadAllBotEmbeds,
	useReadAllBots,
	useReadWidgetById,
	useUpdateWidgetById,
} from '@/api/videobot'
import Error from '@/layout/Error'
import Loader from '@/components/Loader'
import Button from '@/components/Button'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { BotStatusType, WidgetContentType } from '@/api/videobot.schemas'
import { ShareWidgetModal } from '@/features/widget/share_widget_model.component'
import More, { MoreButton } from '@/components/More'
import Icon from '@/components/Icon'

import '@videobotcom/embed/dist/frame-styles/widget.scss'
import './widget_editor.scss'

const PREVIEW_SCREEN = {
	desktop: 1200,
	tablet: 992,
	phone: 390, // fake scale on phone
}

const dataToFormData = (data, allBots) => {
	const botByIds = keyBy(allBots, 'id')
	const formData = {
		...data,
		selectedBotIds: data.selectedBotIds.filter((id) => !!botByIds[id]),
	}
	for (const tagId of data.selectedTagIds) {
		formData[`tag_${tagId}`] = true
	}
	return formData
}

const tagIdsFromFormData = (formData) => {
	const tagIds = []
	for (const key in formData) {
		const match = key.match(/^tag_(\d+)$/)
		if (match && formData[key]) {
			tagIds.push(Number(match[1]))
		}
	}
	return tagIds.length ? tagIds : [0]
}

const EditWidgetViewContent = forwardRef(({ data, mutate, name, allBots }, ref) => {
	const form = useForm({
		defaultValues: dataToFormData(data, allBots),
	})
	const account = useCurrentAccount()
	const formData = form.watch()
	const { data: embedData } = useReadAllBotEmbeds({
		account_id: account.id,
		tag_ids:
			formData.contentType === WidgetContentType.ByTags
				? tagIdsFromFormData(formData).join(',')
				: null,
		size: 100,
	})
	const [isPlaying, setIsPlaying] = useState(false)
	const [playSlideIndex, setPlaySlideIndex] = useState(0)
	const [previewScreen, setPreviewScreen] = useState('desktop')

	const bots = useMemo(() => {
		if (!embedData) {
			return []
		}
		if (formData.contentType === WidgetContentType.All) {
			return embedData.records.filter((embed) => embed.slides.length > 0)
		}
		if (formData.contentType === WidgetContentType.ByTags) {
			return embedData.records.filter((embed) => embed.slides.length > 0)
		}
		return formData.selectedBotIds
			.map((id) => embedData.records.find((embed) => embed.id === `${id}`))
			.filter(Boolean)
			.filter((embed) => embed.slides.length > 0)
	}, [embedData, formData.contentType, formData.selectedBotIds])

	const widget = {
		...formData,
		bots,
	}

	const setValue = function () {
		// eslint-disable-next-line prefer-rest-params
		form.setValue(...arguments)
	}

	const onSlideClick = (botId) => {
		setIsPlaying(true)
		const index = bots.findIndex((bot) => bot.id === botId)
		setPlaySlideIndex(index)
	}

	useImperativeHandle(ref, () => ({
		submit: () =>
			form.handleSubmit(async (data) => {
				// FIXME: Use Toggle Group for tags instead
				const tagIds = []
				for (const key in data) {
					const match = key.match(/^tag_(\d+)$/)
					if (match && data[key]) {
						tagIds.push(Number(match[1]))
					}
				}

				data.appearanceSettings.styles = await parseCss(data.appearanceSettings.stylesRaw)
				data.appearanceSettings.thumbnailSize =
					Number(data.appearanceSettings.thumbnailSize) || null

				mutate({
					widgetId: data.id,
					data: {
						...data,
						status: BotStatusType.Published,
						selectedTagIds: tagIds,
						name,
					},
				})
			})(),
	}))

	return (
		<FormProvider {...form} setValue={setValue}>
			<form
				className="flex w-full pb-[72px] pt-[56px] xl:pb-0 xl:pt-0"
				onSubmit={(e) => e.preventDefault()}
			>
				<section className="w-full overflow-auto bg-body xl:w-112">
					<EditWidgetSettings />
				</section>
				<section className="relative ml-4 mr-4 hidden h-full flex-1 justify-center overflow-hidden xl:flex">
					{embedData && (
						<div className="relative left-0 top-0 flex h-full w-full flex-col items-center">
							<div className="mt-8 flex w-full max-w-[1440px]">
								<RadioGroup.Root
									type="single"
									value={previewScreen}
									onValueChange={(value) => setPreviewScreen(value)}
									className="grid grid-cols-3 items-center justify-center gap-2 rounded-lg border border-line bg-muted-inverted px-4 py-1"
								>
									<RadioGroup.Item
										className="flex h-full items-center justify-center rounded px-3 py-2 opacity-50 data-[state=checked]:bg-body data-[state=checked]:opacity-100"
										value="desktop"
									>
										<img src="/assets/icons/desktop_windows.svg" />
									</RadioGroup.Item>
									<RadioGroup.Item
										className="flex h-full items-center justify-center rounded px-3 py-2 opacity-50 data-[state=checked]:bg-body data-[state=checked]:opacity-100"
										value="tablet"
									>
										<img src="/assets/icons/tablet.svg" />
									</RadioGroup.Item>
									<RadioGroup.Item
										className="flex h-full items-center justify-center rounded px-3 py-2 opacity-50 data-[state=checked]:bg-body data-[state=checked]:opacity-100"
										value="phone"
									>
										<img src="/assets/icons/phone_android.svg" />
									</RadioGroup.Item>
								</RadioGroup.Root>
							</div>
							<div
								className={`mb-4 mt-8 flex max-w-[1440px] overflow-scroll rounded bg-body p-4 ${
									previewScreen === 'desktop' ? 'w-full' : ''
								}`}
							>
								<div
									className="w-[--preview-size]"
									style={{
										'--preview-size': `${PREVIEW_SCREEN[previewScreen]}px`,
									}}
								>
									<Widget
										key={`${data.id}-${bots.length}`}
										isPlayer={false}
										uuid={data.id}
										requestUrl={location.origin}
										widget={widget}
										onSlideClick={onSlideClick}
										breakpointsBase="container"
										forceScreenWidth={PREVIEW_SCREEN[previewScreen]}
										initialScreenWidth={PREVIEW_SCREEN[previewScreen]}
									/>
								</div>
							</div>
							{isPlaying && (
								<div className="absolute left-0 top-0 z-10 h-full w-full">
									<Widget
										key={`${data.id}-${bots.length}-player`}
										isPlayer
										uuid={data.id}
										requestUrl={location.origin}
										widget={widget}
										onClose={() => setIsPlaying(false)}
										slideIndex={playSlideIndex}
										// FIXME: Hax
										initialScreenWidth={PREVIEW_SCREEN.desktop}
										disabledAnalytics
									/>
								</div>
							)}
						</div>
					)}
					{!embedData && <Loader />}
				</section>
			</form>
		</FormProvider>
	)
})
EditWidgetViewContent.displayName = 'EditWidgetViewContent'

const SubActionsTopBarToggler = forwardRef((props, ref) => (
	<Button variant="secondary" iconOnly ref={ref} {...props}>
		<span className="rotate-90">
			<Icon name="dots"></Icon>
		</span>
	</Button>
))

export const EditWidgetView = () => {
	const { id } = useParams()
	const { id: accountId } = useCurrentAccount()
	const [t] = useTranslation(['widget'])
	const { data, isError } = useReadWidgetById(id)
	const formRef = useRef()
	const queryClient = useQueryClient()
	const [isModalActive, setIsModalActive] = useState(false)
	const [isPlayUrlModalActive, setIsPlayUrlModalActive] = useState(false)
	const [embedCode, setEmbedCode] = useState('')

	const [name, setName] = useState(data?.name)

	const { mutate, isLoading: isUpdating } = useUpdateWidgetById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: getReadAllWidgetsQueryKey() })
				toast.success(t('widget:notification.widget_saved'))
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	const { data: allBots } = useReadAllBots(
		{ account_id: accountId, size: 100 },
		{
			query: {
				select: (data) =>
					data.records.filter((bot) => bot.status === BotStatusType.Published),
			},
		},
	)

	const handleSubmit = () => {
		formRef.current.submit()
	}

	const handleGetEmbedCode = () => {
		setIsModalActive(true)
		setEmbedCode(
			`
<div
	id="videobot-widget"
	data-widget-type="widget"
	data-widget-id="${data.publicId}"
	style="width: 100%; height: 100%;"
></div>
<script type="module" src="${import.meta.env.VITE_EMBED_DOMAIN}videobot.mjs"></script>
		`.trim(),
		)
	}

	const handleGetPlayUrl = () => {
		setIsPlayUrlModalActive(true)
		setEmbedCode(`${import.meta.env.VITE_EMBED_DOMAIN}widget/${data.publicId}/play`)
	}

	// TODO: Find a better way to disable Save button on not dirty
	const ActionsTopBar = (
		<div className="flex items-center gap-2">
			<More customTogglerButton={SubActionsTopBarToggler}>
				<MoreButton onClick={handleGetPlayUrl}>{t('widget:get_play_url')}</MoreButton>
			</More>
			<Button onClick={handleGetEmbedCode} variant="secondary">
				{t('widget:get_embed_code')}
			</Button>
			<Button onClick={handleSubmit} isLoading={isUpdating}>
				{t('save')}
			</Button>
		</div>
	)

	const isLoading = !data || !allBots
	const hasData = !!data && !!allBots

	return (
		<View
			title={name || data?.name}
			shortTitle={name || data?.name}
			showTopBar
			showTopBarOnDesktop
			hideHeaderActionsOnMobile
			hide={!data}
			cover
			isRouterView
			isLoading={isLoading}
			topBarActions={ActionsTopBar}
			onTitleChange={(name) => setName(name)}
		>
			{isModalActive && (
				<ShareWidgetModal
					element="textarea"
					title={t('widget:get_embed_code')}
					description={t('widget:embed_description')}
					onClose={() => setIsModalActive(false)}
					code={embedCode}
				/>
			)}
			{isPlayUrlModalActive && (
				<ShareWidgetModal
					element="input"
					title={t('widget:get_play_url')}
					description={t('widget:get_play_url_description')}
					onClose={() => setIsPlayUrlModalActive(false)}
					code={embedCode}
				/>
			)}
			{isLoading && !isError && <Loader cover isLoading />}
			{isError && <Error background="body" />}
			{hasData && (
				<EditWidgetViewContent
					data={data}
					allBots={allBots}
					ref={formRef}
					mutate={mutate}
					name={name || data?.name}
				/>
			)}
		</View>
	)
}
