import React, { Fragment, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import Button from '../../../../components/Button'
import FormRow from '../../../../components/FormRow'
import FormSection from '../../../../components/FormSection/FormSection'
import { Row } from '../../../../components/Grid'
import Loader from '../../../../components/Loader'
import { FEATURE_FLAGS } from '../../../../lib/constants'
import {
	useHubspotAuth,
	useLinearAuth,
	useReadAccountIntegrationsById,
} from '../../../../api/videobot'
import type { LinearAuth } from '../../../../api/videobot.schemas'
import { useGlobalConfig, useFeatureFlags } from '../../../../modules/session/global.hook'
import { useCurrentAccount } from '../../../../modules/session/auth.store'
import FormField from '../../../../components/FormField/FormField'
import FieldText from '../../../../components/FieldText/FieldText'

interface IntegrationMainRef {
	triggerLinearSubmit: () => void
}

interface AccountSettingsIntegrationMainProps {
	endpoints: Record<string, string>
	accountId: number
	ref?: React.Ref<IntegrationMainRef>
}

interface AccountSettingsIntegrationProps {
	ref?: React.Ref<IntegrationMainRef>
}

interface AccountSettingsIntegrationFlaggedProps {
	accountId: number
	ref?: React.Ref<IntegrationMainRef>
}

interface IntegrationButtonProps {
	authLink: string
	isAuthenticating: boolean
	isError: boolean
	integration?: any
}

const IntegrationButton = ({
	authLink,
	isAuthenticating,
	isError,
	integration,
}: IntegrationButtonProps) => {
	const { t } = useTranslation(['account'])
	const AnyButton = Button as any

	if (integration) {
		const accountName = integration.details.user

		return (
			<div className="flex items-center gap-7">
				<AnyButton variant="primary" onClick={(e: any) => e.preventDefault()}>
					{t('account:integration.connected')}
				</AnyButton>
				<div className="text-dark opacity-80">
					<Trans
						i18nKey="account:integration.connectionStatus"
						components={[<span className="font-bold" key="0"></span>]}
						values={{ accountName }}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="flex items-center gap-7">
			<AnyButton variant="secondary" externalTo={authLink} disabled={isAuthenticating}>
				{isAuthenticating && t('account:integration.connecting')}
				{isError && t('account:integration.reconnect')}
				{!isAuthenticating && !isError && t('account:integration.connect')}
			</AnyButton>
			{isAuthenticating && <Loader />}
			{isError && <div className="text-red">{t('account:integration.error')}</div>}
		</div>
	)
}

const AccountSettingsIntegrationMain = forwardRef(
	({ endpoints, accountId }: AccountSettingsIntegrationMainProps, ref) => {
		const { t } = useTranslation(['account'])
		const { data: featureFlags } = useFeatureFlags({ account_id: accountId })
		const navigate = useNavigate()
		const { action } = useParams()
		const [searchParams, setSearchParams] = useSearchParams()
		const { data, error, refetch } = useReadAccountIntegrationsById(accountId)
		const {
			mutate,
			isLoading: isHubspotAuthenticating,
			isError: isHubspotError,
		} = useHubspotAuth()
		const AnyButton = Button as any
		const code = searchParams.get('code')
		const formRef = useRef(null)

		const { register, handleSubmit, watch } = useForm<LinearAuth>({ mode: 'onBlur' })
		const apiKey = watch('apiKey')

		const { mutate: linearAuthMutate } = useLinearAuth({
			mutation: {
				onSuccess: () => {
					refetch()
					toast.success(t('account:integration.successLinear'))
				},
				onError: () => {
					toast.error(t('account:integration.errorLinear'))
				},
			},
		})

		const handleSubmitLinear = () => {
			linearAuthMutate({
				accountId,
				data: {
					apiKey,
				},
			})
		}

		useImperativeHandle(ref, () => ({
			submit: () => handleSubmit(handleSubmitLinear)(),
		}))

		useEffect(() => {
			if (!code || !action || !data || !accountId) {
				return
			}
			// TODO: Check action for different mutations
			mutate(
				{ accountId, data: { code } },
				{
					onSuccess: () => {
						refetch()
						navigate('..')
						toast.success(t('account:integration.successHubspot'))
					},
				},
			)
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), code: '' }))
		}, [action, code, data])

		if (error) {
			return <Fragment>{t('account:integration.integrationError')}</Fragment>
		}

		if (!featureFlags) {
			return null
		}

		if (!data) {
			return <Loader />
		}

		const hubspotIntegration = data.find(
			(integration) => integration.integrationType === 'hubspot',
		)
		const hubspotFlagged = featureFlags.includes(FEATURE_FLAGS.INTEGRATION_HUBSPOT)
		const linearFlagged = featureFlags.includes(FEATURE_FLAGS.INTEGRATION_LINEAR)
		const pipedriveFlagged = featureFlags.includes(FEATURE_FLAGS.INTEGRATION_PIPEDRIVE)
		const salesforceFlagged = featureFlags.includes(FEATURE_FLAGS.INTEGRATION_SALESFORCE)

		return (
			<form onSubmit={handleSubmit(handleSubmitLinear)} ref={formRef}>
				{hubspotFlagged && (
					<FormRow
						title={t('account:group.hubspot.label')}
						description={t('account:group.hubspot.description')}
					>
						<Row>
							<IntegrationButton
								authLink={endpoints.hubspot}
								isAuthenticating={isHubspotAuthenticating}
								isError={isHubspotError}
								integration={hubspotIntegration}
							/>
						</Row>
					</FormRow>
				)}
				{linearFlagged && (
					<FormRow
						title={t('account:group.linear.label')}
						description={t('account:group.linear.description')}
					>
						<Row>
							<FormField label={t('account:group.linear.key')} required>
								<FieldText
									register={register}
									placeholder={t('account:group.linear.key')}
									name="apiKey"
									type="password"
									autocomplete={false}
									required
								/>
							</FormField>
						</Row>
					</FormRow>
				)}
				{pipedriveFlagged && (
					<FormRow
						title={t('account:group.pipedrive.label')}
						description={t('account:group.pipedrive.description')}
					>
						<Row>
							<div>
								<AnyButton variant="secondary" disabled>
									{t('account:integration.connect')}
								</AnyButton>
							</div>
						</Row>
					</FormRow>
				)}
				{salesforceFlagged && (
					<FormRow
						title={t('account:group.salesforce.label')}
						description={t('account:group.salesforce.description')}
					>
						<Row>
							<div>
								<AnyButton variant="secondary" disabled>
									{t('account:integration.connect')}
								</AnyButton>
							</div>
						</Row>
					</FormRow>
				)}
			</form>
		)
	},
)

AccountSettingsIntegrationMain.displayName = 'AccountSettingsIntegrationMain'

export const AccountSettingsIntegrationFlagged = forwardRef(
	({ accountId }: AccountSettingsIntegrationFlaggedProps, ref: React.Ref<IntegrationMainRef>) => {
		const { t } = useTranslation(['account'])
		const { data, isError, isLoading } = useGlobalConfig()

		return (
			<FormSection
				title={t('account:section.integration.label')}
				description={t('account:section.integration.description')}
			>
				{isError && (
					<div className="text-red">{t('account:integration.integrationError')}</div>
				)}
				{isLoading && <Loader />}
				{data && !isLoading && (
					<AccountSettingsIntegrationMain
						accountId={accountId}
						endpoints={data.integrationEndpoint}
						ref={ref}
					/>
				)}
			</FormSection>
		)
	},
)

AccountSettingsIntegrationFlagged.displayName = 'AccountSettingsIntegrationFlagged'

export const AccountSettingsIntegrationForm = forwardRef(
	(props: AccountSettingsIntegrationProps, ref: React.Ref<IntegrationMainRef>) => {
		const activeAccount = useCurrentAccount()
		const { data } = useFeatureFlags({ account_id: activeAccount?.id })

		if (!data || !data.includes(FEATURE_FLAGS.INTEGRATION) || !activeAccount) {
			return null
		}

		return <AccountSettingsIntegrationFlagged accountId={activeAccount.id} ref={ref} />
	},
)

AccountSettingsIntegrationForm.displayName = 'AccountSettingsIntegration'
