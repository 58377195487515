import React, { useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
	DndContext,
	DragOverlay,
	PointerSensor,
	pointerWithin,
	rectIntersection,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { format } from 'date-fns'

import Badge from '@/components/Badge'
import Icon from '@/components/Icon'
import ListTable from '@/components/ListTable/ListTable'
import Popover from '@/components/Popover'
import Thumbnail from '@/components/Thumbnail'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { breakpoints } from '@/lib/theme'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { SlideCategoryType } from '@/api/videobot.schemas'

import Card from '../Card'
import AddSlideModal from './AddSlideModal'
import {
	ContentEditorSlideList,
	ContentEditorSlideListItem,
	ContentEditorSlideTable,
} from './ContentEditor.styles'
import ContentEditorCategory, { ContentEditorCategoryItem } from './ContentEditorCategory'

class MyPointerSensor extends PointerSensor {
	static activators = [
		{
			eventName: 'onPointerDown',
			handler: ({ nativeEvent: event }) => {
				const interactiveElements = ['button', 'input', 'textarea', 'select', 'option']
				if (
					!event.isPrimary ||
					event.button !== 0 ||
					interactiveElements.includes(event.target.tagName.toLowerCase())
				) {
					return false
				}

				return true
			},
		},
	]
}

const customCollisionDetectionAlgorithm = (args) => {
	// First, let's see if there are any collisions with the pointer
	const pointerCollisions = pointerWithin(args)

	// Collision detection algorithms return an array of collisions
	if (pointerCollisions.length > 0) {
		return pointerCollisions
	}

	// If there are no collisions with the pointer, return rectangle intersections
	return rectIntersection(args)
}

const ContentEditorSlidesCategory = ({ category, title, onEdit, onAdd }) => {
	const { watch } = useVideobotEditor()
	const slides = watch('slides')
	const filteredSlides = useMemo(
		() => slides?.filter((o) => o.category === category),
		[slides, category],
	)

	const dragEnabled = useMediaQuery('>sm')

	return (
		<ContentEditorCategory
			title={title}
			items={filteredSlides || []}
			id={category}
			dragEnabled={dragEnabled}
		>
			<AnimatePresence>
				{filteredSlides?.map((slide) => (
					<ContentEditorCategoryItem
						key={slide.id}
						id={slide.id}
						dragEnabled={dragEnabled}
					>
						<Card
							item={slide}
							dragEnabled={dragEnabled}
							onEdit={() => onEdit(slide)}
							variant="SLIDE"
						/>
					</ContentEditorCategoryItem>
				))}
			</AnimatePresence>
			{(category === 'SLIDE' || filteredSlides?.length === 0) && (
				<ContentEditorCategoryItem>
					<Card variant="ADD_SLIDE" onAdd={() => onAdd(category)} />
				</ContentEditorCategoryItem>
			)}
		</ContentEditorCategory>
	)
}

const ContentEditorSlidesGrid = ({ onAdd, onEdit }) => {
	const sensors = useSensors(useSensor(MyPointerSensor))
	const { watch, actions } = useVideobotEditor()
	const [activeId, setActiveId] = useState()
	const slides = watch('slides')
	const { t } = useTranslation()
	const { moveSlide, setIntroSlide, setNormalSlide } = actions

	const onDragOver = (e) => {
		const { active, over } = e

		if (active && over) {
			const activeId = active.data.current?.sortable?.containerId || active.id
			const overId = over.data.current?.sortable?.containerId || over.id

			if (overId && activeId) {
				const activeIndex = slides.findIndex((o) => o.id === active.id)

				if (overId === 'INTRO' && activeId === 'SLIDE') {
					setIntroSlide(activeIndex)
				} else if (overId === 'SLIDE' && activeId === 'INTRO') {
					setNormalSlide(activeIndex)
				}
			}
		}
	}

	const onDragEnd = (e) => {
		const { active, over } = e

		if (active?.id !== over?.id) {
			const oldIndex = slides.findIndex((o) => o.id === active.id)
			const newIndex = slides.findIndex((o) => o.id === over.id)
			moveSlide(oldIndex, newIndex)
		}
	}

	const onDragStart = (e) => {
		const { active } = e
		const { id } = active

		setActiveId(id)
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={customCollisionDetectionAlgorithm}
			onDragOver={onDragOver}
			onDragEnd={onDragEnd}
			onDragStart={onDragStart}
		>
			{/* Intro */}
			<ContentEditorSlidesCategory
				category="INTRO"
				title={t('videobot:intro')}
				onAdd={onAdd}
				onEdit={onEdit}
			/>
			{/* Slides */}
			<ContentEditorSlidesCategory
				category="SLIDE"
				title={t('videobot:slides')}
				onAdd={onAdd}
				onEdit={onEdit}
			/>
			{/* Drag Overlay */}
			<DragOverlay>
				{activeId ? (
					<Card item={slides.find((o) => o.id === activeId)} variant="SLIDE" isOverlay />
				) : null}
			</DragOverlay>
		</DndContext>
	)
}

const ContentEditorSlidePopover = ({ item }) => {
	const { t } = useTranslation(['videobot'])
	const slides = item.options?.filter((o) => o.type === 'SLIDE')
	const forms = item.options?.filter((o) => o.type === 'REGULAR_FORM')
	const links = item.options?.filter((o) => o.type === 'EXTERNAL_LINK')

	return (
		<Popover
			toggler={
				<Badge>
					<Icon name="arrow-right-oval" /> {item.options.length}
				</Badge>
			}
		>
			{slides.length > 0 && (
				<React.Fragment>
					<h6>{t('videobot:slides')}</h6>
					<ContentEditorSlideList>
						{slides.map((o) => (
							<ContentEditorSlideListItem key={o.id}>
								{o.name}
							</ContentEditorSlideListItem>
						))}
					</ContentEditorSlideList>
				</React.Fragment>
			)}
			{forms.length > 0 && (
				<React.Fragment>
					<h6>{t('videobot:contactForms')}</h6>
					<ContentEditorSlideList>
						{forms.map((o) => (
							<ContentEditorSlideListItem key={o.id}>
								{o.name}
							</ContentEditorSlideListItem>
						))}
					</ContentEditorSlideList>
				</React.Fragment>
			)}
			{links.length > 0 && (
				<React.Fragment>
					<h6>{t('videobot:externalLinks')}</h6>
					<ContentEditorSlideList>
						{links.map((o) => (
							<ContentEditorSlideListItem key={o.id}>
								{o.name}
							</ContentEditorSlideListItem>
						))}
					</ContentEditorSlideList>
				</React.Fragment>
			)}
		</Popover>
	)
}

const ContentEditorSlidesList = ({ onAdd, onEdit }) => {
	const { watch, actions, setValue } = useVideobotEditor()
	const slides = watch('slides')
	const { t } = useTranslation(['videobot'])

	const { setIntroSlide } = actions

	const optionsColumns = [
		{
			thumbnail: true,
			cell: (row) => {
				return <Thumbnail url={row.posterUrl} />
			},
		},
		{
			name: t('title'),
			selector: (row) => row.name,
		},
		{
			name: t('filename'),
			selector: (row) => row.filename || '-',
		},
		{
			name: t('duration'),
			selector: (row) => row.email,
			cell: (row) =>
				format(
					new Date('January 1, 2000 00:00:00').setSeconds(row.duration || 0),
					'HH:mm:ss',
				),
		},
		{
			name: t('buttons'),
			selector: (row) => row.email,
			cell: (row) => (row.options.length ? <ContentEditorSlidePopover item={row} /> : '-'),
		},
		{
			hide: breakpoints.lg,
			button: true,
			cell: (row) => {
				return (
					<Icon
						name="edit"
						variant="square"
						size="small"
						color="line"
						isButton
						onClick={() => onEdit(row)}
					></Icon>
				)
			},
		},
	]

	const updateSlidesOrder = (payload) => {
		setValue('slides', payload)
		setIntroSlide(0)
	}

	return (
		<ContentEditorCategory title={t('videobot:slides')} mode="LIST">
			<ContentEditorSlideTable>
				<ListTable
					dragEnabled
					columns={optionsColumns}
					data={slides}
					addAction={t('videobot:addSlide')}
					onAdd={onAdd}
					onReorder={(payload) => updateSlidesOrder(payload)}
					onRowClicked={onEdit}
				/>
			</ContentEditorSlideTable>
		</ContentEditorCategory>
	)
}

const ContentEditorSlides = ({ mode = 'GRID' }) => {
	const { actions, getValues } = useVideobotEditor()
	const [showAddModal, setShowAddModal] = useState(false)
	const navigate = useNavigate()
	const [newSlideCategory, setNewSlideCategory] = useState('SLIDE')

	const { addSlide } = actions

	const handleAddSlide = (data) => {
		const slidePrototype = addSlide({
			category: newSlideCategory,
			...data,
		})
		setShowAddModal(false)

		navigate(`slide/${slidePrototype.id}`)
	}

	const handleAddClick = (category) => {
		setNewSlideCategory(category)
		setShowAddModal(true)
	}

	const handleEditSlide = (slide) => {
		navigate(`slide/${slide.id}`)
	}

	const handleListAddClick = () => {
		const slidesLength = getValues('slides').length
		const category = slidesLength === 0 ? SlideCategoryType.INTRO : SlideCategoryType.SLIDE
		handleAddClick(category)
	}

	return (
		<React.Fragment>
			<AnimatePresence mode="wait">
				{mode === 'GRID' && (
					<ContentEditorSlidesGrid
						key="GRID"
						onAdd={handleAddClick}
						onEdit={handleEditSlide}
					/>
				)}
				{mode === 'LIST' && (
					<ContentEditorSlidesList
						key="LIST"
						onAdd={handleListAddClick}
						onEdit={handleEditSlide}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{showAddModal && (
					<AddSlideModal
						key="ADD_SLIDE"
						category="SLIDE"
						onClose={() => setShowAddModal(false)}
						onSubmit={handleAddSlide}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

export default ContentEditorSlides
