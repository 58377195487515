import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import AuthLayout from '@/features/Auth/components/AuthLayout'
import JoinTeamForm from '@/features/Auth/components/JoinTeamForm'
import JoinTeamSelect from '@/features/Auth/components/JoinTeamSelect'
import { useAuth } from '@/modules/session/auth.store'

const JoinTeamAuthCheck = ({ roleId, teamName, email }) => {
	const { isAuthenticated } = useAuth()
	const location = useLocation()

	if (!isAuthenticated) {
		const urlSearch = new URLSearchParams()
		urlSearch.set('redirect', `${location.pathname}${location.search}`)
		return <Navigate to={{ pathname: '/login', search: urlSearch.toString() }} replace />
	}

	return <JoinTeamSelect roleId={roleId} teamName={teamName} email={email} />
}

const JoinTeam = () => {
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token')
	const email = searchParams.get('email')
	const roleId = Number(searchParams.get('roleid'))
	const teamName = searchParams.get('account_name')
	const accountId = Number(searchParams.get('accountid'))
	const { isAuthenticated, logout } = useAuth()

	const isNewUser = Boolean(token)

	useEffect(() => {
		if (isNewUser && isAuthenticated) {
			logout()
		}
	}, [isNewUser, isAuthenticated, logout])

	return (
		<AuthLayout variant="join-team">
			{isNewUser && <JoinTeamForm email={email} token={token} accountId={accountId} />}
			{!isNewUser && <JoinTeamAuthCheck email={email} roleId={roleId} teamName={teamName} />}
		</AuthLayout>
	)
}

export default JoinTeam
