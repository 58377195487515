import PropTypes from '@/lib/propTypes'

import { PlayWrapper } from './Play.styles'

const Play = (props) => {
	const { children, size = 'md', onClick } = props

	return (
		<PlayWrapper size={size} onClick={onClick}>
			{children}
		</PlayWrapper>
	)
}

Play.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(['md', 'sm', 'lg']),
}

export default Play
