import { Navigate } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import CheckPicker from 'rsuite/CheckPicker'
import { omitBy, isNil } from 'lodash-es'

import FieldText from '@/components/FieldText'
import Button from '@/components/Button'
import FieldSelect from '@/components/FieldSelect'
import { useGetAllFeatureFlags, useReadAccounts } from '@/api/videobot'
import { useCurrentUser } from '@/modules/session/auth.store'
import { FlagType } from '@/api/videobot.schemas'
import { FeatureFlagCreateModal } from '@/features/FeatureFlag/feature_flag_create_modal'
import { viewFeatureAccountsFilter } from '@/pages/admin/admin.store'

import { FeatureFlagsList } from './feature_flags_list'
import View from '../../layout/View'

export const FeatureFlagsView = () => {
	const { t } = useTranslation('feature')
	const { register, handleSubmit, setValue, watch } = useForm()

	const user = useCurrentUser()
	const [accountIds, setAccountIds] = useAtom(viewFeatureAccountsFilter) || []

	const { data: allAccountsData } = useReadAccounts({ size: 500 })

	const { data: allFlags, refetch } = useGetAllFeatureFlags(
		omitBy(
			{
				account_ids: accountIds.join(','),
				flag_name: watch('flagName'),
				flag_type: watch('flagType') || undefined,
			},
			isNil,
		),
		{
			query: { staleTime: 1000 * 30 },
		},
	)

	const refetchFlags = () => {
		setTimeout(() => {
			refetch()
		}, 1000)
	}

	const [showCreateModal, setShowCreateModal] = useState(false)

	const handleCreateNewClick = () => {
		setShowCreateModal(true)
	}

	const onFilter = (payload) => {
		const cleanedPayload = omitBy(payload, (value) => isNil(value) || value === '')
		refetch(cleanedPayload)
	}

	if (!user.isSuperuser) {
		return <Navigate to="/dashboard" replace />
	}

	const accountSelections =
		allAccountsData?.records.map((account) => ({
			label: account.businessName,
			value: account.id,
		})) || []

	const flagTypeOptions = Object.values(FlagType).map((value) => ({
		label: value,
		value,
	}))

	const accountNameMap =
		allAccountsData?.records.reduce((map, account) => {
			map[account.id] = account.businessName
			return map
		}, {}) || {}

	return (
		<Fragment>
			<View
				header
				title="Feature Flags"
				actions={<Button onClick={handleCreateNewClick}>{t('createNew')}</Button>}
				mobileTopBar
			>
				<form
					className="mb-2 flex w-full flex-wrap items-center gap-2 md:gap-4"
					onSubmit={handleSubmit(onFilter)}
				>
					<div className="flex w-full items-center md:w-auto">
						<label className="w-20">{t('Flag name')}</label>
						<div className="w-full md:w-60">
							<FieldText
								register={register}
								placeholder={t('name')}
								name="flagName"
								type="text"
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex w-full items-center md:w-auto">
						<label className="w-20">{t('Account')}</label>
						<div className="w-full md:w-60">
							<CheckPicker
								data={accountSelections}
								value={accountIds}
								onChange={(value) => setAccountIds(value)}
								placeholder={t('selectAccounts')}
								className="w-full"
							/>
						</div>
					</div>
					<div className="flex w-full items-center md:w-auto">
						<label className="w-20">{t('Flag Type')}</label>
						<div className="w-full md:w-60">
							<FieldSelect
								register={register}
								placeholder={t('selectFlagType')}
								name="flagType"
								type="text"
								options={flagTypeOptions}
								setValue={setValue}
								className="w-full"
							/>
						</div>
					</div>
					<Button size="large" variant="primary">
						{t('Filter')}
					</Button>
				</form>

				{allFlags && (
					<FeatureFlagsList
						featureFlagsList={allFlags}
						accountNameMap={accountNameMap}
						onUpdateFlag={refetchFlags}
					/>
				)}
			</View>
			<AnimatePresence>
				{showCreateModal && (
					<FeatureFlagCreateModal
						onClose={() => setShowCreateModal(false)}
						onFlagCreated={refetchFlags}
					/>
				)}
			</AnimatePresence>
		</Fragment>
	)
}
