import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import PropTypes from '@/lib/propTypes'

import {
	FieldCheckboxIndicator,
	FieldCheckboxInput,
	FieldCheckboxLabel,
	FieldCheckboxWrapper,
} from './FieldCheckbox.styles'

const FieldCheckbox = (props) => {
	const { register, error, name, children, required, value, defaultChecked, setValue, disabled } =
		props
	const [selected, setSelected] = useState(defaultChecked || false)

	const id = uuidv4()

	const handleChange = (e) => {
		setSelected(e.target.checked)
		if (setValue) setValue(name, e.target.checked, { shouldValidate: true, shouldDirty: true })
	}

	return (
		<FieldCheckboxWrapper error={error} selected={selected}>
			<FieldCheckboxInput
				{...register?.(name, { required })}
				value={value}
				id={id}
				name={name}
				type="checkbox"
				onChange={handleChange}
				defaultChecked={defaultChecked}
				disabled={disabled}
			/>
			<FieldCheckboxLabel htmlFor={id} disabled={disabled}>
				<FieldCheckboxIndicator>
					<svg x="0" y="0" viewBox="0 0 32 32">
						<path fill="none" strokeLinecap="round" d="M6,16l7,8L27,8" />
					</svg>
				</FieldCheckboxIndicator>
				<span>{children}</span>
			</FieldCheckboxLabel>
		</FieldCheckboxWrapper>
	)
}

FieldCheckbox.propTypes = {
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	children: PropTypes.node,
	defaultChecked: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	register: PropTypes.func,
	setValue: PropTypes.func,
}

export default FieldCheckbox
