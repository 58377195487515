import styled, { css } from 'styled-components'

import { InfoIconWrapper } from '@/components/InfoIcon/InfoIcon.styles'

import media from '../../../lib/media'

export const SummaryWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: ${(props) => props.theme.grid.gutterWidth};
	margin-bottom: ${(props) => props.theme.spacing.desktop.sm};

	${media('<=md')} {
		grid-template-columns: repeat(2, 1fr);
	}
`
export const SummaryItem = styled.div`
	width: 100%;
	border-radius: ${(props) => props.theme.radius.xl};
	background-color: ${(props) => props.theme.color.light};
	color: ${(props) => props.theme.color.dark};

	padding: 1.5rem;
	@media screen {
		${media('<=md')} {
			padding: 1rem;
		}
	}
	@media print {
		padding: 1rem;
	}

	${(props) =>
		props.highlighted &&
		css`
			background: linear-gradient(
				326deg,
				${(props) => props.theme.color.purple} 0%,
				${(props) => props.theme.color.light} 70%
			);
			${media('<=md')} {
				grid-column: span 2;
			}
		`}
`
export const MetricTitle = styled.div`
	font-family: ${(props) => props.theme.font.secondary};
	font-weight: bold;
	font-size: 0.8rem;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	position: relative;

	${InfoIconWrapper} {
		margin-left: 0.25rem;
	}
`
export const Metric = styled.div`
	font-family: ${(props) => props.theme.font.secondary};
	font-weight: bold;
	font-size: 2.2rem;
`
export const MetricCaption = styled.div`
	font-size: 0.75rem;
	margin-bottom: -0.25rem;

	${media('<=xs')} {
		font-size: 0.7rem;
		margin-bottom: 0;
	}

	span {
		&:not(:last-child) {
			&:after {
				content: '|';
				margin: 0 0.25em;
			}
		}
	}
`
export const IconContainer = styled.div`
	height: 1.75rem;
	width: 1.75rem;
	flex-shrink: 0;
	top: 0;
	left: 0;
	border-radius: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => (props.color ? props.theme.color[props.color] : 'transparent')};
	margin-right: 0.5rem;
	border: ${(props) => (props.color ? 'none' : `1px solid ${props.theme.color.global}`)};

	> svg {
		width: 50%;
		height: 50%;
		display: block;
		fill: ${(props) => (props.color ? props.theme.color.inverted : props.theme.color.global)};
	}
`
