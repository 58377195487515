import styled, { css } from 'styled-components'

import { Cover } from '@/lib/mixins'

export const AlertWrapper = styled.div`
	position: relative;
	padding: 0.75rem;
	border-radius: ${(props) => props.theme.radius.sm};
	color: ${(props) => props.theme.color[props.variant]};
	margin-bottom: 1.5rem;
	display: flex;

	&:before {
		${Cover()}
		border-radius: ${(props) => props.theme.radius.sm};
		background-color: ${(props) => props.theme.color[props.variant]};
		opacity: 0.08;
	}

	${(props) =>
		props.transparent &&
		css`
			&:before {
				display: none;
			}
		`};

	${(props) =>
		props.center &&
		css`
			justify-content: center;
		`};

	${(props) =>
		props.noMargin &&
		css`
			margin: 0;
		`};

	svg {
		fill: ${(props) => props.theme.color[props.variant]};
	}
`
export const AlertIcon = styled.div`
	position: relative;
	flex-shrink: 0;
	margin-right: 0.65rem;
	margin-top: 0.2rem;

	svg {
		display: block;
		width: 1rem;
		height: 1rem;
	}
`
export const AlertContent = styled.div`
	position: relative;
`
