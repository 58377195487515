import { forwardRef, useImperativeHandle, useRef, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import FieldAvatar from '@/components/FieldAvatar'
import FieldColor from '@/components/FieldColor'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FormRow from '@/components/FormRow'
import FormSection from '@/components/FormSection/FormSection'
import { Col, Row } from '@/components/Grid'
import { TextArea } from '@/components/TextArea/text_area'
import PropTypes from '@/lib/propTypes'
import FieldMedia from '@/components/FieldMedia'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { getReadAccountDetailByIdQueryKey, useUpdateAccountProfileById } from '@/api/videobot'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FeatureType } from '@/api/videobot.schemas'
import { AccountSettingsContext } from '@/pages/Account/account_settings.context'

import AccountSettingsSocialLinks from './AccountSettingsSocialLinks'
import AccountSettingsActionButtons from './AccountSettingsActionButtons'
import { ButtonPreview } from './AccountSettingsProfileForm.styles'

const AccountSettingsProfileForm = ({ accountProfile }, ref) => {
	const { t } = useTranslation(['account', 'videobot'])

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		trigger,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		defaultValues: {
			logoId: accountProfile?.appearance.logo?.id,
			chatVideoId: accountProfile?.appearance.chatVideo?.id,
			buttonTextColor: accountProfile?.appearance.buttonTextColor,
			buttonBackgroundColor: accountProfile?.appearance.buttonBackgroundColor,
		},
	})

	const formRef = useRef(null)
	const activeAccount = useCurrentAccount()
	const queryClient = useQueryClient()
	const { setIsFormSubmitting } = useContext(AccountSettingsContext)

	const { data: featureFlags } = useFeatureFlags({ account_id: activeAccount.id })
	const isCompanyProfile = featureFlags?.includes(`dashboard:${FeatureType.company_profile}`)

	const updateProfileMutation = useUpdateAccountProfileById({
		mutation: {
			onMutate: () => {
				setIsFormSubmitting(true)
			},
			onSuccess: () => {
				toast.success(t('updated'))
				queryClient.invalidateQueries(getReadAccountDetailByIdQueryKey(activeAccount.id))
				setIsFormSubmitting(false)
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
				setIsFormSubmitting(false)
			},
		},
	})

	const handleUpdateProfile = (profileData) => {
		updateProfileMutation.mutate({
			accountId: activeAccount.id,
			data: profileData,
		})
	}

	const [socialLinks, setSocialLinks] = useState(accountProfile?.profile.socialLinks || [])
	const [actionButtons, setActionButtons] = useState(accountProfile?.profile.actions || [])

	const handleSocialLinksChange = (updatedLinks) => {
		setSocialLinks(updatedLinks)
	}

	const handleActionButtonsChange = (updatedButtons) => {
		setActionButtons(updatedButtons)
	}

	const onSubmit = async (data) => {
		const appearanceData = {
			buttonBackgroundColor: data.buttonBackgroundColor,
			buttonTextColor: data.buttonTextColor,
			chatVideoId: data.chatVideoId,
			logoId: data.logoId,
		}

		const profileData = {
			companyName: data.companyName,
			description: data.profileDescription,
			cover_id: data.cover_id,
			introduction_id: data.introduction_id,
			socialLinks: socialLinks.map((link) => ({
				url: link.url,
				icon: link.icon,
				target: link.target,
			})),
			actions: actionButtons.map((action) => {
				const actionData = {
					name: action.name,
					type: action.type,
				}

				if (action.type === 'external_link') {
					actionData.url = action.url
					actionData.style = action.style
					actionData.target = action.target
					actionData.externalLinkType = action.externalLinkType
				} else if (action.type === 'contact_form') {
					actionData.buttonStyle = action.buttonStyle
					actionData.buttonText = action.buttonText
					actionData.description = action.description
					actionData.emailTo = action.emailTo
					actionData.emailBcc = action.emailBcc
					actionData.title = action.title
					actionData.subject = action.subject
					actionData.fields = action.fields
				}

				return actionData
			}),
		}

		const formattedData = {
			appearance: appearanceData,
			profile: profileData,
		}

		handleUpdateProfile(formattedData)
	}

	useImperativeHandle(ref, () => ({
		submit: () => handleSubmit(onSubmit)(),
	}))

	const handleCoverChange = (payload) => {
		if (payload === null) {
			setValue('cover_id', null, { shouldDirty: true })
		} else {
			const { id } = payload
			setValue('cover_id', id, { shouldDirty: true })
		}
	}

	const handleIntroductionChange = (payload) => {
		if (payload === null) {
			setValue('introduction_id', null, { shouldDirty: true })
		} else {
			const { id } = payload
			setValue('introduction_id', id, { shouldDirty: true })
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
			{/* Appearance */}
			<FormSection
				title={t('account:section.appearanceProfile.label')}
				description={t('account:section.appearanceProfile.description')}
			>
				{/* Colors */}
				<FormRow
					title={t('account:group.themeColors.label')}
					description={t('account:group.themeColors.description')}
				>
					<Row bottom={'xs'}>
						<Col sm={4} xxs={6}>
							<FormField label={t('account:fields.textColor.label')} required>
								<FieldColor
									register={register}
									trigger={trigger}
									setValue={setValue}
									placeholder={t('account:fields.textColor.placeholder')}
									defaultValue={accountProfile?.appearance.buttonTextColor}
									name="buttonTextColor"
									required
								/>
							</FormField>
						</Col>
						<Col sm={4} xxs={6}>
							<FormField label={t('account:fields.backgroundColor.label')} required>
								<FieldColor
									register={register}
									trigger={trigger}
									setValue={setValue}
									placeholder={t('account:fields.backgroundColor.placeholder')}
									defaultValue={accountProfile?.appearance.buttonBackgroundColor}
									name="buttonBackgroundColor"
									required
								/>
							</FormField>
						</Col>
						<Col sm={4} xxs={6}>
							<ButtonPreview
								bgColor={getValues().buttonBackgroundColor}
								textColor={getValues().buttonTextColor}
							>
								Button Label
							</ButtonPreview>
						</Col>
					</Row>
				</FormRow>
				{/* Chat Icon */}
				<FormRow title={t('account:group.globalChatIcon.label')}>
					<FieldAvatar
						name="chatVideo"
						type="video"
						mediaType="LogoVideo"
						defaultValue={accountProfile.appearance.chatVideo?.url}
						valueName="chatVideoId"
						setValue={setValue}
						accountId={accountProfile.id}
					/>
				</FormRow>
				{/* Company Logo */}
				<FormRow title={t('account:group.globalCompanyLogo.label')}>
					<FieldAvatar
						name="logo"
						defaultValue={accountProfile.appearance.logo?.url}
						valueName="logoId"
						setValue={setValue}
						accountId={accountProfile.id}
						label={t('account:group.globalCompanyLogo.label')}
					/>
				</FormRow>
			</FormSection>
			{/* Details */}
			{isCompanyProfile && (
				<FormSection
					title={t('account:section.profileDetails.label')}
					description={t('account:section.profileDetails.description')}
				>
					{/* Business Address */}
					<FormRow
						title={t('account:group.companyName.label')}
						description={t('account:group.companyName.description')}
					>
						<Row>
							<Col xxs={8}>
								<FormField label={t('account:fields.companyName.label')}>
									<FieldText
										register={register}
										placeholder={t('account:fields.companyName.placeholder')}
										name="companyName"
										type="text"
										error={errors.companyName}
										defaultValue={accountProfile?.profile.companyName}
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>
					<FormRow
						title={t('account:group.profileDescription.label')}
						description={t('account:group.profileDescription.description')}
					>
						<Row>
							<Col sm={12} xxs={12}>
								<FormField label={t('account:fields.profileDescription.label')}>
									<TextArea
										rows={5}
										placeholder={t(
											'account:fields.profileDescription.placeholder',
										)}
										defaultValue={accountProfile?.profile.description}
										{...register('profileDescription')}
									/>
								</FormField>
							</Col>
						</Row>
					</FormRow>
					<FormRow
						title={t('account:group.coverImageOrVideo.label')}
						description={t('account:group.coverImageOrVideo.description')}
					>
						<Row>
							<Col sm={12} xxs={12}>
								<FieldMedia
									onChange={handleCoverChange}
									defaultValue={accountProfile?.profile.cover}
									isMedia
								/>
							</Col>
						</Row>
					</FormRow>
					<FormRow
						title={t('account:group.profileVideo.label')}
						description={t('account:group.profileVideo.description')}
					>
						<Row>
							<Col sm={12} xxs={12}>
								<FieldMedia
									onChange={handleIntroductionChange}
									defaultValue={accountProfile?.profile.introduction}
								/>
							</Col>
						</Row>
					</FormRow>
					<AccountSettingsSocialLinks
						initialSocialLinks={socialLinks}
						onSocialLinksChange={handleSocialLinksChange}
					/>
					<AccountSettingsActionButtons
						initialActionButtons={actionButtons}
						onActionButtonsChange={handleActionButtonsChange}
					/>
				</FormSection>
			)}
			<Outlet />
		</form>
	)
}

const propTypes = {
	account: PropTypes.object,
}

const WrappedAccountSettingsProfileForm = forwardRef(AccountSettingsProfileForm)
WrappedAccountSettingsProfileForm.propTypes = propTypes

export default WrappedAccountSettingsProfileForm
