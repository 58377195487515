import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { capitalize } from 'lodash-es'
import { useQueryClient } from '@tanstack/react-query'

import Modal from '@/components/Modal'
import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import type { AccountUpdate, AccountUpdateAnalyticsDisplayFields } from '@/api/videobot.schemas'
import { AnalyticsFieldType, EventChannelType } from '@/api/videobot.schemas'
import Button from '@/components/Button'
import { getReadAccountByIdQueryKey, useUpdateAccountById } from '@/api/videobot'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

interface AnalyticsSettingsModalProps {
	accountId: number
	defaultChannel: EventChannelType
	displayFields: AccountUpdateAnalyticsDisplayFields
	onClose: () => void
}

type FormData = Required<Pick<AccountUpdate, 'analyticsDefaultChannel' | 'analyticsDisplayFields'>>

const EventChannelTypeOptions = Object.values(EventChannelType).map((value) => ({
	value,
	label: capitalize(value),
}))

const getDisplayFields = (displayFields: AccountUpdateAnalyticsDisplayFields) => {
	const result = { ...displayFields }
	const fields = Object.values(AnalyticsFieldType)
	for (const field of fields) {
		// FIXME: check this properly
		if (!(field in result) && field !== 'watermark_clicked') {
			result[field] = true
		}
	}
	return result
}

export const AnalyticsSettingsModal = ({
	onClose,
	accountId,
	defaultChannel,
	displayFields,
}: AnalyticsSettingsModalProps) => {
	const { register, setValue, formState, handleSubmit, control } = useForm<FormData>({
		defaultValues: {
			analyticsDefaultChannel: defaultChannel,
			analyticsDisplayFields: getDisplayFields(displayFields || {}),
		},
	})
	const queryClient = useQueryClient()
	const { mutate, isLoading } = useUpdateAccountById({
		mutation: {
			onSuccess() {
				onClose()
				queryClient.invalidateQueries(getReadAccountByIdQueryKey(accountId))
			},
		},
	})
	const errors = formState.errors
	const AnyButton = Button as any

	const Header = (
		<Fragment>
			<h3>Analytics Settings</h3>
			<p>Configure how analytics would be displayed</p>
		</Fragment>
	)

	const onSubmit = (data: FormData) => {
		mutate({ accountId, data })
	}

	return (
		<Modal header={Header} onClose={onClose}>
			<form className="flex flex-col text-left" onSubmit={handleSubmit(onSubmit)}>
				<section>
					<FormField label="Default channel">
						<FieldSelect
							register={register}
							placeholder={EventChannelType.widget}
							name="analyticsDefaultChannel"
							type="text"
							defaultValue={defaultChannel}
							required
							options={EventChannelTypeOptions}
							setValue={setValue}
							error={errors.analyticsDefaultChannel}
						/>
					</FormField>
				</section>

				<section>
					<FormField label="Display fields">
						<div className="grid grid-cols-2">
							<ControlledFieldToggle
								control={control}
								name="analyticsDisplayFields.email"
							>
								Email
							</ControlledFieldToggle>

							<ControlledFieldToggle
								control={control}
								name="analyticsDisplayFields.cta"
							>
								CTA
							</ControlledFieldToggle>
						</div>
					</FormField>
				</section>

				<section>
					<AnyButton variant="primary" block isLoading={isLoading} disabled={isLoading}>
						Save
					</AnyButton>
				</section>
			</form>
		</Modal>
	)
}
