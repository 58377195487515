import { useOutletContext } from 'react-router'

import { VideobotSettingsForm } from '@/features/Videobot/components/VideobotSettings/VideobotSettingsForm'
import { ViewContent, ViewScroller } from '@/layout/View/View.styles'
import { ViewTransition } from '@/lib/transitions'

import { VideobotSettingsWrapper } from './VideobotSettings.styles'

const VideobotSettings = () => {
	const { readonly } = useOutletContext()

	return (
		<VideobotSettingsWrapper {...ViewTransition} showTopBarOnDesktop>
			<ViewScroller hasTabs hasBottomBar>
				<ViewContent className="relative">
					<VideobotSettingsForm />
					{readonly && <div className="absolute inset-0 z-50 h-full w-full" />}
				</ViewContent>
			</ViewScroller>
		</VideobotSettingsWrapper>
	)
}

export default VideobotSettings
