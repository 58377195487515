import styled, { css } from 'styled-components'

export const BadgeWrapper = styled.span`
	font-size: 0.7rem;
	text-transform: uppercase;
	padding: 0.25rem 0.5rem 0.3rem;
	border-radius: ${(props) => props.theme.radius.sm};
	letter-spacing: 0.1em;
	font-weight: 600;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 0.25rem;
	}

	${(props) =>
		props.size === 'sm' &&
		css`
			padding: 0.1rem 0.35rem 0.1rem;
			font-size: 0.6rem;
		`}

	${(props) =>
		props.variant === 'green' &&
		css`
			color: rgba(12, 208, 0, 1);
			background-color: rgba(12, 208, 0, 0.15);
			border: 1px solid rgba(12, 208, 0, 0.3);
			border-radius: 4px;
		`};

	${(props) =>
		props.variant === 'red' &&
		css`
			background: rgba(223, 28, 0, 0.1);
			color: ${(props) => props.theme.color.red};
			border: 1px solid rgba(223, 28, 0, 0.3);
			border-radius: 4px;
		`};

	${(props) =>
		props.variant === 'purple' &&
		css`
			background-color: #ccbbff;
		`};
	${(props) =>
		props.variant === 'orange' &&
		css`
			background-color: #ffddc9;
		`};
	${(props) =>
		props.variant === 'primary' &&
		css`
			background-color: ${(props) => props.theme.color.primary};
			color: white;
		`};
	${(props) =>
		props.variant === 'primary-inverted' &&
		css`
			background-color: transparent;
			border: 1px solid ${(props) => props.theme.color.primary};
			color: ${(props) => props.theme.color.primary};
		`};
	${(props) =>
		props.variant === 'dark' &&
		css`
			background-color: ${(props) => props.theme.color.global};
			color: white;
		`};
	${(props) =>
		props.variant === 'muted' &&
		css`
			background-color: ${(props) => props.theme.color.line};
			color: ${(props) => props.theme.color.muted};
		`};
	${(props) =>
		props.variant === 'default' &&
		css`
			border: 1px solid ${(props) => props.theme.color.line};
			background-color: ${(props) => props.theme.color.body};
			color: ${(props) => props.theme.color.muted};
		`};

	${(props) =>
		props.variant === 'yellow' &&
		css`
			background-color: #ffeed4;
			color: #f38b29;
			border: 1px solid #f38b29;
		`};
`
