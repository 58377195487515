import { useTranslation } from 'react-i18next'

import FieldUpload from '@/components/FieldUpload/FieldUpload'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { useUploadVideoTus } from '@/features/MediaLibrary/store/media_library.store'

const MediaUploadModal = ({ onClose, onUploaded }) => {
	const { t } = useTranslation(['media'])
	const doUpload = useUploadVideoTus({ onUploaded })

	const onFileUpload = (file) => {
		onClose()
		doUpload(file)
	}

	return (
		<Modal header={<h3>{t('media:addMedia')}</h3>} onClose={onClose} variant="wide">
			<FieldUpload onUpload={onFileUpload} />
		</Modal>
	)
}

MediaUploadModal.propTypes = {
	onUploaded: PropTypes.func,
	onClose: PropTypes.func,
	onUploadStart: PropTypes.func,
}

export default MediaUploadModal
