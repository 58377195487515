import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

import { BadgeWrapper } from '../Badge/Badge.styles'

export const PopoverWrapper = styled.button`
	${Reset('button')}
	${BadgeWrapper} {
		${TransitionPrimary('background-color')}
	}

	${(props) =>
		props.open &&
		css`
			${BadgeWrapper} {
				background-color: ${(props) => props.theme.color.line};
			}
		`};
`

export const PopoverTooltip = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.md};
	box-shadow: ${(props) => props.theme.shadow.sm};
	padding: 1rem;
	min-width: 10rem;
	z-index: 1000;

	h6 {
		text-transform: uppercase;
		margin-bottom: 0.75rem;
	}

	ul {
		${Reset('list')}
		&:not(:last-child) {
			margin-bottom: 1.25rem;
		}
		li {
			&:not(:last-child) {
				margin-bottom: 0.5rem;
			}
		}
	}
`
