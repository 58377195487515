import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { Reset } from '@/lib/mixins'

const ShareCode = styled.textarea`
	${Reset('all')}
	display: block;
	border-radius: 6px;
	text-align: left;
	overflow-x: auto;
	resize: none;

	white-space: pre-wrap;
	width: 100%;
	padding: 1rem;
	margin-bottom: 1.75rem;

	font-size: 1.1rem;
	line-height: 1.65;
	text-size-adjust: 100%;

	background-color: ${(props) => props.theme.color.line};

	&:focus,
	&:active {
		outline: 0;
		box-shadow: 0;
		border: 0;
		background-color: ${(props) => props.theme.color.light};
	}

	${(props) =>
		props.element === 'textarea' &&
		css`
			height: 11rem;
		`};
`

const ActionWrapper = styled.div`
	text-align: right;
`

export const ShareWidgetModal = ({ code, onClose, title, description, element = 'textarea' }) => {
	const [t] = useTranslation(['widget'])
	const codeRef = React.useRef()
	const [isCopied, setIsCopied] = useState(false)
	const copyHandler = async () => {
		await navigator.clipboard.writeText(code)
		if (codeRef.current) {
			codeRef.current.focus()
			codeRef.current.select()
		}
		setIsCopied(true)
	}

	const Header = (
		<React.Fragment>
			<h3>{title}</h3>
			<p>{description}</p>
		</React.Fragment>
	)

	return (
		<Modal header={Header} onClose={onClose} variant="wide">
			{element === 'textarea' && (
				<ShareCode
					element="textarea"
					ref={codeRef}
					defaultValue={code.trim()}
					readOnly
				></ShareCode>
			)}
			{element === 'input' && (
				<ShareCode
					as="input"
					element="input"
					ref={codeRef}
					readOnly
					value={code.trim()}
				></ShareCode>
			)}
			<ActionWrapper>
				<Button variant="text-icon" wide onClick={copyHandler}>
					{isCopied ? t('copied') : t('copyClipboard')}
				</Button>
			</ActionWrapper>
		</Modal>
	)
}
