import { useLayoutEffect, useState } from 'react'

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		windowWidth: undefined,
		windowHeight: undefined,
	})
	useLayoutEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
			})
		}

		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [])
	return windowSize
}
