import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from '@/lib/propTypes'

import Icon from '../Icon'
import { BreadcrumbsItem, BreadcrumbsWrapper } from './Breadcrumbs.styles'

const Breadcrumbs = ({ items }) => {
	return (
		<BreadcrumbsWrapper>
			{items?.map((o, i) => (
				<React.Fragment key={i}>
					{o.to ? (
						<BreadcrumbsItem key={i} as={Link} to={o.to}>
							{o.title}
						</BreadcrumbsItem>
					) : (
						<BreadcrumbsItem key={i}>{o.title}</BreadcrumbsItem>
					)}
					{i < items.length - 1 && <Icon name="chevron-right" />}
				</React.Fragment>
			))}
		</BreadcrumbsWrapper>
	)
}

Breadcrumbs.propTypes = {
	items: PropTypes.array,
}

export default Breadcrumbs
