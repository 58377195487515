import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { allBotsKey } from '@/api/videobot.keys'
import { useDeleteBotById } from '@/api/videobot'

const DeleteVideobotModal = ({ onClose, show, videobot }) => {
	const { t } = useTranslation(['errors', 'common', 'videobot'])
	const queryClient = useQueryClient()
	const { mutate, isLoading } = useDeleteBotById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: allBotsKey() })
				toast.success(t('videobot:videobotDeleted'))
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	return (
		<Modal
			header={<h3>{t('videobot:deleteVideobot')}</h3>}
			show={show}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="videobot:deleteVideobotDescription"
					values={{ name: videobot?.name }}
				>
					Are you sure you want to delete user &nbsp;
					<strong>{videobot?.name}</strong>?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button
				variant="red-solid"
				onClick={() => mutate({ botId: videobot?.id })}
				isLoading={isLoading}
				wide
			>
				{t('remove')}
			</Button>
		</Modal>
	)
}

DeleteVideobotModal.propTypes = {
	onClose: PropTypes.func,
	show: PropTypes.bool,
	videobot: PropTypes.object,
	onDelete: PropTypes.func,
}

export default DeleteVideobotModal
