import PropTypes from '@/lib/propTypes'

import { AvatarWrapper } from './Avatar.styles'

const Avatar = (props) => {
	const { src, size = 'md', name, color, variant } = props

	return (
		<AvatarWrapper size={size} color={color} variant={variant}>
			{src && <img src={src} alt="..." />}
			{name && !src && <span>{name[0]}</span>}
		</AvatarWrapper>
	)
}

Avatar.propTypes = {
	src: PropTypes.string,
	name: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.oneOf(['md', 'lg', 'sm']),
	variant: PropTypes.oneOf(['default', 'purple', 'orange', 'primary', 'dark', 'muted']),
}

export default Avatar
