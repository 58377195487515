import { useContext } from 'react'

import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useReadAccountProfileById } from '@/api/videobot'
import Loader from '@/components/Loader'
import Error from '@/layout/Error'
import { AccountSettingsContext } from '@/pages/Account/account_settings.context'

import AccountSettingsProfileForm from '../../features/Account/components/AccountSettingsProfileForm/AccountSettingsProfileForm'

const AccountSettingsProfileContents = () => {
	const { formRef } = useContext(AccountSettingsContext)

	const activeAccount = useCurrentAccount()
	const { data: accountProfile, isLoading, isError } = useReadAccountProfileById(activeAccount.id)

	const account = accountProfile
		? {
				...accountProfile,
				id: activeAccount.id,
			}
		: null

	if (isLoading) {
		return <Loader />
	}

	if (isError) {
		return <Error background="body" />
	}

	return (
		<div className="mb-16">
			{accountProfile && (
				<AccountSettingsProfileForm ref={formRef} accountProfile={account} />
			)}
		</div>
	)
}

const AccountSettingsProfile = () => {
	return (
		<RoleCheck
			roles={[RoleType.Super_Admin, RoleType.Reseller, RoleType.Owner, RoleType.Admin]}
		>
			<AccountSettingsProfileContents />
		</RoleCheck>
	)
}

export default AccountSettingsProfile
