import { useTranslation } from 'react-i18next'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'

import Modal from '@/components/Modal'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import FieldText from '@/components/FieldText'
import { WidgetType } from '@/api/videobot.schemas'
import { getReadAllWidgetsQueryKey, useCreateWidget } from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'

const WIDGET_TYPES = Object.values(WidgetType)

export const CreateWidgetModal = ({ onClose, show }) => {
	const [t] = useTranslation()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})
	const account = useCurrentAccount()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate, isLoading } = useCreateWidget({
		mutation: {
			onSuccess: (data) => {
				queryClient.invalidateQueries({ queryKey: getReadAllWidgetsQueryKey() })
				toast.success(t('widget:notification.widget_created'))
				navigate(`/dashboard/widget/${data.id}`)
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	useEffect(() => {
		register('type', { required: true })
	}, [register])

	const saveHandler = (payload) => {
		mutate({ data: { accountId: account.id, ...payload } })
	}

	return (
		<Modal header={<h3>{t('widget:create_new_widget')}</h3>} show={show} onClose={onClose}>
			<form autoComplete="off" onSubmit={handleSubmit(saveHandler)}>
				<FormField label={t('widget:fields.name.label')}>
					<FieldText
						register={register}
						placeholder={t('widget:fields.name.placeholder')}
						name="name"
						type="name"
						required
						size="large"
						error={errors.name}
					/>
				</FormField>
				<RadioGroup.Root
					className="grid select-none grid-cols-3 gap-4"
					type="single"
					aria-label={t('widget:editor:widget_types')}
					defaultValue="slider"
					onValueChange={(value) => setValue('type', value, { shouldValidate: true })}
				>
					{WIDGET_TYPES.map((type) => (
						<RadioGroup.Item
							value={type}
							aria-label={t(`widget:types:${type}`)}
							className="group flex flex-col items-center justify-center gap-2"
							key={type}
						>
							<div className="flex h-full w-full items-center justify-center rounded-lg border-2 border-line px-4 py-5 group-data-[state=checked]:border-primary">
								<img
									src={`/assets/icons/widget_${type.toLowerCase()}.svg`}
									alt={t(`widget:types:${type}`)}
								/>
							</div>
							<span>{t(`widget:types:${type}`)}</span>
						</RadioGroup.Item>
					))}
				</RadioGroup.Root>
				<div className="flex pt-8">
					<Button block isLoading={isLoading} disabled={!isValid}>
						Save
					</Button>
				</div>
			</form>
		</Modal>
	)
}
