import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { clamp } from 'lodash-es'
import { useQueryClient } from '@tanstack/react-query'

import { BotStatusType } from '@/api/videobot.schemas'
import DataTable from '@/components/DataTable'
import { useWindowSize } from '@/hooks/useWindowSize'
import { breakpoints } from '@/lib/theme'
import More, { MoreButton } from '@/components/More'
import Badge from '@/components/Badge'
import { useReadAllWidgets, useUpdateWidgetById } from '@/api/videobot'
import { useCurrentAccount, usePowerUserMode } from '@/modules/session/auth.store'
import Loader from '@/components/Loader'
import { DeleteWidgetModal } from '@/features/widget/delete_widget_modal.component'
import { allWidgetsKey } from '@/api/videobot.keys'

import { DeactivateWidgetModal } from './deactivate_widget_modal.component'
import { ReactivateWidgetModal } from './reactivate_widget_modal.component'

const WidgetMenu = ({ row, onEditClick }) => {
	const { t } = useTranslation(['widget'])
	const [isDeleteWidget, setIsDeleteWidget] = useState(false)
	const [showDeactivateModal, setShowDeactivateModal] = useState(false)
	const [showReactivateModal, setShowReactivateModal] = useState(false)
	const { mutate, isLoading } = useUpdateWidgetById()
	const queryClient = useQueryClient()
	const { isPowerUser } = usePowerUserMode()

	const isDeactivated = row.status === BotStatusType.Deactivated

	const handleDeactivateWidget = () => {
		mutate(
			{ widgetId: row.id, data: { status: BotStatusType.Deactivated } },
			{
				onSettled: () => {
					setShowDeactivateModal(false)
					queryClient.invalidateQueries({ queryKey: allWidgetsKey() })
				},
			},
		)
	}

	const handleReactivateWidget = () => {
		mutate(
			{ widgetId: row.id, data: { status: BotStatusType.Published } },
			{
				onSettled: () => {
					setShowReactivateModal(false)
					queryClient.invalidateQueries({ queryKey: allWidgetsKey() })
				},
			},
		)
	}

	return (
		<Fragment>
			{isDeleteWidget && (
				<DeleteWidgetModal
					show={isDeleteWidget}
					accountId={row.accountId}
					id={row.id}
					name={row.name}
					onClose={() => setIsDeleteWidget(false)}
				/>
			)}
			{showDeactivateModal && (
				<DeactivateWidgetModal
					widget={row}
					onClose={() => setShowDeactivateModal(false)}
					isLoading={isLoading}
					onSubmit={handleDeactivateWidget}
				/>
			)}
			{showReactivateModal && (
				<ReactivateWidgetModal
					widget={row}
					onClose={() => setShowReactivateModal(false)}
					isLoading={isLoading}
					onSubmit={handleReactivateWidget}
				/>
			)}
			<More>
				<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
				{!isDeactivated && isPowerUser && (
					<MoreButton onClick={() => setShowDeactivateModal(true)} variant="red">
						{t('deactivate')}
					</MoreButton>
				)}
				{isDeactivated && isPowerUser && (
					<MoreButton onClick={() => setShowReactivateModal(true)} variant="green">
						{t('reactivate')}
					</MoreButton>
				)}
				<MoreButton onClick={() => setIsDeleteWidget(true)} variant="red">
					{t('remove')}
				</MoreButton>
			</More>
		</Fragment>
	)
}

export const WidgetList = () => {
	const [t] = useTranslation(['widget'])
	const [searchParams, setSearchParams] = useSearchParams({ page: 1 })
	const { windowWidth } = useWindowSize()
	const navigate = useNavigate()
	const account = useCurrentAccount()
	const { data } = useReadAllWidgets({
		account_id: account?.id,
		size: 10,
		page: Number(searchParams.get('page')),
	})

	const onPageChange = useCallback(
		(newPage) => {
			const pageCount = data?.metadata.pageCount
			const params = {
				page: clamp(newPage, 1, pageCount),
			}
			if (!pageCount) {
				return
			}
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
		},
		[setSearchParams, data?.metadata.pageCount],
	)

	const handleEdit = (row) => {
		navigate(`/dashboard/widget/${row.id}`)
	}

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: windowWidth > breakpoints.lg,
			button: true,
			cell: (row) => {
				return <WidgetMenu row={row} onEditClick={() => handleEdit(row)} />
			},
		},
		{
			width: '6rem',
			cell: (row) => {
				return (
					<div className="h-full w-full px-1 py-2">
						<div className="h-full w-full rounded-lg bg-line px-2 py-1">
							<img
								src={`/assets/icons/widget_${row.type.toLowerCase()}.svg`}
								alt={t(`widget:types.${row.type}`)}
								className="h-full w-full"
							/>
						</div>
					</div>
				)
			},
		},
		{
			id: 'name',
			name: t('widget:fields.name.label'),
			selector: (row) => row.name,
			sortable: true,
			width: '10rem',
		},
		{
			id: 'type',
			name: t('widget:fields.type.label'),
			selector: (row) => row.type,
			cell: (row) => {
				return <Badge variant="default">{t(`widget:types.${row.type}`)}</Badge>
			},
		},
		{
			id: 'content',
			name: t('widget:fields.contents.label'),
			selector: (row) => row.contentType,
			cell: (row) => {
				return <Badge variant="default">{t(`widget:contents.${row.contentType}`)}</Badge>
			},
		},
		{
			id: 'updatedAt',
			name: t('widget:fields.updated_at.label'),
			selector: (row) => row.updatedAt,
			cell: (row) => {
				return <span>{format(new Date(`${row.updatedAt}Z`), 'dd/MM/yyyy HH:mm')}</span>
			},
		},
		{
			id: 'status',
			name: t('widget:fields.status.label'),
			selector: (row) => row.status,
			cell: (row) => {
				if (row.status === BotStatusType.Published) {
					return <Badge variant="green">{t(row.status.toLowerCase())}</Badge>
				}
				if (row.status === BotStatusType.Draft) {
					return <Badge variant="default">{t(row.status.toLowerCase())}</Badge>
				}
				if (row.status === BotStatusType.Deactivated) {
					return <Badge variant="red">{t(row.status.toLowerCase())}</Badge>
				}
			},
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return <WidgetMenu row={row} onEditClick={() => handleEdit(row)} />
			},
		},
	]

	if (!data) {
		return <Loader cover />
	}

	const widgets = data.records

	return (
		<DataTable
			data={widgets}
			columns={columns}
			pageSize={data.metadata.pageSize}
			totalCount={data.metadata.totalCount}
			page={data.metadata.page}
			onRowClicked={handleEdit}
			onPageChange={onPageChange}
		/>
	)
}
