import PropTypes from 'prop-types'

import { FormRowContent, FormRowHeader, FormRowWrapper } from './FormRow.styles'

const FormRow = ({ title, description, children }) => {
	return (
		<FormRowWrapper>
			<FormRowHeader>
				{title && <h5>{title}</h5>}
				{description && <div>{description}</div>}
			</FormRowHeader>
			<FormRowContent>{children}</FormRowContent>
		</FormRowWrapper>
	)
}

FormRow.propTypes = {
	title: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,
}

export default FormRow
