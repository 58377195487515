import styled from 'styled-components'

import media from '@/lib/media'

export const FilterWrapper = styled.div`
	${media('<=md')} {
		overflow: auto;
		margin-left: -${(props) => props.theme.grid.outerMargin};
		margin-right: -${(props) => props.theme.grid.outerMargin};
		padding-left: ${(props) => props.theme.grid.outerMargin};
		padding-right: ${(props) => props.theme.grid.outerMargin};
	}
`
export const FilterContainer = styled.div`
	min-width: 800px;
	overflow-x: auto;
	scroll-behavior: smooth;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.grid.gutterWidth};
`
export const FilterItem = styled.div`
	margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
`
