import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import Button from '../Button'
import Modal from '../Modal'
import { CropperWrapper } from './Cropper.styles'

const Cropper = (props) => {
	const { onSave, onCancel, src, title, file, aspect } = props
	const { t } = useTranslation()
	const [crop, setCrop] = useState()
	const [completeCrop, setCompletedCrop] = useState()
	const imgRef = useRef(null)

	const handleSave = () => {
		const crop = completeCrop
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')

		if (!ctx) {
			throw new Error('No 2d context')
		}

		const reader = new FileReader()
		const image = imgRef.current
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = Math.floor(crop.width * scaleX)
		canvas.height = Math.floor(crop.height * scaleY)

		const cropX = crop.x * scaleX
		const cropY = crop.y * scaleY

		const centerX = image.naturalWidth / 2
		const centerY = image.naturalHeight / 2

		ctx.translate(-cropX, -cropY)
		ctx.translate(centerX, centerY)
		ctx.translate(-centerX, -centerY)
		ctx.drawImage(
			image,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
			0,
			0,
			image.naturalWidth,
			image.naturalHeight,
		)

		canvas.toBlob((blob) => {
			// reader.readAsDataURL(blob)
			reader.readAsArrayBuffer(blob)
			reader.onloadend = () => {
				onSave(
					new File([reader.result], file?.name, {
						type: file?.type,
					}),
					reader.result,
				)
			}
		}, file?.type)
	}

	const onImageLoad = (e) => {
		const img = e.currentTarget
		const { width, height } = img
		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 100,
				},
				aspect,
				width,
				height,
			),
			width,
			height,
		)

		setCrop(crop)
	}

	return (
		<Modal
			header={<h3>{title || t('cropImage')}</h3>}
			footer={
				<React.Fragment>
					<Button variant="secondary" onClick={onCancel}>
						{t('cancel')}
					</Button>
					<Button variant="primary" onClick={handleSave}>
						{t('save')}
					</Button>
				</React.Fragment>
			}
			onClose={onCancel}
			disableDialogTransition
			fillOnMobile
		>
			<CropperWrapper>
				<ReactCrop
					crop={crop}
					onChange={(_, percentageCrop) => setCrop(percentageCrop)}
					onComplete={(c) => setCompletedCrop(c)}
					aspect={aspect}
				>
					<img
						src={src}
						ref={imgRef}
						crossOrigin="anonymous"
						onLoad={onImageLoad}
						alt={file?.name}
					/>
				</ReactCrop>
			</CropperWrapper>
		</Modal>
	)
}

export default Cropper
