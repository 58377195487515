import { useEffect } from 'react'

import type { AccountCurrentUserRead, UserWithRolesRead } from '@/api/videobot.schemas'

interface WindowWithVelaris extends Window {
	[key: string]: any
}

export const useVelarisSurvey = (
	currentUser: UserWithRolesRead | null,
	currentAccount: AccountCurrentUserRead | null,
) => {
	useEffect(() => {
		const loadSurveyScript = () => {
			// Begin Velaris generated code
			;(function (e: WindowWithVelaris, t: Document, a: string, n: string, i: string) {
				e[n] =
					e[n] ||
					function (...args: any[]) {
						;(e[n].q = e[n].q || []).push(args)
					}
				const c = t.createElement(a) as HTMLScriptElement
				const s = t.getElementsByTagName(a)[0] as HTMLScriptElement
				c.id = n
				c.src = i
				c.async = true
				s.parentNode?.insertBefore(c, s)
			})(
				window as WindowWithVelaris,
				document,
				'script',
				'velaris_survey',
				'https://survey-widget.euw1.velaris.io/widget.js',
			)
			// End Velaris generated code
		}

		const initializeSurvey = () => {
			// Begin Velaris generated code
			if (typeof (window as WindowWithVelaris).velaris_survey === 'function') {
				;(window as WindowWithVelaris).velaris_survey({
					apiKey: import.meta.env.VITE_VELARIS_API_KEY,
					accountId: String(currentAccount?.id),
					userEmail: currentUser?.email,
				})
			} else {
				setTimeout(initializeSurvey, 100)
			}
			// End Velaris generated code
		}

		if (currentUser?.email && currentAccount?.id) {
			try {
				loadSurveyScript()
				initializeSurvey()
			} catch (err) {
				console.error('Failed to load Velaris survey', err)
			}
		}

		return () => {
			const scriptElement = document.getElementById('velaris_survey')
			if (scriptElement) {
				scriptElement.remove()
			}

			if ((window as WindowWithVelaris).velaris_survey) {
				delete (window as WindowWithVelaris).velaris_survey
			}

			const surveyDiv = document.getElementById('widget-velaris_survey')
			if (surveyDiv) {
				surveyDiv.remove()
			}
		}
	}, [currentUser, currentAccount])
}
