import { useTranslation } from 'react-i18next'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { QRCodeSVG } from 'qrcode.react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'

import View from '@/layout/View'
import Button from '@/components/Button'
import { usePreferredMFA } from '@/modules/session/auth.store'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Loader from '@/components/Loader'
import Alert from '@/components/Alert'

// TODO: Fix this
const AnyButton = Button as any
const AnyLoader = Loader as any

type MFAState = 'INIT' | 'LOADING' | 'LOADED' | 'VERIFYING' | 'ERROR' | 'SUCCESS'

// TODO: Refactor this
export const SecurityView = () => {
	const [translate] = useTranslation()
	const preferredMFA = usePreferredMFA()
	const { user } = useAuthenticator((context) => [context.user])
	const [mfaState, setMFAState] = useState<MFAState>('INIT')
	const {
		data: mfaSecretCode,
		remove,
		isFetching,
	} = useQuery({
		queryKey: ['user', user.username, 'mfa'],
		queryFn: () => Auth.setupTOTP(user),
		staleTime: Infinity,
		cacheTime: Infinity,
	})
	const { register, formState, handleSubmit } = useForm<{ verifyCode: string }>()
	const [mfaError, setMFAError] = useState('')

	const hasMFA = preferredMFA !== 'NOMFA'

	const verifyMFAHandler = async (data: { verifyCode: string }) => {
		setMFAState('VERIFYING')
		try {
			await Auth.verifyTotpToken(user, data.verifyCode)
			await Auth.setPreferredMFA(user, 'TOTP')
			setMFAState('SUCCESS')
			remove()
		} catch {
			setMFAState('ERROR')
			setMFAError('Verification failed, please recheck and enter the code again.')
		}
	}

	const issuer =
		import.meta.env.VITE_ENVIRONMENT === 'production'
			? 'Videobot'
			: `Videobot${import.meta.env.VITE_ENVIRONMENT}`
	const qrcode = `otpauth://totp/${issuer}:${
		user.attributes?.email ?? user.username
	}?secret=${mfaSecretCode}&issuer=${issuer}`

	return (
		<View header title={translate('account:setupMFA')} mobileTopBar isLoading={false}>
			<div className="flex max-w-xl flex-col gap-4">
				{isFetching && <AnyLoader cover />}
				{hasMFA && (
					<div className="hyphens-auto break-words">
						<Alert icon="info" variant="green">
							MFA has been setup for your account.
						</Alert>
					</div>
				)}
				{mfaSecretCode && !hasMFA && (
					<div className="hyphens-auto break-words">
						<p className="text-lg">
							Please either scan the QR code below or copy the secret key into your
							preferred authenticator app and enter the authentication code to the
							input field to complete the setup.
						</p>
						<p>
							<QRCodeSVG value={qrcode} size={256} />
							<span>Secret Key: {mfaSecretCode}</span>
						</p>

						<form onSubmit={handleSubmit(verifyMFAHandler)}>
							<FormField label="Authentication Code" required>
								<FieldText
									placeholder="123456"
									name="verifyCode"
									type="text"
									required
									register={register}
									error={formState.errors.verifyCode}
								/>
							</FormField>

							{mfaError && <p className="text-red">{mfaError}</p>}

							<div>
								<AnyButton
									disabled={mfaState === 'VERIFYING'}
									isLoading={mfaState === 'VERIFYING'}
								>
									Confirm
								</AnyButton>
							</div>
						</form>
					</div>
				)}
			</div>
		</View>
	)
}
