import styled from 'styled-components'

import { InputField } from '@/lib/mixins'

import { ButtonWrapper } from '../Button/Button.styles'

export const FieldFileInput = styled.input`
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
`

export const FieldFileWrapper = styled.div`
	${InputField()}
	display: flex;
	align-items: center;
	cursor: pointer;

	${ButtonWrapper} {
		flex-shrink: 0;
		margin-right: -0.75rem;
	}
`

export const FieldFileLabel = styled.div`
	flex: 1 1 auto;
	margin-right: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
`
