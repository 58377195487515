import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'

import PropTypes from '@/lib/propTypes'

import {
	FieldTextBody,
	FieldTextInput,
	FieldTextMessage,
	FieldTextToggler,
	FieldTextWrapper,
} from './FieldText.styles'

const EMPTY_RULES = {}

const FieldText = ({
	register,
	error,
	defaultValue,
	name,
	placeholder,
	type = 'text',
	size = 'default',
	readonly,
	disabled,
	required,
	rules = EMPTY_RULES,
	autocomplete,
}) => {
	const [hideValue, setHideValue] = useState(type === 'password')
	const { t } = useTranslation(['validation'])

	const handleToggler = (e) => {
		e.preventDefault()
		setHideValue(!hideValue)
	}

	const opts = {
		...(required && type !== 'email'
			? { required: t([`validation:${name}`, `validation:required`]) }
			: {}),
		...(required && type === 'email'
			? {
					required: t([`validation:${name}`, `validation:required`]),
					pattern: {
						value: /\S[^\s@]*@\S+\.\S+/,
						message: t([`validation:${name}`, `validation:email`]),
					},
				}
			: {}),
		...(required && type === 'url'
			? {
					required: t([`validation:${name}`, `validation:required`]),
					pattern: {
						value: /https?:\/\/(www\.)?[-\w@:%.+~#=]{2,256}\.[a-z]{2,6}\b([-\w@:%+.~#?&/=]*)/gi,
						message: t([`validation:${name}`, `validation:url`]),
					},
				}
			: {}),
		...(required && type === 'tel'
			? {
					required: t([`validation:${type}`, `validation:required`]),
					pattern: {
						// Regex for telephone link format
						value: /^tel:(.+)$/,
						message: t([`validation:${type}`, `validation:tel`]),
					},
				}
			: {}),
		...(required && type === 'mailto'
			? {
					required: t([`validation:${type}`, `validation:required`]),
					pattern: {
						// Regex for mailto link format
						value: /^mailto:.[^\n\r@\u2028\u2029]*@.[^\n\r.\u2028\u2029]*\..+$/,
						message: t([`validation:${type}`, `validation:mailto`]),
					},
				}
			: {}),
		...(required && type === 'deep_link'
			? {
					required: required ? t([`validation:${name}`, `validation:required`]) : false,
					pattern: {
						value: /^(?!javascript:|js:).+:\/\/.*/,
						message: t([`validation:${name}`, `validation:deeplink`]),
					},
				}
			: {}),
		...(required && type === 'relative_path'
			? {
					required: required ? t([`validation:${name}`, `validation:required`]) : false,
					pattern: {
						value: /^\/.*$/,
						message: t([`validation:${name}`, `validation:relative_path`]),
					},
				}
			: {}),
		...rules,
	}

	return (
		<FieldTextWrapper error={error} readOnly={readonly}>
			<FieldTextBody>
				<FieldTextInput
					{...register?.(name, opts)}
					name={name}
					autocomplete={autocomplete}
					error={error}
					type={type === 'password' && !hideValue ? 'text' : type}
					disabled={disabled}
					size={size}
					readOnly={readonly}
					placeholder={placeholder}
					defaultValue={defaultValue}
					required={required}
				/>
				{type === 'password' && (
					<FieldTextToggler onClick={handleToggler}>
						<SVG
							src={
								hideValue
									? `/assets/icons/visibility-off.svg`
									: `/assets/icons/visibility-on.svg`
							}
						/>
					</FieldTextToggler>
				)}
			</FieldTextBody>
			{error?.message && <FieldTextMessage variant="error">{error.message}</FieldTextMessage>}
		</FieldTextWrapper>
	)
}

FieldText.displayName = 'FieldText'

FieldText.propTypes = {
	register: PropTypes.func,
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,
	readonly: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	rules: PropTypes.object,
	autocomplete: PropTypes.bool,
}

export default FieldText
