import Icon from '../Icon'
import { ThumbnailWrapper } from './Thumbnail.styles'

const Thumbnail = (props) => {
	const { url, size = 'default', title, fallbackIcon = 'exclamation-mark' } = props
	return (
		<ThumbnailWrapper size={size}>
			{url ? <img src={url} alt={title} /> : <Icon name={fallbackIcon} />}
		</ThumbnailWrapper>
	)
}

export default Thumbnail
