import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const AddActionButton = ({ onClose, show, isLoading, onSubmit }) => {
	const { t } = useTranslation(['videobot'])

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})

	const handleFormSubmit = (data) => {
		onSubmit({
			...data,
			type: watch('type'),
		})
	}

	const onButtonClick = (event) => {
		event.preventDefault()
		handleSubmit(handleFormSubmit)()
	}

	return (
		<Modal
			header={<h3>{t('videobot:createNewActionButton')}</h3>}
			show={show}
			onClose={onClose}
		>
			<form autoComplete="off">
				<FormField label={t('videobot:actionButtonFields.name.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:actionButtonFields.name.placeholder')}
						name="name"
						type="text"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>

				<FormField label={t('videobot:actionButtonFields.actionType.label')} required>
					<FieldSelect
						register={register}
						placeholder={t('videobot:actionButtonFields.actionType.placeholder')}
						name="type"
						options={[
							{
								value: 'contact_form',
								label: t('videobot:actionButtonFields.actionType.contactForm'),
							},
							{
								value: 'external_link',
								label: t('videobot:actionButtonFields.actionType.externalLink'),
							},
						]}
						size="large"
						error={errors.type}
						required
						setValue={setValue}
					/>
				</FormField>
				<Button
					type="button"
					onClick={onButtonClick}
					disabled={!isValid}
					block
					isLoading={isLoading}
				>
					{t('videobot:createNewActionButton')}
				</Button>
			</form>
		</Modal>
	)
}

AddActionButton.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default AddActionButton
