import { formatISO } from 'date-fns'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router'

import { CFAnalyticsTable } from '@/pages/cf_analytics/cf_analytics_table.component'
import { useCurrentUser } from '@/modules/session/auth.store'

interface FormPayload {
	botId: string
	iconId: string | undefined
	fromDate: Date
	toDate: Date
}

export const CFAnalyticsView = () => {
	const user = useCurrentUser()
	const [payload, setPayload] = React.useState<{
		botId: string
		iconId: string | undefined
		fromDate: string
		toDate: string
	} | null>(null)
	const { register, handleSubmit, formState } = useForm<FormPayload>({
		defaultValues: { iconId: undefined },
	})
	const onSubmit = (data: FormPayload) => {
		setPayload({
			...data,
			fromDate: formatISO(data.fromDate, { representation: 'date' }),
			toDate: formatISO(data.toDate, { representation: 'date' }),
		})
	}

	if (!user.isSuperuser && !user.isCustomerSuccess) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<div className="w-full p-8">
			<h1>Cloudflare Analytics</h1>

			<form className="grid w-full grid-cols-5 gap-4" onSubmit={handleSubmit(onSubmit)}>
				<label className="flex flex-col gap-1">
					<span className="text-lg">
						Videobot ID <span className="text-red-700">*</span>
					</span>
					<input
						type="text"
						{...register('botId', { required: true })}
						className="form-input"
					/>
					{formState.errors.botId && (
						<span className="text-red-700">{formState.errors.botId.message}</span>
					)}
				</label>

				<label className="flex flex-col gap-1">
					<span className="text-lg">Icon ID</span>
					<input
						type="text"
						{...register('iconId', { required: false })}
						className="form-input"
					/>
				</label>

				<label className="flex flex-col gap-1">
					<span className="text-lg">
						From Date (inclusive) <span className="text-red-700">*</span>
					</span>
					<input
						type="date"
						{...register('fromDate', { required: true, valueAsDate: true })}
						className="form-input"
					/>
					{formState.errors.fromDate && (
						<span className="text-red-700">{formState.errors.fromDate.message}</span>
					)}
				</label>

				<label className="flex flex-col gap-1">
					<span className="text-lg">
						To Date (inclusive) <span className="text-red-700">*</span>
					</span>
					<input
						type="date"
						{...register('toDate', { required: true, valueAsDate: true })}
						className="form-input"
					/>
					{formState.errors.toDate && (
						<span className="text-red-700">{formState.errors.toDate.message}</span>
					)}
				</label>

				<button className="bg-blue-100 text-blue-900 hover:bg-blue-900 hover:text-blue-50 h-12 self-end rounded transition">
					Analyze
				</button>
			</form>

			<div className="mt-8">{payload && <CFAnalyticsTable {...payload} />}</div>
		</div>
	)
}
