import styled from 'styled-components'

import { LoaderWrapper } from '../Loader/Loader.styles'

export const FieldMediaWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`
export const FieldMediaInput = styled.input`
	display: none;
`

export const FieldMediaPreview = styled.div`
	position: relative;
	width: 7rem;
	height: 7rem;
	border-radius: ${(props) => props.theme.radius.lg};
	background-color: ${(props) => props.theme.color.line};
	margin-right: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;

	${LoaderWrapper} {
		border-radius: 50%;
	}

	img,
	video {
		border-radius: ${(props) => props.theme.radius.lg};
		display: block;
		object-fit: cover;
		object-position: center top;
		width: 100%;
		height: 100%;
	}
`

export const FieldMediaActions = styled.div`
	position: relative;
`
