import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import { usePopper } from 'react-popper'
import { v4 } from 'uuid'
import { sortBy } from 'lodash-es'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@/hooks/useMediaQuery'
import PropTypes from '@/lib/propTypes'
import { currentPopupAtom } from '@/common/layout'
import { useAuth, useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import { useReadCurrentUserAccounts } from '@/api/videobot'
import { RoleStatusType } from '@/api/videobot.schemas'

import {
	TeamSelectDropdownWrapper,
	TeamSelectToggler,
	TeamSelectWrapper,
} from './TeamSelect.styles'
import TeamSelectDropdown from './TeamSelectDropdown'
import TeamSelectPopup from './TeamSelectPopup'

const TeamSelect = ({ collapsed }) => {
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [currentPopup, setCurrentPopup] = useAtom(currentPopupAtom)
	const uuidv4 = useRef(v4())
	const popupRef = useRef(null)
	const navigate = useNavigate()
	const [translate] = useTranslation(['account'])

	const { setActiveAccount } = useAuth()
	const currentAccount = useCurrentAccount()
	const { data: accounts } = useReadCurrentUserAccounts()
	const isMobile = useMediaQuery('<=lg')
	const currentUser = useCurrentUser()

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-end',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [-10, 10],
				},
			},
		],
	})

	const handleTriggerClick = (e) => {
		if (uuidv4.current !== currentPopup) {
			setCurrentPopup(uuidv4.current)
		} else {
			setCurrentPopup(null)
		}

		e.stopPropagation()
	}

	const closePopup = () => {
		setIsOpen(false)
		popupRef?.current?.hide()
	}

	const selectAccount = (payload) => {
		closePopup()
		setActiveAccount(payload?.id)
		setCurrentPopup(null)
		navigate('/dashboard')
	}

	const onClose = () => {
		closePopup()
		setCurrentPopup(null)
	}

	useEffect(() => {
		if (currentPopup === uuidv4.current) {
			setIsOpen(true)
		} else {
			// setIsOpen(false)
			// popupRef?.current?.hide()
		}
	}, [currentPopup])

	const accountsSorted = useMemo(() => {
		if (!accounts) {
			return []
		}
		const accountsWithoutAll = accounts.filter((account) => account.id !== -1)
		const allAccount = accounts.find((account) => account.id === -1)
		const results = [allAccount]
			.filter(Boolean)
			.concat(sortBy(accountsWithoutAll, (account) => account.businessName.toLowerCase()))
		if (currentUser.isReseller || currentUser.isSuperuser || currentUser.isCustomerSuccess) {
			return results
		}
		return results.filter((account) => account.roleStatus === RoleStatusType.Active)
	}, [accounts, currentUser.isReseller, currentUser.isSuperuser, currentUser.isCustomerSuccess])

	const canSelectTeam =
		accounts?.length > 1 ||
		currentUser.isReseller ||
		currentUser.isSuperuser ||
		currentUser.isCustomerSuccess

	return (
		<TeamSelectWrapper id="select-wrapper">
			{/* Toggler */}
			<TeamSelectToggler
				collapsed={collapsed}
				open={isOpen}
				onClick={handleTriggerClick}
				ref={setReferenceElement}
			>
				<SVG src={'/assets/img/logo-emblem.svg'}></SVG>
				<span>
					{currentAccount
						? currentAccount.businessName
						: translate('account:allAccounts')}
				</span>
				{canSelectTeam && <SVG src={'/assets/icons/chevron-down.svg'}></SVG>}
			</TeamSelectToggler>
			{/* Dropdown */}
			{canSelectTeam && (
				<Fragment>
					{!isMobile &&
						createPortal(
							<AnimatePresence mode="wait">
								{isOpen && (
									<motion.div
										initial={{ opacity: 0, y: 5 }}
										animate={{ opacity: 1, y: 0 }}
										exit={{ opacity: 0, y: 5 }}
										transition={{ duration: 0.2 }}
									>
										<div className="h-screen w-screen">
											<TeamSelectDropdownWrapper
												open={isOpen}
												style={styles.popper}
												{...attributes.popper}
												ref={setPopperElement}
											>
												<TeamSelectDropdown
													onSelect={selectAccount}
													accounts={accountsSorted}
													onClose={onClose}
												/>
											</TeamSelectDropdownWrapper>
											<div className="h-full w-full" onClick={closePopup} />
										</div>
									</motion.div>
								)}
							</AnimatePresence>,
							document.querySelector('#popups'),
						)}
					{/* Popup */}
					<AnimatePresence mode="wait">
						{isMobile && isOpen && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.6 }}
							>
								<TeamSelectPopup
									accounts={accountsSorted}
									ref={popupRef}
									open={isOpen}
									onClose={closePopup}
									onSelect={selectAccount}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</Fragment>
			)}
		</TeamSelectWrapper>
	)
}

TeamSelect.propTypes = {
	collapsed: PropTypes.bool,
}

export default TeamSelect
