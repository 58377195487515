import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@/components/Button'
import FieldFile from '@/components/FieldFile'
import FieldSelect from '@/components/FieldSelect'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Panel from '@/components/Panel'
import { LANGS } from '@/lib/constants'
import { allMediasKey } from '@/api/videobot.keys'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useUploadMediaCaptionById } from '@/api/videobot'

const AddSubtitlesActions = ({ onCancel, onSave, saveDisabled, isSaveLoading }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onCancel} variant="secondary">
				{t('cancel')}
			</Button>
			<Button disabled={saveDisabled} onClick={onSave} isLoading={isSaveLoading}>
				{t('save')}
			</Button>
		</React.Fragment>
	)
}

const AddSubtitlesPanel = ({ onClose, media }) => {
	const { t } = useTranslation(['media'])
	const formRef = useRef()
	const activeAccount = useCurrentAccount()
	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		setValue,
		watch,
	} = useForm()
	const watchFile = watch('file')
	const queryClient = useQueryClient()
	const { mutate: uploadSubtitles, isLoading } = useUploadMediaCaptionById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: allMediasKey(),
				})
				toast.success(t('media:subtitlesUploaded'))
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	const handleSave = (data) => {
		uploadSubtitles({
			data: { file: data.file[0] },
			mediaId: media.id,
			params: { account_id: activeAccount.id, language: data.language },
		})
	}

	return (
		<Panel
			title={t('media:addSubtitles')}
			onBack={onClose}
			zIndex={165}
			footer={
				<AddSubtitlesActions
					onCancel={onClose}
					onSave={() =>
						formRef.current?.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
					}
					saveDisabled={!isValid || watchFile.length === 0}
					isSaveLoading={isLoading}
				/>
			}
		>
			<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
				<Row>
					<Col sm={6} xxs={12}>
						{/* Language */}
						<FormField label={t('media:language')} required>
							<FieldSelect
								register={register}
								placeholder={t('media:selectLanguage')}
								name="language"
								error={errors.language}
								options={LANGS.map((item) => ({
									value: item.code,
									label: item.name,
									item,
								}))}
								setValue={setValue}
								required
							/>
						</FormField>
					</Col>
					<Col sm={6} xxs={12}>
						{/* File */}
						<FormField label={`${t('media:uploadFile')} (.vtt)`} required>
							<FieldFile
								register={register}
								setValue={setValue}
								placeholder={t('chooseFile')}
								name="file"
								error={errors.file}
								fileTypes=".vtt"
								required
							/>
						</FormField>
					</Col>
				</Row>
			</form>
		</Panel>
	)
}

export default AddSubtitlesPanel
