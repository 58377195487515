import { clamp } from 'lodash-es'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Button from '@/components/Button'
import Loader from '@/components/Loader'
import AccountsList from '@/features/Account/components/AccountsList'
import Error from '@/layout/Error'
import View from '@/layout/View'
import FieldText from '@/components/FieldText'
import { useReadAccounts, useReadAccountsDetail } from '@/api/videobot'
import { AccountsFilterType, RoleType } from '@/api/videobot.schemas'
import { useCurrentUser } from '@/modules/session/auth.store'
import { RoleCheck } from '@/modules/session/auth.component'

const INITIAL_PARAMS = { order_by: 'business_name', order_direction: 'asc', page: 1 }

const AllAccountsContents = ({ data, error, isFetching }) => {
	const [searchParams, setSearchParams] = useSearchParams(INITIAL_PARAMS)
	const { register, handleSubmit } = useForm()
	const orderBy = searchParams.get('order_by')
	const orderDirection = searchParams.get('order_direction')
	const page = Number(searchParams.get('page')) || 1
	const size = 10
	const isFetchingRef = useRef(false)

	const { t } = useTranslation(['account', 'user'])

	const onPageChange = useCallback(
		(newPage) => {
			if (!data) {
				return
			}
			// FIXME: hax for sorting
			if (isFetchingRef.current) {
				return
			}
			setSearchParams((prev) => ({
				...Object.fromEntries(prev),
				page: clamp(newPage, 1, data.metadata.pageCount),
			}))
		},
		[setSearchParams, data],
	)

	const onSort = useCallback(
		(selectedColumn, sortDirection) => {
			setSearchParams((prev) => ({
				...Object.fromEntries(prev),
				page: 1,
				order_by: selectedColumn?.id,
				order_direction: sortDirection,
			}))
			isFetchingRef.current = true
		},
		[setSearchParams],
	)

	useEffect(() => {
		isFetchingRef.current = isFetching
	}, [isFetching])

	const Actions = <Button to={'/dashboard/accounts/create'}>{t('account:createAccount')}</Button>

	const onSearch = (data) => {
		setSearchParams((prev) => ({ ...Object.fromEntries(prev), page: 1, ...data }))
	}

	if (error) {
		return <Error />
	}

	if (data) {
		const accounts = data.records

		return (
			<View
				header
				noHeaderBorder
				title={t('accounts')}
				actions={Actions}
				isLoading={isFetching}
			>
				<form onSubmit={handleSubmit(onSearch)} className="mb-2 flex gap-4">
					<label className="flex items-center gap-2">
						<FieldText
							type="text"
							className="rounded"
							placeholder="Search accounts..."
							name="business_name"
							register={register}
						/>
					</label>

					<button
						className="rounded bg-primary px-8 text-body transition hover:bg-dark"
						type="submit"
					>
						Search
					</button>
				</form>
				<AccountsList
					accountsList={accounts}
					pageSize={size}
					totalCount={data.metadata.totalCount}
					onPageChange={onPageChange}
					page={page}
					onSort={onSort}
					defaultSortFieldId={orderBy}
					defaultSortAsc={orderDirection === 'asc'}
				/>
			</View>
		)
	}

	return <Loader cover />
}

const AllAccountsAdmin = () => {
	const [searchParams] = useSearchParams(INITIAL_PARAMS)
	const page = Number(searchParams.get('page')) || INITIAL_PARAMS.page
	const orderBy = searchParams.get('order_by') || INITIAL_PARAMS.order_by
	const orderDirection = searchParams.get('order_direction') || INITIAL_PARAMS.order_direction
	const size = 10
	const businessName = searchParams.get('business_name')

	const { data, error, isFetching } = useReadAccountsDetail(
		{
			page,
			size,
			order_direction: orderDirection,
			order_by: orderBy,
			business_name: businessName,
		},
		{ query: { keepPreviousData: true } },
	)

	return <AllAccountsContents data={data} error={error} isFetching={isFetching} />
}

const AllAccountsReseller = () => {
	const [searchParams] = useSearchParams(INITIAL_PARAMS)
	const page = Number(searchParams.get('page')) || INITIAL_PARAMS.page
	const orderBy = searchParams.get('order_by') || INITIAL_PARAMS.order_by
	const orderDirection = searchParams.get('order_direction') || INITIAL_PARAMS.order_direction
	const size = 10
	const businessName = searchParams.get('business_name')

	const { data, error, isFetching } = useReadAccounts(
		{
			filter: AccountsFilterType.Joined,
			page,
			size,
			order_direction: orderDirection,
			order_by: orderBy,
			business_name: businessName,
		},
		{ query: { keepPreviousData: true } },
	)

	return <AllAccountsContents data={data} error={error} isFetching={isFetching} />
}

const AllAccounts = () => {
	const currentUser = useCurrentUser()

	if (currentUser.isSuperuser || currentUser.isCustomerSuccess) {
		return <AllAccountsAdmin />
	}

	if (currentUser.isReseller) {
		return <AllAccountsReseller />
	}

	//  TODO: add error page
	return <div>No permission!</div>
}

export const AllAccountsView = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin, RoleType.Reseller]} skipAccountRole>
			<AllAccounts />
		</RoleCheck>
	)
}
