import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary, TransitionSecondary } from '../../lib/mixins'

export const MoreTogglerWrapper = styled.button.attrs({
	type: 'button',
})`
	${Reset('button')}
	position: relative;
	display: inline-block;
`

export const MoreToggler = styled.span`
	position: relative;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: ${(props) => props.theme.radius.sm};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	&:before {
		${Cover()}
		border-radius: ${(props) => props.theme.radius.sm};
		background-color: ${(props) => props.theme.color.line};
		opacity: 0;
		transform: scale(0.75);
		${TransitionPrimary('opacity, transform')}
	}

	${(props) =>
		props.open &&
		css`
			&:before {
				opacity: 1;
				transform: scale(1);
			}
		`};

	&:hover {
		&:before {
			opacity: 1;
			transform: scale(1);
		}
	}

	svg {
		position: relative;
		height: 50%;
		width: 100%;
		display: block;
	}
`

export const MoreDropdown = styled.div`
	z-index: 10;
	min-width: 13rem;
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.md};
	box-shadow: ${(props) => props.theme.shadow.sm};
	padding: 0.25rem;
	white-space: nowrap;
	${TransitionSecondary('opacity, transform, visibility')}
`

export const MoreButton = styled.button`
	${Reset('button')}
	display: block;
	width: 100%;
	text-align: left;
	padding: 0.65rem 0.75rem;
	${TransitionPrimary('background')}
	border-radius: ${(props) => props.theme.radius.sm};

	&:hover:not(:disabled) {
		background-color: ${(props) => props.theme.color.light};
	}

	&:disabled {
		opacity: 0.5;
	}

	${(props) =>
		props.variant === 'red' &&
		css`
			color: ${(props) => props.theme.color.red};
		`};

	${(props) =>
		props.variant === 'green' &&
		css`
			color: ${(props) => props.theme.color.green};
		`};
`

export const MoreWrapper = styled.div`
	position: relative;

	${(props) =>
		props.inverted &&
		css`
			${MoreToggler} {
				&:before {
					background-color: rgba(255, 255, 255, 0.2);
				}

				svg {
					fill: ${(props) => props.theme.color.inverted};
				}
			}
		`}
`

export const MoreDropdownWrapper = styled.div`
	${(props) =>
		props.open &&
		css`
			${MoreToggler} {
				&:before {
					opacity: 1;
					transform: scale(1);
				}
			}
		`};
`
