import { AnimatePresence, motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import useKeypress from 'react-use-keypress'

import Icon from '@/components/Icon'

import {
	ModalBackdrop,
	ModalBody,
	ModalClose,
	ModalDialog,
	ModalFooter,
	ModalHeader,
	ModalWrapper,
} from './Modal.styles'

const Modal = (props) => {
	const {
		children,
		footer,
		onClose,
		footerKey,
		header,
		variant,
		disableDialogTransition,
		fillOnMobile,
	} = props

	const handleClose = (e) => {
		e.stopPropagation()
		e.preventDefault()
		if (onClose) onClose()
	}

	useKeypress('Escape', () => {
		if (onClose) onClose()
	})

	const dialogTransition = disableDialogTransition
		? {}
		: { as: motion.div, initial: { scale: 0.95 }, animate: { scale: 1 }, exit: { scale: 1.05 } }

	return createPortal(
		<ModalWrapper
			as={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			onAnimationComplete={() => window.dispatchEvent(new Event('scroll'))}
			fillOnMobile={fillOnMobile}
		>
			<ModalBackdrop onClick={handleClose}></ModalBackdrop>
			<ModalDialog {...dialogTransition} variant={variant}>
				<ModalClose onClick={handleClose}>
					<Icon name="cross"></Icon>
				</ModalClose>
				<ModalHeader>{header}</ModalHeader>
				<ModalBody>{children}</ModalBody>
				<AnimatePresence mode="wait">
					{footer && <ModalFooter key={footerKey}>{footer}</ModalFooter>}
				</AnimatePresence>
			</ModalDialog>
		</ModalWrapper>,
		document.querySelector('#modals'),
	)
}

Modal.displayName = 'Modal'

export default Modal
