import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldRadio from '@/components/FieldRadio'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Panel from '@/components/Panel'
import { mapStr2bool } from '@/lib/utils'

const EditContactFormFieldActions = ({ onSave, onDelete, saveDisabled, deleteDisabled }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button type="button" onClick={onDelete} variant="secondary" disabled={deleteDisabled}>
				{t('delete')}
			</Button>
			<Button type="button" onClick={onSave} disabled={saveDisabled}>
				{t('save')}
			</Button>
		</React.Fragment>
	)
}

const EditContactFormFieldPanel = ({
	fieldData,
	onClose,
	onRemove,
	requiredByIntegration,
	onSave,
}) => {
	const { t } = useTranslation()
	const {
		register,
		formState: { errors, isValid },
		getValues,
		setValue,
		handleSubmit,
	} = useForm({
		mode: 'onBlur',
	})
	const formRef = useRef()
	const type = getValues('type')

	const fieldTypes = [
		{
			name: 'text',
			title: 'Text',
			tag: 'input',
		},
		{
			name: 'email',
			title: 'Email',
			tag: 'input',
		},
		{
			name: 'textarea',
			title: 'Textarea',
			tag: 'textarea',
		},
		{
			name: 'general_checkbox',
			title: 'Checkbox',
			tag: 'input',
		},
	]

	const handleSave = (payload) => {
		const fieldPrototype = { ...fieldData, ...mapStr2bool(payload) }

		onSave(fieldPrototype)

		if (onClose) onClose()
	}

	const handleRemove = () => {
		if (onRemove) onRemove(fieldData)
		if (onClose) onClose()
	}

	return (
		<Panel
			title={t('videobot:editField')}
			onBack={onClose}
			footer={
				<EditContactFormFieldActions
					onSave={handleSubmit(handleSave)}
					onDelete={() => handleRemove()}
					saveDisabled={!type || !isValid}
					deleteDisabled={requiredByIntegration}
				/>
			}
			zIndex={155}
		>
			<form ref={formRef} onSubmit={handleSubmit((fieldData) => handleSave(fieldData))}>
				<Row>
					<Col sm={6} xxs={12}>
						{/* Link to */}
						<FormField label={t('videobot:formElementFields.type.label')}>
							<FieldSelect
								register={register}
								placeholder={t('videobot:formElementFields.type.placeholder')}
								name="type"
								error={errors.type}
								setValue={setValue}
								defaultValue={fieldData.type}
								required
								options={fieldTypes.map((o) => ({
									value: o.name,
									label: o.title,
									item: o,
								}))}
								disabled={requiredByIntegration}
							/>
						</FormField>
					</Col>
					<Col sm={6} xxs={12}>
						{/* Name */}
						<FormField label={t('videobot:formElementFields.label.label')}>
							<FieldText
								register={register}
								placeholder={t('videobot:formElementFields.label.placeholder')}
								name="label"
								type="text"
								error={errors.label}
								defaultValue={fieldData.label}
								required
							/>
						</FormField>
					</Col>
				</Row>
				{/* Style */}
				<FormField label={t('videobot:formElementFields.validation.label')}>
					<FieldRadio
						register={register}
						name="required"
						value={false}
						defaultChecked={!fieldData.required}
						setValue={setValue}
						inline
						disabled={requiredByIntegration}
					>
						{t('videobot:formElementFields:validation.optional')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="required"
						value
						defaultChecked={fieldData.required}
						setValue={setValue}
						inline
						disabled={requiredByIntegration}
					>
						{t('videobot:formElementFields:validation.required')}
					</FieldRadio>
				</FormField>
			</form>
		</Panel>
	)
}

export default EditContactFormFieldPanel
