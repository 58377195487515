import styled, { css } from 'styled-components'

import { LoaderWrapper } from '../Loader/Loader.styles'

export const FieldAvatarInput = styled.input`
	display: none;
`

export const FieldAvatarPreview = styled.div`
	position: relative;
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	background-color: ${(props) => props.theme.color.light};
	border: 3px solid ${(props) => props.theme.color.light};
	margin-right: 1rem;

	${LoaderWrapper} {
		border-radius: 50%;
	}

	img,
	video {
		border-radius: 50%;
		display: block;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`

export const FieldAvatarActions = styled.div`
	position: relative;
`

export const FieldAvatarWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	${(props) =>
		props.error &&
		css`
			& ${FieldAvatarPreview} {
				border-color: ${(props) => props.theme.color.red};
			}
		`}
`
