import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import useLocalStorageState from 'use-local-storage-state'
import { useQueryClient } from '@tanstack/react-query'

import Loader from '@/components/Loader'
import { useWindowSize } from '@/hooks/useWindowSize'
import Error from '@/layout/Error'
import { breakpoints } from '@/lib/theme'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import {
	getGetUserByIdQueryKey,
	getUsersCurrentUserQueryKey,
	useGetUserById,
	useUpdateUserRoleFromAccountById,
	useUsersPatchCurrentUser,
	useUsersPatchUser,
} from '@/api/videobot'

import Button from '../../components/Button'
import UserProfileForm from '../../features/Users/components/UserProfileForm'
import View from '../../layout/View'

const SingleUserContents = () => {
	const { t, i18n } = useTranslation(['account', 'user', 'errors'])
	const { id } = useParams()
	const currentUser = useCurrentUser()
	const activeAccount = useCurrentAccount()
	const formRef = useRef(null)
	const { windowWidth } = useWindowSize()
	const navigate = useNavigate()
	const [, setCurrentLanguage] = useLocalStorageState('language', {
		defaultValue: 'en',
	})
	const { search: urlQuery } = useLocation()
	const queryClient = useQueryClient()
	const { data: userData, isLoading: isUserLoading, error: userError } = useGetUserById(id)
	const userRole = userData?.roles.find((o) => o.accountId === activeAccount.id)
	const { mutateAsync: mutateUserById, isLoading: isMutateUserByIdLoading } = useUsersPatchUser({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries(getGetUserByIdQueryKey(id))
			},
		},
	})
	const { mutateAsync: mutateRoleForUserById, isLoading: isMutateRoleForUserById } =
		useUpdateUserRoleFromAccountById({
			mutation: {
				onSuccess: () => {
					queryClient.invalidateQueries(getGetUserByIdQueryKey(id))
				},
			},
		})
	const { mutate: mutateCurrentUser, isLoading: isMutateCurrentUserLoading } =
		useUsersPatchCurrentUser({
			mutation: {
				onSuccess: () => {
					queryClient.invalidateQueries(getGetUserByIdQueryKey(id))
					queryClient.invalidateQueries(getUsersCurrentUserQueryKey())
				},
			},
		})
	const isFormLoading =
		isMutateUserByIdLoading || isMutateCurrentUserLoading || isMutateRoleForUserById

	const Actions = (
		<Button onClick={() => formRef?.current?.submit()} isLoading={isFormLoading}>
			{t('saveChanges')}
		</Button>
	)

	const ActionsTopBar = (
		<Button
			onClick={() => formRef?.current?.submit()}
			variant={windowWidth <= breakpoints.lg ? 'link' : 'primary'}
			isLoading={isFormLoading}
		>
			{windowWidth <= breakpoints.lg ? t('save') : t('saveChanges')}
		</Button>
	)

	const updateUser = async (data) => {
		const role = {
			status: 'Active',
			role: data.role,
		}

		try {
			await Promise.all([
				mutateRoleForUserById({
					userId: userData.id,
					accountId: activeAccount.id,
					data: role,
				}),
				mutateUserById({ userId: userData.id, data }),
			])

			toast.success(t('user:userUpdated'))
			navigate('/dashboard/users')
		} catch {
			toast.error(t('errors:errorOccured'))
		}
	}

	const updateCurrentUser = (data) => {
		mutateCurrentUser(
			{ data },
			{
				onSuccess: () => {
					toast.success(t('user:userUpdated'))
					navigate('/dashboard/users')

					if (userData.id === currentUser.id) {
						i18n.changeLanguage(data.language)
						setCurrentLanguage(data.language)
					}
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
				},
			},
		)
	}

	return (
		<React.Fragment>
			{userData && (userRole || Number(id) === currentUser.id) && (
				<View
					title={userData && `${userData.firstName || ''} ${userData.lastName || ''}`}
					shortTitle={
						userData && `${userData.firstName || ''} ${userData.lastName || ''}`
					}
					actions={Actions}
					topBarActions={ActionsTopBar}
					showTopBar
					showTopBarOnDesktop
					isLoading={isUserLoading}
					hideHeaderActionsOnMobile
					hide={!userData}
					cover
					backRoute={`/dashboard/users${urlQuery}`}
				>
					<UserProfileForm
						ref={formRef}
						userData={userData}
						role={userRole}
						onSubmit={(data) =>
							Number(id) === currentUser.id
								? updateCurrentUser(data)
								: updateUser(data)
						}
					/>
				</View>
			)}
			{isUserLoading && <Loader cover isLoading={isFormLoading} />}
			{userError && <Error background="body" />}
		</React.Fragment>
	)
}

const SingleUser = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Super_Admin,
				RoleType.Reseller,
				RoleType.Owner,
				RoleType.Member,
				RoleType.Admin,
			]}
		>
			<SingleUserContents />
		</RoleCheck>
	)
}

export default SingleUser
