import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import CheckPicker from 'rsuite/CheckPicker'
import { debounce } from 'lodash-es'
import { useAtom } from 'jotai'

import Modal from '@/components/Modal'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import { FlagType, FeatureType } from '@/api/videobot.schemas'
import { useReadAccounts, useGetAllUsers, useCreateFlag } from '@/api/videobot'
import { createFeatureAccountsFilter, createFeatureUsersFilter } from '@/pages/admin/admin.store'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

export const FeatureFlagCreateModal = ({ onClose, onFlagCreated }) => {
	const { t } = useTranslation(['feature'])
	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		defaultValues: {
			is_everyone: false,
			is_superadmin: false,
		},
	})

	const [accountIds, setAccountIds] = useAtom(createFeatureAccountsFilter) || []
	const [userIds, setUserIds] = useAtom(createFeatureUsersFilter) || []

	const [businessName, setBusinessName] = useState('')

	const debouncedSearch = useMemo(
		() =>
			debounce((value) => {
				setBusinessName(value)
			}, 300),
		[],
	)

	const handleBusinessNameChange = (value) => {
		debouncedSearch(value)
	}

	const { data: allAccountsData } = useReadAccounts({ size: 100, business_name: businessName })
	const { data: users } = useGetAllUsers()

	const accountSelections =
		allAccountsData?.records.map((account) => ({
			label: account.businessName,
			value: account.id,
		})) || []

	const userSelections =
		users?.map((user) => ({
			label: user.email,
			value: user.id,
		})) || []

	const handleClose = () => {
		setAccountIds([])
		setUserIds([])
		onClose()
	}

	const createFlagMutation = useCreateFlag()

	const submitForm = async (data) => {
		const selectedUserIds = userIds || []
		const selectedAccountIds = accountIds || []
		const payload = {
			name: data.flagName,
			description: data.flagDescription,
			flag_type: data.flagType,
			is_everyone: data.is_everyone,
			is_superadmin: data.is_superadmin,
			user_ids: selectedUserIds,
			account_ids: selectedAccountIds,
		}

		try {
			await createFlagMutation.mutateAsync({ data: payload })
			toast.success(t('Feature flag created successfully!'))
			onFlagCreated()
			handleClose()
		} catch {
			toast.error(t('Error creating flag. Please try again.'))
		}
	}

	const flagTypeOptions = Object.values(FlagType).map((value) => ({
		label: value,
		value,
	}))

	const featureTypeOptions = Object.values(FeatureType).map((value) => ({
		label: value,
		value,
	}))

	return (
		<Modal header={<h3>{t('createFlag')}</h3>} onClose={handleClose}>
			<form onSubmit={handleSubmit(submitForm)}>
				<FormField label={t('flagName')}>
					<FieldSelect
						register={register}
						placeholder={t('selectFlagName')}
						name="flagName"
						error={errors.flagName}
						options={featureTypeOptions}
						setValue={setValue}
					/>
				</FormField>

				<FormField label={t('flagDescription')}>
					<FieldText
						register={register}
						placeholder={t('addFlagDescription')}
						name="flagDescription"
						type="text"
						error={errors.flagDescription}
					/>
				</FormField>

				<FormField label={t('flagType')}>
					<FieldSelect
						register={register}
						placeholder={t('selectFlagType')}
						name="flagType"
						error={errors.flagType}
						options={flagTypeOptions}
						setValue={setValue}
						required
					/>
				</FormField>

				<FormField label={t('addAccountIds')}>
					<div style={{ width: '100%' }}>
						<CheckPicker
							data={accountSelections}
							value={accountIds}
							onChange={(value) => {
								setAccountIds(value)
							}}
							onSearch={handleBusinessNameChange}
							style={{ width: '100%' }}
							placeholder="Select accounts"
						/>
					</div>
				</FormField>

				<FormField label={t('addUserId')}>
					<div style={{ width: '100%' }}>
						<CheckPicker
							data={userSelections}
							value={userIds}
							onChange={(value) => {
								setUserIds(value)
							}}
							style={{ width: '100%' }}
							placeholder="Select users"
						/>
					</div>
				</FormField>

				<FormField label={t('is_everyone')}>
					<ControlledFieldToggle control={control} name="is_everyone" />
				</FormField>

				<FormField label={t('is_superadmin')}>
					<ControlledFieldToggle control={control} name="is_superadmin" />
				</FormField>

				<Button disabled={!isValid} block>
					{t('common:Save')}
				</Button>
			</form>
		</Modal>
	)
}
