import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const ExitEditorModal = ({ onClose, onExit, onSave }, _ref) => {
	const { t } = useTranslation(['videobot'])

	return (
		<Modal
			header={<h3>{t('videobot:leaveEditorTitle')}</h3>}
			onClose={onClose}
			variant="confirmation"
		>
			<p>{t('videobot:leaveEditorDescription')}</p>
			<Button variant="secondary" onClick={onExit}>
				{t('exit')}
			</Button>
			<Button onClick={onSave}>{t('saveAndExit')}</Button>
		</Modal>
	)
}

ExitEditorModal.propTypes = {
	onClose: PropTypes.func,
	onExit: PropTypes.func,
	onSave: PropTypes.func,
}

export default ExitEditorModal
