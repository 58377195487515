import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Col } from '.'
import media from '../../lib/media'
import config, { DIMENSION_NAMES } from './config'

const ModificatorType = PropTypes.oneOf(DIMENSION_NAMES)

const Row = styled.div`
	box-sizing: border-box;
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	margin-right: ${(p) => (config(p).gutterWidth / 2) * -1}px;
	margin-left: ${(p) => (config(p).gutterWidth / 2) * -1}px;

	${(props) =>
		props.gutters === 'large' &&
		css`
			${media('>md')} {
				margin-right: ${(p) => (config(p).gutterWidthLg / 2) * -1}px;
				margin-left: ${(p) => (config(p).gutterWidthLg / 2) * -1}px;

				> ${Col} {
					padding-right: ${(p) => config(p).gutterWidthLg / 2}px;
					padding-left: ${(p) => config(p).gutterWidthLg / 2}px;
				}
			}
		`};

	${(props) =>
		props.gutters === 'small' &&
		css`
			margin-right: ${(p) => (config(p).gutterWidthSm / 2) * -1}px;
			margin-left: ${(p) => (config(p).gutterWidthSm / 2) * -1}px;

			> ${Col} {
				padding-right: ${(p) => config(p).gutterWidthSm / 2}px;
				padding-left: ${(p) => config(p).gutterWidthSm / 2}px;
			}
		`};

	${(p) =>
		p.reverse &&
		`
    flex-direction: row-reverse;
  `}

	${(p) =>
		p.start &&
		config(p).media[p.start]`
    justify-content: flex-start;
  `}

  ${(p) =>
		p.center &&
		config(p).media[p.center]`
    justify-content: center;
  `}

  ${(p) =>
		p.end &&
		config(p).media[p.end]`
    justify-content: flex-end;
  `}

  ${(p) =>
		p.top &&
		config(p).media[p.top]`
    align-items: flex-start;
  `}

  ${(p) =>
		p.middle &&
		config(p).media[p.middle]`
    align-items: center;
  `}

  ${(p) =>
		p.bottom &&
		config(p).media[p.bottom]`
    align-items: flex-end;
  `}

  ${(p) =>
		p.around &&
		config(p).media[p.around]`
    justify-content: space-around;
  `}

  ${(p) =>
		p.between &&
		config(p).media[p.between]`
    justify-content: space-between;
  `}

  ${(p) =>
		p.first &&
		config(p).media[p.first]`
    order: -1;
  `}

  ${(p) =>
		p.last &&
		config(p).media[p.last]`
    order: 1;
  `}
`

Row.displayName = 'Row'

Row.propTypes = {
	reverse: PropTypes.bool,
	start: ModificatorType,
	center: ModificatorType,
	end: ModificatorType,
	top: ModificatorType,
	middle: ModificatorType,
	bottom: ModificatorType,
	around: ModificatorType,
	between: ModificatorType,
	first: ModificatorType,
	last: ModificatorType,
	children: PropTypes.node,
}

Row.defaultProps = { gutters: 'default' }

export default Row
