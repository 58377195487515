import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@/components/Button'
import FieldUpload from '@/components/FieldUpload/FieldUpload'
import Panel from '@/components/Panel'
import PropTypes from '@/lib/propTypes'
import FieldText from '@/components/FieldText'
import { useUploadVideoTus } from '@/features/MediaLibrary/store/media_library.store'
import { MEDIA_LIST_INITIAL_PARAMS } from '@/lib/constants'
import { useGetAllMedia } from '@/api/videobot'
import { allMediasKey } from '@/api/videobot.keys'
import { useCurrentAccount } from '@/modules/session/auth.store'

import MediaList from '../MediaList'
import MediaUploadList from '../MediaUploadList'

const MediaPanelActions = ({ onSave, onCancel }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onCancel} variant="secondary">
				{t('cancel')}
			</Button>
			<Button onClick={onSave}>{t('confirm')}</Button>
		</React.Fragment>
	)
}

const MediaPanel = ({ onClose, onSelect, isMedia = false }) => {
	const [searchParams, setSearchParams] = useSearchParams(MEDIA_LIST_INITIAL_PARAMS)
	const { register, handleSubmit } = useForm({
		defaultValues: { name: searchParams.get('name') },
	})
	const { t } = useTranslation(['media'])
	const activeAccount = useCurrentAccount()
	const [selectedMedia, setSelectedMedia] = useState(null)

	const params = Object.fromEntries(searchParams)
	const queryClient = useQueryClient()

	const { data, isFetching } = useGetAllMedia(
		{ account_id: activeAccount?.id, ...params },
		{
			query: {
				enabled: Boolean(activeAccount),
				refetchInterval: 5000,
				keepPreviousData: true,
			},
		},
	)

	const invalidateQueries = () => {
		queryClient.invalidateQueries({
			queryKey: allMediasKey(),
		})
	}

	const doUpload = useUploadVideoTus({
		onUploaded: () => {
			invalidateQueries()
		},
	})

	const onFileUpload = (file) => {
		doUpload(file)
	}

	const onSave = (event) => {
		event.preventDefault()
		if (onSelect) onSelect(selectedMedia)
	}

	const onSearch = handleSubmit((data, event) => {
		event.preventDefault()
		setSearchParams((prev) => ({ ...Object.fromEntries(prev), page: 1, ...data }))
	})

	return (
		<Panel
			title={isMedia ? t('media:replaceMedia') : t('media:replaceVideo')}
			onBack={() => onClose()}
			footer={<MediaPanelActions onCancel={onClose} onSave={onSave} />}
			zIndex={155}
		>
			<FieldUpload onUpload={onFileUpload} isMedia={isMedia} />
			<MediaUploadList />
			<form className="mb-2 flex gap-4">
				<label className="flex items-center gap-2">
					<FieldText
						type="text"
						className="rounded"
						placeholder={isMedia ? 'Search media...' : 'Search videos...'}
						name="name"
						register={register}
					/>
				</label>

				<button
					onClick={onSearch}
					className="rounded bg-primary px-8 text-body transition hover:bg-dark"
					type="button"
				>
					Search
				</button>
			</form>
			<MediaList
				singleSelect
				onMediaSelect={setSelectedMedia}
				data={data}
				invalidateMediaList={invalidateQueries}
				isLoading={isFetching}
			/>
		</Panel>
	)
}

MediaPanel.propTypes = {
	onUploaded: PropTypes.func,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
	onUpload: PropTypes.func,
	show: PropTypes.bool,
	isMedia: PropTypes.bool,
}

export default MediaPanel
