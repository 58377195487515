import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import config, { DIMENSION_NAMES } from './config'

const Container = styled.div`
	margin-right: auto;
	margin-left: auto;
	padding-right: ${(p) => `${config(p).outerMargin}px`};
	padding-left: ${(p) => `${config(p).outerMargin}px`};

	${(p) =>
		!p.fluid &&
		css`
			${DIMENSION_NAMES.map((t) => {
				if (config(p).container[t]) {
					return config(p).media[t]`width: ${(p) => config(p).container[t]}px;`
				} else {
					return 'width: 100%;'
				}
			})}
		`}
`

Container.displayName = 'Container'

Container.propTypes = {
	fluid: PropTypes.bool,
	children: PropTypes.node,
}

export default Container
