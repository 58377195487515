import styled from 'styled-components'

import { MoreTogglerWrapper } from '../../components/More/More.styles'
import media from '../../lib/media'

export const TopBarWrapper = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	position: fixed;
	top: 0;
	height: ${(props) => props.theme.size.topBar.mobile};
	display: flex;
	align-items: center;
	padding: 0 ${(props) => props.theme.grid.outerMargin};
	left: 0;
	right: 0;
	z-index: 120;

	${media('>lg')} {
		display: none;
	}
`

export const TopBarTeam = styled.div``

export const TopBarUser = styled.div`
	position: relative;
	margin-left: auto;

	${MoreTogglerWrapper} {
		display: block;
	}
`
