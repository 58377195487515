import ky, { HTTPError } from 'ky'
import { omitBy, isNull } from 'lodash-es'
import { Auth } from 'aws-amplify'

function sanitizeApiUrl(baseUrl: string) {
	const url = new URL(baseUrl)
	url.username = ''
	url.password = ''
	return url.href
}

const baseUrl = sanitizeApiUrl(import.meta.env.VITE_API_URL)

export class FetchError<TError> extends Error {
	public readonly status: string
	public readonly payload: TError
	public readonly statusCode: number

	constructor(code: number, status: string, payload: TError) {
		super(`${code} - ${status}`)
		this.name = `HTTP Error`
		this.statusCode = code
		this.status = status
		this.payload = payload
	}
}

export type ErrorType<TError> = FetchError<TError>

type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch'

// eslint-disable-next-line ts/no-empty-object-type
export const videobotFetch = async <TData, THeader extends {} = {}>({
	url,
	method,
	params,
	data,
	signal,
	headers,
}: {
	url: string
	method: HttpMethod | Uppercase<HttpMethod>
	params?: any
	data?: BodyType<unknown>
	signal?: any
	headers?: THeader
}) => {
	let token = ''
	try {
		token = (await Auth.currentSession()).getIdToken().getJwtToken()
	} catch {
		// do nothing
	}

	const requestHeaders: HeadersInit = {
		'Content-Type': 'application/json',
		authorization: `Bearer ${token}`,
		...headers,
	}

	/**
	 * As the fetch API is being used, when multipart/form-data is specified
	 * the Content-Type header must be deleted so that the browser can set
	 * the correct boundary.
	 * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
	 */
	if (requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data')) {
		delete requestHeaders['Content-Type']
	}

	let query = new URLSearchParams(omitBy(params, isNull)).toString()
	if (query) query = `?${query}`
	const base = new URL(url, baseUrl).href
	const urlHref = `${base}${query}`

	try {
		const response = await ky(urlHref, {
			method: method.toLowerCase(),
			body: data
				? data instanceof FormData || data instanceof URLSearchParams
					? data
					: JSON.stringify(data)
				: undefined,
			headers: requestHeaders,
			retry: 0,
			timeout: 150000,
			signal,
		})

		if (response.headers.get('content-type')?.includes('json')) {
			return (await response.json()) as unknown as TData
		} else {
			// if it is not a json response, assume it is a blob and cast it to TData
			return (await response.blob()) as unknown as TData
		}
	} catch (error) {
		if (error instanceof HTTPError) {
			let err: ErrorType<any>

			try {
				const json = await error.response.json()
				const statusText =
					error.response.statusText || json.detail || json.body || 'Unexpected error'
				err = new FetchError(error.response.status, statusText, json)
			} catch (e) {
				if (e instanceof Error) {
					err = new FetchError(500, 'unknown', {
						detail: `Unexpected error (${e.message})`,
					} as any)
				} else {
					throw e
				}
			}

			throw err
		} else {
			throw error
		}
	}
}

export default videobotFetch

export type BodyType<BodyData> = BodyData
