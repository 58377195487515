import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'lodash-es'

import Alert from '@/components/Alert'
import Loader from '@/components/Loader'
import { useReadDetailBotAnalytic } from '@/api/videobot'
import InfoIcon from '@/components/InfoIcon'

import {
	AnalyticsDetailsBar,
	AnalyticsDetailsBarIndicator,
	AnalyticsDetailsBarValue,
	AnalyticsDetailsBox,
	AnalyticsDetailsContent,
	AnalyticsDetailsEntry,
	AnalyticsDetailsHeader,
	AnalyticsDetailsWrapper,
} from './AnalyticsList.styles'

export const AnalyticsDetails = ({ data: botData, params, channel }) => {
	const { bot } = botData
	const { t } = useTranslation(['analytics', 'errors'])
	const asyncSource = !botData.slides
	const { data, isLoading, isError, isSuccess } = useReadDetailBotAnalytic(
		bot?.id,
		{
			channel,
			...params,
		},
		{
			enabled: asyncSource,
		},
	)
	const computedData = botData.slides ? botData : data
	const slidesTotal = useMemo(
		() => (data ? computedData.slides.reduce((acc, curr) => acc + curr.played, 0) : 0),
		[data],
	)

	const groupedActions = useMemo(() => {
		if (!data?.actions) return {}
		return groupBy(data.actions, 'slideName')
	}, [data])
	const actionsTotal = useMemo(
		() => data?.actions?.reduce((prev, cur) => prev + cur.actionClicked, 0),
		[data],
	)

	const fromDate = params?.from_date ? new Date(params.from_date) : null

	// FIXME: Temporary solution to display warning message for actions before 20.07.2023
	const isBeforeActionLogged = !fromDate || fromDate < new Date('2023-07-20')

	return (
		<AnalyticsDetailsWrapper>
			{(isSuccess || !asyncSource) && (
				<Fragment>
					<AnalyticsDetailsBox>
						<AnalyticsDetailsHeader>
							<span>{t('analytics:slides')}</span>
							<span>{t('analytics:views')}</span>
						</AnalyticsDetailsHeader>
						<AnalyticsDetailsContent>
							{computedData.slides?.length > 0 &&
								computedData.slides.map((item, index) => (
									<AnalyticsDetailsEntry key={`slide_${index}`}>
										<span>{item.name}</span>
										<AnalyticsDetailsBar>
											<AnalyticsDetailsBarIndicator
												grow={item.played / slidesTotal}
											/>
											<AnalyticsDetailsBarValue>
												{item.played}
											</AnalyticsDetailsBarValue>
										</AnalyticsDetailsBar>
									</AnalyticsDetailsEntry>
								))}
							{computedData.slides?.length === 0 && '-'}{' '}
						</AnalyticsDetailsContent>
					</AnalyticsDetailsBox>
					<AnalyticsDetailsBox>
						<AnalyticsDetailsHeader>
							<span>{t('analytics:topSources')}</span>
							<span>{t('analytics:views')}</span>
						</AnalyticsDetailsHeader>
						<AnalyticsDetailsContent>
							{computedData.sources?.length > 0 &&
								computedData.sources.map((item, index) => (
									<dl key={`source_${index}`}>
										<dt>{item.source}</dt>
										<dd>{item.opened}</dd>
									</dl>
								))}
							{computedData.sources?.length === 0 && '-'}
						</AnalyticsDetailsContent>
					</AnalyticsDetailsBox>
					<AnalyticsDetailsBox>
						<AnalyticsDetailsHeader>
							<span className="flex justify-end gap-1">
								{t('analytics:topActions')}{' '}
								{isBeforeActionLogged && (
									<InfoIcon color="red">{t('analytics:actionsWarning')}</InfoIcon>
								)}
							</span>
							<span>{t('analytics:clicks')}</span>
						</AnalyticsDetailsHeader>
						<AnalyticsDetailsContent className="flex flex-col gap-3">
							{Object.entries(groupedActions).map(([key, value]) => (
								<AnalyticsDetailsEntry
									key={key}
									className="flex flex-1 flex-col gap-1"
								>
									<span className="text-sm text-muted">{key}</span>
									{value.map((item) => (
										<div key={item.actionName}>
											<span>{item.actionName}</span>
											<AnalyticsDetailsBar>
												<AnalyticsDetailsBarIndicator
													grow={item.actionClicked / actionsTotal}
												/>
												<AnalyticsDetailsBarValue>
													{item.actionClicked}
												</AnalyticsDetailsBarValue>
											</AnalyticsDetailsBar>
										</div>
									))}
								</AnalyticsDetailsEntry>
							))}
						</AnalyticsDetailsContent>
					</AnalyticsDetailsBox>
				</Fragment>
			)}
			{isError && asyncSource && (
				<Alert variant="transparent" center icon="exclamation-mark">
					{t('errors:errorOccured')}
				</Alert>
			)}
			{isLoading && asyncSource && <Loader cover noOverlay size={28} />}
		</AnalyticsDetailsWrapper>
	)
}
