import styled, { css } from 'styled-components'

import { MoreWrapper } from '@/components/More/More.styles'

import media from '../../lib/media'
import { Reset, TransitionPrimary } from '../../lib/mixins'

export const NavigationHeader = styled.div`
	${media('>lg')} {
		display: flex;
		align-items: center;
		padding: 0 1.75rem;
		height: 5rem;
		border-bottom: 1px solid ${(props) => props.theme.color.line};
		flex-shrink: 0;
	}
	${media('<=lg')} {
		display: none;
	}
`

export const NavigationBody = styled.div`
	${media('>lg')} {
		padding: 1.25rem 1.75rem;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
`

export const NavigationList = styled.div`
	position: relative;
	margin-bottom: auto;

	${media('<=lg')} {
		display: flex;
		align-items: center;
		justify-content: space-around;
		height: ${(props) => props.theme.size.mobileNavBar};
	}
`

export const NavigationLink = styled.a`
	position: relative;
	color: ${(props) => props.theme.color.muted};
	font-weight: 500;
	font-family: ${(props) => props.theme.font.secondary};
	display: flex;
	align-items: center;
	height: 2.7rem;
	${TransitionPrimary('color')}
	${media('>lg')} {
		margin-bottom: 0.5rem;
	}
	${media('<=lg')} {
		width: 2.7rem;
		justify-content: center;
	}

	&:before {
		position: absolute;
		top: 0;
		bottom: 0;
		content: ' ';
		${TransitionPrimary('opacity', 'background')}
		background-color: ${(props) => props.theme.color.light};
		border-radius: ${(props) => props.theme.radius.md};
		opacity: 0;
		${media('>lg')} {
			left: -1rem;
			right: -1rem;
		}
		${media('<=lg')} {
			left: 0;
			right: 0;
		}
	}

	span {
		position: relative;
		margin-left: 0.75rem;

		${media('<=lg')} {
			display: none;
		}
	}

	svg {
		position: relative;
		fill: currentColor;
	}

	&:hover {
		color: ${(props) => props.theme.color.global};

		&:before {
			opacity: 1;
		}
	}

	&.active {
		color: ${(props) => props.theme.color.inverted};
		&:before {
			background-color: ${(props) => props.theme.color.global};
			opacity: 1;
		}
	}
`

export const NavigationUser = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	font-size: 0.9rem;

	${media('<=lg')} {
		display: none;
	}

	> span {
		padding: 0 0.75rem;
		flex: 1 1 auto;
	}
`

export const NavigationWrapper = styled.div`
	position: fixed;
	${TransitionPrimary('background, color')}

	${(props) =>
		!props.inverted &&
		css`
			background-color: ${(props) => props.theme.color.light};
		`}

  ${(props) =>
		props.inverted &&
		css`
			background-color: ${(props) => props.theme.color.dark};
			color: white;

			${media('>lg')} {
				${NavigationHeader} {
					border-bottom: 1px solid ${(props) => props.theme.color.lineInverted};
				}
			}

			${NavigationLink} {
				color: ${(props) => props.theme.color.mutedInverted};
				&:before {
					background-color: rgba(255, 255, 255, 0.1);
				}
				&.active {
					color: ${(props) => props.theme.color.inverted};
					&:before {
						background-color: rgba(255, 255, 255, 0.2);
						opacity: 1;
					}
				}
			}
		`};

	${media('>lg')} {
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
		width: ${(props) => props.theme.size.desktopNav.extended};
		display: flex;
		border-right: 1px solid ${(props) => props.theme.color.line};

		${(props) =>
			props.collapsed &&
			css`
				${NavigationBody} {
					padding-left: 0;
					padding-right: 0;
					align-items: center;
				}
				${NavigationLink} {
					width: 2.7rem;
					justify-content: center;

					&:before {
						left: 0;
						right: 0;
					}
					span {
						display: none;
					}
				}
				${NavigationUser} {
					> span {
						display: none;
					}

					${MoreWrapper} {
						display: none;
					}
				}
			`};
	}

	${media('<=lg')} {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
`
export const NavigationInner = styled.div`
	width: 100%;
	${TransitionPrimary('opacity')}

	${(props) =>
		props.hide &&
		css`
			opacity: 0;
		`};

	${media('>lg')} {
		display: flex;
		flex-direction: column;
	}
`

export const NavigationToggler = styled.button`
	${Reset('button')}
	position: absolute;
	top: 5rem;
	right: 0;
	width: 2rem;
	height: 2rem;
	transform: translate3d(50%, -50%, 0);
	border-radius: 50%;
	background: ${(props) => props.theme.color.body};
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	box-shadow: 0 2px 10px 0 rgba(0, 3, 41, 0.1);

	${media('<=lg')} {
		display: none;
	}

	svg {
		width: 0.7rem;
		display: block;
		height: 0.7rem;
	}

	${(props) =>
		props.collapsed &&
		css`
			svg {
				transform: rotate(180deg);
			}
		`};
`
