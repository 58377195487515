import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PropTypes from '@/lib/propTypes'

import Button from '../Button'
import { FieldFileInput, FieldFileLabel, FieldFileWrapper } from './FieldFile.styles'

const FieldFile = (props) => {
	const { fileTypes = '*', required, name, placeholder, register, error } = props
	const { onChange, ref } = register(name, { required })
	const [value, setValue] = useState('')

	const { t } = useTranslation(['common', 'media'])
	const wrapperRef = useRef(null)

	const handleChange = (e) => {
		onChange(e)

		if (e.target.files.length > 0) {
			setValue(
				Array.from(e.target.files)
					.map((file) => file.name)
					.join(', '),
			)
		} else {
			setValue('')
		}
	}

	useEffect(() => {
		return () => {}
	}, [])

	return (
		<FieldFileWrapper
			error={error}
			ref={wrapperRef}
			onClick={() => wrapperRef.current.querySelector('input[type="file"]').click()}
		>
			<FieldFileInput
				ref={ref}
				onChange={handleChange}
				name={name}
				type="file"
				accept={fileTypes}
			/>
			<FieldFileLabel>{value || placeholder}</FieldFileLabel>
			<Button size="small">{t('browse')}</Button>
		</FieldFileWrapper>
	)
}

FieldFile.propTypes = {
	fileTypes: PropTypes.string,
	name: PropTypes.string,
	onFile: PropTypes.func,
}

FieldFile.defaultProps = {
	placeholder: 'Select...',
}

export default FieldFile
