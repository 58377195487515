import { useContext } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'
import AccountSettingsAccountForm from '@/features/Account/components/AccountSettingsAccountForm/AccountSettingsAccountForm'
import {
	getReadAccountDetailByIdQueryKey,
	useReadAccountDetailById,
	useUpdateAccountById,
} from '@/api/videobot'
import { AccountSettingsContext } from '@/pages/Account/account_settings.context'

const AccountSettingsAccountContents = () => {
	const { t } = useTranslation(['common', 'account', 'user', 'errors'])
	const { formRef, setIsFormSubmitting } = useContext(AccountSettingsContext)
	const activeAccount = useCurrentAccount()
	const queryClient = useQueryClient()
	const { data: account, isLoading: isAccountLoading } = useReadAccountDetailById(
		activeAccount.id,
	)

	const updateAccountMutation = useUpdateAccountById()
	const handleUpdateAccount = (accountData) => {
		setIsFormSubmitting(true)
		updateAccountMutation.mutate(
			{ accountId: activeAccount.id, data: accountData },
			{
				onSuccess: () => {
					toast.success(t('updated'))
					queryClient.invalidateQueries(
						getReadAccountDetailByIdQueryKey(activeAccount.id),
					)
					setIsFormSubmitting(false)
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
					setIsFormSubmitting(false)
				},
			},
		)
	}

	return (
		<div className="mb-20">
			{account && (
				<AccountSettingsAccountForm
					ref={formRef}
					account={account}
					variant="UPDATE"
					isLoading={isAccountLoading}
					onSubmit={handleUpdateAccount}
				/>
			)}
		</div>
	)
}

const AccountSettingsAccount = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin, RoleType.Reseller, RoleType.Owner]}>
			<AccountSettingsAccountContents />
		</RoleCheck>
	)
}

export default AccountSettingsAccount
