import PropTypes from 'prop-types'

import InfoIcon from '../InfoIcon'
import { FormFieldWrapper } from './FormField.styles'

const FormField = ({ label, noMargin, children, required, tooltip }) => {
	return (
		<FormFieldWrapper noMargin={noMargin}>
			{label && (
				<label>
					{label}
					{required && '*'}
					{tooltip && <InfoIcon wide>{tooltip}</InfoIcon>}
				</label>
			)}
			{children}
		</FormFieldWrapper>
	)
}

FormField.propTypes = {
	label: PropTypes.string,
	noMargin: PropTypes.bool,
	required: PropTypes.bool,
	children: PropTypes.node,
	tooltip: PropTypes.node,
}

export default FormField
