import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { Auth } from 'aws-amplify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const ResetPaswordModal = ({ onClose, show, user }) => {
	const { t } = useTranslation(['common', 'user'])
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)

	const ModalHeader = <h3>{t('user:sendResetPasswordLink')}</h3>

	const sendResetPasswordLink = async () => {
		setIsLoading(true)
		try {
			await Auth.forgotPassword(user.email)
			toast.success(t('user:resetPasswordLinkSent'))
			navigate('/dashboard/users')
			onClose()
		} catch (e) {
			toast.error(t('errors:errorOccured'))
			onClose()
			throw e
		}
	}

	return (
		<Modal header={ModalHeader} show={show} onClose={onClose} variant="confirmation">
			<p>
				<Trans
					i18nKey="user:sendResetPasswordLinkDescription"
					values={{ firstName: user?.firstName, lastName: user?.lastName }}
				>
					Do you want to send a reset password link to &nbsp;
					<strong>
						{user?.firstName} {user?.lastName}
					</strong>
					?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="primary" onClick={sendResetPasswordLink} isLoading={isLoading} wide>
				{t('send')}
			</Button>
		</Modal>
	)
}

ResetPaswordModal.propTypes = {
	onClose: PropTypes.func,
	show: PropTypes.bool,
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
}

export default ResetPaswordModal
