import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import AccountLink from '@/components/AccountLink'
import Avatar from '@/components/Avatar'
import { useAuth, useCurrentUser } from '@/modules/session/auth.store'
import {
	getReadCurrentUserAccountsQueryKey,
	getUsersCurrentUserQueryKey,
	useReadCurrentUserAccounts,
	useUpdateRoleForUserById,
} from '@/api/videobot'
import AuthLayout from '@/features/Auth/components/AuthLayout'
import { RoleStatusType } from '@/api/videobot.schemas'

import AuthForm from '../AuthForm'

const TeamSelectHeader = () => {
	const { t } = useTranslation(['auth'])
	const user = useCurrentUser()
	return (
		<React.Fragment>
			<Avatar size="lg" name={user.firstName} src={user.profilePicture?.url}></Avatar>
			<h4>{t('auth:selectAccountToLaunch')}</h4>
			<p>
				<Trans i18nKey="auth:youAreLoggedAs" values={{ email: user.email }}>
					Youre logged in as
					<strong>{user.email}</strong>
				</Trans>
			</p>
		</React.Fragment>
	)
}

const TeamSelect = () => {
	const { t } = useTranslation(['auth', 'common'])
	const { setActiveAccount, logout } = useAuth()
	const { data: accounts } = useReadCurrentUserAccounts()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: mutateRole, isLoading } = useUpdateRoleForUserById({
		mutation: {
			onSuccess: (data) => {
				setActiveAccount(data.accountId)
				queryClient.invalidateQueries(getReadCurrentUserAccountsQueryKey())
				queryClient.invalidateQueries(getUsersCurrentUserQueryKey())
				navigate('/dashboard')
			},
			onError: (error) => {
				toast.error(error.message)
			},
		},
	})
	const currentUser = useCurrentUser()

	const selectAccount = (account) => {
		setActiveAccount(account.id)
		navigate('/dashboard')
	}

	const loginOtherAccount = () => {
		logout()
		navigate('/login')
	}

	const joinAccount = (roleId) => {
		mutateRole({ userId: currentUser.id, roleId, data: { status: RoleStatusType.Active } })
	}

	return (
		<AuthLayout variant="login">
			<AuthForm header={<TeamSelectHeader />} isLoading={isLoading}>
				{accounts
					.filter((o) => o.roleStatus === RoleStatusType.Active)
					.map((account) => (
						<AccountLink
							key={account.id}
							color={account.color}
							button={t('select')}
							onButtonClick={() => selectAccount(account)}
						>
							{account.businessName}
						</AccountLink>
					))}
				{accounts
					.filter((o) => o.roleStatus === RoleStatusType.Invited)
					.map((account) => (
						<AccountLink
							key={account.id}
							button={t('join')}
							onButtonClick={() => joinAccount(account.roleId)}
						>
							{account.businessName}
						</AccountLink>
					))}
				<AccountLink onClick={() => loginOtherAccount()}>
					{t('auth:logInToOtherAccount')}
				</AccountLink>
			</AuthForm>
		</AuthLayout>
	)
}

export default TeamSelect
