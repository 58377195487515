import type { PrimitiveAtom } from 'jotai'
import { atom, useSetAtom } from 'jotai'
import { clamp } from 'lodash-es'
import * as tus from 'tus-js-client'
import { toast } from 'react-toastify'
import { captureException } from '@sentry/react'
import { Auth } from 'aws-amplify'

import { useCurrentAccount } from '@/modules/session/auth.store'

export interface UploadingMedia {
	filename: string
	progress: number
	abort: () => void
}

export const uploadingMediasAtom = atom<PrimitiveAtom<UploadingMedia>[]>([])
export const cancelUploadMediaAtom = atom(
	null,
	(get, set, mediaAtom: PrimitiveAtom<UploadingMedia>) => {
		set(
			uploadingMediasAtom,
			get(uploadingMediasAtom).filter((m) => m !== mediaAtom),
		)
		get(mediaAtom).abort()
	},
)
export const addUploadMediaAtom = atom(
	null,
	(get, set, mediaAtom: PrimitiveAtom<UploadingMedia>, abortFn: () => void) => {
		set(mediaAtom, { ...get(mediaAtom), abort: abortFn })
		set(uploadingMediasAtom, [...get(uploadingMediasAtom), mediaAtom])
	},
)
export const setProgressUploadMediaAtom = atom(
	null,
	(get, set, mediaAtom: PrimitiveAtom<UploadingMedia>, progress: number) => {
		set(mediaAtom, { ...get(mediaAtom), progress: clamp(progress, 0, 1) })
	},
)

export const useUploadVideoTus = (options: { onUploaded?: () => void } | undefined) => {
	const activeAccount = useCurrentAccount()
	const addUpload = useSetAtom(addUploadMediaAtom)
	const removeUpload = useSetAtom(cancelUploadMediaAtom)
	const setProgressUploadMedia = useSetAtom(setProgressUploadMediaAtom)

	const doUpload = async (file: File) => {
		// get token from amplify
		const token = (await Auth.currentSession()).getIdToken().getJwtToken()

		const mediaAtom = atom<UploadingMedia>({
			filename: file.name,
			progress: 0,
			abort: () => undefined,
		})

		if (!activeAccount) {
			throw new Error('No active account')
		}

		const upload = new tus.Upload(file, {
			storeFingerprintForResuming: true,
			removeFingerprintOnSuccess: true,
			chunkSize: 150 * 1024 * 1024,
			endpoint: new URL('/media/tus', import.meta.env.VITE_API_URL).href,
			retryDelays: [0, 1000],
			metadata: {
				name: file.name,
			},
			headers: {
				Authorization: `Bearer ${token}`,
				'Account-Id': `${activeAccount.id}`,
			},
			onUploadUrlAvailable() {
				this.headers = {}
			},
			onSuccess: () => {
				options?.onUploaded?.()
				setProgressUploadMedia(mediaAtom, 1)
				setTimeout(() => {
					removeUpload(mediaAtom)
				}, 1000)
			},
			onProgress: (bytesSent, bytesTotal) => {
				setProgressUploadMedia(mediaAtom, Math.min(bytesSent / bytesTotal, 0.99))
			},
			onError: (error) => {
				console.error(error)
				if (error) {
					toast.error((error as any)?.response?.data || error?.message)
				} else {
					toast.error('Unexpected error')
				}
				captureException(error)
				setTimeout(() => {
					removeUpload(mediaAtom)
				}, 1000)
			},
		})

		if (!tus.isSupported) {
			toast.error('Browser does not support uploads, please use modern browsers instead.')
			return
		}

		addUpload(mediaAtom, () => upload.abort())
		upload.start()
	}

	return doUpload
}
