import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

export const DeactivateVideobotModal = ({ onClose, onSubmit, videobot, isLoading }) => {
	const { t } = useTranslation(['errors', 'common', 'videobot'])

	return (
		<Modal
			header={<h3>{t('videobot:deactivateVideobot')}</h3>}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="videobot:deactivateVideobotDescription"
					values={{ name: videobot?.name }}
				>
					Are you sure you want to deactivate videobot
					<strong>{videobot?.name}</strong>?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="red-solid" onClick={() => onSubmit()} isLoading={isLoading} wide>
				{t('deactivate')}
			</Button>
		</Modal>
	)
}
