import styled, { css } from 'styled-components'

import { MediaListWrapper } from '@/features/MediaLibrary/components/MediaList/MediaList.styles'
import { Cover, LinkUnderlineOnHover, Reset, TransitionPrimary } from '@/lib/mixins'

export const FieldUploadInput = styled.input`
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
`
export const FieldUploadBorder = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;

	rect {
		fill: none;
		stroke: ${(props) => props.theme.color.line};
		stroke-width: 1;
		stroke-dasharray: 5, 5;
	}
`

export const FieldUploadContent = styled.div`
	max-width: 30rem;
	margin: 0 auto;
	text-align: center;
	position: relative;

	h5 {
		margin-bottom: 0.75rem;
		font-size: 1rem;
		font-weight: 600;

		button {
			${Reset('button')}
			${LinkUnderlineOnHover()}
      color: ${(props) => props.theme.color.primary};
		}
	}

	p {
		font-size: 0.9rem;
		margin-bottom: 0;
		color: ${(props) => props.theme.color.muted};
	}
`

export const FieldUploadDrop = styled.div`
	${Cover()}
	z-index: 3;
	cursor: pointer;
`

export const FieldUploadIcon = styled.div`
	width: 4rem;
	height: 4rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.color.light};
	border-radius: ${(props) => props.theme.radius.xl};
	margin-bottom: 1.5rem;
	${TransitionPrimary('background')};

	svg {
		fill: ${(props) => props.theme.color.global};
		width: 45%;
		height: 45%;
		display: block;
	}
`

export const FieldUploadWrapper = styled.div`
	position: relative;
	padding: 3rem 2rem;
	border-radius: 8px;
	margin-bottom: 1rem;
	${TransitionPrimary('background')};
	${(props) =>
		props.dragEnter &&
		css`
			background-color: ${(props) => props.theme.color.light};

			${FieldUploadIcon} {
				background-color: ${(props) => props.theme.color.body};
			}
		`};

	&:hover {
		background-color: ${(props) => props.theme.color.light};

		${FieldUploadIcon} {
			background-color: ${(props) => props.theme.color.body};
		}
	}

	+ ${MediaListWrapper} {
		padding-top: 1.5rem;
	}
`
