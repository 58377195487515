import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { getReadAllWidgetsQueryKey, useDeleteWidgetById } from '@/api/videobot'

interface DeleteWidgetModalProps {
	onClose: () => void
	show: boolean
	name: string
	id: number
	accountId: number
}

const AnyButton = Button as any

export const DeleteWidgetModal = ({
	onClose,
	show,
	name,
	id,
	accountId,
}: DeleteWidgetModalProps) => {
	const { t } = useTranslation(['errors', 'common', 'widget'])
	const queryClient = useQueryClient()
	const { mutate, isLoading } = useDeleteWidgetById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: getReadAllWidgetsQueryKey({ account_id: accountId }),
				})
				toast.success(t('widget:notification.widget_deleted'))
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	return (
		<Modal
			header={<h3>{t('widget:delete_widget')}</h3>}
			show={show}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans i18nKey="widget:delete_widget_description" values={{ name }}>
					Are you sure you want to delete widget &nbsp;
					<strong>{name}</strong>?
				</Trans>
			</p>
			<AnyButton variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</AnyButton>
			<AnyButton
				variant="red-solid"
				onClick={() => mutate({ widgetId: id })}
				isLoading={isLoading}
				wide
			>
				{t('remove')}
			</AnyButton>
		</Modal>
	)
}
