import React, { useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { produce } from 'immer'
import { kebabCase, capitalize } from 'lodash-es'

import { getNextId } from '@/lib/utils'
import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FieldRadio from '@/components/FieldRadio'
import FormField from '@/components/FormField'
import Panel from '@/components/Panel'
import { Row, Col } from '@/components/Grid'
import { breakpoints } from '@/lib/theme'
import ListTable from '@/components/ListTable/ListTable'
import More, { MoreButton } from '@/components/More'

import EditContactFormFieldPanel from './EditContactFormFieldsPanel'

const ContactFormFieldMenu = ({ row, onEditClick, onDeleteClick }) => {
	const { t } = useTranslation(['user'])

	return (
		<More>
			<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

const EditContactFormActions = ({ onSave, onDelete, isDisabled }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onDelete} variant="secondary">
				{t('delete')}
			</Button>
			<Button onClick={onSave} disabled={isDisabled}>
				{t('save')}
			</Button>
		</React.Fragment>
	)
}

const EditContactFormActionPanel = ({ formData, onSave, onDelete, onBack }) => {
	const { t } = useTranslation()
	const [fieldToEdit, setFieldToEdit] = useState(null)
	const formRef = useRef()
	const {
		register,
		setValue,
		formState: { errors, isDirty },
		handleSubmit,
		reset,
		watch,
		getValues,
	} = useForm({
		mode: 'onBlur',
		values: formData,
	})

	const currentForm = watch()

	const handleSave = (data) => {
		onSave({ ...formData, ...data })
		reset()
	}

	const handleDelete = () => {
		onDelete(formData.id)
	}

	const handleAddField = () => {
		const fieldPrototype = { id: getNextId(currentForm.fields) }
		setFieldToEdit(fieldPrototype)
	}

	const handleEditField = (field) => {
		setFieldToEdit(field)
	}

	const handleFieldReorder = (payload) => {
		setValue('fields', payload)
	}

	const handleRemoveField = (payload) => {
		const fields = getValues('fields')
		setValue(
			'fields',
			fields.filter((o) => o.id !== payload.id),
			{ shouldDirty: true },
		)
	}

	const handleFieldSave = (fieldObj) => {
		const fields = currentForm.fields
		const index = fields.findIndex((o) => o.id === fieldObj.id)

		const newFields = produce(fields, (draft) => {
			if (index !== -1) {
				draft[index] = fieldObj
			} else {
				draft.push({
					name: kebabCase(fieldObj.label.toLowerCase()),
					...fieldObj,
				})
			}
		})
		setValue('fields', newFields, { shouldDirty: true })
	}

	const optionsColumns = [
		{
			name: t('videobot:formElementFields.type.label'),
			selector: (row) => {
				if (row.type === 'general_checkbox') {
					return 'Checkbox'
				}
				return capitalize(row.type)
			},
		},
		{
			name: t('videobot:formElementFields.label.label'),
			selector: (row) => row.label,
		},
		{
			name: t('videobot:formElementFields.validation.label'),
			cell: (row) =>
				row.required ? (
					<React.Fragment>
						{t('videobot:formElementFields.validation.required')}
					</React.Fragment>
				) : (
					t('videobot:formElementFields.validation.optional')
				),
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<ContactFormFieldMenu
						row={row}
						onDeleteClick={() => handleRemoveField(row)}
						onEditClick={() => setFieldToEdit(row)}
					/>
				)
			},
		},
	]

	return (
		<Panel
			title={t('videobot:editContactForm')}
			onBack={() => onBack()}
			footer={
				<EditContactFormActions
					onSave={handleSubmit(handleSave)}
					onDelete={() => handleDelete()}
					saveDisabled={!isDirty && !currentForm?.isNew}
				/>
			}
		>
			<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
				<Row>
					<Col sm={6} xxs={12}>
						<FormField label={t('videobot:contactFormFields.name.label')} required>
							<FieldText
								register={register}
								placeholder={t('videobot:contactFormFields.name.placeholder')}
								name="buttonText"
								error={errors.buttonText}
								defaultValue={formData?.buttonText}
								required
							/>
						</FormField>
					</Col>
					<Col sm={6} xxs={12}>
						{/* Title */}
						<FormField label={t('videobot:socialLinkFields.button.label')}>
							<FieldRadio
								register={register}
								name="buttonStyle"
								value="standard"
								defaultChecked={formData.buttonStyle === 'standard'}
								setValue={setValue}
								inline
							>
								{t('videobot:socialLinkFields:button.standard')}
							</FieldRadio>
							<FieldRadio
								register={register}
								name="buttonStyle"
								value="highlight"
								defaultChecked={formData.buttonStyle === 'highlight'}
								setValue={setValue}
								inline
							>
								{t('videobot:socialLinkFields:button.highlight')}
							</FieldRadio>
						</FormField>
					</Col>
				</Row>
				<FormField label={t('videobot:formFields.description.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:formFields.description.placeholder')}
						name="description"
						error={errors.description}
						required
					/>
				</FormField>
				<Row>
					<Col sm={6} xxs={12}>
						{/* Title */}
						<FormField label={t('videobot:formFields.email.label')} required>
							<FieldText
								register={register}
								placeholder={t('videobot:formFields.email.placeholder')}
								name="emailTo"
								type="email"
								error={errors.emailTo}
								required
							/>
						</FormField>
					</Col>
					<Col sm={6} xxs={12}>
						{/* Title */}
						<FormField label={t('videobot:formFields.emailBcc.label')}>
							<FieldText
								register={register}
								placeholder={t('videobot:formFields.emailBcc.placeholder')}
								type="email"
								name="emailBcc"
								error={errors.emailBcc}
							/>
						</FormField>
					</Col>
				</Row>
				<FormField label={t('videobot:formFields.title.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:formFields.title.placeholder')}
						name="title"
						error={errors.title}
						required
					/>
				</FormField>
				<FormField label={t('videobot:formFields.subject.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:formFields.subject.placeholder')}
						name="subject"
						error={errors.subject}
						required
					/>
				</FormField>
			</form>
			<ListTable
				dragEnabled
				columns={optionsColumns}
				data={currentForm.fields}
				addAction={t('videobot:addField')}
				onAdd={handleAddField}
				onReorder={handleFieldReorder}
				onRowClicked={handleEditField}
			/>
			{/* Edit Option */}
			<AnimatePresence>
				{fieldToEdit && (
					<EditContactFormFieldPanel
						fieldData={fieldToEdit}
						onClose={() => setFieldToEdit(null)}
						onRemove={handleRemoveField}
						onSave={handleFieldSave}
					/>
				)}
			</AnimatePresence>
		</Panel>
	)
}

export default EditContactFormActionPanel
