import PropTypes from '@/lib/propTypes'

import Button from '../Button'
import Icon from '../Icon'
import { AccountLinkIcon, AccountLinkLabel, AccountLinkWrapper } from './AccountLink.styles'

const AccountLink = (props) => {
	const { children, other, onClick, color, button, onButtonClick } = props

	return (
		<AccountLinkWrapper
			as={onClick ? 'button' : 'div'}
			onClick={onClick}
			other={other}
			clickable={onClick}
		>
			<AccountLinkIcon color={color}>
				{other && <Icon name="user" />}
				{!other && <span>{children && children[0]}</span>}
			</AccountLinkIcon>
			<AccountLinkLabel>{children}</AccountLinkLabel>
			{button && (
				<Button size="small" variant="secondary" onClick={onButtonClick}>
					{button}
				</Button>
			)}
		</AccountLinkWrapper>
	)
}

AccountLink.propTypes = {
	children: PropTypes.any,
	other: PropTypes.bool,
	onClick: PropTypes.func,
	color: PropTypes.string,
	button: PropTypes.string,
	onButtonClick: PropTypes.func,
}

export default AccountLink
