import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { nanoid } from 'nanoid'

import FormRow from '@/components/FormRow'
import { Row, Col } from '@/components/Grid'
import ListTable from '@/components/ListTable/ListTable'
import PropTypes from '@/lib/propTypes'
import More, { MoreButton } from '@/components/More'
import { breakpoints } from '@/lib/theme'
import InfoIcon from '@/components/InfoIcon'
import { SocialIconType } from '@/api/videobot.schemas'

import EditSocialLinkPanel from './EditSocialLinkPanel'

const getSocialLinkInfoIcon = (icon) => {
	switch (icon) {
		case SocialIconType.Linkedin:
			return (
				<InfoIcon noWrap variant="square" name="linkedin">
					Linkedin
				</InfoIcon>
			)
		case SocialIconType.Facebook:
			return (
				<InfoIcon noWrap variant="square" name="facebook">
					Facebook
				</InfoIcon>
			)
		case SocialIconType.Instagram:
			return (
				<InfoIcon noWrap variant="square" name="instagram">
					Instagram
				</InfoIcon>
			)
		case SocialIconType.Tiktok:
			return (
				<InfoIcon noWrap variant="square" name="tiktok">
					TikTok
				</InfoIcon>
			)
		case SocialIconType.X:
			return (
				<InfoIcon noWrap variant="square" name="x-twitter">
					X / Twitter
				</InfoIcon>
			)
		case SocialIconType.Website:
			return (
				<InfoIcon noWrap variant="square" name="website">
					Website
				</InfoIcon>
			)
		case SocialIconType.WhatsApp:
			return (
				<InfoIcon noWrap variant="square" name="whatsapp">
					Whatsapp
				</InfoIcon>
			)
		case SocialIconType.Telegram:
			return (
				<InfoIcon noWrap variant="square" name="telegram">
					Telegram
				</InfoIcon>
			)
		case SocialIconType.Reddit:
			return (
				<InfoIcon noWrap variant="square" name="reddit">
					Reddit
				</InfoIcon>
			)
		case SocialIconType.Calendly:
			return (
				<InfoIcon noWrap variant="square" name="calendly">
					Calendly
				</InfoIcon>
			)
		case SocialIconType.Viber:
			return (
				<InfoIcon noWrap variant="square" name="viber">
					Viber
				</InfoIcon>
			)
		case SocialIconType.Snapchat:
			return (
				<InfoIcon noWrap variant="square" name="snapchat">
					Snapchat
				</InfoIcon>
			)
		case SocialIconType.Email:
			return (
				<InfoIcon noWrap variant="square" name="email">
					Email
				</InfoIcon>
			)
		case SocialIconType.Pinterest:
			return (
				<InfoIcon noWrap variant="square" name="pinterest">
					Pinterest
				</InfoIcon>
			)
		case SocialIconType.Youtube:
			return (
				<InfoIcon noWrap variant="square" name="youtube">
					Youtube
				</InfoIcon>
			)
		case SocialIconType.Vimeo:
			return (
				<InfoIcon noWrap variant="square" name="vimeo">
					Vimeo
				</InfoIcon>
			)
		default:
			return (
				<InfoIcon noWrap variant="square" name="website">
					Website
				</InfoIcon>
			)
	}
}

const RowButtonsMenu = ({ row, onEditClick, onDeleteClick }) => {
	const { t } = useTranslation(['user'])
	return (
		<More>
			<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

const AccountSettingsSocialLinks = ({ initialSocialLinks, onSocialLinksChange }) => {
	const { t } = useTranslation(['user'])
	const initializedLinks = initialSocialLinks.map((link) => ({
		...link,
		id: nanoid(),
	}))
	const [socialLinks, setSocialLinks] = useState(initializedLinks)
	const [linkToEdit, setLinkToEdit] = useState(null)
	const [isAddingNew, setIsAddingNew] = useState(false)
	const [savedScrollPos, setSavedScrollPos] = useState(0)

	const handleAddLink = () => {
		setSavedScrollPos(window.scrollY)
		const newLink = {
			id: nanoid(),
			url: '',
			icon: '',
			target: 'new_tab',
		}
		setLinkToEdit(newLink)
		setIsAddingNew(true)
	}

	const handleBackClick = () => {
		setLinkToEdit(null)
		setIsAddingNew(false)
	}

	const handleEditLink = (link) => {
		setSavedScrollPos(window.scrollY)
		setLinkToEdit(link)
		setIsAddingNew(false)
	}

	const handleSaveEdit = (editedLink) => {
		let updatedLinks
		if (editedLink.id && socialLinks.some((link) => link.id === editedLink.id)) {
			updatedLinks = socialLinks.map((link) =>
				link.id === editedLink.id ? editedLink : link,
			)
		} else {
			updatedLinks = [...socialLinks, { ...editedLink, id: nanoid() }]
		}
		setSocialLinks(updatedLinks)
		onSocialLinksChange(updatedLinks)
		setLinkToEdit(null)
		setIsAddingNew(false)
	}

	const handleDeleteLink = (linkId) => {
		setSavedScrollPos(window.scrollY)
		const updatedLinks = socialLinks.filter((link) => link.id !== linkId)
		setSocialLinks(socialLinks.filter((link) => link.id !== linkId))
		onSocialLinksChange(updatedLinks)
		setLinkToEdit(null)
	}

	const handleReorder = (newOrder) => {
		setSocialLinks(newOrder)
		onSocialLinksChange(newOrder)
	}

	const optionsColumns = [
		{
			name: t('videobot:formElementFields.type.label'),
			cell: (row) => (
				<div className="flex items-center">
					{getSocialLinkInfoIcon(row.icon)}
					<span className="ml-2"> {row.icon}</span>
				</div>
			),
		},
		{
			name: t('videobot:formElementFields.url.url'),
			selector: (row) => row.url,
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<RowButtonsMenu
						row={row}
						onDeleteClick={() => handleDeleteLink(row.id)}
						onEditClick={handleEditLink}
					/>
				)
			},
		},
	]

	return (
		<FormRow
			title={t('account:group.socialLinks.label')}
			description={t('account:group.socialLinks.description')}
		>
			<Row>
				<Col sm={12} xxs={12}>
					<ListTable
						dragEnabled
						columns={optionsColumns}
						data={socialLinks}
						addAction="Add link"
						onAdd={handleAddLink}
						onReorder={handleReorder}
					/>
					{(linkToEdit !== null || isAddingNew) && (
						<EditSocialLinkPanel
							linkData={linkToEdit}
							onSave={handleSaveEdit}
							onDelete={handleDeleteLink}
							savedScrollPos={savedScrollPos}
							onBack={handleBackClick}
						/>
					)}
				</Col>
			</Row>
		</FormRow>
	)
}

AccountSettingsSocialLinks.propTypes = {
	initialSocialLinks: PropTypes.array,
	onSocialLinksChange: PropTypes.func.isRequired,
}

export default AccountSettingsSocialLinks
