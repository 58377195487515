import React, { useCallback, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { clamp } from 'lodash-es'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'

import Badge from '@/components/Badge'
import DataTable from '@/components/DataTable'
import FieldRadio from '@/components/FieldRadio'
import Icon from '@/components/Icon'
import More, { MoreButton } from '@/components/More'
import Popover from '@/components/Popover'
import { useWindowSize } from '@/hooks/useWindowSize'
import { LANGS, MEDIA_LIST_INITIAL_PARAMS } from '@/lib/constants'
import { breakpoints } from '@/lib/theme'

import DeleteMediaModal from '../DeleteMediaModal'
import MediaListToolbar from '../MediaListToolbar/MediaListToolbar'
import SubtitlesManager from '../SubtitlesManager/SubtitlesManager'
import { MediaListWrapper, MediaThumbnail } from './MediaList.styles'

const MediaMenu = ({ onDelete, onManageSubtitles, media }) => {
	const { t } = useTranslation(['common', 'media'])
	return (
		<More>
			<MoreButton onClick={() => onManageSubtitles(media)}>
				{t('media:manageSubtitles')}
			</MoreButton>
			<MoreButton onClick={() => onDelete(media)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

MediaMenu.propTypes = {
	onDelete: PropTypes.func,
	media: PropTypes.object,
}

const MediaList = (props) => {
	const { singleSelect, onMediaSelect, data, invalidateMediaList, isLoading } = props
	const [searchParams, setSearchParams] = useSearchParams(MEDIA_LIST_INITIAL_PARAMS)
	const page = Number(searchParams.get('page'))
	const orderBy = searchParams.get('order_by')
	const orderDirection = searchParams.get('order_direction')
	const { t } = useTranslation(['common', 'media'])
	const [mediaToRemove, setMediaToRemove] = useState(null)
	const [selectedItem, setSelectedItem] = useState(null)
	const [selectedItems, setSelectedItems] = useState([])
	const [subtitlesSettings, setSubtitlesSettings] = useState(null)
	const { windowWidth } = useWindowSize()

	const pageCount = data?.metadata?.pageCount ?? 0

	const onPageChange = useCallback(
		(newPage) => {
			const params = {
				order_by: orderBy === 'createdAt' ? 'id' : orderBy,
				order_direction: orderDirection,
				page: clamp(newPage, 1, pageCount),
			}
			if (!pageCount) {
				return
			}
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
		},
		[setSearchParams, pageCount, orderBy, orderDirection],
	)

	const onSort = useCallback(
		(selectedColumn, sortDirection) => {
			const params = {
				order_by: selectedColumn?.id === 'createdAt' ? 'id' : selectedColumn?.id,
				order_direction: sortDirection,
				page: 1,
			}
			setSearchParams((prev) => ({ ...Object.fromEntries(prev), ...params }))
		},
		[setSearchParams],
	)

	const handleDelete = (media) => {
		setMediaToRemove(media)
	}

	const handleChange = (checked, id) => {
		if (!data) {
			return
		}
		if (checked) {
			const items = data.records
			const media = items.find((o) => o.id === id)
			setSelectedItem(media)
			if (onMediaSelect) onMediaSelect(media)
		}
	}

	const conditionalRowStyles = [
		{
			when: (row) =>
				selectedItems?.find((o) => o.id === row.id) || selectedItem?.id === row.id,
			classNames: ['selected'],
		},
	]

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: windowWidth > breakpoints.lg,
			button: true,
			cell: (row) => {
				return (
					<MediaMenu
						media={row}
						onDelete={handleDelete}
						onManageSubtitles={setSubtitlesSettings}
					/>
				)
			},
		},
		{
			id: 'name',
			name: t('name'),
			selector: (row) => row.name,
			sortable: true,
			grow: 1.5,
			cell: (row) => {
				return (
					<React.Fragment>
						{row.thumbnailUrl && row.status === 'Draft' ? (
							<Icon name="visibility-off" variant="square" />
						) : (
							<React.Fragment>
								{row.thumbnailUrl && row.status === 'Ready' && (
									<MediaThumbnail>
										<img src={row.thumbnailUrl} alt="..." />
									</MediaThumbnail>
								)}
							</React.Fragment>
						)}
						{!row.thumbnailUrl && <Icon name="video" variant="square" />}
						<a href={row.previewUrl} target="_blank" rel="noreferrer">
							{row.name || 'No name'}
						</a>
					</React.Fragment>
				)
			},
		},
		{
			id: 'status',
			name: t('status'),
			selector: (row) => row.status,
			grow: 0.75,
			cell: (row) => {
				if (row.status === 'Error') {
					return <Badge variant="red">{t('error')}</Badge>
				}
				return (
					<Badge variant={row.status === 'Ready' ? 'green' : 'muted'}>
						{row.status === 'Ready' ? t('ready') : t('pendingUpload')}
					</Badge>
				)
			},
			sortable: true,
		},
		{
			id: 'duration',
			name: t('media:duration'),
			selector: (row) => row.duration,
			grow: 0.75,
			sortable: false,
			cell: (row) => {
				return format(
					new Date('January 1, 2000 00:00:00').setSeconds(row.duration),
					'HH:mm:ss',
				)
			},
		},
		{
			id: 'createdAt',
			name: t('media:uploaded'),
			selector: (row) => row.createdAt,
			grow: 0.75,
			cell: (row) => {
				return format(new Date(`${row.createdAt}Z`), 'dd/MM/yyyy HH:mm')
			},
			sortable: true,
		},
		{
			id: 'captions',
			name: t('media:subtitles'),
			sortable: false,
			grow: 0.5,
			cell: (row) => {
				return row.captions?.length > 0 ? (
					<Popover toggler={<Badge size="sm">CC</Badge>}>
						<h6>{t('media:subtitles')}</h6>
						<ul>
							{row.captions.map((caption, index) => (
								<li key={index}>
									{LANGS.find((o) => o.code === caption.languageCode).name}
								</li>
							))}
						</ul>
					</Popover>
				) : (
					''
				)
			},
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<MediaMenu
						media={row}
						onDelete={handleDelete}
						onManageSubtitles={setSubtitlesSettings}
					/>
				)
			},
		},
	]

	if (singleSelect)
		columns.unshift({
			width: '2.75rem',
			center: true,
			cell: (row) => {
				return (
					<FieldRadio
						id={`media-${row.id}`}
						name="media"
						watch
						defaultChecked={selectedItem?.id === row.id}
						value={row.id}
						onChange={(e) => handleChange(e, row.id)}
					/>
				)
			},
		})

	const handleMediaSelect = (e) => {
		setSelectedItems(e.selectedRows)
	}

	if (!data) return null

	const paginateMetadata = data.metadata
	const items = data.records

	return (
		<MediaListWrapper>
			{/* Data Table */}
			<DataTable
				columns={columns}
				defaultSortFieldId={orderBy || 'createdAt'}
				defaultSortAsc={orderDirection === 'asc'}
				selectableRows={!singleSelect}
				paginationPerPage={paginateMetadata.pageSize}
				onSelectedRowsChange={handleMediaSelect}
				data={items}
				conditionalRowStyles={conditionalRowStyles}
				pageSize={paginateMetadata.pageSize}
				totalCount={paginateMetadata.totalCount}
				page={page}
				onPageChange={onPageChange}
				onSort={onSort}
				sortServer
				isLoading={isLoading}
				// onRowClicked={handleRowClick}
			/>

			{/* Detele Modal (Single) */}
			<AnimatePresence mode="wait">
				{mediaToRemove && (
					<DeleteMediaModal
						media={mediaToRemove}
						onClose={() => setMediaToRemove(null)}
						onDeleted={() => invalidateMediaList()}
					/>
				)}

				{/* Toolbar */}
				{selectedItems.length > 0 && (
					<MediaListToolbar
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						invalidateMediaList={() => invalidateMediaList()}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{/* Upload Modal */}
				{subtitlesSettings && (
					<SubtitlesManager
						media={subtitlesSettings}
						onClose={() => setSubtitlesSettings(null)}
					/>
				)}
			</AnimatePresence>
		</MediaListWrapper>
	)
}

export default MediaList
