import { forwardRef, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import useSize from '@react-hook/size'

import { VIDEO_MIMETYPE_SUPPORT, IMAGE_MIMETYPE_SUPPORT } from '@/lib/constants'
import PropTypes from '@/lib/propTypes'

import Icon from '../Icon'
import {
	FieldUploadBorder,
	FieldUploadContent,
	FieldUploadDrop,
	FieldUploadIcon,
	FieldUploadInput,
	FieldUploadWrapper,
} from './FieldUpload.styles'

const FieldUpload = forwardRef((props, _ref) => {
	const { onUpload, isMedia = false } = props
	const acceptedTypes = isMedia
		? `${VIDEO_MIMETYPE_SUPPORT}, ${IMAGE_MIMETYPE_SUPPORT}`
		: VIDEO_MIMETYPE_SUPPORT
	const inputRef = useRef()
	const dropRef = useRef()
	const [wrapperWidth, wrapperHeight] = useSize(dropRef)
	const [dragEnter, setDragEnter] = useState(false)
	const { t } = useTranslation(['media'])

	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDragEnter(true)
	}

	const handleDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDragEnter(false)
	}

	const handleDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDragEnter(false)

		const { files } = e.dataTransfer

		if (files && files.length) {
			Array.from(files).forEach((file) => {
				if (acceptedTypes.includes(file.type)) {
					onUpload(file)
				} else {
					toast.error(t('media:mediaUploadFileNotSupported', { name: file.name }))
				}
			})
		}
	}

	const handleInput = (e) => {
		const fileList = e.target.files
		Array.from(fileList).forEach((file) => {
			if (acceptedTypes.includes(file.type)) {
				onUpload(file)
			} else {
				toast.error(t('media:mediaUploadFileNotSupported', { name: file.name }))
			}
		})
	}

	useEffect(() => {
		dropRef.current?.addEventListener('dragover', handleDragOver)
		dropRef.current?.addEventListener('dragenter', handleDragEnter)
		dropRef.current?.addEventListener('dragleave', handleDragLeave)
		dropRef.current?.addEventListener('drop', handleDrop)

		return () => {
			dropRef.current?.removeEventListener('dragover', handleDragOver)
			dropRef.current?.removeEventListener('dragenter', handleDragEnter)
			dropRef.current?.removeEventListener('dragleave', handleDragLeave)
			dropRef.current?.removeEventListener('drop', handleDrop)
		}
	}, [])

	return (
		<FieldUploadWrapper dragEnter={dragEnter}>
			<FieldUploadInput
				type="file"
				ref={inputRef}
				onChange={handleInput}
				multiple="multiple"
				accept={acceptedTypes}
			/>
			{wrapperWidth && (
				<FieldUploadBorder
					viewBox={`0 0 ${wrapperWidth} ${wrapperHeight}`}
					preserveAspectRatio="none"
				>
					<rect
						rx={8}
						x={1}
						y={1}
						width={wrapperWidth - 2}
						height={wrapperHeight - 2}
					></rect>
				</FieldUploadBorder>
			)}
			<FieldUploadContent>
				<FieldUploadIcon>
					<Icon name="upload" />
				</FieldUploadIcon>
				<h5>
					<Trans i18nKey="media:uploadInstruction">
						Drag and drop files, or <button type="button">Browse</button>
					</Trans>
				</h5>
				<p>
					{isMedia
						? 'MP4, MKV, MOV, AVI, FLV, MPEG-2 TS, MPEG-2 PS, 3GP, WebM, MPG, QuickTime, JPEG, PNG, GIF'
						: 'MP4, MKV, MOV, AVI, FLV, MPEG-2 TS, MPEG-2 PS, 3GP, WebM, MPG, QuickTime'}
				</p>
			</FieldUploadContent>
			<FieldUploadDrop ref={dropRef} onClick={() => inputRef.current?.click()} />
		</FieldUploadWrapper>
	)
})

FieldUpload.displayName = 'FieldUpload'

FieldUpload.propTypes = {
	mediaType: PropTypes.string,
	name: PropTypes.string,
	onUpload: PropTypes.func,
	isMedia: PropTypes.bool,
}

FieldUpload.defaultProps = {
	placeholder: 'Select...',
}

export default FieldUpload
