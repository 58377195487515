import type { ReactNode } from 'react'
import type { SwitchProps, SwitchRenderProps } from 'react-aria-components'
import { Fragment, forwardRef } from 'react'
import { Switch } from 'react-aria-components'
import {
	useController,
	type Control,
	type FieldValues,
	type FieldPathByValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

interface FieldToggleProps extends Omit<SwitchProps, 'children'> {
	children?: ReactNode | ((renderProps: SwitchRenderProps) => ReactNode)
	reversed?: boolean
	error?: { message: string }
}

const FieldToggleMessage = ({
	error,
	variant,
}: {
	error: { message: string }
	variant: string
}) => (
	<div
		className={twMerge(
			'mt-2 block text-[0.8rem] font-semibold',
			variant === 'error' ? 'text-red' : 'text-primary',
		)}
	>
		{error.message}
	</div>
)

export const FieldToggle = forwardRef<HTMLLabelElement, FieldToggleProps>(
	({ children, reversed, error, ...props }: FieldToggleProps, ref) => {
		const { t } = useTranslation()

		return (
			<Fragment>
				<Switch
					className={twMerge(
						'flex cursor-pointer items-center gap-3',
						reversed && 'flex-row-reverse text-right',
					)}
					ref={ref}
					{...props}
				>
					{(renderProps) => (
						<Fragment>
							<div
								className={twMerge(
									'flex h-6 w-9 items-center rounded-full bg-line p-1 transition duration-200 ease-out',
									renderProps.isSelected && 'bg-primary',
								)}
							>
								<span
									className={twMerge(
										'h-4 w-4 translate-x-0 transform rounded-full bg-body shadow transition duration-200 ease-in-out',
										renderProps.isSelected && 'translate-x-3',
									)}
								/>
							</div>
							<span>
								{!children &&
									(renderProps.isSelected ? t('enabled') : t('disabled'))}
								{typeof children === 'function' ? children(renderProps) : children}
							</span>
						</Fragment>
					)}
				</Switch>
				{error && <FieldToggleMessage error={error} variant="error" />}
			</Fragment>
		)
	},
)

FieldToggle.displayName = 'FieldToggle'

export const ControlledFieldToggle = <
	TFieldValues extends FieldValues,
	TPath extends FieldPathByValue<TFieldValues, TValue extends string ? string : boolean>,
	TValue extends string | undefined = undefined,
>({
	values,
	...props
}: FieldToggleProps & {
	control: Control<TFieldValues>
	name: TPath

	/**
	 * Map toggle values.
	 */
	values?: { on: TValue; off: TValue }
}) => {
	const { control, name } = props
	const { field } = useController({
		control,
		name,
	})

	const onChange: FieldToggleProps['onChange'] = (isSelected) => {
		if (values) {
			field.onChange(isSelected ? values.on : values.off)
		} else {
			field.onChange(isSelected)
		}

		props.onChange?.(isSelected)
	}

	const defaultSelected = values ? field.value === values.on : field.value

	return (
		<FieldToggle {...field} defaultSelected={defaultSelected} {...props} onChange={onChange} />
	)
}
