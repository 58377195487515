import { RoleType } from '@/api/videobot.schemas'
import VideobotSettings from '@/features/Videobot/components/VideobotSettings'
import { RoleCheck } from '@/modules/session/auth.component'

const VideobotConfigurationContents = () => {
	return <VideobotSettings />
}

const VideobotConfiguration = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Super_Admin,
				RoleType.Admin,
				RoleType.Owner,
				RoleType.Reseller,
				RoleType.Member,
			]}
		>
			<VideobotConfigurationContents />
		</RoleCheck>
	)
}

export default VideobotConfiguration
