import { AnimatePresence, motion } from 'framer-motion'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import { usePopper } from 'react-popper'
import { v4 } from 'uuid'
import { useAtom } from 'jotai'

import PropTypes from '@/lib/propTypes'
import { currentPopupAtom } from '@/common/layout'

import {
	FieldSelectArrow,
	FieldSelectContainer,
	FieldSelectDropdown,
	FieldSelectDropdownCategory,
	FieldSelectDropdownCategoryLabel,
	FieldSelectDropdownItem,
	FieldSelectLabel,
	FieldSelectToggler,
	FieldSelectWrapper,
} from './FieldSelect.styles'

const FieldSelectContents = (props, ref) => {
	const {
		setValue,
		onChange,
		error,
		defaultValue,
		name,
		options,
		size = 'default',
		placeholder = 'Select',
		disabled,
		groups,
		register,
		required,
	} = props

	const wrapperRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [currentPopup, setCurrentPopup] = useAtom(currentPopupAtom)
	const uuidv4 = useRef(v4())

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		strategy: 'absolute',
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 5],
				},
			},
		],
	})

	const handleTogglerClick = (e) => {
		if (!disabled) {
			if (uuidv4.current !== currentPopup) {
				setCurrentPopup(uuidv4.current)
			} else {
				setCurrentPopup(null)
			}
		}

		e.stopPropagation()
		e.preventDefault()
	}

	const handleChange = (e, payload) => {
		e.preventDefault()
		setSelectedItem(payload)
		setCurrentPopup(null)

		if (setValue) setValue(name, payload.value, { shouldValidate: true, shouldDirty: true })
		if (onChange) onChange(e, payload)
	}

	const handleDropdownClick = (e) => {
		e.stopPropagation()
	}

	const handleDocumentClick = () => {
		setCurrentPopup(null)
	}

	useImperativeHandle(ref, () => ({
		reset: () => {
			setSelectedItem(null)
		},
	}))

	useEffect(() => {
		if (currentPopup === uuidv4.current) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [currentPopup])

	useEffect(() => {
		if (register) {
			register(name, { required })
		}
		if (defaultValue !== undefined) {
			if (groups) {
				options.forEach((cat) => {
					cat.options.forEach((item) => {
						if (item.value === defaultValue) {
							setSelectedItem(item)
						}
					})
				})
			} else {
				setSelectedItem(options?.find((o) => o.value === defaultValue))
			}
			if (setValue) setValue(name, defaultValue)

			document.addEventListener('click', handleDocumentClick)

			return () => {
				document.removeEventListener('click', handleDocumentClick)
			}
		}
	}, [defaultValue])

	return (
		<FieldSelectWrapper open={isOpen} ref={wrapperRef} disabled={disabled}>
			<FieldSelectContainer ref={setReferenceElement}>
				<FieldSelectToggler
					error={error}
					disabled={disabled}
					size={size}
					onClick={handleTogglerClick}
				>
					<FieldSelectLabel muted={placeholder && !selectedItem}>
						{selectedItem?.label || placeholder}
					</FieldSelectLabel>
					<FieldSelectArrow>
						<SVG src={'/assets/icons/chevron-down.svg'} />
					</FieldSelectArrow>
				</FieldSelectToggler>
				{createPortal(
					<AnimatePresence mode="wait">
						{isOpen && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								transition={{ duration: 0.15 }}
							>
								<FieldSelectDropdown
									onClick={handleDropdownClick}
									style={{
										...styles.popper,
										width: `${wrapperRef.current?.clientWidth}px`,
									}}
									{...attributes.popper}
									ref={setPopperElement}
								>
									{!groups &&
										options
											.filter((o) => !o.disabled)
											?.map((item, index) => (
												<FieldSelectDropdownItem
													key={index}
													onClick={(e) => handleChange(e, item)}
												>
													{item.label}
												</FieldSelectDropdownItem>
											))}

									{groups &&
										options?.map((category, index) =>
											category.options.length ? (
												<FieldSelectDropdownCategory key={index}>
													<FieldSelectDropdownCategoryLabel>
														{category.name}
													</FieldSelectDropdownCategoryLabel>
													{category.options?.map((item, itemIndex) => (
														<FieldSelectDropdownItem
															key={itemIndex}
															onClick={(e) => handleChange(e, item)}
														>
															{item.label}
														</FieldSelectDropdownItem>
													))}
												</FieldSelectDropdownCategory>
											) : (
												''
											),
										)}
								</FieldSelectDropdown>
							</motion.div>
						)}
					</AnimatePresence>,
					document.querySelector('#popups'),
				)}
			</FieldSelectContainer>
		</FieldSelectWrapper>
	)
}

const FieldSelect = forwardRef(FieldSelectContents)
FieldSelect.displayName = 'FieldSelect'

FieldSelect.propTypes = {
	register: PropTypes.func,
	setValue: PropTypes.func,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	defaultValue: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	size: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	groups: PropTypes.bool,
}

export default FieldSelect
