import styled, { css } from 'styled-components'

import { IconSquare } from '@/components/Icon/Icon.styles'
import { ThumbnailWrapper } from '@/components/Thumbnail/Thumbnail.styles'
import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

export const CardContent = styled.div`
	margin-top: 1rem;
`

export const CardDetails = styled.div`
	flex: 1 1 auto;
	padding-top: 0.25rem;
	overflow: hidden;

	h5 {
		margin-bottom: 0.25rem;
		font-weight: 500;
	}

	> span {
		display: block;
		font-size: 0.8rem;
		opacity: 0.6;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:not(:last-child) {
			margin-bottom: 0.2rem;
		}
	}
`

export const CardHeader = styled.div`
	display: flex;

	${ThumbnailWrapper} {
		margin-right: 1rem;
	}

	${(props) =>
		props.center &&
		css`
			align-items: center;
		`};

	${IconSquare} {
		margin-right: 1rem;

		svg {
			fill: currentColor;
		}

		+ ${CardDetails} {
			padding-top: 0;
			h5 {
				margin-bottom: 0.2rem;
			}
		}
	}
`

export const CardWrapper = styled.div`
	padding: 1rem;
	border-radius: ${(props) => props.theme.radius.lg};
	background: ${(props) =>
		props.background ? props.theme.color[props.background] : props.theme.color.body};
	color: ${(props) => (props.inverted ? props.theme.color.inverted : props.theme.color.global)};
	touch-action: manipulation;

	${(props) =>
		props.isOverlay &&
		css`
			opacity: 0.5;
		`};

	${(props) =>
		props.dragEnabled &&
		css`
			touch-action: manipulation;
			cursor: grab;
		`};

	${(props) =>
		props.variant === 'ADD_SLIDE' &&
		css`
			cursor: pointer;
			&:hover {
				${CardHeader} {
					${IconSquare} {
						&:before {
							background-color: ${(props) => props.theme.color.primary};
							transform: scale(0.95);
						}
						svg {
							fill: ${(props) => props.theme.color.body};
						}
					}
				}
			}
		`};
`

export const CardOption = styled.span`
	display: block;
	border-radius: 2rem;
	padding: 0.5rem 0.75rem;
	font-size: 0.9rem;
	text-align: center;
	background-color: ${(props) => props.theme.color.medium};

	${(props) =>
		props.featured &&
		css`
			background-color: ${(props) => props.buttonBackgroundColor};
			color: ${(props) => props.buttonTextColor};
		`};

	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
`

export const CardField = styled.span`
	display: block;
	border-radius: 0.25rem;
	padding: 0.5rem 0.75rem;
	font-size: 0.9rem;
	color: ${(props) => props.theme.color.global};
	background-color: ${(props) => props.theme.color.body};

	> strong {
		font-weight: 700;
		&:after {
			content: ': ';
		}
	}

	${(props) =>
		props.featured &&
		css`
			background-color: ${(props) => props.theme.color.primary};
			color: ${(props) => props.buttonTextColor};
		`};

	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
`

export const CardUtils = styled.div`
	margin-left: 0.75rem;
	button {
		position: relative;
		height: 2rem;
		width: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before {
			${Cover()}
			border-radius: ${(props) => props.theme.radius.sm};
			background-color: rgba(255, 255, 255, 0.2);
			transform: scale(0.65);
			opacity: 0;
			${TransitionPrimary()}
		}

		svg {
			fill: currentColor;
		}

		${Reset('button')} {
			svg {
				position: relative;
				display: block;
				height: 50%;
				width: 50%;
				pointer-events: none;
			}
		}

		&:hover {
			&:before {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
`
