import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'

import Badge from '@/components/Badge'
import DataTable from '@/components/DataTable'
import More, { MoreButton } from '@/components/More'
import { useWindowSize } from '@/hooks/useWindowSize'
import { breakpoints } from '@/lib/theme'
import { useReadCurrentUserAccounts } from '@/api/videobot'
import { useAuth, useCurrentUser } from '@/modules/session/auth.store'

const AccountsList = (props) => {
	const { accountsList } = props
	const { t } = useTranslation(['common', 'account'])
	const navigate = useNavigate()
	const { search: urlQuery } = useLocation()
	const { setActiveAccount } = useAuth()
	const { data: accounts } = useReadCurrentUserAccounts()
	const currentUser = useCurrentUser()
	const handleEdit = (account) => {
		if (currentUser.isReseller) {
			setActiveAccount(account.id)
		}
		navigate(`/dashboard/accounts/${account.id}${urlQuery}`)
	}
	const { windowWidth } = useWindowSize()

	const handleLogInToAccount = (accountId) => {
		setActiveAccount(accounts.find((o) => o.id === accountId)?.id)
		navigate('/dashboard')
	}

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: windowWidth > breakpoints.lg,
			button: true,
			cell: (row) => {
				return (
					<More>
						<React.Fragment>
							<MoreButton onClick={() => handleEdit(row)}>{t('edit')}</MoreButton>
							<MoreButton variant="red">{t('delete')}</MoreButton>
						</React.Fragment>
					</More>
				)
			},
		},
		{
			id: 'business_name',
			name: t('account:fields.businessName.label'),
			selector: (row) => row.businessName,
			sortable: true,
		},
		{
			id: 'status',
			name: t('status'),
			selector: (row) => row.role,
			sortable: true,
			cell: (row) => {
				return (
					<Badge variant={row.isActive ? 'green' : 'default'}>
						{row.isActive ? t('active') : t('inactive')}
					</Badge>
				)
			},
		},

		{
			name: t('account:ownerEmail'),
			selector: (row) => row.billingEmail,
			sortable: false,
		},
		{
			name: t('account:ownerPhoneNumber'),
			selector: (row) => row.phoneNumber,
			sortable: false,
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<More>
						<React.Fragment>
							<MoreButton onClick={() => handleEdit(row)}>{t('edit')}</MoreButton>
							<MoreButton onClick={() => handleLogInToAccount(row.id)}>
								{t('account:loginToAccount')}
							</MoreButton>
						</React.Fragment>
					</More>
				)
			},
		},
	]

	return (
		<React.Fragment>
			{accountsList && (
				<DataTable
					columns={columns}
					data={accountsList.map(
						({ id, businessName, billingEmail, phoneNumber, status }) => ({
							id,
							businessName,
							billingEmail,
							phoneNumber,
							isActive: status === 'Active',
						}),
					)}
					onRowClicked={handleEdit}
					sortServer
					{...props}
				/>
			)}
		</React.Fragment>
	)
}

AccountsList.propTypes = {
	accountsList: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
}

export default AccountsList
