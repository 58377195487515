import ky from 'ky'

export const apiClient = ky.create({
	prefixUrl: import.meta.env.VITE_API_URL,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = localStorage.getItem('token')
					? JSON.parse(localStorage.getItem('token'))
					: null
				if (token) {
					request.headers.set(
						'Authorization',
						`${token.token_type} ${token.access_token}`,
					)
				}
			},
		],
	},
})
