import type {
	getGetAllMediaQueryKey,
	getReadAllBotsQueryKey,
	getReadAllWidgetsQueryKey,
} from '@/api/videobot'

// Setup keys for invalidating all relating queries
// Some of generated key functions required params, these are just for invalidating
// all relating queries.

export const allBotsKey = (): ReturnType<typeof getReadAllBotsQueryKey>[number][] => ['bot']
export const allMediasKey = (): ReturnType<typeof getGetAllMediaQueryKey>[number][] => ['media']
export const allWidgetsKey = (): ReturnType<typeof getReadAllWidgetsQueryKey>[number][] => [
	'widget',
]
