import { css } from 'styled-components'

import theme, { breakpoints } from '../../lib/theme'

const THEME_CONF = 'flexboxgrid'

const pxToInt = (val) => Number(val.replace('px', ''))

export const BASE_CONF = {
	gridSize: 12,
	gutterWidth: pxToInt(theme.grid.gutterWidth),
	gutterWidthLg: pxToInt(theme.grid.gutterWidth) * 3.5,
	gutterWidthSm: pxToInt(theme.grid.gutterWidth) * 0.75,
	outerMargin: pxToInt(theme.grid.outerMargin),
	mediaQuery: 'only screen',
	container: {
		sm: 720,
		md: 950,
		lg: 1170,
	},
	breakpoints,
}

const configCache = []
const makeCacheId = (props) => JSON.stringify((props.theme && props.theme[THEME_CONF]) || {})
const resolveConfig = (_props) => {
	const conf = {
		...BASE_CONF,
		container: {
			...BASE_CONF.container,
		},
		breakpoints: {
			...BASE_CONF.breakpoints,
		},
	}

	conf.media = Object.keys(conf.breakpoints).reduce((media, breakpoint) => {
		const breakpointWidth = conf.breakpoints[breakpoint]
		media[breakpoint] = makeMedia(
			[conf.mediaQuery, breakpoint !== 0 && `(min-width: ${breakpointWidth}px)`]
				.filter(Boolean)
				.join(' and '),
		)

		return media
	}, {})

	return conf
}

export const DIMENSION_NAMES = Object.keys(breakpoints)

export default function config(props) {
	const cacheId = makeCacheId(props)
	if (configCache[0] === cacheId) {
		return configCache[1]
	}

	const conf = resolveConfig(props)

	configCache[0] = cacheId
	configCache[1] = conf

	return conf
}

function makeMedia(media) {
	return (...args) => {
		return css`
			@media ${media} {
				${css(...args)}
			}
		`
	}
}
