import { useState } from 'react'
import { addDays, endOfDay, startOfDay, subDays, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import CheckPicker from 'rsuite/CheckPicker'
import DateRangePicker from 'rsuite/DateRangePicker'
import SelectPicker from 'rsuite/SelectPicker'
import { capitalize, debounce } from 'lodash-es'

import { useReadAllBots, useReadAllWidgets } from '@/api/videobot'
import { EventChannelType, FeatureType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'

import { FilterContainer, FilterItem, FilterWrapper } from './AnalyticsFilter.styles'

export const AnalyticsFilter = ({
	onDateRangeChange,
	onBotsChange,
	onChannelChange,
	onSlidersChange,
	params,
}) => {
	const { t } = useTranslation(['analytics'])
	const activeAccount = useCurrentAccount()
	const [botFilter, setBotFilter] = useState('')
	const [debounceSetBotFilter] = useState(() => debounce(setBotFilter, 250))
	const { data: featureFlags } = useFeatureFlags({ account_id: activeAccount?.id })

	const { data: allBots } = useReadAllBots(
		{
			account_id: activeAccount.id,
			size: 100,
			bot_name: botFilter,
		},
		{
			query: {
				keepPreviousData: true,
			},
		},
	)

	const isSliderAnalytics = params.channel === EventChannelType.slider
	const { data: allWidgets } = useReadAllWidgets(
		{
			account_id: activeAccount.id,
			size: 100,
		},
		{
			query: {
				keepPreviousData: true,
				enabled:
					featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`) &&
					isSliderAnalytics,
			},
		},
	)

	const defaultValue = [new Date(params.from_date), new Date(params.to_date)]
	const pastMonth = [subDays(new Date(), 29), new Date()]

	const Ranges = [
		{
			label: t('analytics:today'),
			value: [startOfDay(new Date()), endOfDay(new Date())],
		},
		{
			label: t('analytics:yesterday'),
			value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
		},
		{
			label: t('analytics:last7Days'),
			value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
		},
		{
			label: t('analytics:last30days'),
			value: pastMonth,
		},
	]

	const { afterToday } = DateRangePicker

	return (
		<FilterWrapper>
			<FilterContainer>
				<FilterItem>
					<DateRangePicker
						shouldDisableDate={afterToday()}
						defaultValue={defaultValue}
						ranges={Ranges}
						onChange={(payload) =>
							payload
								? onDateRangeChange([
										format(new Date(payload[0]), 'yyyy-MM-dd'),
										format(new Date(payload[1]), 'yyyy-MM-dd'),
									])
								: onDateRangeChange([null, null])
						}
						cleanable={false}
					/>
				</FilterItem>
				{!isSliderAnalytics && (
					<FilterItem>
						<CheckPicker
							data={
								allBots?.records?.map((o) => ({
									label: o.name,
									value: o.id,
								})) || []
							}
							value={params.bot_ids?.split(',').map(Number) ?? []}
							onOpen={() => setBotFilter('')}
							onChange={onBotsChange}
							onSearch={(keyword) => debounceSetBotFilter(keyword)}
							style={{ width: 224 }}
							placeholder={t('analytics:allVideobots')}
						/>
					</FilterItem>
				)}
				{isSliderAnalytics && allWidgets && (
					<FilterItem>
						<CheckPicker
							data={
								allWidgets?.records?.map((o) => ({
									label: o.name,
									value: o.id,
								})) || []
							}
							value={params.slider_ids?.split(',').map(Number) ?? []}
							onOpen={() => setBotFilter('')}
							onChange={onSlidersChange}
							searchable={false}
							style={{ width: 224 }}
							placeholder={t('analytics:allWidgets')}
						/>
					</FilterItem>
				)}
				<FilterItem>
					<SelectPicker
						data={Object.entries(EventChannelType)
							.filter(
								([_, value]) =>
									value !== 'slider' ||
									featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`),
							)
							.map(([label, value]) => ({
								label: capitalize(label),
								value,
							}))}
						value={params.channel}
						onChange={onChannelChange}
						style={{ width: 224 }}
						cleanable={false}
					/>
				</FilterItem>
			</FilterContainer>
		</FilterWrapper>
	)
}
