import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

export const DeactivateWidgetModal = ({ onClose, onSubmit, widget, isLoading }) => {
	const { t } = useTranslation(['errors', 'common', 'widget'])

	return (
		<Modal
			header={<h3>{t('widget:deactivate_widget')}</h3>}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="widget:deactivate_widget_description"
					values={{ name: widget?.name }}
				>
					Are you sure you want to deactivate widget
					<strong>{widget?.name}</strong>?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="red-solid" onClick={() => onSubmit()} isLoading={isLoading} wide>
				{t('deactivate')}
			</Button>
		</Modal>
	)
}
