import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Panel from '@/components/Panel'
import FieldRadio from '@/components/FieldRadio'
import FieldSelect from '@/components/FieldSelect'
import { SocialIconType } from '@/api/videobot.schemas'

const EditSocialLinkActions = ({ onSave, onDelete }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onDelete} variant="secondary">
				{t('delete')}
			</Button>
			<Button onClick={onSave}>{t('save')}</Button>
		</React.Fragment>
	)
}

const EditSocialLink = ({ linkData, onSave, onDelete, savedScrollPos, onBack }) => {
	const { t } = useTranslation()
	const formRef = useRef()

	useEffect(() => {
		return () => {
			window.scrollTo(0, savedScrollPos)
		}
	}, [savedScrollPos])

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({
		defaultValues: linkData,
	})

	const handleSave = (data) => {
		onSave({ ...linkData, ...data })
		reset()
	}

	const handleDelete = () => {
		onDelete(linkData.id)
	}

	const socialMediaTypes = Object.keys(SocialIconType).map((key) => {
		return { value: SocialIconType[key], label: SocialIconType[key] }
	})

	const currentLink = watch()

	return (
		<Panel
			title={t('videobot:editSocialLink')}
			onBack={() => onBack()}
			footer={
				<EditSocialLinkActions onSave={handleSubmit(handleSave)} onDelete={handleDelete} />
			}
		>
			<form ref={formRef} onSubmit={handleSubmit(handleSave)}>
				<FormField label={t('videobot:socialLinkFields.icon.label')}>
					<FieldSelect
						register={register}
						placeholder={t('videobot:socialLinkFields.icon.placeholder')}
						name="icon"
						type="text"
						error={errors.icon}
						options={socialMediaTypes}
						defaultValue={linkData.icon}
						setValue={setValue}
						required
					/>
				</FormField>
				{currentLink.icon === 'Email' ? (
					<FormField label={t('videobot:linkFields.mailto.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.mailto.placeholder')}
							name="url"
							type="email"
							error={errors.url}
							required
						/>
					</FormField>
				) : (
					<FormField label={t('videobot:socialLinkFields.url.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:socialLinkFields.url.placeholder')}
							name="url"
							type="url"
							error={errors.url}
							required
						/>
					</FormField>
				)}
				<FormField label={t('videobot:socialLinkFields.target.label')}>
					<FieldRadio
						register={register}
						name="target"
						value="new_tab"
						defaultChecked={linkData.target === 'new_tab'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:target.newWindow')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="target"
						value="same_tab"
						defaultChecked={linkData.target === 'same_tab'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:target.sameTab')}
					</FieldRadio>
				</FormField>
			</form>
		</Panel>
	)
}

export default EditSocialLink
