import styled, { css } from 'styled-components'

import { InputField, Reset } from '../../lib/mixins'

export const FieldColorContainer = styled.div`
	position: relative;
	white-space: nowrap;
`

export const FieldColorLabel = styled.div`
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	text-transform: uppercase;

	${(props) =>
		props.muted &&
		css`
			color: ${(props) => props.theme.color.muted};
		`};
`

export const FieldColorArrow = styled.div`
	position: absolute;
	top: calc(50% - 5px);
	width: 10px;
	height: 10px;
	pointer-events: none;
	right: 1.5rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: ${(props) => props.theme.color.muted};
	}
`

export const FieldColorToggler = styled.button`
	${InputField()}
	height: 3rem;
	padding: 0 1rem;
	text-align: left;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 3;
	cursor: pointer;
`
export const FieldColorDropdown = styled.div`
	background-color: ${(props) => props.theme.color.body};
	box-shadow: ${(props) => props.theme.shadow.sm};
	border-radius: ${(props) => props.theme.radius.md};

	pointer-events: initial;
	min-width: 15rem;
`

export const FieldColorDropdownContent = styled.div`
	padding: 1.5rem;
`

export const FieldColorPointer = styled.span`
	display: block;
	position: relative;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	border: 2px solid white;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
	background-color: ${(props) => props.color || 'white'};
	z-index: 200;
`

export const FieldColorSlider = styled.div`
	position: relative;
	margin-bottom: 0.85rem;
	height: 0.8rem;
	border-radius: 0.5rem;
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.color.line};

	> div > div:nth-child(1),
	> div > div:nth-child(2) {
		border-radius: 1rem;
	}
	${FieldColorPointer} {
		pointer-events: none;
		transform: translateX(-0.6rem) translateY(-0.25rem);
	}
`

export const FieldColorSaturation = styled.div`
	position: relative;
	height: 9rem;
	${FieldColorPointer} {
		transform: translateX(-0.6rem) translateY(-0.6rem);
		cursor: pointer;
	}
`

export const FieldColorInput = styled.div`
	position: relative;
	margin-top: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	input {
		${Reset('all')}
		border: 1px solid ${(props) => props.theme.color.line};
		padding: 0 1.25rem;
		width: 9rem;
		border-radius: 2rem;
		height: 2.75rem;
	}
`
export const FieldColorPreview = styled.div`
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	margin-right: 0.5rem;
	flex-shrink: 0;
	border: 1px solid ${(props) => props.theme.color.line};
	background-color: ${(props) => props.color || 'white'};
`

export const FieldColorWrapper = styled.div`
	position: relative;
	z-index: 3;

	${(props) =>
		props.open &&
		css`
			z-index: 10;

			${FieldColorToggler} {
				box-shadow: ${(props) => props.theme.shadow.sm};
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			${FieldColorToggler} {
				cursor: not-allowed;
				background-color: ${(props) => props.theme.color.line};
			}
		`};

	${(props) =>
		props.empty &&
		css`
			${FieldColorLabel} {
				text-transform: none;
			}
		`};
`

export const FieldColorSample = styled.div`
	margin-right: 0.75rem;
	svg {
		height: 1.15rem;
		width: 1.15rem;
		display: block;
		path {
			stroke: ${(props) => props.theme.color.line};
			fill: ${(props) => props.color};
		}
	}
`
export const FieldColorIndicator = styled.div``
