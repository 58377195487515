import clsx from 'clsx'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'

import { useBotImport } from '@/api/videobot'

interface Props {
	bots: string[]
	accounts: { id: number; business_name: string }[]
}

interface FormPayload {
	legacyBotId: string
	accountId?: number
	accountName?: string
	accountType: 'existed' | 'new'
	embedCode?: string
}

export const ImportLegacyBot = ({ bots, accounts }: Props) => {
	const { register, watch, handleSubmit, setError, formState } = useForm<FormPayload>({
		defaultValues: {
			accountType: 'existed',
		},
	})
	const mutation = useBotImport()

	const watchAccountType = watch('accountType')

	const onSubmit = (payload: FormPayload) => {
		const mutatePayload: Parameters<typeof mutation.mutate>[0]['data'] = {
			legacyBotId: payload.legacyBotId,
			widgetSettings: {
				width: 96,
				height: 96,
			},
		}

		if (payload.embedCode) {
			const botElement = document.createElement('div')
			botElement.innerHTML = payload.embedCode

			const videobot = botElement.querySelector<HTMLDivElement>('[data-url]')
			const widgetSettings = mutatePayload.widgetSettings ?? {}

			if (videobot) {
				if (!videobot.dataset.url) {
					setError('embedCode', { message: 'Missing data-url' })
					return
				}
				if (!videobot.dataset.avatarVideo) {
					setError('embedCode', { message: 'Missing data-avatar-video' })
					return
				}
				const embedUrl = new URL(videobot.dataset.url)
				const videoUrl = new URL(videobot.dataset.avatarVideo)
				const searchParams = embedUrl.searchParams
				widgetSettings.borderColor = videobot.dataset.color
				widgetSettings.align = videobot.dataset.align as any
				widgetSettings.automaticallyOpen = Boolean(videobot.dataset.autoopen)
				widgetSettings.openAfter = Number(videobot.dataset.autoopen) || undefined
				widgetSettings.horizontalMode = Boolean(videobot.dataset.horizontal)
				widgetSettings.text = videobot.dataset.label
				widgetSettings.coverOffset = Number(videoUrl.searchParams.get('time')) || undefined
				widgetSettings.language = (searchParams.get('lang') || undefined) as any
				mutatePayload.chatVideoUrl = videobot.dataset.avatarVideo
			} else {
				setError('embedCode', { message: 'Embed code invalid' })
				return
			}

			mutatePayload.widgetSettings = widgetSettings
		}

		mutatePayload.legacyBotId = payload.legacyBotId
		mutatePayload.accountId = payload.accountType === 'existed' ? payload.accountId : undefined
		mutatePayload.accountName = payload.accountType === 'new' ? payload.accountName : undefined

		mutation.mutate({ data: mutatePayload })
	}

	return (
		<section className="flex flex-col gap-2">
			<h2>Import legacy bot to dashboard</h2>

			<form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
				<label className="flex flex-col gap-2">
					<span className="text-lg">
						Select legacy bot <span className="text-red">*</span>
					</span>
					<select
						className="form-select w-96"
						{...register('legacyBotId', { required: true })}
					>
						{bots.sort().map((bot) => (
							<option key={bot} value={bot}>
								{bot}
							</option>
						))}
					</select>
				</label>

				<label className="flex flex-col gap-2">
					<span className="text-lg">
						Select account <span className="text-red">*</span>
					</span>

					<label>
						<input
							type="radio"
							value="existed"
							{...register('accountType')}
							className="form-input"
						/>{' '}
						Existing account
					</label>
					<label>
						<input
							type="radio"
							value="new"
							{...register('accountType')}
							className="form-input"
						/>{' '}
						Create new account
					</label>

					{watchAccountType === 'existed' && (
						<Fragment>
							<select
								className="form-select w-96"
								{...register('accountId', { valueAsNumber: true, required: true })}
							>
								{accounts.map((account) => (
									<option key={account.id} value={account.id}>
										{account.business_name}
									</option>
								))}
							</select>
						</Fragment>
					)}

					{watchAccountType === 'new' && (
						<Fragment>
							<input
								placeholder="Enter new account name..."
								className="form-input w-96"
								type="text"
								{...register('accountName', { required: true })}
							/>
							<span className="max-w-xl text-dark">
								A new account will be created with above name, and current logged in
								user will be the owner.
							</span>
						</Fragment>
					)}
				</label>

				<label className="flex flex-col gap-2">
					<span className="text-lg">Embed widget code</span>
					<textarea
						placeholder="Enter current embed widget code..."
						className="form-textarea w-96"
						rows={4}
						{...register('embedCode')}
					/>
					<span className="max-w-xl text-dark">
						The system will automatically import the bot icon and other widget settings.
					</span>
					{formState.errors.embedCode && (
						<span className="max-w-xl text-red">
							{formState.errors.embedCode.message}
						</span>
					)}
				</label>

				<button
					className={clsx(
						'h-12 w-48 rounded bg-primary px-4 text-body transition hover:bg-dark',
						mutation.isLoading && 'cursor-wait opacity-50',
					)}
					disabled={mutation.isLoading}
				>
					{mutation.isLoading ? 'Importing...' : 'Import Bot'}
				</button>

				{mutation.isSuccess && (
					<span className="text-lg text-green">Imported successfully</span>
				)}
				{mutation.error instanceof Error && (
					<span className="text-lg text-red">{mutation.error.message}</span>
				)}
			</form>
		</section>
	)
}
