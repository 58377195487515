import { isNil } from 'lodash-es'

export const LANGUAGES = [
	{
		id: 'en',
		name: 'English',
	},
	{
		id: 'fi',
		name: 'Finnish',
	},
	{
		id: 'nl',
		name: 'Dutch',
	},
	{
		id: 'fr',
		name: 'French',
	},
	{
		id: 'de',
		name: 'German',
	},
]

export const createEmbedUrl = (publicId: string, options: { [key: string]: string }): string => {
	const url = new URL(publicId, import.meta.env.VITE_EMBED_DOMAIN)
	const filteredOptions = Object.fromEntries(
		Object.entries(options).filter(([_, value]) => !isNil(value)),
	)
	const params = new URLSearchParams(filteredOptions)
	url.search = params.toString()
	return url.toString()
}
