import React, { useEffect, useRef } from 'react'
import { pick } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/Button'
import FieldCheckbox from '@/components/FieldCheckbox'
import FieldRadio from '@/components/FieldRadio'
import FieldSelect from '@/components/FieldSelect'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { Col, Row } from '@/components/Grid'
import Panel from '@/components/Panel'
import { mapStr2bool } from '@/lib/utils'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'

const EditButtonActions = ({ onSave, onDelete, saveDisabled }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<div className="ml-0 mr-auto">
				<Button onClick={onDelete} variant="red">
					{t('delete')}
				</Button>
			</div>
			<div>
				<Button onClick={onSave} disabled={saveDisabled}>
					{t('save')}
				</Button>
			</div>
		</React.Fragment>
	)
}

const EditButtonPanel = ({ buttonData, onSave, onClose, onSaved, onDelete, currentSlide }) => {
	const { t } = useTranslation()
	const { watch: watchEditor } = useVideobotEditor()
	const slides = watchEditor('slides')
	const actions = watchEditor('actions')
	const {
		register,
		formState: { errors, isValid },
		getValues,
		setValue,
		handleSubmit,
		watch,
	} = useForm({
		mode: 'onBlur',
	})
	const formRef = useRef()
	const targetId = getValues('targetId')
	const name = getValues('name')
	const watchVideoEndListener = watch('videoEndListener')

	const handleSave = (payload) => {
		const { type, id } = payload.targetId

		const arr = !type ? [...slides] : [...actions]
		let obj = arr.find((o) => o.id === id)

		if (!obj?.type) {
			obj = { ...pick(obj, ['name']), type: 'SLIDE' }
		}

		const options = [...currentSlide.options]
		const index = options.findIndex((o) => o.id === buttonData.id)
		const buttonPrototype = { ...obj, ...mapStr2bool(payload), id: buttonData.id, targetId: id }

		if (index !== -1) {
			options[index] = buttonPrototype
		} else {
			options.push(buttonPrototype)
		}

		onSave(options)

		if (onClose) onClose()
		if (onSaved) onSaved()
	}

	const handleRemove = () => {
		onDelete(buttonData)
		if (onClose) onClose()
	}

	useEffect(() => {
		if (!name && targetId)
			setValue('name', targetId.name, { shouldValidate: true, shouldDirty: true })
	}, [targetId])

	return (
		<Panel
			title={t('videobot:editButton')}
			onBack={onClose}
			footer={
				<EditButtonActions
					onDelete={handleRemove}
					onSave={() =>
						formRef.current?.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
					}
					saveDisabled={!targetId || !isValid}
				/>
			}
			zIndex={155}
		>
			<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
				<Row>
					<Col sm={6} xxs={12}>
						{/* Link to */}
						<FormField label={t('videobot:optionFields.linkTo.label')}>
							<FieldSelect
								register={register}
								placeholder={t('videobot:optionFields.linkTo.placeholder')}
								name="targetId"
								type="text"
								error={errors.targetId}
								groups
								setValue={setValue}
								defaultValue={(buttonData.type === 'SLIDE'
									? slides
									: actions
								)?.find((o) => o.id === buttonData.targetId)}
								required
								options={[
									{
										name: t('videobot:optionFields.linkTo.categories.slides'),
										options: slides?.map((item) => ({
											value: item,
											label: item.name,
											type: 'SLIDE',
											item,
										})),
									},
									{
										name: t('videobot:optionFields.linkTo.categories.forms'),
										options: actions
											?.filter((o) => o.type === 'REGULAR_FORM')
											.map((item) => ({
												value: item,
												label: item.name,
												type: item.type,
												item,
											})),
									},
									{
										name: t(
											'videobot:optionFields.linkTo.categories.externalLinks',
										),
										options: actions
											?.filter((o) => o.type === 'EXTERNAL_LINK')
											.map((item) => ({
												value: item,
												label: item.name,
												type: item.type,
												item,
											})),
									},
									{
										name: t(
											'videobot:optionFields.linkTo.categories.postMessages',
										),
										options: actions
											?.filter((o) => o.type === 'POST_MESSAGE')
											.map((item) => ({
												value: item,
												label: item.name,
												type: item.type,
												item,
											})),
									},
									{
										name: t(
											'videobot:optionFields.linkTo.categories.closeVideobot',
										),
										options: actions
											?.filter((o) => o.type === 'CLOSE_VIDEOBOT')
											.map((item) => ({
												value: item,
												label: item.name,
												type: item.type,
												item,
											})),
									},
								]}
							/>
						</FormField>
					</Col>
					<Col sm={6} xxs={12}>
						{/* Name */}
						<FormField label={t('videobot:optionFields.buttonName.label')}>
							<FieldText
								register={register}
								placeholder={t('videobot:optionFields.buttonName.placeholder')}
								name="name"
								type="text"
								error={errors.name}
								defaultValue={buttonData.name}
								required
							/>
						</FormField>
					</Col>
				</Row>
				{/* Style */}
				<FormField label={t('videobot:optionFields.buttonStyle.label')}>
					<FieldRadio
						register={register}
						name="featured"
						value={false}
						defaultChecked={!buttonData.featured}
						setValue={setValue}
						inline
					>
						{t('videobot:optionFields:buttonStyle.standard')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="featured"
						value
						defaultChecked={!!buttonData.featured}
						setValue={setValue}
						inline
					>
						{t('videobot:optionFields:buttonStyle.highlight')}
					</FieldRadio>
				</FormField>
				{/* Functions */}
				<FormField label={t('videobot:optionFields.functions.label')}>
					<FieldCheckbox
						register={register}
						name="videoEndListener"
						defaultChecked={!!buttonData.videoEndListener}
						value
						setValue={setValue}
					>
						{t('videobot:optionFields:functions.videoEndListener')}
					</FieldCheckbox>
					<div
						className={twMerge(
							'ml-4 opacity-50',
							watchVideoEndListener && 'opacity-100',
						)}
					>
						<FieldCheckbox
							register={register}
							name="videoEndAutoplay"
							defaultChecked={!!buttonData.videoEndAutoplay}
							value
							setValue={setValue}
							disabled={!watchVideoEndListener}
						>
							{t('videobot:optionFields:functions.videoEndAutoplay')}
						</FieldCheckbox>
					</div>
					<FieldCheckbox
						register={register}
						name="trackConversion"
						defaultChecked={!!buttonData.trackConversion}
						value
						setValue={setValue}
					>
						{t('videobot:optionFields:functions.trackConversion')}
					</FieldCheckbox>
				</FormField>
			</form>
		</Panel>
	)
}

export default EditButtonPanel
