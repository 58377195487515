import styled from 'styled-components'

import { LinkUnderlineOnHover } from '../../lib/mixins'

export const BreadcrumbsWrapper = styled.div`
	font-size: 0.75rem;
	font-family: ${(props) => props.theme.font.secondary};
	font-weight: 600;
	margin-bottom: 0.5rem;
	display: flex;
	align-items: center;

	> svg {
		margin-right: 0.5rem;
		width: 0.8em;
		height: 0.8em;
		fill: ${(props) => props.theme.color.mutedLight};
	}
`

export const BreadcrumbsItem = styled.span`
	&:not(:last-child) {
		margin-right: 0.5rem;
	}

	&:last-child {
		color: ${(props) => props.theme.color.mutedLight};
	}

	&[href] {
		${LinkUnderlineOnHover()}
		color: inherit;
	}
`
