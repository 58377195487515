import { motion, useAnimation, useDragControls } from 'framer-motion'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Combobox } from '@headlessui/react'

import Icon from '@/components/Icon'

import {
	TeamSelectPopupBackdrop,
	TeamSelectPopupBody,
	TeamSelectPopupDragger,
	TeamSelectPopupInner,
	TeamSelectPopupItem,
	TeamSelectPopupWrapper,
} from './TeamSelect.styles'

const TeamSelectPopup = ({ open, onClose, onSelect, accounts }, ref) => {
	const controls = useAnimation()
	const wrapperRef = useRef(null)
	const dragControls = useDragControls()
	const [visible, setVisible] = useState(false)
	const [query, setQuery] = useState('')
	const filteredAccounts = query
		? accounts.filter((account) =>
				account.businessName.toLowerCase().includes(query.toLowerCase()),
			)
		: accounts

	const handleClick = (e, payload) => {
		e.stopPropagation()
		return onSelect(payload)
	}

	const handleDragEnd = (event, info) => {
		const shouldClose = info.offset.y > 80

		if (shouldClose) {
			controls.start('hidden')
			setVisible(false)
			onClose()
		} else {
			controls.start('visible')
			setVisible(true)
		}
	}

	useImperativeHandle(ref, () => ({
		hide: () => {
			controls.start('hidden')
			setVisible(false)
		},
	}))

	useEffect(() => {
		if (open) setVisible(true)
	}, [])

	useEffect(() => {
		if (open) {
			controls.start('visible')
		} else {
			controls.start('hidden')
		}
	}, [open])

	return createPortal(
		<TeamSelectPopupWrapper open={visible}>
			<TeamSelectPopupBackdrop onClick={() => onClose()} />
			<TeamSelectPopupBody
				ref={wrapperRef}
				as={motion.div}
				drag="y"
				initial="hidden"
				onDragEnd={handleDragEnd}
				animate={controls}
				onSelect={(e) => e.stopPropagation()}
				transition={{
					type: 'spring',
					damping: 40,
					stiffness: 400,
				}}
				variants={{
					visible: { y: 0 },
					hidden: { y: '100%' },
				}}
				dragConstraints={{ top: 0 }}
				dragElastic={0.2}
				dragControls={dragControls}
			>
				<TeamSelectPopupDragger onPointerDown={(e) => dragControls.start(e)} />
				<TeamSelectPopupInner>
					<Combobox>
						<div className="relative flex">
							<Combobox.Button className="absolute inset-y-0 left-4">
								<div className="h-5 w-5">
									<Icon name="search" className="fill-dark" />
								</div>
							</Combobox.Button>
							<Combobox.Input
								className="mx-2 my-2 flex-1 rounded border-none bg-light pl-10 text-dark"
								onChange={(event) => setQuery(event.target.value)}
								displayValue={(account) => account.businessName}
								placeholder="Search"
							/>
						</div>
						<Combobox.Options static>
							{filteredAccounts?.map((account) => (
								<TeamSelectPopupItem
									onClick={(e) => handleClick(e, account)}
									key={account.id}
									as={Combobox.Option}
									value={account}
								>
									{account.businessName}
								</TeamSelectPopupItem>
							))}
						</Combobox.Options>
					</Combobox>
				</TeamSelectPopupInner>
			</TeamSelectPopupBody>
		</TeamSelectPopupWrapper>,
		document.querySelector('#modals'),
	)
}

export default forwardRef(TeamSelectPopup)
