import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const ThumbnailWrapper = styled.span`
	position: relative;
	${Reset('button')}
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: ${(props) => props.theme.radius.sm};
	overflow: hidden;
	${TransitionPrimary('background')}
	cursor: inherit;
	background-color: ${(props) => props.theme.color.light};

	${(props) =>
		props.size === 'default' &&
		css`
			width: ${(props) => props.theme.size.thumbnail.desktop};
			height: ${(props) => props.theme.size.thumbnail.desktop};
			min-width: ${(props) => props.theme.size.thumbnail.desktop};
		`}

	${(props) =>
		props.size === 'large' &&
		css`
			width: 4rem;
			height: 4rem;
			min-width: 4rem;
		`}

  svg {
		position: relative;
		${(props) =>
			props.inverted &&
			css`
				fill: ${(props) => props.theme.color.inverted};
			`};
		${(props) =>
			!props.inverted &&
			css`
				fill: ${(props) => props.theme.color.global};
			`};
		width: 32%;
		height: 32%;
		display: block;
		${TransitionPrimary('fill')}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: ${(props) => props.theme.radius.sm};
		display: block;
	}

	${(props) =>
		props.size === 'small' &&
		css`
			border-radius: ${(props) => props.theme.radius.md};
			width: 1.7rem;
			height: 1.7rem;
			min-width: 1rem;

			svg {
				width: 55%;
				height: 55%;
			}
		`};

	${(props) =>
		props.size === 'medium' &&
		css`
			border-radius: ${(props) => props.theme.radius.md};
			width: 2.3rem;
			height: 2.3rem;
			min-width: 1rem;

			&:before {
				border-radius: ${(props) => props.theme.radius.md};
			}

			svg {
				width: 45%;
				height: 45%;
			}
		`};
`
