import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import Error from '@/layout/Error'
import { useAuth } from '@/modules/session/auth.store'

const AnyButton = Button as any

export const InvalidUserView = () => {
	const [t] = useTranslation()
	const { logout } = useAuth()
	const navigate = useNavigate()
	const logoutHandler = async () => {
		await logout()
		navigate('/login')
	}

	const actions = (
		<div>
			<AnyButton onClick={logoutHandler}>Log Out</AnyButton>
		</div>
	)

	return (
		<Error
			title={t(
				'[Error] Emails mismatch, please login with the same email as you got the invitation to.',
			)}
			actions={actions}
			errorCode={403}
		/>
	)
}
