import AuthLayout from '@/features/Auth/components/AuthLayout'
import ResetPassword from '@/features/Auth/components/ResetPasswordForm'

const ForgotPassword = () => {
	return (
		<AuthLayout variant="password" theme="dark">
			<ResetPassword />
		</AuthLayout>
	)
}
export default ForgotPassword
