import styled, { css } from 'styled-components'

import { InputField, Reset, TransitionPrimary } from '../../lib/mixins'

export const FieldSelectContainer = styled.div`
	position: relative;
`

export const FieldSelectLabel = styled.div`
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: calc(100% - 1.5rem);
	flex: 1;
	min-width: 0;

	${(props) =>
		props.muted &&
		css`
			color: ${(props) => props.theme.color.muted};
		`};
`

export const FieldSelectArrow = styled.div`
	position: absolute;
	top: calc(50% - 5px);
	width: 10px;
	height: 10px;
	pointer-events: none;
	right: 1.5rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: ${(props) => props.theme.color.muted};
	}
`

export const FieldSelectToggler = styled.div`
	${InputField()}
	/* height: 3rem; */
    padding: 0 1rem;
	text-align: left;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
`
export const FieldSelectDropdown = styled.div`
	max-height: 12rem;
	padding: 0.5rem 0;
	overflow: auto;
	z-index: 200;
	background-color: ${(props) => props.theme.color.body};
	box-shadow: ${(props) => props.theme.shadow.sm};
	border-radius: ${(props) => props.theme.radius.md};

	&::-webkit-scrollbar {
		display: block;
		right: 5rem;
	}

	/* width */
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${(props) => props.theme.color.line};
		border-radius: 7px;
		border: 2px solid ${(props) => props.theme.color.body};
	}
`

export const FieldSelectDropdownItem = styled.button`
	${Reset('button')}
	width: 100%;
	display: block;
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0.65rem 1.25rem;
	border-radius: ${(props) => props.theme.radius.default};
	${TransitionPrimary('background')}
	&:hover {
		background-color: ${(props) => props.theme.color.light};
	}
`
export const FieldSelectDropdownCategory = styled.div`
	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
`

export const FieldSelectDropdownCategoryLabel = styled.div`
	padding: 0.25rem 1.25rem;
	color: ${(props) => props.theme.color.mutedLight};
	font-weight: 600;
	font-size: 0.8rem;
`

export const FieldSelectWrapper = styled.div`
	position: relative;
	z-index: 3;

	${(props) =>
		props.open &&
		css`
			z-index: 10;

			${FieldSelectToggler} {
				box-shadow: ${(props) => props.theme.shadow.sm};
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			${FieldSelectToggler} {
				cursor: not-allowed;
				background-color: ${(props) => props.theme.color.line};
			}
		`};
`
