import { RoleType } from '@/api/videobot.schemas'
import ContentEditor from '@/features/Videobot/components/ContentEditor'
import { RoleCheck } from '@/modules/session/auth.component'

const VideobotContentContents = () => {
	return <ContentEditor />
}

const VideobotContent = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Owner,
				RoleType.Super_Admin,
				RoleType.Admin,
				RoleType.Reseller,
				RoleType.Member,
			]}
		>
			<VideobotContentContents />
		</RoleCheck>
	)
}

export default VideobotContent
