import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FieldRadio from '@/components/FieldRadio'
import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import Panel from '@/components/Panel'

const EditExternalLinkActions = ({ onSave, onDelete }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<Button onClick={onDelete} variant="secondary">
				{t('delete')}
			</Button>
			<Button onClick={onSave}>{t('save')}</Button>
		</React.Fragment>
	)
}

const EditExternaLinkActionPanel = ({ linkData, onSave, onDelete, onBack }) => {
	const { t } = useTranslation()
	const formRef = useRef()
	const {
		register,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		setValue,
		reset,
		watch,
	} = useForm({
		defaultValues: linkData,
	})

	const handleSave = (data) => {
		onSave({ ...linkData, ...data })
		reset()
	}

	const handleDelete = () => {
		onDelete(linkData.id)
	}

	const externalLinkTypeOptions = [
		{ value: 'tel', label: t('videobot:linkFields.tel.label') },
		{ value: 'mailto', label: t('videobot:linkFields.mailto.label') },
		{ value: 'url', label: t('videobot:linkFields.url.label') },
		{ value: 'deep_link', label: t('videobot:linkFields.deepLink.label') },
		{ value: 'relative_path', label: t('videobot:linkFields.relativePath.label') },
	]

	const externalLinkType = watch('externalLinkType')

	return (
		<Panel
			title={t('videobot:editExternalLink')}
			onBack={() => onBack()}
			footer={
				<EditExternalLinkActions
					onSave={handleSubmit(handleSave)}
					onDelete={() => handleDelete()}
					saveDisabled={!isValid || !isDirty}
				/>
			}
		>
			<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
				<FormField label={t('videobot:linkFields.name.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:linkFields.name.placeholder')}
						name="name"
						error={errors.name}
						defaultValue={linkData?.name}
						required
					/>
				</FormField>

				<FormField label={t('videobot:linkFields.type.label')} required>
					<FieldSelect
						register={register}
						placeholder={t('videobot:linkFields.type.placeholder')}
						name="externalLinkType"
						error={errors.externalLinkType}
						options={externalLinkTypeOptions}
						defaultValue={linkData?.externalLinkType}
						setValue={setValue}
						size="large"
					/>
				</FormField>

				{externalLinkType === 'tel' && (
					<FormField label={t('videobot:linkFields.tel.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.tel.placeholder')}
							name="url"
							type="tel"
							error={errors.url}
							defaultValue={linkData?.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'url' && (
					<FormField label={t('videobot:linkFields.url.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.url.placeholder')}
							name="url"
							type="url"
							error={errors.url}
							defaultValue={linkData?.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'mailto' && (
					<FormField label={t('videobot:linkFields.mailto.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.mailto.placeholder')}
							name="url"
							type="mailto"
							error={errors.url}
							defaultValue={linkData?.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'deep_link' && (
					<FormField label={t('videobot:linkFields.deepLink.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.deepLink.placeholder')}
							name="url"
							type="deep_link"
							error={errors.url}
							defaultValue={linkData?.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'relative_path' && (
					<FormField label={t('videobot:linkFields.relativePath.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.relativePath.placeholder')}
							name="url"
							type="relative_path"
							error={errors.url}
							defaultValue={linkData?.url}
							required
							size="large"
						/>
					</FormField>
				)}
				<FormField label={t('videobot:socialLinkFields.button.label')}>
					<FieldRadio
						register={register}
						name="style"
						value="standard"
						defaultChecked={linkData.style === 'standard'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:button.standard')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="style"
						value="highlight"
						defaultChecked={linkData.style === 'highlight'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:button.highlight')}
					</FieldRadio>
				</FormField>
				<FormField label={t('videobot:socialLinkFields.target.label')}>
					<FieldRadio
						register={register}
						name="target"
						value="new_tab"
						defaultChecked={linkData.target === 'new_tab'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:target.newWindow')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="target"
						value="same_tab"
						defaultChecked={linkData.target === 'same_tab'}
						setValue={setValue}
						inline
					>
						{t('videobot:socialLinkFields:target.sameTab')}
					</FieldRadio>
				</FormField>
			</form>
		</Panel>
	)
}

export default EditExternaLinkActionPanel
