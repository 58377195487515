import { motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtomValue } from 'jotai'

import Button from '@/components/Button'
import { isNavigationCollapsedAtom } from '@/common/layout'

import DeleteMediaModal from '../DeleteMediaModal'
import { MediaListToolbarCounter, MediaListToolbarWrapper } from './MediaListToolbar.styles'

const MediaListToolbar = ({ selectedItems, setSelectedItems, invalidateMediaList }) => {
	const isNavigationCollapsed = useAtomValue(isNavigationCollapsedAtom)
	const { t } = useTranslation(['common'])
	const [mediaToRemove, setMediaToRemove] = useState([])

	const onDeleted = () => {
		setMediaToRemove([])
		setSelectedItems([])
		invalidateMediaList()
	}

	return (
		<MediaListToolbarWrapper
			as={motion.div}
			initial={{ y: '100%' }}
			animate={{ y: '0%' }}
			exit={{ y: '100%' }}
			transition={{ ease: 'easeOut' }}
			extended={isNavigationCollapsed}
		>
			<Button onClick={() => setMediaToRemove(selectedItems)} size="small" variant="red">
				{t('delete')}
			</Button>
			<MediaListToolbarCounter>
				{t('selectedNumberOfItems', { count: selectedItems.length })}
			</MediaListToolbarCounter>
			{mediaToRemove.length > 0 && (
				<DeleteMediaModal
					show={mediaToRemove.length > 0}
					multiple={mediaToRemove.length > 1}
					media={mediaToRemove.length === 1 ? mediaToRemove[0] : mediaToRemove}
					onClose={() => setMediaToRemove([])}
					onDeleted={onDeleted}
				/>
			)}
		</MediaListToolbarWrapper>
	)
}

export default MediaListToolbar
