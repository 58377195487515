import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import AccountLink from '@/components/AccountLink'
import Avatar from '@/components/Avatar'
import PropTypes from '@/lib/propTypes'
import { useAuth, useCurrentUser } from '@/modules/session/auth.store'
import {
	getUsersCurrentUserQueryKey,
	useReadCurrentUserAccounts,
	useUpdateRoleForUserById,
} from '@/api/videobot'

import AuthForm from '../AuthForm'
import AuthLayout from '../AuthLayout'

const JoinTeamHeader = () => {
	const { t } = useTranslation(['common', 'auth'])
	const user = useCurrentUser()
	return (
		<React.Fragment>
			<Avatar size="lg" src={user.profilePicture?.url}></Avatar>
			<h4>{t('auth:invitedToTeamBlank')}</h4>
			<p>
				<Trans i18nKey="auth:youAreLoggedAs" values={{ email: user.email }}>
					Youre logged in as
					<strong>{user.email}</strong>
				</Trans>
			</p>
		</React.Fragment>
	)
}

const JoinTeamSelect = ({ roleId, teamName, email }) => {
	const [searchParams] = useSearchParams()
	const userId = Number(searchParams.get('userid'))

	const { t } = useTranslation(['auth', 'errors'])
	const { logout, setActiveAccount } = useAuth()
	const user = useCurrentUser()
	const navigate = useNavigate()
	const location = useLocation()
	const { data: accounts } = useReadCurrentUserAccounts()
	const queryClient = useQueryClient()
	const { mutate, isLoading } = useUpdateRoleForUserById()

	const toOtherUser = () => {
		logout()
		navigate('/login', { replace: true })
	}

	useEffect(() => {
		if (user.id !== userId) {
			const urlSearch = new URLSearchParams()
			urlSearch.set('redirect', `${location.pathname}${location.search}`)
			urlSearch.set('error', 'loginWrongUser')
			logout()
			navigate({ pathname: '/login', search: urlSearch.toString() }, { replace: true })
		}
	}, [user, userId, logout, navigate, location, email, t])

	const joinTeam = () => {
		mutate(
			{
				userId: user.id,
				roleId,
				data: {
					status: 'Active',
				},
			},
			{
				onSuccess: (data) => {
					toast.success(t('auth:joinedToTheTeam'))
					queryClient.invalidateQueries(getUsersCurrentUserQueryKey())
					setActiveAccount(accounts.find((o) => o.id === data.accountId)?.id)
					navigate('/dashboard')
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
				},
			},
		)
	}

	return (
		<AuthLayout variant="join-team">
			<AuthForm header={<JoinTeamHeader />} isLoading={isLoading}>
				<AccountLink button={t('join')} onButtonClick={() => joinTeam()}>
					{teamName}
				</AccountLink>
				<AccountLink onClick={toOtherUser} other>
					{t('auth:logInToOtherAccount')}
				</AccountLink>
			</AuthForm>
		</AuthLayout>
	)
}

JoinTeamSelect.propTypes = {
	roleId: PropTypes.number,
	teamName: PropTypes.string,
}

export default JoinTeamSelect
