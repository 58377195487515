import { AnimatePresence, motion } from 'framer-motion'
import { forwardRef } from 'react'

import PropTypes from '@/lib/propTypes'

import { LoaderCircle, LoaderObject, LoaderWrapper } from './Loader.styles'

const Loader = forwardRef((props, ref) => {
	const { size = 50, color, cover, transparent, noOverlay, fixed } = props

	const LoaderIndicator = (
		<LoaderObject
			color={color}
			size={size}
			viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
			xmlns="http://www.w3.org/2000/svg"
			key="indicator"
			as={motion.svg}
			initial={{ opacity: 0, y: -10 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 10 }}
			style={{ originX: `${size / 2}x`, originY: `${size / 2}px` }}
			ref={ref}
		>
			<LoaderCircle
				fill="none"
				stroke-linecap="round"
				cx={size}
				cy={size}
				r={size / 2 - 5}
			></LoaderCircle>
		</LoaderObject>
	)

	return (
		<AnimatePresence mode="wait">
			{cover ? (
				<LoaderWrapper
					key="wrapper"
					transparent={transparent ? 1 : 0}
					noOverlay={noOverlay ? 1 : 0}
					fixed={fixed ? 1 : 0}
					as={motion.div}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.25 }}
					aria-busy
				>
					{LoaderIndicator}
				</LoaderWrapper>
			) : (
				LoaderIndicator
			)}
		</AnimatePresence>
	)
})

Loader.displayName = 'Loader'

Loader.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	cover: PropTypes.bool,
	transparent: PropTypes.bool,
	fixed: PropTypes.bool,
}

export default Loader
