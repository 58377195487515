import styled, { css } from 'styled-components'

export const FieldRadioIndicator = styled.span`
	position: relative;
	width: 16px;
	height: 16px;
	border: 2px solid ${(props) => props.theme.color.line};
	flex-shrink: 0;
	transition: border 0.2s ease-out;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	margin-right: 0.5rem;
	cursor: inherit;

	&:after {
		position: absolute;
		width: 8px;
		height: 8px;
		display: block;
		flex-shrink: 0;
		content: ' ';
		background-color: ${(props) => props.theme.color.primary};
		border-radius: 50%;
		transition: transform 0.2s ease-out;
		transform: scale(0);
	}
`

export const FieldRadioLabel = styled.label`
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	z-index: 2;
	cursor: inherit;
`

export const FieldRadioInput = styled.input`
	display: none;
	position: absolute;
	visibility: hidden;
	height: 0;
	width: 0;

	&:checked ~ ${FieldRadioLabel} {
		${FieldRadioIndicator} {
			border-color: ${(props) => props.theme.color.primary};

			&:after {
				transform: scale(1);
			}
		}
	}
`

export const FieldRadioWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	cursor: pointer;

	${(props) =>
		props.inline &&
		css`
			display: inline-flex;

			&:not(:last-child) {
				margin-right: 1rem;
			}
		`};

	${(props) =>
		props.single &&
		css`
			${FieldRadioIndicator} {
				margin-right: 0;
			}
		`};

	${(props) =>
		props.disabled &&
		css`
			cursor: not-allowed;
			${FieldRadioIndicator} {
				opacity: 0.5;
			}

			${FieldRadioLabel} {
				opacity: 0.5;
			}
		`};
`
