import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import Loader from '@/components/Loader'

import { AuthBox, AuthBoxInner, AuthFooter, AuthHeader } from './AuthForm.styles'

const AuthForm = ({ children, footer, header, isLoading }) => {
	return (
		<AuthBox
			as={motion.div}
			initial={{ opacity: 0, y: -25 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.95, ease: [0.16, 1, 0.3, 1], delay: 0.3 }}
		>
			<AuthBoxInner>
				{header && <AuthHeader>{header}</AuthHeader>}
				{children}
				{footer && <AuthFooter>{footer}</AuthFooter>}
			</AuthBoxInner>
			{isLoading && <Loader cover />}
		</AuthBox>
	)
}

AuthForm.propTypes = {
	children: PropTypes.node,
	footer: PropTypes.element,
	header: PropTypes.element,
	isLoading: PropTypes.bool,
}

export default AuthForm
