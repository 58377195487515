import type { PropsWithChildren } from 'react'

import Loader from '@/components/Loader'
import { AuthenticatedStartup } from '@/modules/session/auth.component'
import { useGlobalConfig } from '@/modules/session/global.hook'

export const Startup = ({ children }: PropsWithChildren) => {
	const { data } = useGlobalConfig()

	// FIXME: This is a hack to work around plain JS
	const AnyLoader = Loader as any
	// FIXME: This is a hack to work around videobot preview
	const urlSearchParams = new URLSearchParams(window.location.search)
	const preview = urlSearchParams.get('preview')
	const embed = urlSearchParams.get('embed')
	const isInIframe = preview || embed

	if (!data && !isInIframe) {
		return <AnyLoader cover />
	}

	return <AuthenticatedStartup>{children}</AuthenticatedStartup>
}
