import { useEffect, useState } from 'react'

import { breakpoints } from '@/lib/theme'

const signRegex = /^[<=>]+/

export const useMediaQuery = (query) => {
	const [matches, setMatches] = useState(false)

	useEffect(() => {
		const calculateSize = (sign, screenSize) => {
			if (sign.includes('=')) {
				return breakpoints[screenSize]
			}

			if (sign.includes('>')) {
				return breakpoints[screenSize] + 1
			}

			return breakpoints[screenSize] - 1
		}
		const sign = query.match(signRegex) ? query.match(signRegex)[0].trim() : ''
		const screenSize = query.replace(signRegex, '').trim()
		const size = calculateSize(sign, screenSize)
		const unit = typeof size === 'number' ? 'px' : ''
		const widthCondition = sign.includes('>') ? 'min-width' : 'max-width'

		const media = window.matchMedia(`(${widthCondition}: ${size}${unit})`)

		if (media.matches !== matches) {
			setMatches(media.matches)
		}
		const listener = () => {
			setMatches(media.matches)
		}
		media.addEventListener('change', listener)
		return () => media.removeEventListener('change', listener)
	}, [matches, query])

	return matches
}
