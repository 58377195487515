import { useState } from 'react'

import PropTypes from '@/lib/propTypes'

import { LazyImageWrapper } from './LazyImage.styles'

const LazyImage = (props) => {
	const { src } = props
	const [isLoaded, setIsLoaded] = useState()

	const handleLoad = () => {
		setIsLoaded(true)
	}

	return (
		<LazyImageWrapper loaded={isLoaded}>
			<img onLoad={handleLoad} src={src} alt="" />
		</LazyImageWrapper>
	)
}

LazyImage.propTypes = {
	src: PropTypes.string,
}

export default LazyImage
