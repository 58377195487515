import * as React from 'react'
import { Navigate } from 'react-router'

import { ImportLegacyBot } from '@/pages/legacy/import_legacy_bot.component'
import { SyncLegacyBots } from '@/pages/legacy/sync_legacy_bots.component'
import { useGetLegacyInfo } from '@/api/videobot'
import { useCurrentUser } from '@/modules/session/auth.store'

export const LegacyToolkitView = () => {
	const user = useCurrentUser()
	const { data, error } = useGetLegacyInfo()

	if (!user.isSuperuser && !user.isCustomerSuccess) {
		return <Navigate to="/dashboard" replace />
	}

	if (!data) {
		return <div>Loading...</div>
	}

	if (error instanceof Error) {
		return <div>Error: {error.message}</div>
	}

	return (
		<div className="flex w-full flex-col gap-4 p-8">
			<h1>Legacy Toolkit</h1>

			<div className="flex flex-col gap-16">
				<SyncLegacyBots />

				<hr />

				<ImportLegacyBot bots={(data as any).bots} accounts={(data as any).accounts} />
			</div>
		</div>
	)
}
