import styled, { css } from 'styled-components'

import config from '@/components/Grid/config'
import { ListTableBody, ListTableRow } from '@/components/ListTable/ListTable.styles'
import { ViewScroller } from '@/layout/View/View.styles'
import media from '@/lib/media'
import { Cover, Reset, TransitionPrimary, TransitionSecondary } from '@/lib/mixins'

export const ContentEditorWrapper = styled.div`
	position: relative;
	width: 100%;
	max-height: 100%;

	&:before {
		${Cover()}
		background: linear-gradient(90deg, ${(props) =>
			props.theme.color.light} 11px, transparent 1%) center,
    linear-gradient(${(props) =>
			props.theme.color.light} 11px, transparent 1%) center, rgba(0, 0, 0, 0.125);
		background-size: 13px 13px;
		${TransitionSecondary('transform, opacity')}

		${(props) =>
			props.mode === 'LIST' &&
			css`
				opacity: 0;
				transform: scale(1.1);
			`};
	}

	${ViewScroller} {
		${media('<=lg')} {
			padding-top: calc(
				${(props) => props.theme.size.topBar.mobile} +
					${(props) => props.theme.spacing.mobile.sm} +
					${(props) => props.theme.size.tabsBar.mobile}
			);
		}
	}
`

export const ContentEditorCategoryWrapper = styled.div`
	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}
`

export const ContentEditorBodyInner = styled.div`
	position: relative;
`

export const ContentEditorCategoryTitle = styled.h5``

export const ContentEditorCategoryList = styled.div``
export const ContentEditorCategoryGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: ${(p) => (config(p).gutterWidth / 2) * -1}px;
	margin-left: ${(p) => (config(p).gutterWidth / 2) * -1}px;
	width: 100%;
	min-height: 8rem;
`

export const ContentEditorCategoryItemWrapper = styled.div`
	margin-right: ${(p) => config(p).gutterWidth / 2}px;
	margin-left: ${(p) => config(p).gutterWidth / 2}px;
	margin-bottom: ${(props) => props.theme.grid.gutterWidth};

	${media('>xs')} {
		flex: 0 0 25%;
		max-width: 21rem;
		min-width: 16rem;
	}

	${media('<=xs')} {
		flex: 0 0 100%;
	}
`

export const ContentEditorSwitcher = styled.nav`
	position: absolute;
	top: -0.6rem;
	right: 0;
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.sm};
	overflow: hidden;
	display: flex;
	z-index: 5;
`

export const ContentEditorSwitcherButton = styled.div`
	${Reset('button')}
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	${TransitionPrimary('background, color')};

	${(props) =>
		props.active &&
		css`
			background-color: ${(props) => props.theme.color.global};
			color: ${(props) => props.theme.color.inverted};
		`};

	svg {
		width: 40%;
		height: 40%;
		fill: currentColor;
		display: block;
	}
`

export const ContentEditorSlideTable = styled.div`
	${ListTableBody} {
		${ListTableRow} {
			&:first-child {
				margin-bottom: 0.5rem;
			}
		}
	}
`

export const ContentEditorSlideList = styled.ul`
	${Reset('list')}
	&:not(:last-child) {
		margin-bottom: 1.25rem;
	}
`
export const ContentEditorSlideListItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
`
