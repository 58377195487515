import styled, { css } from 'styled-components'

import { InputField, Reset } from '../../lib/mixins'

export const FieldTextWrapper = styled.div`
	position: relative;
`

export const FieldTextInput = styled.input`
	${Reset('all')}
	${InputField()}
    display: block;
	width: 100%;
	text-overflow: ellipsis;

	${(props) =>
		props.readOnly &&
		css`
			background-color: ${(props) => props.theme.color.light};
			cursor: not-allowed;

			&:focus,
			&:focus-visible {
				outline: 0;
				border: 1px solid ${(props) => props.theme.color.line};
			}
		`};
`

export const FieldTextMessage = styled.div`
	position: absolute;
	left: 0;
	top: calc(100% + 0.05rem);
	font-size: 0.8rem;
	display: block;
	color: ${(props) => props.theme.color.muted};
	font-weight: 600;

	${(props) =>
		props.variant === 'error' &&
		css`
			color: ${(props) => props.theme.color.red};
		`};
`

export const FieldTextBody = styled.div`
	position: relative;
`

export const FieldTextToggler = styled.span`
	${Reset('button')}
	position: absolute;
	top: 50%;
	right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	transform: translateY(-50%);

	svg {
		height: 100%;
		width: 100%;
		display: block;
		fill: ${(props) => props.theme.color.muted};
		display: block;
	}
`
