import styled, { keyframes } from 'styled-components'

import media from '../../../../lib/media'

export const AuthBody = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
`

export const AuthContent = styled.div`
	padding: 10vh ${(props) => props.theme.grid.outerMargin};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 100%;
	max-width: 100%;

	${media('<=lg')} {
		padding: 3rem ${(props) => props.theme.grid.outerMargin};
	}
`

export const AuthLogo = styled.div`
	display: inline-block;

	svg {
		display: block;
		height: 1.25rem;
		fill: ${(props) =>
			props.scheme === 'light' ? props.theme.color.global : props.theme.color.body};
	}

	${media('>lg')} {
		position: absolute;
		left: 2.5rem;
		top: 2.5rem;
	}

	${media('<=lg')} {
		position: relative;
		margin-bottom: 3rem;
	}
`

const backgroundAnimation = keyframes`
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`

export const AuthBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: ${(props) => props.theme.color[props.scheme]};

	> * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		@media (orientation: landscape) {
			width: 200%;
			max-width: 200%;
			height: 250%;
		}
		@media (orientation: portrait) {
			width: 250%;
			max-width: 250%;
			height: 200%;
		}
		> img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
			animation: ${backgroundAnimation} 21s infinite linear;
		}
	}
`
