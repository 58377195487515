import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import FieldRadio from '@/components/FieldRadio'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { allBotsKey } from '@/api/videobot.keys'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useCreateBot } from '@/api/videobot'

const CreateVideobotModal = ({ onClose, show }, _ref) => {
	const { t } = useTranslation(['videobot'])
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const activeAccount = useCurrentAccount()

	const { mutate, isLoading } = useCreateBot({
		mutation: {
			onSuccess: (data) => {
				queryClient.invalidateQueries({ queryKey: allBotsKey() })
				toast.success(t('videobot:videobotCreated'))
				navigate(`/dashboard/videobots/${data.id}`)
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})

	const handleCreate = (data) => {
		mutate({ data: { accountId: activeAccount.id, ...data } })
	}

	return (
		<Modal header={<h3>{t('videobot:createNewVideobot')}</h3>} show={show} onClose={onClose}>
			<form autoComplete="off" onSubmit={handleSubmit(handleCreate)}>
				<FormField label={t('videobot:botFields.name.label')}>
					<FieldText
						register={register}
						placeholder={t('videobot:botFields.name.placeholder')}
						name="name"
						type="name"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>
				<FormField label={t('videobot:botFields.privacy.label')}>
					<FieldRadio
						register={register}
						name="privacy"
						value="Public"
						defaultChecked
						setValue={setValue}
						inline
					>
						{t('videobot:botFields:privacy.public')}
					</FieldRadio>
					<FieldRadio
						register={register}
						name="privacy"
						value="Private"
						setValue={setValue}
						inline
					>
						{t('videobot:botFields:privacy.private')}
					</FieldRadio>
				</FormField>
				<Button disabled={!isValid} block isLoading={isLoading}>
					{t('videobot:createVideobot')}
				</Button>
			</form>
		</Modal>
	)
}

CreateVideobotModal.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default CreateVideobotModal
