import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '../../lib/mixins'

export const PlayWrapper = styled.button`
	${Reset('button')}
	top: calc(50% - 2.5rem);
	left: calc(50% - 2.5rem);
	width: 5rem;
	height: 5rem;
	position: absolute;
	border-radius: 50%;
	z-index: 4;
	background: transparent;

	${(props) =>
		props.size === 'sm' &&
		css`
			top: calc(50% - 1.5rem);
			left: calc(50% - 1.5rem);
			width: 3rem;
			height: 3rem;
		`};

	&:before {
		${Cover()}
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		${TransitionPrimary('transform')};
	}

	&:after {
		position: absolute;
		top: 50%;
		left: 53%;
		transform: translate3d(-50%, -50%, 0);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 14px 0 14px 22px;
		border-color: transparent transparent transparent white;
		content: ' ';
		${(props) =>
			props.size === 'sm' &&
			css`
				border-width: 7px 0 7px 11px;
			`}
	}

	&:hover {
		&:before {
			transform: scale(1.2);
		}
	}
`
