import styled, { css } from 'styled-components'

import media from '@/lib/media'

export const MediaListToolbarCounter = styled.div`
	margin-left: auto;
	font-size: 0.9rem;
	color: ${(props) => props.theme.color.muted};
`

export const MediaListToolbarWrapper = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 50;
	background-color: ${(props) => props.theme.color.body};
	box-shadow: ${(props) => props.theme.shadow.md};
	display: flex;
	align-items: center;

	${media('>lg')} {
		left: ${(props) => props.theme.size.desktopNav.extended};
		padding: 0.75rem 2.5rem;
		${(props) =>
			props.extended &&
			css`
				left: ${(props) => props.theme.size.desktopNav.collapsed};
			`};
	}

	${media('<=lg')} {
		left: 0;
		padding: 0.5rem ${(props) => props.theme.grid.outerMargin};
	}
`
