import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { useDeleteMediaById } from '@/api/videobot'

const DeleteMediaModal = ({ onClose, show, onDeleted, media, multiple }) => {
	const { t } = useTranslation(['common', 'media'])
	const { mutate, mutateAsync, isLoading } = useDeleteMediaById()

	const handleDeleteMedia = () => {
		mutate(
			{ mediaId: media.id },
			{
				onSuccess() {
					toast.success(t('media:mediaDeleted'))
					onClose()

					if (typeof onDeleted === 'function') onDeleted()
				},
				onError() {
					toast.error(t('errors:errorOccured'))
					onClose()
				},
			},
		)
	}

	const handleDeleteMultipleMedia = () => {
		const promises = []

		media.forEach((item) => {
			promises.push(mutateAsync({ mediaId: item.id }))
		})

		Promise.allSettled(promises).then(() => {
			toast.success(t('media:mediaDeleted'))
			onClose()
			if (typeof onDeleted === 'function') onDeleted()
		})
	}

	return (
		<Modal
			header={<h3>{t('media:removeMedia')}</h3>}
			show={show}
			onClose={onClose}
			variant="confirmation"
		>
			{multiple ? (
				<p>
					<Trans
						i18nKey="media:removeMultipleMediaDescription"
						values={{ count: media.length }}
					>
						Are you sure you want to remove <strong>2 files</strong>?
					</Trans>
				</p>
			) : (
				<p>
					<Trans i18nKey="media:removeMediaDescription" values={{ name: media?.name }}>
						Are you sure you want to remove &nbsp;
						<strong>{media?.name}</strong>?
					</Trans>
				</p>
			)}
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button
				variant="red-solid"
				onClick={() => (multiple ? handleDeleteMultipleMedia() : handleDeleteMedia())}
				isLoading={isLoading}
				wide
			>
				{t('remove')}
			</Button>
		</Modal>
	)
}

DeleteMediaModal.propTypes = {
	onClose: PropTypes.func,
	multiple: PropTypes.bool,
	show: PropTypes.bool,
	media: PropTypes.oneOfType[(PropTypes.object, PropTypes.array)],
	onDeleted: PropTypes.func,
}

export default DeleteMediaModal
