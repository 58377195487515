import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import Button from '@/components/Button'
import ListTable from '@/components/ListTable/ListTable'
import More, { MoreButton } from '@/components/More'
import Panel from '@/components/Panel'
import { LANGS } from '@/lib/constants'
import { breakpoints } from '@/lib/theme'
import { useGetMediaById } from '@/api/videobot'

import AddSubtitlesPanel from './AddSubtitlesPanel'
import DeleteSubtitlesModal from './DeleteSubtitlesModa'

const SubtitlesManagerActions = ({ onClose }) => {
	const { t } = useTranslation()
	return (
		<Button variant="secondary" onClick={onClose}>
			{t('close')}
		</Button>
	)
}

const SubtitlesMenu = ({ row, onDeleteClick }) => {
	const { t } = useTranslation(['videobot'])

	return (
		<More>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('delete')}
			</MoreButton>
		</More>
	)
}

const SubtitlesManager = ({ media, onClose }) => {
	const { t } = useTranslation(['videobot', 'common', 'media'])
	const [showAddModal, setShowAddModal] = useState(false)
	const { data: mediaData } = useGetMediaById(media.id)
	const [subtitlesToDelete, setSubtitlesToDelete] = useState(null)

	const subtitlesColumns = [
		{
			name: t('media:language'),
			selector: (row) => LANGS.find((o) => o.code === row.languageCode)?.name || '-',
		},
		{
			name: t('media:uploaded'),
			selector: (row) => format(new Date(`${row.createdAt}Z`), 'dd.MM.yyyy HH:mm'),
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return <SubtitlesMenu row={row} onDeleteClick={() => setSubtitlesToDelete(row)} />
			},
		},
	]

	return (
		<React.Fragment>
			<Panel
				title={t('media:manageSubtitles')}
				onBack={onClose}
				zIndex={160}
				footer={<SubtitlesManagerActions onClose={onClose} />}
			>
				<h6>{media?.name}</h6>
				{mediaData && (
					<ListTable
						columns={subtitlesColumns}
						data={mediaData.captions}
						addAction={t('media:addSubtitles')}
						onAdd={() => setShowAddModal(true)}
					/>
				)}
			</Panel>
			<AnimatePresence>
				{showAddModal && (
					<AddSubtitlesPanel
						key="ADD"
						media={media}
						onClose={() => setShowAddModal(false)}
					/>
				)}
				{subtitlesToDelete && (
					<DeleteSubtitlesModal
						key="DELETE"
						subtitles={subtitlesToDelete}
						media={media}
						onClose={() => setSubtitlesToDelete(null)}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

export default SubtitlesManager
