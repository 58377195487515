import { Provider } from 'jotai'
import { useState, type PropsWithChildren, createContext, useCallback, useContext } from 'react'

const StoreIdContext = createContext(() => {})

export const StoreProvider = ({ children }: PropsWithChildren) => {
	const [storeId, setStoreId] = useState(Date.now())
	const resetStore = useCallback(() => setStoreId(Date.now()), [])

	return (
		<Provider key={storeId}>
			<StoreIdContext.Provider value={resetStore}>{children}</StoreIdContext.Provider>
		</Provider>
	)
}

export const useResetStore = () => {
	const resetStore = useContext(StoreIdContext)
	return resetStore
}
