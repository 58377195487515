import { Combobox } from '@headlessui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import PropTypes from '@/lib/propTypes'
import Icon from '@/components/Icon'
import { useAuth } from '@/modules/session/auth.store'

import { TeamSelectDropdownBody, TeamSelectDropdownItem } from './TeamSelect.styles'

const TeamSelectDropdown = ({ onSelect, accounts, onClose }) => {
	const { clearActiveAccount, activeAccountId } = useAuth()
	const navigate = useNavigate()
	const [translate] = useTranslation(['account'])
	const [query, setQuery] = useState('')
	const filteredAccounts = query
		? accounts.filter((account) =>
				account.businessName.toLowerCase().includes(query.toLowerCase()),
			)
		: accounts

	const handleClick = (e, payload) => {
		e.stopPropagation()
		onSelect(payload)
	}

	const handleAllAccountsClick = () => {
		clearActiveAccount()
		navigate('/dashboard')
		onClose()
	}

	return (
		<TeamSelectDropdownBody>
			<Combobox>
				<div className="relative flex">
					<Combobox.Button className="absolute inset-y-0 left-4">
						<div className="h-5 w-5">
							<Icon name="search" className="fill-dark" />
						</div>
					</Combobox.Button>
					<Combobox.Input
						className="form-input mx-2 my-2 flex-1 rounded border-none bg-light pl-10 text-dark"
						onChange={(event) => setQuery(event.target.value)}
						displayValue={(account) => account.businessName}
						placeholder="Search"
						autoFocus
					/>
				</div>
				<Combobox.Options static>
					<TeamSelectDropdownItem
						onClick={handleAllAccountsClick}
						as={Combobox.Option}
						disabled={!activeAccountId}
						isActive={!activeAccountId}
					>
						{translate('allAccounts')}
					</TeamSelectDropdownItem>
					{filteredAccounts?.map((account) => (
						<TeamSelectDropdownItem
							onClick={(e) => handleClick(e, account)}
							key={account.id}
							as={Combobox.Option}
							value={account}
							disabled={activeAccountId === account.id}
							isActive={activeAccountId === account.id}
						>
							{account.businessName}
						</TeamSelectDropdownItem>
					))}
				</Combobox.Options>
			</Combobox>
		</TeamSelectDropdownBody>
	)
}

TeamSelectDropdown.propTypes = {
	onSelect: PropTypes.func,
	accounts: PropTypes.array,
}

export default TeamSelectDropdown
