import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { PropTypes } from 'prop-types'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { v4 } from 'uuid'
import { useAtom } from 'jotai'

import { currentPopupAtom } from '@/common/layout'

import { PopoverTooltip, PopoverWrapper } from './Popover.styles'

const Popover = (props) => {
	const { toggler, children, variant = 'default', triggerOnHover = false } = props
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [currentPopup, setCurrentPopup] = useAtom(currentPopupAtom)
	const uuidv4 = useRef(v4())

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		strategy: 'absolute',
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 5],
				},
			},
		],
	})

	const handleTriggerClick = (e) => {
		if (uuidv4.current !== currentPopup) {
			setCurrentPopup(uuidv4.current)
		} else {
			setCurrentPopup(null)
		}

		e.stopPropagation()
	}

	const handleMouseEnter = () => {
		setCurrentPopup(uuidv4.current)
	}

	const handleMouseLeave = () => {
		setCurrentPopup(null)
	}

	useEffect(() => {
		if (currentPopup === uuidv4.current) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	}, [currentPopup])

	return (
		<React.Fragment>
			<PopoverWrapper
				open={isOpen}
				onClick={triggerOnHover ? null : handleTriggerClick}
				onMouseEnter={triggerOnHover ? handleMouseEnter : null}
				onMouseLeave={triggerOnHover ? handleMouseLeave : null}
				ref={setReferenceElement}
				variant={variant}
			>
				{toggler}
			</PopoverWrapper>

			{createPortal(
				<AnimatePresence mode="wait">
					{isOpen && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.1 }}
						>
							<PopoverTooltip
								style={{ ...styles.popper }}
								{...attributes.popper}
								ref={setPopperElement}
							>
								{children}
							</PopoverTooltip>
						</motion.div>
					)}
				</AnimatePresence>,
				document.querySelector('#tooltips'),
			)}
		</React.Fragment>
	)
}

Popover.propTypes = {
	name: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.string,
	triggerOnHover: PropTypes.bool,
}

export default Popover
