import styled from 'styled-components'

import media from '@/lib/media'

export const CropperWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.color.light};
	border-radius: ${(props) => props.theme.radius.lg};
	flex: 1 1 auto;
	user-select: none;
	${media('>lg')} {
		min-height: max(10rem, 25vh);
		.ReactCrop__child-wrapper > img,
		.ReactCrop__child-wrapper > video {
			max-height: calc(100vh - 20rem);
		}
	}

	${media('<=lg')} {
		.ReactCrop__child-wrapper > img,
		.ReactCrop__child-wrapper > video {
			max-height: calc(
				100vh - ${(props) => props.theme.size.topBar.mobile} -
					${(props) => props.theme.size.topBar.mobile}
			);
		}
	}
`
