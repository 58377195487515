import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@uidotdev/usehooks'
import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { toast } from 'react-toastify'

import { useCreateReseller, useUsersResellers } from '@/api/videobot'
import { RoleType } from '@/api/videobot.schemas'
import View from '@/layout/View'
import { RoleCheck } from '@/modules/session/auth.component'
import { Error500 } from '@/pages/Errors/Error500'
import Button from '@/components/Button'
import DataTable from '@/components/DataTable'
import { breakpoints } from '@/lib/theme'
import { useCurrentUser } from '@/modules/session/auth.store'
import More, { MoreButton } from '@/components/More'
import { DeleteResellerModal } from '@/pages/reseller/delete_reseller_modal.component'
import InviteUserModal from '@/features/Users/components/InviteUserModal'

const AnyButton = Button as any
const AnyMoreButton = MoreButton as any

interface UserMenuProps {
	row: any
	onDeleteClick: (row: any) => void
}

const UserMenu = ({ row, onDeleteClick }: UserMenuProps) => {
	const user = useCurrentUser()
	const { t } = useTranslation(['user'])

	return (
		<Fragment>
			{user.isSuperuser && (
				<More>
					<AnyMoreButton onClick={() => onDeleteClick(row)} variant="red">
						{t('remove')}
					</AnyMoreButton>
				</More>
			)}
		</Fragment>
	)
}

export const ResellerViewContents = () => {
	const [t] = useTranslation(['user'])
	const { data, error, isLoading, refetch } = useUsersResellers()
	const { width: windowWidth } = useWindowSize()
	const [userToDelete, setUserToDelete] = useState(null)
	const [showInviteModal, setShowInviteModal] = useState(false)
	const { mutate: mutateCreateReseller, isLoading: isLoadingCreateReseller } = useCreateReseller()

	if (error) {
		return <Error500 />
	}

	if (!data) {
		return <div>Loading...</div>
	}

	const onDeleteClick = (user: any) => {
		setUserToDelete(user)
	}

	const onDelete = () => {
		refetch()
	}

	const inviteUser = (data: any) => {
		mutateCreateReseller(
			{ data: { email: data.userEmail } },
			{
				onSuccess: () => {
					refetch()
					setShowInviteModal(false)
					toast.success(t('user:invitationHasBeenSent'))
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
				},
			},
		)
	}

	const columns = [
		{
			width: '2.75rem',
			center: true,
			omit: (windowWidth || 0) > breakpoints.lg,
			button: true,
			cell: (row: any) => {
				return <UserMenu row={row} onDeleteClick={onDeleteClick} />
			},
		},
		{
			name: t('account:fields.email.label'),
			sortable: false,
			grow: 2,
			minWidth: '200px',
			selector: (row: any) => row.email,
		},
		{
			name: t('account:fields.firstName.label'),
			selector: (row: any) => row.firstName,
			sortable: false,
		},
		{
			name: t('account:fields.lastName.label'),
			selector: (row: any) => row.lastName,
			sortable: false,
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row: any) => {
				return <UserMenu row={row} onDeleteClick={onDeleteClick} />
			},
		},
	]

	// TODO: Pagination
	return (
		<View
			header
			title={t('resellers')}
			actions={
				<AnyButton onClick={() => setShowInviteModal(true)}>
					{t('user:inviteReseller')}
				</AnyButton>
			}
			noHeaderBorder
			isLoading={isLoading}
		>
			<DataTable
				columns={columns}
				data={data}
				pageSize={100}
				totalCount={data.length}
				page={1}
				pagination={false}
			/>
			<AnimatePresence>
				{userToDelete && (
					<DeleteResellerModal
						user={userToDelete}
						onClose={() => setUserToDelete(null)}
						onDelete={onDelete}
					/>
				)}
				{showInviteModal && (
					<InviteUserModal
						onSubmit={inviteUser}
						isLoading={isLoadingCreateReseller}
						onClose={() => setShowInviteModal(false)}
						noAccount
					/>
				)}
			</AnimatePresence>
		</View>
	)
}

export const ResellerView = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin]}>
			<ResellerViewContents />
		</RoleCheck>
	)
}
