import { forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router'

import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FormRow from '@/components/FormRow'
import FormSection from '@/components/FormSection/FormSection'
import { Col, Row } from '@/components/Grid'
import PropTypes from '@/lib/propTypes'
import { useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'

const AccountSettingsAccountForm = ({ account, onSubmit }, ref) => {
	const { t } = useTranslation(['account'])
	const formRef = useRef(null)
	const activeAccount = useCurrentAccount()
	const currentUser = useCurrentUser()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})

	useImperativeHandle(ref, () => ({
		submit: () => {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
		},
	}))

	useEffect(() => {
		register('expiredAt')
	}, [register])

	const isCurrentUserAllowed =
		currentUser.isSuperuser || currentUser.isCustomerSuccess || currentUser.isReseller

	if (!isCurrentUserAllowed || !activeAccount) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<form onSubmit={handleSubmit((data) => onSubmit(data))} ref={formRef}>
			{/* Details */}
			<FormSection
				title={t('account:section.accountDetails.label')}
				description={t('account:section.accountDetails.description')}
			>
				{/* Name */}
				<FormRow title={t('account:group.name.label')}>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.firstName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.firstName.placeholder')}
									name="firstName"
									type="text"
									error={errors.firstName}
									defaultValue={account?.firstName}
									required
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.lastName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.lastName.placeholder')}
									name="lastName"
									type="text"
									error={errors.lastName}
									defaultValue={account?.lastName}
									required
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Email & Phone */}
				<FormRow
					title={t('account:group.emailAndPhone.label')}
					description={t('account:group.emailAndPhone.description')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.billingEmail.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.billingEmail.placeholder')}
									name="billingEmail"
									type="email"
									error={errors.billingEmail}
									defaultValue={account?.billingEmail}
									required
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.phoneNumber.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.phoneNumber.placeholder')}
									name="phoneNumber"
									type="text"
									error={errors.phoneNumber}
									defaultValue={account?.phoneNumber}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Business Details */}
				<FormRow
					title={t('account:group.businessDetails.label')}
					description={t('account:group.businessDetails.description')}
				>
					<Row>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.businessName.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessName.placeholder')}
									name="businessName"
									type="text"
									error={errors.businessName}
									defaultValue={account?.businessName}
									required
								/>
							</FormField>
							<FormField label={t('account:fields.taxId.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.taxId.placeholder')}
									name="taxId"
									type="text"
									error={errors.taxId}
									defaultValue={account?.taxId}
								/>
							</FormField>
						</Col>
						<Col sm={6} xxs={12}>
							<FormField label={t('account:fields.businessId.label')} required>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessId.placeholder')}
									name="businessId"
									type="text"
									defaultValue={account?.businessId}
									error={errors.businessId}
									required
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
				{/* Business Address */}
				<FormRow
					title={t('account:group.businessAddress.label')}
					description={t('account:group.businessAddress.description')}
				>
					<Row>
						<Col xxs={12}>
							<FormField label={t('account:fields.businessAddress.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.businessAddress.placeholder')}
									name="address"
									type="text"
									defaultValue={account?.address}
								/>
							</FormField>
						</Col>
					</Row>
					<Row>
						<Col md={4} sm={6} xxs={12}>
							<FormField label={t('account:fields.zip.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.zip.placeholder')}
									name="zipCode"
									country
									type="text"
									error={errors.zipCode}
									defaultValue={account?.zipCode}
								/>
							</FormField>
						</Col>
						<Col md={4} sm={6} xxs={12}>
							<FormField label={t('account:fields.country.label')}>
								<FieldText
									register={register}
									placeholder={t('account:fields.country.placeholder')}
									name="country"
									type="text"
									error={errors.country}
									defaultValue={account?.country}
								/>
							</FormField>
						</Col>
					</Row>
				</FormRow>
			</FormSection>
		</form>
	)
}

const propTypes = {
	account: PropTypes.object,
	onSubmit: PropTypes.func,
	variant: PropTypes.oneOf(['CREATE', 'UPDATE']),
}

const ForwardedAccountSettingsAccountForm = forwardRef(AccountSettingsAccountForm)

ForwardedAccountSettingsAccountForm.propTypes = propTypes

export default ForwardedAccountSettingsAccountForm
