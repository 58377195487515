import styled, { keyframes } from 'styled-components'

export const ErrorWrapper = styled.div`
	height: 100vh;
	background-color: ${(props) => props.theme.color[props.background]};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	overflow: auto;
`

export const ErrorContent = styled.div`
	padding: 10vh ${(props) => props.theme.grid.outerMargin};
	width: 40rem;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;

	h2 {
		margin-bottom: 2.25rem;
	}
`
const playAnimation = keyframes`
  0%{ transform: scale(1.2); opacity: 1; }
  50%{ transform: scale(0.9); opacity: 0.5;}
  100%{ transform: scale(1.2); opacity: 1;}
`

const cogAnimation = keyframes`
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`

export const ErrorIcon = styled.div`
	display: inline-block;
	margin-bottom: 2rem;
	max-width: 80%;

	svg {
		height: 10rem;
		max-width: 100%;
		margin: 0 auto;
		display: block;

		#play {
			transform-origin: 50% 50%;
			animation: ${playAnimation} 2.5s infinite;
		}

		#cog-1 {
			transform-origin: 190px 71px;
			animation: ${cogAnimation} 3s infinite linear;
		}

		#cog-2 {
			transform-origin: 331px 70px;
			animation: ${cogAnimation} 5s infinite linear;
		}
	}
`
