import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Modal from '@/components/Modal'

export const AddCloseVideobotModal = ({ onClose, show, isLoading, onSubmit }, _ref) => {
	const { t } = useTranslation(['videobot'])

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})

	const handleFormSubmit = (data) => {
		if (onSubmit)
			onSubmit({
				...data,
				type: 'CLOSE_VIDEOBOT',
			})
	}

	return (
		<Modal header={<h3>{t('videobot:createCloseVideobot')}</h3>} show={show} onClose={onClose}>
			<form autoComplete="off" onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
				<FormField label={t('videobot:closeVideobotFields.name.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:closeVideobotFields.name.placeholder')}
						name="name"
						type="text"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>

				<Button disabled={!isValid} block isLoading={isLoading}>
					{t('videobot:createCloseVideobot')}
				</Button>
			</form>
		</Modal>
	)
}
