import { useTranslation } from 'react-i18next'
import { padStart } from 'lodash-es'

import DataTable from '@/components/DataTable'
import { AnalyticsFieldType } from '@/api/videobot.schemas'
import { useReadPaginatedWidgetAnalytics } from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'
import Loader from '@/components/Loader'
import { AnalyticsSliderDetails } from '@/features/analytics/components/AnalyticsSliderDetails'

const formatDurationToHours = (durationSeconds) => {
	const hours = Math.floor(durationSeconds / 3600)
	const minutes = Math.floor((durationSeconds % 3600) / 60)
	const seconds = Math.floor((durationSeconds % 3600) % 60)

	return `${padStart(hours, 2, '0')}:${padStart(minutes, 2, '0')}:${padStart(seconds, 2, '0')}`
}

const fieldEnabled = (analyticsDisplayFields, fieldName) => {
	return analyticsDisplayFields[fieldName] === true || !(fieldName in analyticsDisplayFields)
}

export const AnalyticsSliderList = ({
	reportData,
	params,
	onPageChange,
	analyticsDisplayFields,
	onBotEdit,
	onBotPreview,
	newCtrFormula,
	onSort,
}) => {
	const { t } = useTranslation(['analytics'])
	const activeAccount = useCurrentAccount()
	const { data: analyticsData } = useReadPaginatedWidgetAnalytics(
		activeAccount.id,
		{
			from_date: params.from_date,
			to_date: params.to_date,
			slider_ids: params.slider_ids,
			page: 1,
			size: 1000,
		},
		{ query: { retry: false, staleTime: 60000, enabled: !reportData } },
	)

	const customStyles = {
		rows: {
			style: {
				minHeight: '64px', // override the row height
			},
		},
		expanderButton: {
			style: {
				backgroundColor: 'transparent',
				'&:hover:not(:disabled)': {
					backgroundColor: 'transparent',
				},
				'&:focus': {
					backgroundColor: 'transparent',
				},
			},
		},
	}

	const columns = [
		{
			width: '4.7rem',
			cell: (row) => {
				return (
					<img
						src={`/assets/icons/widget_${row.slider.type.toLowerCase()}.svg`}
						alt={t(`widget:types.${row.slider.type}`)}
						className="h-full w-full"
					/>
				)
			},
		},
		{
			id: 'name',
			name: t('name'),
			selector: (row) => row.slider.name,
			sortable: true,
			sortFunction: (a, b) =>
				a.slider.name.localeCompare(b.slider.name, undefined, { sensitivity: 'base' }),
			minWidth: '200px',
		},
		{
			id: 'session',
			name: t('analytics:session'),
			selector: (row) => {
				return row.sliderSession
			},
			sortable: true,
		},
		{
			id: 'slider_opened',
			name: t('analytics:sliderTimesOpened'),
			selector: (row) => {
				return row.sliderPlayed
			},
			sortable: true,
		},
		{
			id: 'opened',
			name: t('analytics:timesPlayed'),
			selector: (row) => {
				return newCtrFormula ? row.opened : row.sliderIntroViewed
			},
			sortable: true,
		},
		{
			name: t('analytics:ctr'),
			selector: (row) => {
				return (row.sliderPlayed / row.sliderSession) * 100 || 0
			},
			cell: (row) => {
				return `${((row.sliderPlayed / row.sliderSession) * 100 || 0).toFixed(1)}%`
			},
			sortable: true,
		},
		{
			name: t('analytics:minutesViewed'),
			selector: (row) => row.watched,
			cell: (row) => formatDurationToHours(row.watched) || '00:00:00',
			sortable: true,
		},
		{
			name: t('analytics:ctaClicks'),
			selector: (row) => row.cta,
			sortable: true,
			omit: !fieldEnabled(analyticsDisplayFields, AnalyticsFieldType.cta),
		},
	]

	const data = analyticsData || reportData

	if (!data) {
		return <Loader />
	}

	return (
		<DataTable
			columns={columns}
			data={data.records || data}
			expandableRows
			expandOnRowClicked
			expandableRowExpanded={() => !!reportData}
			expandableRowsComponent={(props) => (
				<AnalyticsSliderDetails
					sliderData={props.data}
					sliderId={props.data.id}
					accountId={activeAccount.id}
					analyticsDisplayFields={analyticsDisplayFields}
					params={params}
					onBotEdit={onBotEdit}
					onBotPreview={onBotPreview}
				/>
			)}
			pagination={!reportData}
			customStyles={customStyles}
			pageSize={params.paginationSize}
			paginationServer={false}
			onPageChange={onPageChange}
			page={params.paginationPage}
			onSort={onSort}
			defaultSortFieldId={params.orderBy}
			defaultSortAsc={params.orderDirection === 'asc'}
		/>
	)
}
