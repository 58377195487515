import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useDeleteUserFromAccountById } from '@/api/videobot'

const DeleteUserModal = ({ onClose, show, user, onDelete }) => {
	const { t } = useTranslation(['common', 'user'])
	const navigate = useNavigate()
	const activeAccount = useCurrentAccount()
	const { mutate, isLoading } = useDeleteUserFromAccountById()

	const deleteUser = () => {
		mutate(
			{ accountId: activeAccount.id, userId: user.id },
			{
				onSuccess: () => {
					toast.success(t('user:userDeleted'))
					navigate('/dashboard/users')
					onClose()

					if (typeof onDelete === 'function') onDelete()
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
					onClose()
				},
			},
		)
	}

	return (
		<Modal
			header={<h3>{t('user:deleteUser')}</h3>}
			show={show}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="user:deleteUserDescription"
					values={{ firstName: user?.firstName, lastName: user?.lastName }}
				>
					Are you sure you want to delete user &nbsp;
					<strong>
						{user?.firstName} {user?.lastName}
					</strong>
					?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="red-solid" onClick={() => deleteUser()} isLoading={isLoading} wide>
				{t('remove')}
			</Button>
		</Modal>
	)
}

DeleteUserModal.propTypes = {
	onClose: PropTypes.func,
	show: PropTypes.bool,
	user: PropTypes.object,
	onDelete: PropTypes.func,
}

export default DeleteUserModal
