import type { GetFeatureFlagsForCurrentUserParams } from '@/api/videobot.schemas'
import { useGetFeatureFlagsForCurrentUser, useGetGlobalConfig } from '@/api/videobot'
import { useAuth } from '@/modules/session/auth.store'

export const useGlobalConfig = () => {
	return useGetGlobalConfig({ query: { staleTime: 1000 * 60 } })
}

export const useFeatureFlags = (params?: GetFeatureFlagsForCurrentUserParams) => {
	const { isAuthenticated } = useAuth()

	// FIXME: where does undefined come from?
	const transformedParams = params
		? { ...params, account_id: params?.account_id ?? 0 }
		: undefined

	return useGetFeatureFlagsForCurrentUser(transformedParams, {
		query: { staleTime: 1000 * 30, enabled: isAuthenticated },
	})
}
