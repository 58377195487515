import SVG from 'react-inlinesvg'

import { IconSquare, IconRound } from './Icon.styles'

const Icon = (props) => {
	const {
		name,
		variant,
		size = 'default',
		color = 'light',
		inverted,
		isButton,
		onClick,
		className,
	} = props

	try {
		const icon = `/assets/icons/${name}.svg`

		if (variant === 'square') {
			return (
				<IconSquare
					as={isButton ? 'button' : 'span'}
					hoverable={isButton}
					size={size}
					color={color}
					inverted={inverted}
					onClick={onClick}
				>
					<SVG src={icon} preProcessor={(code) => code.replace(/fill=".*?"/g, '')}></SVG>
				</IconSquare>
			)
		} else if (variant === 'round') {
			return (
				<IconRound
					as={isButton ? 'button' : 'span'}
					hoverable={isButton}
					size={size}
					color={color}
					inverted={inverted}
					onClick={onClick}
				>
					<SVG src={icon} preProcessor={(code) => code.replace(/fill=".*?"/g, '')}></SVG>
				</IconRound>
			)
		}

		return (
			<SVG
				className={className}
				src={icon}
				preProcessor={(code) => code.replace(/fill=".*?"/g, '')}
			></SVG>
		)
	} catch {
		console.error(`<Icon> Missing "${name}" icon`)
		return <div></div>
	}
}

export default Icon
