import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import PropTypes from '@/lib/propTypes'

import {
	FieldRadioIndicator,
	FieldRadioInput,
	FieldRadioLabel,
	FieldRadioWrapper,
} from './FieldRadio.styles'

const FieldRadio = (props) => {
	const {
		id: initId,
		register,
		error,
		name,
		children,
		required,
		value,
		defaultChecked,
		setValue,
		onChange,
		inline,
		disabled,
	} = props

	const [id] = useState(() => initId ?? uuidv4())

	const handleChange = (e) => {
		const checked = e.target.checked

		if (checked) {
			if (setValue)
				setValue(name, e.target.value, { shouldValidate: true, shouldDirty: true })
			if (onChange) onChange(checked, e.target.value)
		}
	}

	useEffect(() => {}, [])

	return (
		<FieldRadioWrapper error={error} inline={inline} single={!children} disabled={disabled}>
			<FieldRadioInput
				{...(register && register(name, { required }))}
				value={value}
				id={id}
				name={name}
				type="radio"
				onChange={handleChange}
				disabled={disabled}
				defaultChecked={defaultChecked}
			/>
			<FieldRadioLabel htmlFor={id}>
				<FieldRadioIndicator />
				{children}
			</FieldRadioLabel>
		</FieldRadioWrapper>
	)
}

FieldRadio.propTypes = {
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	children: PropTypes.node,
	defaultChecked: PropTypes.bool,
	required: PropTypes.bool,
	inline: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	register: PropTypes.func,
	setValue: PropTypes.func,
	onChange: PropTypes.func,
}

export default FieldRadio
