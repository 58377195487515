import { useState } from 'react'

import usePrompt from '@/hooks/usePrompt'

const useEditorFormPrompt = ({ when, condition }) => {
	const [isSubmitted, setIsSubmitted] = useState(false)
	const { showPrompt, acceptPrompt, cancelPrompt } = usePrompt({
		when,
		condition,
	})

	return {
		isSubmitted,
		setIsSubmitted,
		showPrompt,
		acceptPrompt,
		cancelPrompt,
	}
}

export default useEditorFormPrompt
