import { useSearchParams } from 'react-router-dom'

import AuthLayout from '@/features/Auth/components/AuthLayout'
import NewPasswordForm from '@/features/Auth/components/NewPasswordForm'

export const NewPasswordView = () => {
	const [searchParams] = useSearchParams()

	const token = searchParams.get('token')
	const email = searchParams.get('email')
	const newPassword = Boolean(searchParams.get('new'))

	return (
		<AuthLayout variant="password" theme="dark">
			<NewPasswordForm token={token} email={email} newPassword={newPassword} />
		</AuthLayout>
	)
}
