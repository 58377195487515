import { useContext } from 'react'

import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useReadAccountDetailById } from '@/api/videobot'
import Loader from '@/components/Loader'
import Error from '@/layout/Error'
import { AccountSettingsContext } from '@/pages/Account/account_settings.context'

import { AccountSettingsIntegrationForm } from '../../features/Account/components/AccountSettingsIntegrationForm/AccountSettingsIntegration'

const AccountSettingsIntegrationsContents = () => {
	const activeAccount = useCurrentAccount()
	const { formRef } = useContext(AccountSettingsContext)

	const { data: account, isLoading, isError } = useReadAccountDetailById(activeAccount.id)

	if (isLoading) {
		return <Loader />
	}

	if (isError) {
		return <Error background="body" />
	}

	return <div>{account && <AccountSettingsIntegrationForm ref={formRef} />}</div>
}

const AccountSettingsIntegrations = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin, RoleType.Reseller, RoleType.Owner]}>
			<AccountSettingsIntegrationsContents />
		</RoleCheck>
	)
}

export default AccountSettingsIntegrations
