import React, { useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { upperFirst } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import FieldSelect from '@/components/FieldSelect'
import Icon from '@/components/Icon'
import ListTable from '@/components/ListTable/ListTable'
import { breakpoints } from '@/lib/theme'
import { AddPostMessageModal } from '@/features/Videobot/components/ContentEditor/add_post_message.modal'
import { AddCloseVideobotModal } from '@/features/Videobot/components/ContentEditor/add_close_videobot.modal'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'

import Card from '../Card'
import AddExternalLink from './AddExternalLinkModal'
import AddFormModal from './AddFormModal'
import ContentEditorCategory, { ContentEditorCategoryItem } from './ContentEditorCategory'

const EditorActionsList = ({ onAdd, onEdit, addComponent }) => {
	const { watch, setValue } = useVideobotEditor()
	const actions = watch('actions')
	const { t } = useTranslation(['videobot'])

	const types = [
		{
			title: t('videobot:regularForm'),
			name: 'REGULAR_FORM',
			icon: 'mail',
			color: 'primary',
			inverted: true,
		},
		{
			title: t('videobot:externalLink'),
			name: 'EXTERNAL_LINK',
			icon: 'external-link',
			color: 'purple',
			inverted: false,
		},
		{
			title: t('videobot:postMessage'),
			name: 'POST_MESSAGE',
			icon: 'mail',
			color: 'purple',
			inverted: false,
		},
		{
			title: t('videobot:closeVideobot'),
			name: 'CLOSE_VIDEOBOT',
			icon: 'cross',
			color: 'purple',
			inverted: false,
		},
	]

	const optionsColumns = [
		{
			thumbnail: true,
			selector: (row) => {
				const obj = types.find((o) => o.name === row.type)

				if (obj)
					return (
						<Icon
							name={obj.icon}
							variant="square"
							color={obj.color}
							inverted={obj.inverted}
						/>
					)
				else return '-'
			},
		},
		{
			name: t('type'),
			selector: (row) => {
				const obj = types.find((o) => o.name === row.type)

				if (obj) return <span>{obj.title}</span>
				else return '-'
			},
		},
		{
			name: t('videobot:formFields.title.label'),
			selector: (row) => row.name || row.title,
		},
		{
			name: t('to'),
			selector: (row) => row.email || row.url,
		},
		{
			name: t('fields'),
			selector: (row) =>
				row.fields
					? row.fields
							?.filter((o) => o.type !== 'checkbox')
							.map(
								(option) =>
									`${upperFirst(option.label)}${option.required ? '*' : ''}`,
							)
							.join(', ')
					: '-',
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<Icon
						name="edit"
						variant="square"
						size="small"
						color="line"
						isButton
						onClick={() => onEdit(row)}
					></Icon>
				)
			},
		},
	]

	return (
		<ContentEditorCategory title={t('videobot:callToAction')} mode="LIST">
			<ListTable
				dragEnabled
				columns={optionsColumns}
				data={actions}
				addAction={t('videobot:addForm')}
				onAdd={onAdd}
				addComponent={addComponent}
				onReorder={(payload) => setValue('actions', payload)}
				onRowClicked={onEdit}
			/>
		</ContentEditorCategory>
	)
}

const EditorActionsGrid = ({ onAdd, onEdit, addComponent }) => {
	const { watch } = useVideobotEditor()
	const actions = watch('actions')
	const { t } = useTranslation()

	return (
		<ContentEditorCategory title={t('videobot:callToAction')} mode="GRID">
			<AnimatePresence>
				{actions?.map((action) => (
					<ContentEditorCategoryItem key={action.id} id={action.id}>
						<Card item={action} onEdit={() => onEdit(action)} />
					</ContentEditorCategoryItem>
				))}
			</AnimatePresence>
			<ContentEditorCategoryItem>
				<Card variant="ADD_ACTION" onAdd={onAdd} addComponent={addComponent} />
			</ContentEditorCategoryItem>
		</ContentEditorCategory>
	)
}

const ContentEditorActions = ({ mode }) => {
	const { t } = useTranslation()
	const {
		actions: { addAction },
	} = useVideobotEditor()
	const navigate = useNavigate()
	const [addModal, setAddModal] = useState(null)
	const selectFieldRef = useRef(null)

	const actionTypes = [
		{
			label: t('videobot:contactForm'),
			value: 'FORM',
		},
		{
			label: t('videobot:externalLink'),
			value: 'EXTERNAL_LINK',
		},
		{
			label: t('videobot:postMessage'),
			value: 'POST_MESSAGE',
		},
		{
			label: t('videobot:closeVideobot'),
			value: 'CLOSE_VIDEOBOT',
		},
	]

	const handleAddChange = (e, payload) => {
		setAddModal(payload.value)
		selectFieldRef.current.reset()
	}

	const handleAddAction = (data) => {
		let actionPrototype = {
			...data,
		}

		if (data.type === 'REGULAR_FORM') {
			actionPrototype = { ...actionPrototype, fields: [] }
		} else if (data.type === 'EXTERNAL_LINK') {
			actionPrototype = { ...actionPrototype, linkType: 'new_tab' }
		} else if (data.type === 'POST_MESSAGE') {
			actionPrototype = { ...actionPrototype }
		} else if (data.type === 'CLOSE_VIDEOBOT') {
			actionPrototype = { ...actionPrototype }
		}

		const { id } = addAction(actionPrototype)
		setAddModal(null)

		if (actionPrototype.type === 'REGULAR_FORM') navigate(`form/${id}`)
		else if (actionPrototype.type === 'EXTERNAL_LINK') navigate(`link/${id}`)
		else if (actionPrototype.type === 'POST_MESSAGE') navigate(`action/${id}`)
		else if (actionPrototype.type === 'CLOSE_VIDEOBOT') navigate(`close-action/${id}`)

		setTimeout(() => {
			window.dispatchEvent(new Event('scroll'))
		}, 500)
	}

	const handleEditAction = (action) => {
		if (action.type === 'EXTERNAL_LINK') navigate(`link/${action.id}`)
		else if (action.type === 'POST_MESSAGE') navigate(`action/${action.id}`)
		else if (action.type === 'CLOSE_VIDEOBOT') navigate(`close-action/${action.id}`)
		else navigate(`form/${action.id}`)
	}

	const AddField = (
		<FieldSelect
			ref={selectFieldRef}
			options={actionTypes}
			placeholder={t('videobot:createNew')}
			onChange={handleAddChange}
		></FieldSelect>
	)

	return (
		<React.Fragment>
			<AnimatePresence mode="wait">
				{mode === 'GRID' && (
					<EditorActionsGrid
						key="GRID"
						addComponent={AddField}
						onEdit={handleEditAction}
					/>
				)}
				{mode === 'LIST' && (
					<EditorActionsList
						key="LIST"
						addComponent={AddField}
						onEdit={handleEditAction}
					/>
				)}
				{addModal === 'FORM' && (
					<AddFormModal
						key="ADD_FORM"
						onClose={() => setAddModal(null)}
						onSubmit={handleAddAction}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{addModal === 'EXTERNAL_LINK' && (
					<AddExternalLink
						key="ADD_EXTERNAL_LINK"
						onClose={() => setAddModal(null)}
						onSubmit={handleAddAction}
					/>
				)}
				{addModal === 'POST_MESSAGE' && (
					<AddPostMessageModal
						key="ADD_POST_MESSAGE"
						onClose={() => setAddModal(null)}
						onSubmit={handleAddAction}
					/>
				)}
				{addModal === 'CLOSE_VIDEOBOT' && (
					<AddCloseVideobotModal
						key="ADD_CLOSE_VIDEOBOT"
						onClose={() => setAddModal(null)}
						onSubmit={handleAddAction}
					/>
				)}
			</AnimatePresence>
		</React.Fragment>
	)
}

export default ContentEditorActions
