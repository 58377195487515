import styled from 'styled-components'

import { AvatarWrapper } from '@/components/Avatar/Avatar.styles'

import media from '../../../../lib/media'
import { LastMb0, LinkUnderline } from '../../../../lib/mixins'

export const AuthBox = styled.div`
	position: relative;
	width: 42rem;
	max-width: 100%;
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.lg};
	box-shadow: ${(props) => props.theme.shadow.lg};

	${media('>lg')} {
		padding: 4rem ${(props) => props.theme.grid.outerMargin} 3rem;
	}
	${media('<=lg')} {
		padding: 3rem ${(props) => props.theme.grid.outerMargin} 2rem;
	}
`

export const AuthBoxInner = styled.div`
	position: relative;
	width: 28rem;
	max-width: 100%;
	margin: 0 auto;
`

export const AuthHeader = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	${LastMb0()}
	${media('>lg')} {
		margin-bottom: 3rem;
	}
	${media('<=lg')} {
		margin-bottom: 2rem;
	}

	p {
		max-width: 80%;
	}

	h4 {
		margin-bottom: 0.5rem;

		+ p {
			color: ${(props) => props.theme.color.muted};

			strong {
				color: ${(props) => props.theme.color.global};
				font-weight: 600;
			}
		}
	}

	${AvatarWrapper} {
		margin-bottom: 2rem;
	}
`

export const AuthFooter = styled.div`
	text-align: center;
	margin-top: 1.5rem;
	${LastMb0()}

	a {
		${LinkUnderline()}
		color: ${(props) => props.theme.color.primary};
		font-weight: 700;
	}

	p {
		margin-bottom: 0.5rem;
	}

	.text-sm {
		a {
			color: ${(props) => props.theme.color.muted};
		}
	}
`
