import { useMemo } from 'react'
import { Outlet, useOutletContext } from 'react-router'

import { ShareVideobotTabs } from '@/features/Videobot/components/ShareVideobot/ShareVideobotTabs'
import { ViewTransition } from '@/lib/transitions'

import { ShareVideobotContentWrapper, ShareVideobotWrapper } from './ShareVideobot.styles'

const ShareVideobot = () => {
	const { formRef, readonly } = useOutletContext()
	const context = useMemo(() => ({ formRef }), [formRef])

	return (
		<ShareVideobotWrapper {...ViewTransition}>
			<ShareVideobotTabs />
			<ShareVideobotContentWrapper>
				<Outlet context={context} />
				{readonly && <div className="absolute inset-0 z-50 h-full w-full" />}
			</ShareVideobotContentWrapper>
		</ShareVideobotWrapper>
	)
}

export default ShareVideobot
