import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import useKeypress from 'react-use-keypress'

import Button from '../Button'
import Icon from '../Icon'
import {
	PanelBackdrop,
	PanelBody,
	PanelContent,
	PanelFooter,
	PanelHeader,
	PanelHeaderInner,
	PanelWrapper,
} from './Panel.styles'

const Panel = (props) => {
	const { children, footer, onClose, onBack, title, zIndex, centerContent, addBackdrop } = props

	useKeypress('Escape', () => {
		if (onBack) onBack()
		if (!onBack && onClose) onClose()
	})

	return createPortal(
		<PanelWrapper zIndex={zIndex} role="dialog">
			<PanelBackdrop
				as={motion.div}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ default: { ease: [0.22, 1, 0.36, 1] }, duration: 0.2 }}
				addbackdrop={addBackdrop ? 1 : 0}
				onClick={onBack || onClose || false}
			></PanelBackdrop>
			<PanelBody
				as={motion.div}
				initial={{ x: '100%' }}
				animate={{ x: 0 }}
				exit={{ x: '100%' }}
				transition={{ default: { ease: [0.22, 1, 0.36, 1] }, duration: 0.2 }}
			>
				<PanelHeader>
					<PanelHeaderInner>
						{onBack && (
							<Button variant="icon" onClick={onBack}>
								<Icon name="chevron-left" />
							</Button>
						)}
						<h4>{title}</h4>
						{onClose && (
							<Button variant="icon" onClick={onClose}>
								<Icon name="cross" />
							</Button>
						)}
					</PanelHeaderInner>
				</PanelHeader>
				<PanelContent center={centerContent}>{children}</PanelContent>
				{footer && <PanelFooter>{footer}</PanelFooter>}
			</PanelBody>
		</PanelWrapper>,
		document.querySelector('#panels'),
	)
}

export default Panel
