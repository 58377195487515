import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { WidgetDefault } from '@videobotcom/embed'

import { useReadBotWidgetById } from '@/api/videobot'

import '@videobotcom/embed/dist/widget-styles/styles.scss'

export const VideobotWidgetPreview = () => {
	const { id } = useParams()
	const queryEnabled = Boolean(id) && id !== 'current'

	useReadBotWidgetById(id, undefined, {
		query: {
			enabled: queryEnabled,
		},
	})

	const widgetRef = useRef(null)
	const [widgetData, setWidgetData] = useState()
	const chatVideo = useMemo(() => widgetData?.widgetSettings.chatVideo, [widgetData])

	useEffect(() => {
		window.dispatchEvent(new Event('resize'))
	}, [widgetData?.widgetSettings?.align])

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data.action === 'SET_DATA') {
				setWidgetData(event.data.payload)
			}
			if (event.data.action === 'VIDEOBOT_PREVIEW_READY') {
				widgetRef.current.postMessage('SET_DATA', { payload: widgetData })
			}
		}

		window.addEventListener('message', handleMessage)

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [widgetData])

	if (!widgetData) return <div />

	return (
		<div data-videobot-widget>
			<WidgetDefault
				ref={widgetRef}
				iframeUrl={`/preview/current/?embed=true&preview=false`}
				avatarVideo={chatVideo?.url}
				avatarPoster={
					chatVideo
						? `${chatVideo?.thumbnailUrl}?height=150&time=${widgetData.widgetSettings.coverOffset}s`
						: undefined
				}
				color={widgetData.widgetSettings.borderColor}
				autoopen={widgetData.widgetSettings.openAfter}
				height={widgetData.widgetSettings.height}
				width={widgetData.widgetSettings.width}
				loopLimit={1000}
				label={widgetData.widgetSettings.text}
				labelDuration={widgetData.widgetSettings.textDurationMs}
				ctaTextColor={widgetData.widgetSettings.ctaTextColor}
				ctaBackgroundColor={widgetData.widgetSettings.ctaBackgroundColor}
				mobileBreakpoint={768}
				align={widgetData.widgetSettings.align}
				offset={{
					x: widgetData.widgetSettings.horizontalOffset,
					y: widgetData.widgetSettings.verticalOffset,
				}}
				isHorizontal={widgetData.widgetSettings.horizontalMode}
				mobileHorizontal={widgetData.widgetSettings.horizontalModeMobile}
				closeButtonEnabled={widgetData.widgetSettings.closeButtonEnabled}
				features={widgetData.features}
				defaultReady
				widgetUuid="PREVIEW"
				onIframeLoaded={() =>
					widgetRef.current.postMessage('SET_DATA', { payload: widgetData })
				}
			/>
		</div>
	)
}
