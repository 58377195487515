import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useAuth, useCurrentUser } from '@/modules/session/auth.store'

import Avatar from '../../components/Avatar'
import More, { MoreButton } from '../../components/More'
import TeamSelect from '../../features/Account/components/TeamSelect/TeamSelect'
import { TopBarTeam, TopBarUser, TopBarWrapper } from './TopBar.styles'

const TopBar = () => {
	const { t } = useTranslation(['common', 'account'])
	const { logout } = useAuth()
	const user = useCurrentUser()
	const navigate = useNavigate()

	return (
		<TopBarWrapper>
			<TopBarTeam>
				<TeamSelect />
			</TopBarTeam>
			<TopBarUser>
				<More
					placement="top-start"
					customToggler={
						<Avatar src={user.profilePicture?.url} name={user.firstName}></Avatar>
					}
				>
					<React.Fragment>
						<MoreButton onClick={() => navigate(`/dashboard/users/${user.id}`)}>
							{t('account:editProfile')}
						</MoreButton>
						<MoreButton onClick={() => logout()} variant="red">
							{t('account:logout')}
						</MoreButton>
					</React.Fragment>
				</More>
			</TopBarUser>
		</TopBarWrapper>
	)
}

export default TopBar
