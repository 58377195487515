import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import MediaPanel from '@/features/MediaLibrary/components/MediaLibraryPanel'
import PropTypes from '@/lib/propTypes'

import Button from '../Button'
import Icon from '../Icon'
import Loader from '../Loader'
import Play from '../Play'
import { FieldMediaActions, FieldMediaPreview, FieldMediaWrapper } from './FieldMedia.styles'

const FieldMedia = ({ error, defaultValue, _type = 'video', onChange, isMedia = false }) => {
	const { t } = useTranslation(['validation', 'media'])
	const timeoutRef = useRef(null)
	const [media, setMedia] = useState(defaultValue)
	const [status] = useState('DEFAULT')
	const [showMediaPanel, setShowMediaPanel] = useState(false)
	const isImage = (fileName) => {
		if (typeof fileName !== 'string') {
			return false
		}
		const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif']
		return imageExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))
	}

	// const acceptedTypes = type === 'video' ? 'video/mp4' : 'image/png, image/jpeg'

	const handleSelect = (payload) => {
		setMedia(payload)
		onChange(payload)
		setShowMediaPanel(false)
	}

	const handleAddVideoClick = (e) => {
		setShowMediaPanel(true)
		e.preventDefault()
	}

	useEffect(() => {
		return () => {
			clearTimeout(timeoutRef.current)
		}
	}, [])

	return (
		<FieldMediaWrapper error={error}>
			<FieldMediaPreview>
				{!media && <Icon name="no-video" />}
				{media && !media.thumbnailUrl && <Icon name="video" />}
				{media && media.thumbnailUrl && (
					<React.Fragment>
						<img src={media.thumbnailUrl} alt="" />
						{!isImage(media.name) && (
							<Play
								size="sm"
								onClick={() => window.open(media.previewUrl, '_blank')}
							/>
						)}
					</React.Fragment>
				)}
				{status === 'LOADING' && <Loader cover size={32} />}
			</FieldMediaPreview>
			<FieldMediaActions>
				{!media && (
					<Button
						size="small"
						variant="secondary"
						disabled={status === 'LOADING'}
						onClick={handleAddVideoClick}
					>
						{isMedia ? t('media:addMediaCover') : t('media:addVideo')}
					</Button>
				)}
				{media && (
					<React.Fragment>
						<Button size="small" variant="secondary" onClick={handleAddVideoClick}>
							{t('replace')}
						</Button>
						<Button size="small" variant="secondary" onClick={() => handleSelect(null)}>
							{t('remove')}
						</Button>
					</React.Fragment>
				)}
			</FieldMediaActions>
			<AnimatePresence>
				{showMediaPanel && (
					<MediaPanel
						onClose={() => setShowMediaPanel(false)}
						onSelect={handleSelect}
						isMedia={isMedia}
					/>
				)}
			</AnimatePresence>
		</FieldMediaWrapper>
	)
}

FieldMedia.displayName = 'FieldMedia'

FieldMedia.propTypes = {
	error: PropTypes.bool,
	defaultValue: PropTypes.object,
	type: PropTypes.oneOf(['image', 'video']),
	valueName: PropTypes.string,
	setValue: PropTypes.func,
	onChange: PropTypes.func,
	mediaType: PropTypes.oneOf(['LogoImage', 'LogoVideo']),
	accountId: PropTypes.number,
	isMedia: PropTypes.bool,
}

export default FieldMedia
