import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import Loader from '@/components/Loader'
import { VideobotWidget } from '@/features/Videobot/components/ShareVideobot/VideobotWidget'
import Error from '@/layout/Error'
import { useReadBotWidgetById } from '@/api/videobot'

export const VideobotPreview = () => {
	const { id } = useParams()
	const [searchParams] = useSearchParams()
	const queryEnabled = Boolean(id) && id !== 'current'
	const { data, isLoading, isError, isSuccess } = useReadBotWidgetById(id, undefined, {
		query: {
			enabled: queryEnabled,
		},
	})
	const preview = searchParams.get('preview') === 'true'
	const slideId = searchParams.get('slide_id')

	const [widgetData, setWidgetData] = useState()
	const [externalLink, setExternalLink] = useState('')

	const handleMessage = (event) => {
		if (event.data.action === 'SET_DATA') {
			setWidgetData(event.data.payload)
		}
	}

	const handleMessageTop = (event) => {
		if (event.data.action === 'EMBED_EXTERNAL_LINK') {
			setExternalLink(event.data.url)
		}
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage)
		if (window !== window.parent && window.parent === window.top) {
			window.parent.addEventListener('message', handleMessageTop)
		}
		document.querySelector('html').style.fontSize = '17px'
		window.parent.postMessage({ action: 'VIDEOBOT_PREVIEW_READY' }, '*')

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [])

	if (isSuccess || !queryEnabled) {
		return (
			<React.Fragment>
				{(data || widgetData) && !externalLink && (
					<VideobotWidget
						preview={preview}
						config={data || widgetData}
						slideId={slideId}
					/>
				)}
				{externalLink && (
					// eslint-disable-next-line react-dom/no-missing-iframe-sandbox
					<iframe
						src={externalLink}
						width={(data || widgetData)?.iframeSettings?.width}
						height={(data || widgetData)?.iframeSettings?.height}
					/>
				)}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{queryEnabled && isLoading && <Loader cover isLoading />}
			{queryEnabled && isError && <Error background="body" />}
		</React.Fragment>
	)
}
