import styled, { css } from 'styled-components'

import { LinkUnderline } from '../../lib/mixins'

export const FieldCheckboxInput = styled.input`
	display: none;
	position: absolute;
	visibility: hidden;
	height: 0;
	width: 0;
`

export const FieldCheckboxIndicator = styled.span`
	width: 1rem;
	height: 1rem;
	display: inline-block;
	border: 2px solid ${(props) => props.theme.color.line};
	border-radius: ${(props) => props.theme.radius.xs};
	flex-shrink: 0;
	margin-top: 0.15rem;
	margin-right: 0.5rem;
	border-radius: 0.2rem;
	cursor: pointer;
	transition: border 0.3s ease-out;

	svg {
		display: block;
		width: 100%;
		height: 100%;

		path {
			stroke-dasharray: 36;
			stroke-dashoffset: 36;
			stroke-width: 4;
			transition: stroke-dashoffset 0.2s ease-out;
			stroke: ${(props) => props.theme.color.primary};
		}
	}
`

export const FieldCheckboxLabel = styled.label`
	flex: 1 1 auto;
	cursor: pointer;
	z-index: 2;
	font-size: 0.95rem;
	display: flex;

	${(props) =>
		props.disabled &&
		css`
			cursor: not-allowed;
		`}

	a {
		${LinkUnderline()}
	}
`

export const FieldCheckboxWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
	margin-top: 0.75rem;
	cursor: pointer;
	${(props) =>
		props.error &&
		css`
			${FieldCheckboxIndicator} {
				border-color: ${(props) => props.theme.color.red};
			}
		`};

	${(props) =>
		props.selected &&
		css`
			${FieldCheckboxIndicator} {
				border-color: ${(props) => props.theme.color.primary};
				background-color: ${(props) => props.theme.color.primary};

				svg path {
					stroke-dashoffset: 0;
					stroke: ${(props) => props.theme.color.inverted};
				}
			}
		`};
`
