import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDroppable } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import {
	ContentEditorCategoryGrid,
	ContentEditorCategoryItemWrapper,
	ContentEditorCategoryList,
	ContentEditorCategoryTitle,
	ContentEditorCategoryWrapper,
} from './ContentEditor.styles'

export const ContentEditorCategoryItem = (props) => {
	const { children, id, dragEnabled } = props
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id,
	})
	const style = {
		transform: CSS.Translate.toString(transform),
		transition: transition || undefined,
	}

	const dragProps = dragEnabled
		? {
				ref: setNodeRef,
				style,
				...props,
				...attributes,
				...listeners,
			}
		: {}

	return (
		<AnimatePresence mode="wait">
			<ContentEditorCategoryItemWrapper
				// as={motion.div}
				// initial={{ opacity: 0 }}
				// animate={{ opacity: 1 }}
				// exit={{ opacity: 0 }}
				// transition={{ duration: 0.15 }}
				{...dragProps}
			>
				{children}
			</ContentEditorCategoryItemWrapper>
		</AnimatePresence>
	)
}

const ContentEditorCategory = ({ title, children, dragEnabled, id, items, mode = 'GRID' }) => {
	const { setNodeRef } = useDroppable({
		id,
	})

	return (
		<ContentEditorCategoryWrapper
			as={motion.div}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.15 }}
		>
			<ContentEditorCategoryTitle>
				<span>{title}</span>
			</ContentEditorCategoryTitle>
			{mode === 'GRID' && (
				<React.Fragment>
					{dragEnabled ? (
						<SortableContext id={id} items={items} strategy={rectSortingStrategy}>
							<ContentEditorCategoryGrid ref={setNodeRef}>
								{children}
							</ContentEditorCategoryGrid>
						</SortableContext>
					) : (
						<ContentEditorCategoryGrid>{children}</ContentEditorCategoryGrid>
					)}
				</React.Fragment>
			)}
			{mode === 'LIST' && <ContentEditorCategoryList>{children}</ContentEditorCategoryList>}
		</ContentEditorCategoryWrapper>
	)
}

export default ContentEditorCategory
