import { EventChannelType } from '@/api/videobot.schemas'
import { AnalyticsList } from '@/features/analytics/components/AnalyticsList'

export const AnalyticsSliderDetails = ({
	sliderData,
	params,
	analyticsDisplayFields,
	onBotEdit,
	onBotPreview,
}) => {
	const data = sliderData.botAnalytics

	return (
		<div className="bg-light p-2">
			<AnalyticsList
				data={data}
				isPaginated={false}
				onBotEdit={onBotEdit}
				onBotPreview={onBotPreview}
				params={params}
				channel={EventChannelType.slider}
				hasSessions
				analyticsDisplayFields={analyticsDisplayFields}
				sliderAnalytics={sliderData}
			/>
		</div>
	)
}
