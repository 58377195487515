import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const currentPopupAtom = atom('')

const NAVIGATION_COLLAPSED_STORAGE_KEY = 'navigation-collapsed'
export const isNavigationCollapsedAtom = atomWithStorage(NAVIGATION_COLLAPSED_STORAGE_KEY, false)
export const toggleNavigationCollapsedAtom = atom(null, (get, set) => {
	set(isNavigationCollapsedAtom, !get(isNavigationCollapsedAtom))
})
