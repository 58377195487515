import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'

import PropTypes from '@/lib/propTypes'

import { ErrorContent, ErrorIcon, ErrorWrapper } from './Error.styles'

export const Error = ({ title, img, actions }) => {
	const { t } = useTranslation(['errors'])
	return (
		<AnimatePresence mode="wait">
			<ErrorWrapper
				as={motion.div}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.25 }}
			>
				<ErrorContent
					as={motion.div}
					initial={{ opacity: 0, y: -25 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.95, ease: [0.16, 1, 0.3, 1], delay: 0.3 }}
				>
					<ErrorIcon>
						<SVG src={img || '/assets/img/error-500.svg'} />
					</ErrorIcon>
					<h2>{title || t('errors:errorOccured')}</h2>
					{actions}
				</ErrorContent>
			</ErrorWrapper>
		</AnimatePresence>
	)
}

Error.propTypes = {
	title: PropTypes.string,
	img: PropTypes.string,
	actions: PropTypes.node,
}

export default Error
