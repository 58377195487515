import { range } from 'lodash-es'
import { useEffect } from 'react'
import DataTableComponent from 'react-data-table-component'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@/hooks/useMediaQuery'
import PropTypes from '@/lib/propTypes'
import Loader from '@/components/Loader'

import FieldCheckboxSimple from '../FieldCheckbox/FieldCheckboxSimple'
import Icon from '../Icon'
import {
	DataTableBody,
	DataTableEmptyWrapper,
	DataTablePaginationArrow,
	DataTablePaginationButton,
	DataTablePaginationControls,
	DataTablePaginationCounter,
	DataTablePaginationWrapper,
	DataTableWrapper,
} from './DataTable.styles'

const DataTablePagination = ({ rowsPerPage, rowCount, currentPage, onChangePage }) => {
	const rangeOffset = useMediaQuery('<=sm') ? 1 : 3
	const numPages = Math.ceil(rowCount / rowsPerPage)
	const lastIndex = currentPage * rowsPerPage
	const firstIndex = lastIndex - rowsPerPage + 1
	const startPage =
		currentPage <= rangeOffset
			? 1
			: currentPage <= numPages - rangeOffset
				? currentPage - rangeOffset
				: currentPage - rangeOffset - (rangeOffset - (numPages - currentPage))
	const endPage =
		currentPage <= rangeOffset
			? currentPage + rangeOffset + (rangeOffset - currentPage + 2)
			: currentPage <= numPages - rangeOffset
				? currentPage + rangeOffset + 1
				: numPages + 1
	const pageRange = range(
		startPage < 1 ? 1 : startPage,
		endPage > numPages ? numPages + 1 : endPage,
	)

	const { t } = useTranslation()
	return (
		<DataTablePaginationWrapper>
			<DataTablePaginationCounter>
				{t('showingNumberOfItems', {
					count:
						currentPage === numPages
							? `${firstIndex}-${rowCount}`
							: `${firstIndex}-${lastIndex}`,
					all: rowCount,
				})}
			</DataTablePaginationCounter>
			{numPages > 1 && (
				<DataTablePaginationControls>
					<DataTablePaginationArrow
						onClick={() => onChangePage(currentPage - 1)}
						disabled={currentPage === 1}
						aria-label="Previous Page"
					>
						<Icon name="chevron-left" />
					</DataTablePaginationArrow>
					{pageRange.map((num) => (
						<DataTablePaginationButton
							key={num}
							active={num === currentPage}
							onClick={() => onChangePage(num)}
						>
							<span>{num}</span>
						</DataTablePaginationButton>
					))}
					<DataTablePaginationArrow
						onClick={() => onChangePage(currentPage + 1)}
						disabled={currentPage === numPages}
						aria-label="Next Page"
					>
						<Icon name="chevron-right" />
					</DataTablePaginationArrow>
				</DataTablePaginationControls>
			)}
		</DataTablePaginationWrapper>
	)
}

const DataTableNoEmpty = () => {
	const { t } = useTranslation()
	return (
		<DataTableEmptyWrapper>
			<Icon name="search" />
			{t('noResults')}
		</DataTableEmptyWrapper>
	)
}

DataTablePagination.propTypes = {
	rowsPerPage: PropTypes.number,
	rowCount: PropTypes.number,
	currentPage: PropTypes.number,
	onChangePage: PropTypes.func,
}

const DataTable = (props) => {
	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('scroll'))
		}, 500)
	}, [])

	const {
		pagination,
		selectableRows,
		isLoading,
		pageSize,
		totalCount,
		page,
		onPageChange,
		onRowClicked,
	} = props

	const isPaginated = pagination ?? true

	return (
		<DataTableWrapper pagination={isPaginated}>
			<DataTableBody selectable={selectableRows}>
				{isLoading && (
					<div aria-label="Loading">
						<div className="absolute left-0 top-0 z-10 h-full w-full bg-muted-inverted" />
						<div className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
							<Loader />
						</div>
					</div>
				)}
				<DataTableComponent
					pagination={isPaginated}
					paginationServer={Boolean(onPageChange)}
					paginationPerPage={pageSize}
					paginationTotalRows={totalCount}
					paginationDefaultPage={page}
					onChangePage={onPageChange}
					pointerOnHover={onRowClicked}
					sortIcon={<Icon name="chevron-down" />}
					selectableRowsComponent={FieldCheckboxSimple}
					paginationComponent={DataTablePagination}
					noDataComponent={<DataTableNoEmpty />}
					{...props}
				/>
			</DataTableBody>
		</DataTableWrapper>
	)
}

DataTable.propTypes = {
	selectableRows: PropTypes.bool,
	onRowClicked: PropTypes.func,
}

export default DataTable
