import { useMemo, useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import * as RadioGroup from '@radix-ui/react-radio-group'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { useQueryClient } from '@tanstack/react-query'
import { ListBox, useDragAndDrop, ListBoxItem as Item } from 'react-aria-components'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { difference, keyBy, capitalize } from 'lodash-es'
import { toast } from 'react-toastify'

import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import FieldCheckbox from '@/components/FieldCheckbox'
import FieldText from '@/components/FieldText'
import { TextArea } from '@/components/TextArea/text_area'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import {
	useReadAllBots,
	useReadAllTags,
	useCreateTag,
	getReadAllTagsQueryKey,
} from '@/api/videobot'
import { useCurrentAccount, usePowerUserMode } from '@/modules/session/auth.store'
import {
	BotStatusType,
	GAConsentMode,
	WidgetAlignment,
	WidgetContentSortBy,
	WidgetContentType,
	WidgetDescriptionPosition,
	WidgetType,
} from '@/api/videobot.schemas'
import Loader from '@/components/Loader'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'
import FieldColor from '@/components/FieldColor'
import { DEFAULT_PLAYER_BACKGROUND_COLOR } from '@/features/widget/constants'

const makeVisibleSelects = (maxVisible) =>
	Array(maxVisible)
		.fill(0)
		.map((_, i) => ({ label: `${i + 1}`, value: i + 1 }))

const WIDGET_TYPES = Object.values(WidgetType)
const VisibleSelections = {
	[WidgetType.Card]: {
		mobile: makeVisibleSelects(4),
		tablet: makeVisibleSelects(7),
		desktop: makeVisibleSelects(10),
	},
	[WidgetType.Slider]: {
		mobile: makeVisibleSelects(2),
		tablet: makeVisibleSelects(4),
		desktop: makeVisibleSelects(6),
	},
	[WidgetType.Thumbnail]: {
		mobile: makeVisibleSelects(4),
		tablet: makeVisibleSelects(7),
		desktop: makeVisibleSelects(10),
	},
}

const ALIGNMENT = Object.entries(WidgetAlignment).map(([key, value]) => ({
	label: key,
	value,
}))

const DESCRIPTION_POSITIONS = Object.entries(WidgetDescriptionPosition).map(([key, value]) => ({
	label: key,
	value,
}))

const GAConsentGrantedMode = {
	on: GAConsentMode.granted,
	off: GAConsentMode.denied,
}

const SettingsSection = ({ children, title }) => {
	const [isOpen, setIsOpen] = useState(true)

	return (
		<Collapsible.Root
			className="border-b border-line px-8 py-6"
			open={isOpen}
			onOpenChange={setIsOpen}
		>
			<Collapsible.Trigger asChild>
				<div className="flex cursor-pointer select-none items-center">
					<h3 className="m-0 text-base font-semibold">{title}</h3>
					<span className="ml-auto">
						<img
							src={
								isOpen
									? '/assets/icons/chevron-up.svg'
									: '/assets/icons/chevron-down.svg'
							}
							alt="Collapse"
							className="h-3 w-3"
						/>
					</span>
				</div>
			</Collapsible.Trigger>

			<Collapsible.Content className="mt-4 data-[state=closed]:hidden" forceMount>
				{children}
			</Collapsible.Content>
		</Collapsible.Root>
	)
}

const EditWidgetType = () => {
	const [t] = useTranslation(['widget'])
	const { setValue, getValues } = useFormContext()

	return (
		<SettingsSection title={t('widget:editor:choose_type')}>
			<RadioGroup.Root
				className="grid select-none grid-cols-3 gap-4"
				type="single"
				aria-label={t('widget:editor:widget_types')}
				defaultValue={getValues('type')}
				onValueChange={(value) => setValue('type', value)}
			>
				{WIDGET_TYPES.map((type) => (
					<RadioGroup.Item
						value={type}
						aria-label={t(`widget:types:${type}`)}
						className="group"
						key={type}
					>
						<div className="flex h-full items-center rounded-lg border-2 border-line px-4 py-5 group-data-[state=checked]:border-primary">
							<img
								src={`/assets/icons/widget_${type.toLowerCase()}.svg`}
								alt={t(`widget:types:${type}`)}
							/>
						</div>
					</RadioGroup.Item>
				))}
			</RadioGroup.Root>
		</SettingsSection>
	)
}

const VideobotCard = ({ bot, selectable }) => {
	return (
		<div className="flex items-center gap-2 rounded border border-line bg-body p-4 group-data-[state=on]:outline group-data-[state=on]:outline-primary">
			<div className="flex aspect-square w-14">
				{(bot.posterUrl || bot.widgetCover) && (
					<img
						src={bot.widgetCover ? bot.widgetCover.url : bot.posterUrl}
						alt={bot.name}
						className="flex-1 bg-body object-cover"
					/>
				)}
			</div>
			<div className="flex flex-col items-start leading-relaxed">
				<div>{bot.name}</div>
				<div className="text-sm">by {bot.author.email}</div>
			</div>
			{selectable && (
				<div className="ml-auto">
					<img
						src="/assets/icons/uncheck_circle.svg"
						alt=""
						className="group-data-[state=off]:block group-data-[state=on]:hidden"
					/>
					<img
						src="/assets/icons/check_circle.svg"
						alt=""
						className="group-data-[state=on]:block group-data-[state=off]:hidden"
					/>
				</div>
			)}
		</div>
	)
}

const SelectVideobotModal = ({ onClose, show }) => {
	const { setValue, getValues } = useFormContext()
	const [botIds, setBotIds] = useState(getValues('selectedBotIds'))
	const { id } = useCurrentAccount()
	const [t] = useTranslation()
	const { data } = useReadAllBots(
		{ account_id: id, size: 100 },
		{
			query: {
				select: (data) =>
					data.records.filter((bot) => bot.status === BotStatusType.Published),
			},
		},
	)

	const saveHandler = () => {
		onClose()
		setValue('selectedBotIds', botIds)
	}

	return (
		<Modal
			header={<h3>{t('widget:editor:select_videobots')}</h3>}
			show={show}
			onClose={onClose}
			variant="wide"
		>
			{!data && <div>Loading...</div>}
			{data && (
				<div className="flex flex-col gap-4">
					<ToggleGroup.Root
						className="flex h-96 flex-col gap-4 overflow-y-auto p-2"
						type="multiple"
						aria-label="Select Videobots"
						onValueChange={(value) => setBotIds(value)}
						defaultValue={getValues('selectedBotIds')}
					>
						{data.map((bot) => (
							<ToggleGroup.Item key={bot.id} className="group" value={bot.id}>
								<VideobotCard bot={bot} selectable />
							</ToggleGroup.Item>
						))}
					</ToggleGroup.Root>

					<div className="flex p-2">
						<Button wide block onClick={saveHandler}>
							Save
						</Button>
					</div>
				</div>
			)}
		</Modal>
	)
}

const EditWidgetContent = () => {
	const { id } = useCurrentAccount()
	const [t] = useTranslation(['widget'])
	const { register, setValue, watch, getValues } = useFormContext()
	const [isSelectModalShow, setIsSelectModalShow] = useState(false)
	const contentType = watch('contentType')
	const contentSortBy = watch('contentSortBy')
	const botIds = watch('selectedBotIds', [])
	const showTagOptions = contentType === WidgetContentType.ByTags
	const [isAddingTag, setIsAddingTag] = useState(false)
	const newTagName = watch('newTagName')
	const { mutate: createTag } = useCreateTag()
	const queryClient = useQueryClient()

	const { data: tags } = useReadAllTags({ account_id: id, size: 100 })

	const { data: allBots } = useReadAllBots(
		{ account_id: id, size: 100 },
		{
			query: {
				select: (data) =>
					data.records.filter((bot) => bot.status === BotStatusType.Published),
			},
		},
	)
	const selectChosenBots = (data) => {
		const publishedBots = data

		if (contentType === WidgetContentType.All) {
			return publishedBots
		}

		if (contentType === WidgetContentType.Selected) {
			return publishedBots.filter((bot) => (botIds ?? []).includes(bot.id))
		}

		if (contentType === WidgetContentType.ByTags) {
			const selectedTagIds = (tags?.records ?? [])
				.filter((tag) => watch(`tag_${tag.id}`))
				.map((tag) => tag.id)

			return publishedBots.filter((bot) =>
				bot.tagsList.some((botTag) => selectedTagIds.includes(botTag.id)),
			)
		}

		return []
	}
	const chosenBots = selectChosenBots(allBots ?? [])
	const botByIds = useMemo(() => keyBy(allBots, 'id'), [allBots])

	const VideobotsContentType = [
		{
			label: t('widget:contents.All', 'All Videobots'),
			value: WidgetContentType.All,
		},
		{
			label: t('widget:contents.Selected', 'Selected Videobots'),
			value: WidgetContentType.Selected,
		},
		{
			label: t('widget:contents.ByTags', 'By Tags'),
			value: WidgetContentType.ByTags,
		},
	]

	const ContentSortBy = [
		{
			label: t('widget:contents_sort.Latest', 'Latest first'),
			value: WidgetContentSortBy.Latest,
		},
		{
			label: t('widget:contents_sort.Random', 'Random order'),
			value: WidgetContentSortBy.Random,
		},
		contentType === WidgetContentType.Selected && {
			label: t('widget:contents_sort.Custom', 'Custom order'),
			value: WidgetContentSortBy.Custom,
		},
	].filter(Boolean)

	const { dragAndDropHooks } = useDragAndDrop({
		getItems: (keys) => {
			return [...keys].map((key) => ({ id: key }))
		},
		onReorder(e) {
			const movingKeysList = Array.from(e.keys)
			const sortingList = difference(botIds, movingKeysList)
			const sortingPosition = sortingList.indexOf(e.target.key)

			if (movingKeysList.includes(e.target.key)) {
				return
			}

			if (e.target.dropPosition === 'before') {
				setValue('selectedBotIds', [
					...sortingList.slice(0, sortingPosition),
					...movingKeysList,
					...sortingList.slice(sortingPosition),
				])
			} else if (e.target.dropPosition === 'after') {
				setValue('selectedBotIds', [
					...sortingList.slice(0, sortingPosition + 1),
					...movingKeysList,
					...sortingList.slice(sortingPosition + 1),
				])
			}
		},
	})

	const onContentTypeChange = (_e, payload) => {
		const contentType = payload.value
		setValue('contentType', contentType)

		// TODO: If selected videobots, save previous selected videobots
		// setValue('selectedBotIds', [])

		if (contentType === WidgetContentType.All) {
			// setValue(
			// 	'selectedBotIds',
			// 	allBots.map((bot) => bot.id),
			// )

			if (contentSortBy === WidgetContentSortBy.Custom) {
				setValue('contentSortBy', WidgetContentSortBy.Latest)
			}
		}
	}

	const addNewTag = () => {
		if (newTagName && newTagName.trim() !== '') {
			createTag(
				{ data: { name: newTagName, account_id: id } },
				{
					onSuccess: () => {
						setValue('newTagName', '')
						queryClient.invalidateQueries(getReadAllTagsQueryKey({ account_id: id }))
						toast.success('New tag created')
					},
					onError: (error) => {
						toast.error('Creating tag failed', error)
					},
				},
			)
		}
	}

	const onSortByChange = (_e, payload) => {
		const sortBy = payload.value
		setValue('contentSortBy', sortBy)

		setValue(
			'selectedBotIds',
			chosenBots.map((bot) => bot.id),
		)
	}

	const isSortable = contentSortBy === WidgetContentSortBy.Custom
	const selectedBots = botIds.map((id) => botByIds[id]).filter(Boolean)

	return (
		<SettingsSection title="Content">
			{isSelectModalShow && (
				<SelectVideobotModal
					show={isSelectModalShow}
					onClose={() => setIsSelectModalShow(false)}
				/>
			)}

			<FormField label={t('widget:fields.videobots_content.label')}>
				<FieldSelect
					placeholder={t('widget:fields.videobots_content.placeholder')}
					type="text"
					required
					options={VideobotsContentType}
					name="contentType"
					register={register}
					onChange={onContentTypeChange}
					defaultValue={getValues('contentType')}
				/>
			</FormField>

			<FormField label={t('widget:fields.sort_by.label')}>
				<FieldSelect
					placeholder={t('widget:fields.sort_by.placeholder')}
					type="text"
					required
					options={ContentSortBy}
					name="contentSortBy"
					register={register}
					onChange={onSortByChange}
					disabled={!contentType}
					defaultValue={getValues('contentSortBy')}
				/>
			</FormField>

			{showTagOptions && (
				<div>
					<div>
						<FormField label={t('widget:fields.chooseTag.label')}>
							<div>
								{tags?.records.map((tag) => (
									<FieldCheckbox
										key={tag.id}
										name={`tag_${tag.id}`}
										register={register}
										setValue={setValue}
										defaultChecked={getValues(`tag_${tag.id}`)}
									>
										{tag.name}
									</FieldCheckbox>
								))}
							</div>
						</FormField>
					</div>
					<div>
						<p
							className="mb-2 mt-1 cursor-pointer text-primary"
							onClick={() => setIsAddingTag(!isAddingTag)} // Toggle the boolean value
						>
							Add New Tag
						</p>

						{isAddingTag && (
							<div className="grid grid-cols-[80%_20%] gap-1">
								<FieldText
									name="newTagName"
									register={register}
									setValue={setValue}
									placeholder={t('widget:fields.newTagName.label')}
									type="text"
									required
								/>
								<Button size="default" variant="secondary" onClick={addNewTag}>
									Add
								</Button>
							</div>
						)}
					</div>
				</div>
			)}

			{!allBots && <Loader />}

			{contentType === WidgetContentType.Selected && allBots && (
				<div className="flex flex-col gap-2">
					{isSortable && (
						<ListBox
							aria-label="Selected Videobots"
							className="flex flex-col gap-2"
							items={selectedBots}
							selectionMode="none"
							dragAndDropHooks={dragAndDropHooks}
						>
							{(bot) => (
								<Item
									id={bot.id}
									aria-label={bot.name}
									className="data-[allows-dragging]:cursor-pointer data-[dragging]:opacity-10"
								>
									<VideobotCard bot={bot} />
								</Item>
							)}
						</ListBox>
					)}
					{!isSortable && (
						<div className="flex flex-col gap-2">
							{selectedBots.map((bot) => (
								<VideobotCard bot={bot} key={bot.id} />
							))}
						</div>
					)}
					<Button variant="secondary" block onClick={() => setIsSelectModalShow(true)}>
						{t('widget:editor:select_videobots')}
					</Button>
				</div>
			)}
		</SettingsSection>
	)
}

const EditWidgetGeneralSettings = () => {
	const [t] = useTranslation(['widget'])
	const { register, control, setValue, watch, getValues, trigger } = useFormContext()
	const widgetType = watch('type')
	const visibleMap = {
		[WidgetType.Card]: 'cardsVisible',
		[WidgetType.Slider]: 'slidesVisible',
		[WidgetType.Thumbnail]: 'thumbnailsVisible',
	}
	const visible = visibleMap[widgetType]
	const playerBackgroundColor =
		getValues('appearanceSettings.playerBackgroundColor') || DEFAULT_PLAYER_BACKGROUND_COLOR

	const { powerUserMode } = usePowerUserMode()

	return (
		<SettingsSection title="Widget settings">
			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.slides_visible.label')}
				</h3>

				<div className="grid grid-cols-3 gap-8">
					<FormField label={t('widget:fields.mobile.label')}>
						<FieldSelect
							placeholder={1}
							type="text"
							required
							options={VisibleSelections[widgetType].mobile}
							name={`${visible}.mobile`}
							register={register}
							setValue={setValue}
							defaultValue={getValues(`${visible}.mobile`)}
						/>
					</FormField>

					<FormField label={t('widget:fields.tablet.label')}>
						<FieldSelect
							placeholder={4}
							type="text"
							required
							options={VisibleSelections[widgetType].tablet}
							name={`${visible}.tablet`}
							register={register}
							setValue={setValue}
							defaultValue={getValues(`${visible}.tablet`)}
						/>
					</FormField>

					<FormField label={t('widget:fields.desktop.label')}>
						<FieldSelect
							placeholder={6}
							type="text"
							required
							options={VisibleSelections[widgetType].desktop}
							name={`${visible}.desktop`}
							register={register}
							setValue={setValue}
							defaultValue={getValues(`${visible}.desktop`)}
						/>
					</FormField>
				</div>

				{widgetType === WidgetType.Slider && (
					<div>
						<FormField label={t('widget:fields.navigation.label')}>
							<FieldCheckbox
								name="appearanceSettings.arrows"
								register={register}
								setValue={setValue}
								defaultChecked={getValues('appearanceSettings.arrows')}
							>
								{t('widget:fields.arrow_navigation.label')}
							</FieldCheckbox>
							<FieldCheckbox
								name="appearanceSettings.bullets"
								register={register}
								setValue={setValue}
								defaultChecked={getValues('appearanceSettings.bullets')}
							>
								{t('widget:fields.bullet_navigation.label')}
							</FieldCheckbox>
						</FormField>
					</div>
				)}

				<div>
					<FormField label={t('widget:fields.infinite_carousel.label')}>
						<ControlledFieldToggle
							control={control}
							name="appearanceSettings.infinite"
						/>
					</FormField>

					<FormField label={t('widget:fields.auto_preview.label')}>
						<ControlledFieldToggle
							control={control}
							name="appearanceSettings.autoPreview"
						/>
					</FormField>

					{powerUserMode && (
						<FormField label={t('widget:fields.horizontal_mode.label')}>
							<ControlledFieldToggle
								control={control}
								name="appearanceSettings.horizontalMode"
							/>
						</FormField>
					)}

					{widgetType !== WidgetType.Slider && (
						<FormField label={t('widget:fields.align.label')}>
							<FieldSelect
								placeholder={6}
								type="text"
								required
								options={ALIGNMENT}
								name={`appearanceSettings.align`}
								register={register}
								setValue={setValue}
								defaultValue={getValues(`appearanceSettings.align`)}
							/>
						</FormField>
					)}
				</div>

				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.slider_description.label')}
				</h3>

				<div>
					<FormField label={t('widget:fields.description_enabled.label')}>
						<ControlledFieldToggle
							control={control}
							name="appearanceSettings.descriptionEnabled"
						/>
					</FormField>

					<FormField label={t('widget:fields.description_position.label')}>
						<FieldSelect
							type="text"
							required
							options={DESCRIPTION_POSITIONS}
							name="appearanceSettings.descriptionPosition"
							register={register}
							setValue={setValue}
							defaultValue={getValues('appearanceSettings.descriptionPosition')}
						/>
					</FormField>
				</div>

				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.player_appearance.label')}
				</h3>

				<div>
					<FormField label={t('widget:fields.player_background_color.label')}>
						<FieldColor
							register={register}
							trigger={trigger}
							setValue={setValue}
							placeholder={t('account:fields.textColor.placeholder')}
							defaultValue={playerBackgroundColor}
							name="appearanceSettings.playerBackgroundColor"
							required
							withAlpha
						/>
					</FormField>
				</div>
			</div>
		</SettingsSection>
	)
}

const EditWidgetAdvancedSettings = () => {
	const [t] = useTranslation(['widget'])
	const { register, setValue, watch, control } = useFormContext()
	const widgetType = watch('type')
	const widgetGoogleTrackingEnabled = watch('googleTrackingSettings.enabled')

	return (
		<SettingsSection title="Advanced">
			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.breakpoints.label')}
				</h3>

				<div className="flex flex-col gap-2">
					<div className="text-sm text-muted">
						{t('widget:fields.breakpoints.tooltip')}
					</div>
					<div className="grid grid-cols-2 gap-8">
						<FormField label={t('widget:fields.tablet.breakpoint')}>
							<FieldText
								name="breakpoints.tablet"
								register={register}
								setValue={setValue}
								type="text"
								placeholder={`992`}
								required
							/>
						</FormField>

						<FormField label={t('widget:fields.desktop.breakpoint')}>
							<FieldText
								name="breakpoints.desktop"
								register={register}
								setValue={setValue}
								type="text"
								placeholder={`1200`}
								required
							/>
						</FormField>
					</div>
				</div>
			</div>

			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.border_radius.label')}
				</h3>

				<div>
					<FormField label={t('widget:fields.radius.label')}>
						<FieldText
							name="appearanceSettings.radius"
							register={register}
							setValue={setValue}
							type="text"
							placeholder={`0`}
							required
						/>
					</FormField>
				</div>
			</div>

			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.analytics.title')}
				</h3>

				<div className="flex flex-col gap-1">
					<ControlledFieldToggle control={control} name="isAnalyticsEnabled" />
					<p className="mb-2 text-sm text-muted">
						{t('widget:fields.analytics.enabled')}
					</p>
				</div>
			</div>

			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.gaAnalytics')}
				</h3>

				<div className="mb-2 flex flex-col gap-3">
					<ControlledFieldToggle
						control={control}
						name="googleTrackingSettings.enabled"
					/>
				</div>

				{widgetGoogleTrackingEnabled && (
					<div>
						<div className="flex flex-col gap-1">
							<ControlledFieldToggle
								control={control}
								name="googleTrackingSettings.overrideBotSettings"
							>
								Overrides Videobot settings
							</ControlledFieldToggle>

							<p className="mb-2 text-sm text-muted">
								Videobots in this Slider will use Slider Google Tracking settings
								instead.
							</p>
						</div>

						<div className="flex flex-col gap-1">
							<ControlledFieldToggle
								control={control}
								name="googleTrackingSettings.sendIframeMessage"
							>
								{t('widget:fields.analyticsIframeMessage')}
							</ControlledFieldToggle>

							<p className="mb-2 text-sm text-muted">
								{t('widget:fields.analyticsIframeMessageDescription')}
							</p>
						</div>

						<div>
							<FormField label={t('widget:fields.ga4Id.label')}>
								<FieldText
									register={register}
									setValue={setValue}
									placeholder={t('widget:fields.ga4Id.placeholder')}
									name="googleTrackingSettings.ga4Id"
									type="text"
								/>
							</FormField>
						</div>

						<div>
							<FormField label={t('widget:fields.gtmId.label')}>
								<FieldText
									register={register}
									setValue={setValue}
									placeholder={t('widget:fields.gtmId.placeholder')}
									name="googleTrackingSettings.gtmId"
									type="text"
								/>
							</FormField>
						</div>

						<div className="grid grid-cols-2">
							<FormField label={t('widget:fields.gaAdStorage.label')}>
								<div>
									<ControlledFieldToggle
										control={control}
										name="googleTrackingSettings.adStorage"
										values={GAConsentGrantedMode}
									>
										{({ isSelected }) =>
											capitalize(
												isSelected
													? GAConsentMode.granted
													: GAConsentMode.denied,
											)
										}
									</ControlledFieldToggle>
								</div>
							</FormField>

							<FormField label={t('widget:fields.gaAnalyticsStorage.label')}>
								<div>
									<ControlledFieldToggle
										control={control}
										name="googleTrackingSettings.analyticsStorage"
										values={GAConsentGrantedMode}
									>
										{({ isSelected }) =>
											capitalize(
												isSelected
													? GAConsentMode.granted
													: GAConsentMode.denied,
											)
										}
									</ControlledFieldToggle>
								</div>
							</FormField>

							<FormField label={t('widget:fields.gaFunctionalityStorage.label')}>
								<div>
									<ControlledFieldToggle
										control={control}
										name="googleTrackingSettings.functionalityStorage"
										values={GAConsentGrantedMode}
									>
										{({ isSelected }) =>
											capitalize(
												isSelected
													? GAConsentMode.granted
													: GAConsentMode.denied,
											)
										}
									</ControlledFieldToggle>
								</div>
							</FormField>

							<FormField label={t('widget:fields.gaPersonalizationStorage.label')}>
								<div>
									<ControlledFieldToggle
										control={control}
										name="googleTrackingSettings.personalizationStorage"
										values={GAConsentGrantedMode}
									>
										{({ isSelected }) =>
											capitalize(
												isSelected
													? GAConsentMode.granted
													: GAConsentMode.denied,
											)
										}
									</ControlledFieldToggle>
								</div>
							</FormField>

							<FormField label={t('widget:fields.gaSecurityStorage.label')}>
								<div>
									<ControlledFieldToggle
										control={control}
										name="googleTrackingSettings.securityStorage"
										values={GAConsentGrantedMode}
									>
										{({ isSelected }) =>
											capitalize(
												isSelected
													? GAConsentMode.granted
													: GAConsentMode.denied,
											)
										}
									</ControlledFieldToggle>
								</div>
							</FormField>
						</div>
					</div>
				)}
			</div>

			{widgetType === WidgetType.Thumbnail && (
				<div className="flex flex-col gap-3">
					<h3 className="mb-0 mt-2 text-base font-semibold">
						{t('widget:fields.story_settings.label')}
					</h3>

					<div>
						<FormField label={t('widget:fields.story_size.label')}>
							<FieldText
								name="appearanceSettings.thumbnailSize"
								register={register}
								setValue={setValue}
								type="text"
								placeholder={`80`}
							/>
						</FormField>
					</div>
				</div>
			)}

			<div className="flex flex-col gap-3">
				<h3 className="mb-0 mt-2 text-base font-semibold">
					{t('widget:fields.custom_css.label')}
				</h3>

				<div>
					<TextArea
						rows={8}
						placeholder={t('widget:fields.custom_css.placeholder')}
						{...register('appearanceSettings.stylesRaw')}
					/>
				</div>
			</div>
		</SettingsSection>
	)
}

export const EditWidgetSettings = () => {
	return (
		<div className="flex w-full flex-col py-2 text-global">
			<EditWidgetType />
			<EditWidgetContent />
			<EditWidgetGeneralSettings />
			<EditWidgetAdvancedSettings />
		</div>
	)
}
