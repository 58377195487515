import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Auth } from 'aws-amplify'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import PropTypes from '@/lib/propTypes'

import AuthForm from '../AuthForm'

const NewPassworForm = ({ token, email, newPassword }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		values: {
			token,
			email,
		},
	})
	const { t } = useTranslation(['auth', 'common', 'account', 'errors'])
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)

	const submitForm = async (data) => {
		setIsLoading(true)
		try {
			if (newPassword) {
				const user = await Auth.signIn(data.email, data.token)
				await Auth.completeNewPassword(user, data.newPassword)
			} else {
				await Auth.forgotPasswordSubmit(data.email, data.token, data.newPassword)
			}
			toast.success(t('auth:yourPasswordHasBeenUpdated'))
			navigate('/')
			setIsLoading(false)
		} catch (e) {
			toast.error(e.code ? t(`errors:${e.code}`) : t('errors:errorOccured'))
			setIsLoading(false)
			throw e
		}
	}

	const NewPasswordHeader = <h1>{t('auth:newPassword')}</h1>

	const NewPasswordFooter = (
		<p className="text-muted">
			{t('backTo')} <Link to="/login">{t('auth:logIn')}</Link>
		</p>
	)

	return (
		<AuthForm header={NewPasswordHeader} footer={NewPasswordFooter}>
			<form onSubmit={handleSubmit(submitForm)}>
				<FormField label={t('account:fields.email.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.email.placeholder')}
						name="email"
						type="email"
						disabled={!!email}
						size="large"
						defaultValue={email}
					/>
				</FormField>
				<FormField label={t('account:fields.newPasswordCode.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.newPasswordCode.placeholder')}
						name="token"
						type="text"
						disabled={!!token}
						size="large"
						defaultValue={token}
					/>
				</FormField>
				<FormField label={t('account:fields.newPassword.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.newPassword.placeholder')}
						name="newPassword"
						type="password"
						error={errors.newPassword}
						required
						size="large"
					/>
					<span className="mt-6 block text-muted">
						Password should be at least 8 characters length, includes at least 1 number,
						1 uppercase character.
					</span>
				</FormField>
				<FormField label={t('account:fields.repeatPassword.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.repeatPassword.placeholder')}
						name="repeatPassword"
						type="password"
						error={errors.repeatPassword}
						required
					/>
				</FormField>
				<Button block isLoading={isLoading}>
					{t('auth:resetPassword')}
				</Button>
			</form>
		</AuthForm>
	)
}

NewPassworForm.propTypes = {
	token: PropTypes.string,
}
export default NewPassworForm
