import type { GetAllMediaParams } from '@/api/videobot.schemas'

export const VIDEO_MIMETYPE_SUPPORT =
	'video/mp4, video/quicktime, video/x-msvideo, video/webm, video/mpeg, video/mp2t, video/3gpp, video/x-matroska, video/mpg'

export const IMAGE_MIMETYPE_SUPPORT = 'image/jpeg, image/png, image/gif'

export const VIDEO_MAX_LENGTH = 300 // seconds

export const LANGS = [
	{ name: 'Abkhazian', code: 'ab' },
	{ name: 'Afrikaans', code: 'af' },
	{ name: 'Akan', code: 'ak' },
	{ name: 'Albanian', code: 'sq' },
	{ name: 'Amharic', code: 'am' },
	{ name: 'Arabic', code: 'ar' },
	{ name: 'Armenian', code: 'hy' },
	{ name: 'Assamese', code: 'as' },
	{ name: 'Avaric', code: 'av' },
	{ name: 'Avestan', code: 'ae' },
	{ name: 'Aymara', code: 'ay' },
	{ name: 'Azerbaijani', code: 'az' },
	{ name: 'Bambara', code: 'bm' },
	{ name: 'Bashkir', code: 'ba' },
	{ name: 'Basque', code: 'eu' },
	{ name: 'Belarusian', code: 'be' },
	{ name: 'Bengali', code: 'bn' },
	{ name: 'Bihari languages', code: 'bh' },
	{ name: 'Bislama', code: 'bi' },
	{ name: 'Bosnian', code: 'bs' },
	{ name: 'Brazilian Portuguese', code: 'pt_BR' },
	{ name: 'Breton', code: 'br' },
	{ name: 'Bulgarian', code: 'bg' },
	{ name: 'Burmese', code: 'my' },
	{ name: 'Canadian French', code: 'fr_CA' },
	{ name: 'Catalan / Valencian', code: 'ca' },
	{ name: 'Central Khmer', code: 'km' },
	{ name: 'Chamorro', code: 'ch' },
	{ name: 'Chechen', code: 'ce' },
	{ name: 'Chichewa / Chewa / Nyanja', code: 'ny' },
	{ name: 'Chinese', code: 'zh' },
	{ name: 'Chinese (simplified)', code: 'zh_CN' },
	{ name: 'Chinese (traditional)', code: 'zh_TW' },
	{ name: 'Chuvash', code: 'cv' },
	{ name: 'Cornish', code: 'kw' },
	{ name: 'Corsican', code: 'co' },
	{ name: 'Cree', code: 'cr' },
	{ name: 'Croatian', code: 'hr' },
	{ name: 'Czech', code: 'cs' },
	{ name: 'Danish', code: 'da' },
	{ name: 'Divehi / Dhivehi / Maldivian', code: 'dv' },
	{ name: 'Dutch / Flemish', code: 'nl' },
	{ name: 'Dzongkha', code: 'dz' },
	{ name: 'English', code: 'en' },
	{ name: 'Esperanto', code: 'eo' },
	{ name: 'Estonian', code: 'et' },
	{ name: 'European Portuguese', code: 'pt_PT' },
	{ name: 'European Spanish', code: 'es_ES' },
	{ name: 'Ewe', code: 'ee' },
	{ name: 'Faroese', code: 'fo' },
	{ name: 'Fijian', code: 'fj' },
	{ name: 'Finnish', code: 'fi' },
	{ name: 'French', code: 'fr' },
	{ name: 'French (France)', code: 'fr_FR' },
	{ name: 'Fulah', code: 'ff' },
	{ name: 'Gaelic / Scottish Gaelic', code: 'gd' },
	{ name: 'Galician', code: 'gl' },
	{ name: 'Ganda', code: 'lg' },
	{ name: 'Georgian', code: 'ka' },
	{ name: 'German', code: 'de' },
	{ name: 'Greek', code: 'el' },
	{ name: 'Guarani', code: 'gn' },
	{ name: 'Gujarati', code: 'gu' },
	{ name: 'Haitian / Haitian Creole', code: 'ht' },
	{ name: 'Hausa', code: 'ha' },
	{ name: 'Hebrew', code: 'he' },
	{ name: 'Herero', code: 'hz' },
	{ name: 'Hindi', code: 'hi' },
	{ name: 'Hiri Motu', code: 'ho' },
	{ name: 'Hungarian', code: 'hu' },
	{ name: 'Icelandic', code: 'is' },
	{ name: 'Ido', code: 'io' },
	{ name: 'Igbo', code: 'ig' },
	{ name: 'Indonesian', code: 'id' },
	{ name: 'Interlingua', code: 'ia' },
	{ name: 'Interlingue / Occidental', code: 'ie' },
	{ name: 'Inuktitut', code: 'iu' },
	{ name: 'Inupiaq', code: 'ik' },
	{ name: 'Irish', code: 'ga' },
	{ name: 'Italian', code: 'it' },
	{ name: 'Japanese', code: 'ja' },
	{ name: 'Javanese', code: 'jv' },
	{ name: 'Kalaallisut / Greenlandic', code: 'kl' },
	{ name: 'Kannada', code: 'kn' },
	{ name: 'Kanuri', code: 'kr' },
	{ name: 'Kashmiri', code: 'ks' },
	{ name: 'Kazakh', code: 'kk' },
	{ name: 'Kikuyu / Gikuyu', code: 'ki' },
	{ name: 'Kinyarwanda', code: 'rw' },
	{ name: 'Kirghiz / Kyrgyz', code: 'ky' },
	{ name: 'Komi', code: 'kv' },
	{ name: 'Kongo', code: 'kg' },
	{ name: 'Korean', code: 'ko' },
	{ name: 'Kuanyama / Kwanyama', code: 'kj' },
	{ name: 'Kurdish', code: 'ku' },
	{ name: 'Lao', code: 'lo' },
	{ name: 'Latin', code: 'la' },
	{ name: 'Latin American Spanish', code: 'es_419' },
	{ name: 'Latvian', code: 'lv' },
	{ name: 'Limburgan / Limburger / Limburgish', code: 'lb' },
	{ name: 'Lingala', code: 'ln' },
	{ name: 'Lithuanian', code: 'lt' },
	{ name: 'Luba-Katanga', code: 'lu' },
	{ name: 'Luxembourgish / Letzeburgesch', code: 'lb' },
	{ name: 'Macedonian', code: 'mk' },
	{ name: 'Malagasy', code: 'mg' },
	{ name: 'Malay', code: 'ms' },
	{ name: 'Malayalam', code: 'ml' },
	{ name: 'Maltese', code: 'mt' },
	{ name: 'Manx', code: 'gv' },
	{ name: 'Maori', code: 'mi' },
	{ name: 'Marathi', code: 'mr' },
	{ name: 'Marshallese', code: 'mh' },
	{ name: 'Mongolian', code: 'mn' },
	{ name: 'Nauru', code: 'na' },
	{ name: 'Navajo / Navaho', code: 'nv' },
	{ name: 'Ndonga', code: 'ng' },
	{ name: 'Nepali', code: 'ne' },
	{ name: 'North Ndebele', code: 'nd' },
	{ name: 'Northern Sami', code: 'se' },
	{ name: 'Norwegian', code: 'no' },
	{ name: 'Norwegian Bokmål', code: 'nb' },
	{ name: 'Norwegian, Nynorsk', code: 'nn' },
	{ name: 'Occitan', code: 'oc' },
	{ name: 'Ojibwa', code: 'oj' },
	{ name: 'Oriya', code: 'or' },
	{ name: 'Oromo', code: 'om' },
	{ name: 'Ossetian / Ossetic', code: 'os' },
	{ name: 'Pali', code: 'pi' },
	{ name: 'Panjabi / Punjabi', code: 'pa' },
	{ name: 'Persian', code: 'fa' },
	{ name: 'Polish', code: 'pl' },
	{ name: 'Portuguese', code: 'pt' },
	{ name: 'Pushto / Pashto', code: 'ps' },
	{ name: 'Quechua', code: 'qu' },
	{ name: 'Romanian / Moldavian / Moldovan', code: 'ro' },
	{ name: 'Romansh', code: 'rm' },
	{ name: 'Rundi', code: 'rn' },
	{ name: 'Russian', code: 'ru' },
	{ name: 'Samoan', code: 'sm' },
	{ name: 'Sango', code: 'sg' },
	{ name: 'Sanskrit', code: 'sa' },
	{ name: 'Sardinian', code: 'sc' },
	{ name: 'Serbian', code: 'sr' },
	{ name: 'Shona', code: 'sn' },
	{ name: 'Sichuan Yi / Nuosu', code: 'ii' },
	{ name: 'Sindhi', code: 'sd' },
	{ name: 'Sinhala / Sinhalese', code: 'si' },
	{ name: 'Slovak', code: 'sk' },
	{ name: 'Slovenian', code: 'sl' },
	{ name: 'Somali', code: 'so' },
	{ name: 'Sotho, southern', code: 'st' },
	{ name: 'South Ndebele', code: 'nr' },
	{ name: 'Spanish', code: 'es' },
	{ name: 'Swedish', code: 'sv' },
]

export const FEATURE_FLAGS = {
	INTEGRATION: 'dashboard:integration',
	INTEGRATION_HUBSPOT: 'dashboard:integration_hubspot',
	INTEGRATION_PIPEDRIVE: 'dashboard:integration_pipedrive',
	INTEGRATION_LINEAR: 'dashboard:integration_linear',
	INTEGRATION_SALESFORCE: 'dashboard:integration_salesforce',
} as const

export const MEDIA_LIST_INITIAL_PARAMS: Omit<GetAllMediaParams, 'account_id'> = {
	order_by: 'id',
	order_direction: 'desc',
	page: 1,
	name: '',
}
