import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Alert from '@/components/Alert'
import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import { useAuth } from '@/modules/session/auth.store'

import AuthForm from '../AuthForm'

const LoginForm = () => {
	const [searchParams] = useSearchParams()
	const redirect = searchParams.get('redirect')
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
	})
	const mfaForm = useForm()
	const { login, mfaLogin } = useAuth()
	const navigate = useNavigate()
	const errorMessage = searchParams.get('error')
	const [isAuthenticating, setIsAuthenticating] = useState(false)
	const [errorAuthMessage, setErrorMessage] = useState('')
	const [currentUser, setCurrentUser] = useState(null)
	const [loginState, setLoginState] = useState('')

	const { t } = useTranslation(['auth', 'account', 'errors'])

	const LoginHeader = <h1>{t('auth:logIn')}</h1>

	const LoginFooter = (
		<div>
			<p className="text-sm text-muted">
				{t('auth:forgotPassword')}{' '}
				<Link to="/reset-password">{t('auth:resetPassword')}</Link>
			</p>
			<p className="text-sm text-muted">
				{t('auth:enterNewPassowrd')} <Link to="/new-password">{t('auth:newPassword')}</Link>
			</p>
		</div>
	)

	const submitForm = async (data) => {
		let user
		setIsAuthenticating(true)
		try {
			user = await login(data.username, data.password)
		} catch (e) {
			setErrorMessage(e.message)
			setIsAuthenticating(false)
			return
		}
		if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
			const urlSearch = new URLSearchParams()
			urlSearch.set('token', data.password)
			urlSearch.set('email', data.username)
			urlSearch.set('new', true)
			navigate(
				{
					pathname: '/new-password',
					search: urlSearch.toString(),
				},
				{ replace: true },
			)
			return
		}
		// TODO: handle other challenges
		if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
			setCurrentUser(user)
			setLoginState('MFA')
			setIsAuthenticating(false)
			return
		}
		navigate(redirect || '/dashboard')
		setIsAuthenticating(false)
	}

	const mfaHandler = async (data) => {
		const { authenticationCode } = data
		setIsAuthenticating(true)
		try {
			await mfaLogin(currentUser, authenticationCode)
		} catch (e) {
			setErrorMessage(e.message)
			setIsAuthenticating(false)
			return
		}
		navigate(redirect || '/dashboard')
		setIsAuthenticating(false)
	}

	const googleAuth = () => {
		Auth.federatedSignIn({ provider: 'Google' })
		setIsAuthenticating(true)
	}

	if (loginState === 'MFA') {
		return (
			<AuthForm header={LoginHeader} footer={LoginFooter}>
				<form onSubmit={mfaForm.handleSubmit(mfaHandler)}>
					<FormField label="Authentication Code">
						<FieldText
							register={mfaForm.register}
							placeholder="123456"
							name="authenticationCode"
							error={mfaForm.formState.errors.authenticationCode}
							required
							size="large"
						/>
					</FormField>

					{errorAuthMessage && (
						<Alert icon="exclamation-mark" variant="red">
							{t(errorAuthMessage)}
						</Alert>
					)}

					<Button block size="large" isLoading={isAuthenticating}>
						{t('auth:logIn')}
					</Button>
				</form>
			</AuthForm>
		)
	}

	return (
		<AuthForm header={LoginHeader} footer={LoginFooter}>
			<div className="mb-4 flex">
				<Button
					variant="secondary"
					block
					size="large"
					onClick={googleAuth}
					disabled={isAuthenticating}
				>
					<div className="flex items-center gap-4">
						<img src="/assets/icons/google.svg" className="aspect-square h-6" />
						Log in with Google
					</div>
				</Button>
			</div>
			<form onSubmit={handleSubmit(submitForm)}>
				<FormField label={t('account:fields.email.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.email.placeholder')}
						name="username"
						type="email"
						error={errors.username}
						required
						size="large"
					/>
				</FormField>
				<FormField label={t('account:fields.password.label')}>
					<FieldText
						register={register}
						placeholder={t('account:fields.password.placeholder')}
						name="password"
						type="password"
						error={errors.password}
						required
						size="large"
					/>
				</FormField>
				{errorMessage && (
					<Alert icon="exclamation-mark" variant="red">
						{t(`auth:${errorMessage}`)}
					</Alert>
				)}
				{errorAuthMessage && (
					<Alert icon="exclamation-mark" variant="red">
						{t(errorAuthMessage)}
					</Alert>
				)}
				<Button block size="large" isLoading={isAuthenticating}>
					{t('auth:logIn')}
				</Button>
			</form>
		</AuthForm>
	)
}

export default LoginForm
