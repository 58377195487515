import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { LANGS } from '@/lib/constants'
import PropTypes from '@/lib/propTypes'
import { allMediasKey } from '@/api/videobot.keys'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { useDeleteMediaCaptionById } from '@/api/videobot'

const DeleteSubtitlesModal = ({ onClose, show, subtitles, media }) => {
	const { t } = useTranslation(['errors', 'common', 'media'])
	const queryClient = useQueryClient()
	const activeAccount = useCurrentAccount()
	const { mutate: deleteSubtitles, isLoading } = useDeleteMediaCaptionById({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: allMediasKey(),
				})
				toast.success(t('media:subtitlesDeleted'))
				if (onClose) onClose()
			},
			onError: () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	const handleDelete = () => {
		deleteSubtitles({
			mediaId: media.id,
			captionId: subtitles?.id,
			params: { account_id: activeAccount.id },
		})
	}

	return (
		<Modal
			header={<h3>{t('media:deleteSubtitles')}</h3>}
			show={show}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="media:deleteSubtitlesDescription"
					values={{
						name: LANGS.find((o) => o.code === subtitles?.languageCode)?.name || '-',
					}}
				>
					Are you sure you want to delete{' '}
					<strong>
						{LANGS.find((o) => o.code === subtitles?.languageCode)?.name || '-'}
					</strong>{' '}
					subtitles?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="red-solid" onClick={handleDelete} isLoading={isLoading} wide>
				{t('remove')}
			</Button>
		</Modal>
	)
}

DeleteSubtitlesModal.propTypes = {
	onClose: PropTypes.func,
	show: PropTypes.bool,
	media: PropTypes.object,
	subtitles: PropTypes.array,
	onDelete: PropTypes.func,
}

export default DeleteSubtitlesModal
