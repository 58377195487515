import styled from 'styled-components'

import media from '../../lib/media'
import { FormRowWrapper } from '../FormRow/FormRow.styles'

export const FormSectionWrapper = styled.div`
	&:last-child {
		${FormRowWrapper}:last-child {
			border-bottom: 1px solid transparent;
			padding-bottom: 0;
		}
	}
`

export const FormSectionHeader = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.color.line};

	${media('>md')} {
		flex: 0 0 30%;
		padding-bottom: ${(props) => props.theme.spacing.desktop.sm};
		margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
	}
	${media('<=md')} {
		padding-bottom: ${(props) => props.theme.spacing.mobile.sm};
		margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
	}

	h4 {
		margin-bottom: 0.4rem;
	}

	p {
		margin-bottom: 0;
		margin-top: 0.25rem;
		font-size: 0.95rem;
		color: ${(props) => props.theme.color.muted};
	}
`
