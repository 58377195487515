import styled, { css } from 'styled-components'

import media from '@/lib/media'
import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

export const ModalBackdrop = styled.div`
	${Cover()}
	background-color: ${(props) => props.theme.color.darkOverlay};
`

export const ModalHeader = styled.div`
	text-align: center;

	& h3 {
		margin-bottom: 1rem;
	}

	& p {
		opacity: 0.8;
	}

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	> *:last-child {
		margin-bottom: 0;
	}
`

export const ModalDialog = styled.div`
	position: relative;
	width: 32rem;
	padding: 3rem;
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.lg};
	color: ${(props) => props.theme.color.global};
	overflow: hidden;

	${media('<=md')} {
		padding: 2rem ${(props) => props.theme.grid.outerMargin};
	}

	${(props) =>
		props.variant === 'confirmation' &&
		css`
			width: 24rem;
			text-align: center;
		`};

	${(props) =>
		props.variant === 'wide' &&
		css`
			width: 46rem;
			text-align: center;
		`};
`

export const ModalBody = styled.div`
	h2 {
		margin-bottom: 2rem;
	}
	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}
`

export const ModalClose = styled.div`
	${Reset('button')}
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	${TransitionPrimary('transform')}

	svg {
		fill: currentColor;
		display: block;
		width: 0.9rem;
		height: 0.9rem;
	}

	&:hover {
		transform: rotate(90deg);
	}
`

export const ModalFooter = styled.div`
	padding-top: 1.75rem;
	text-align: right;
	${media('>md')} {
		margin-bottom: -1rem;
	}
	${media('<=md')} {
		margin-bottom: -0.5rem;
	}
`

export const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	bottom: 0;
	z-index: 200;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5vh 1rem;
	overflow: auto;

	${(props) =>
		props.fillOnMobile &&
		css`
			${media('<=md')} {
				padding: 0;

				${ModalDialog} {
					height: 100%;
					border-radius: 0;
					width: 100%;
					padding: 0;
					display: flex;
					flex-direction: column;
				}

				${ModalHeader} {
					text-align: left;
					padding-right: 4rem;
					height: ${(props) => props.theme.size.topBar.mobile};
					display: flex;
					align-items: center;
					margin-bottom: 0;
					flex-shrink: 0;
					padding: 0 ${(props) => props.theme.grid.outerMargin};

					h3 {
						margin-bottom: 0;
						font-size: 1.1rem;
					}
				}
				${ModalFooter} {
					display: flex;
					align-items: center;
					padding: 0 ${(props) => props.theme.grid.outerMargin};
					height: ${(props) => props.theme.size.topBar.mobile};

					> * {
						flex: 1 0 0;
					}
				}

				${ModalBody} {
					flex: 1 1 auto;
					display: flex;
					overflow: hidden;
				}

				${ModalClose} {
					width: 2rem;
					height: 2rem;
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0.95rem;
					&:before {
						${Cover()}
						background-color: ${(props) => props.theme.color.light};
						border-radius: ${(props) => props.theme.radius.sm};
						${TransitionPrimary()}
					}

					svg {
						position: relative;
						width: 0.85rem;
						height: 0.85rem;
					}
				}
			}
		`};
`
