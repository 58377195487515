import styled, { css } from 'styled-components'

import media from '@/lib/media'
import { Cover } from '@/lib/mixins'

import { ButtonWrapper } from '../Button/Button.styles'

export const PanelBackdrop = styled.div`
	${Cover()}

	${(props) =>
		props.addbackdrop &&
		css`
			background-color: ${(props) => props.theme.color.darkOverlay};
		`};
`

export const PanelWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	bottom: 0;
	z-index: ${(props) => props.zIndex || 150};

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5vh 1rem;
	overflow: auto;
`

export const PanelBody = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: ${(props) => props.theme.color.body};
	box-shadow: ${(props) => props.theme.shadow.md};
	display: flex;
	flex-direction: column;
	z-index: ${(props) => props.zIndex || 150};

	${media('>md')} {
		width: 50vw;
		max-width: 56rem;
	}
	${media('<=md')} {
		width: 100vw;
	}
`

export const PanelHeader = styled.div`
	position: relative;
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	flex-shrink: 0;
	display: flex;
	align-items: center;
	padding: 0 ${(props) => props.theme.grid.outerMargin};
	text-align: center;

	${media('>lg')} {
		padding: 0 1.5rem;
		height: ${(props) => props.theme.size.topBar.desktop};
	}

	${media('<=lg')} {
		padding: 0 ${(props) => props.theme.grid.outerMargin};
		height: ${(props) => props.theme.size.topBar.mobile};
	}

	h4 {
		margin-bottom: 0;
		flex: 1 1 auto;
		padding: 0 4rem;
		font-weight: 700;
	}

	${ButtonWrapper} {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
		}
	}
`

export const PanelHeaderInner = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex: 1 1 auto;
`

export const PanelContent = styled.div`
	position: relative;
	flex: 1 1 auto;
	padding: 1.5rem;
	overflow: auto;

	${media('>lg')} {
		padding: 1.5rem;
	}

	${media('<=lg')} {
		padding: 1.5rem ${(props) => props.theme.grid.outerMargin};
	}

	> *:last-child {
		margin-bottom: 0;
	}
	iframe {
		${media('>lg')} {
			max-height: calc(90vh - ${(props) => props.theme.size.topBar.desktop});
		}
		${media('<=lg')} {
			max-height: calc(90vh - ${(props) => props.theme.size.topBar.mobile});
		}
	}

	${(props) =>
		props.center &&
		css`
			display: flex;
			align-items: center;
			justify-content: center;
		`};
`

export const PanelFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: ${(props) => props.theme.size.topBar.desktop};
	border-top: 1px solid ${(props) => props.theme.color.line};
	flex-shrink: 0;
	padding: 0 ${(props) => props.theme.grid.outerMargin};

	${media('<=md')} {
		height: ${(props) => props.theme.size.topBar.mobile};
	}
	${media('<=sm')} {
		${ButtonWrapper} {
			flex: 1 0 0;
		}
	}
`
