import clsx from 'clsx'

import { useSyncLegacyBotsMutation } from '@/api/legacy'

export const SyncLegacyBots = () => {
	const syncLegacyBots = useSyncLegacyBotsMutation()
	const syncLegacyBotsHandler: React.MouseEventHandler = (event) => {
		event.preventDefault()
		syncLegacyBots.mutate()
	}

	return (
		<section className="flex flex-col gap-2">
			<h2>Sync Bots</h2>
			<div>
				<button
					className={clsx(
						'h-12 rounded bg-primary px-4 text-body transition hover:bg-dark',
						syncLegacyBots.isLoading && 'cursor-wait opacity-50',
					)}
					onClick={syncLegacyBotsHandler}
					disabled={syncLegacyBots.isLoading}
				>
					{syncLegacyBots.isLoading ? 'Syncing...' : 'Sync Legacy bots'}
				</button>
			</div>
			{syncLegacyBots.error instanceof Error && (
				<span className="text-red">Error occurred: {syncLegacyBots.error?.message}</span>
			)}
			{syncLegacyBots.isSuccess && <span className="text-green">Synced successfully</span>}
			<span className="text-dark">
				By clicking this button, the system will sync legacy bots. This might take a few
				seconds to be completed.
			</span>
		</section>
	)
}
