import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useKeypress from 'react-use-keypress'

import { useOnClickOutside } from '@/lib/hooks'
import PropTypes from '@/lib/propTypes'

import { TabsIndicator, ViewTabs, ViewTabsButton } from './View.styles'

const styleClasses = {
	wrapper: `relative flex flex-col items-start w-full mt-4 transition-opacity transition-visibility transition-transform`,
	border: 'absolute bottom-0 left-10 right-10 h-px bg-[#DCE2EC]',
	body: 'flex items-center justify-between w-full px-[outerMargin] sm:h-[topBarMobileSize] lg:h-[topBarDesktopSize] lg:px-10',
	actions: 'ml-auto',
	title: 'flex-1 mb-0 whitespace-nowrap overflow-hidden text-ellipsis flex items-center pr-4 flex-shrink-0 sm:text-[1.1rem] lg:text-[1.6rem]',
}

const ViewHeaderTabs = (props) => {
	const { title, actions, tabs, hide } = props

	const navigate = useNavigate()
	const location = useLocation()
	const activeTabRef = useRef(null)
	const [activeTabStyle, setActiveTabStyle] = useState({})

	const inputRef = useRef(null)

	useOnClickOutside(inputRef, () => inputRef.current?.blur())

	useKeypress(['Escape', 'Enter'], () => {
		inputRef.current?.blur()
	})

	useEffect(() => {
		function updateIndicator() {
			const tabBounds = activeTabRef.current?.getBoundingClientRect()
			const parentBounds = activeTabRef.current?.parentElement?.getBoundingClientRect()

			if (tabBounds && parentBounds) {
				setActiveTabStyle({
					transform: `translateX(${tabBounds.left - parentBounds.left}px)`,
					width: `${tabBounds.width}px`,
				})
			}
		}

		updateIndicator()

		window.addEventListener('resize', updateIndicator)
		return () => window.removeEventListener('resize', updateIndicator)
	}, [location.pathname, tabs])

	const defaultTransition = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
		transition: { duration: 0.15 },
	}

	const hideClass = hide ? 'opacity-0 translate-y-1 invisible' : ''
	const wrapperClasses = `${styleClasses.wrapper} ${hideClass}`

	return (
		<motion.div className={wrapperClasses} {...defaultTransition}>
			<div className={styleClasses.border}></div>
			<div className={styleClasses.body}>
				<AnimatePresence mode="wait">
					<motion.h1 className={styleClasses.title} {...defaultTransition}>
						<span className="block overflow-hidden text-ellipsis">{title}</span>
					</motion.h1>
				</AnimatePresence>
				<div className={styleClasses.actions}>{actions}</div>
			</div>
			<div className="ml-2 mt-2">
				{tabs && (
					<ViewTabs>
						{tabs.map((tab, index) => (
							<ViewTabsButton
								ref={location.pathname.includes(tab.path) ? activeTabRef : null}
								key={index}
								onClick={() => navigate(tab.path)}
								active={location.pathname.includes(tab.path)}
							>
								{tab.name}
							</ViewTabsButton>
						))}
						<TabsIndicator style={activeTabStyle} />
					</ViewTabs>
				)}
			</div>
		</motion.div>
	)
}

ViewHeaderTabs.propTypes = {
	shortTitle: PropTypes.string,
	topBarActions: PropTypes.node,
	showOnDesktop: PropTypes.bool,
	tabs: PropTypes.array,
	backRoute: PropTypes.string,
}

export default ViewHeaderTabs
