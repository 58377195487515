import PropTypes from 'prop-types'

import {
	FormGroupContent,
	FormGroupHeader,
	FormGroupHeaderMain,
	FormGroupHeaderUtils,
	FormGroupWrapper,
} from './FormGroup.styles'

const FormGroup = ({ title, description, children, utils, hide }) => {
	return (
		<FormGroupWrapper>
			<FormGroupHeader>
				<FormGroupHeaderMain>
					{title && <h6>{title}</h6>}
					{description && <p>{description}</p>}
				</FormGroupHeaderMain>
				<FormGroupHeaderUtils>{utils}</FormGroupHeaderUtils>
			</FormGroupHeader>
			{!hide && <FormGroupContent>{children}</FormGroupContent>}
		</FormGroupWrapper>
	)
}

FormGroup.propTypes = {
	title: PropTypes.string,
	hide: PropTypes.bool,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,
	utils: PropTypes.node,
}

export default FormGroup
