import PropTypes from '@/lib/propTypes'

import { BadgeWrapper } from './Badge.styles'

const Badge = (props) => {
	const { children, variant = 'default', size = 'md' } = props

	return (
		<BadgeWrapper variant={variant} size={size}>
			{children}
		</BadgeWrapper>
	)
}

Badge.propTypes = {
	children: PropTypes.node,
	variant: PropTypes.oneOf([
		'default',
		'purple',
		'orange',
		'yellow',
		'primary',
		'dark',
		'muted',
		'green',
		'red',
		'primary-inverted',
	]),
	size: PropTypes.oneOf(['md', 'sm', 'lg']),
}

export default Badge
