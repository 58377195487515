import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { useState, type PropsWithChildren, useEffect } from 'react'
import type { NavigateFunction } from 'react-router'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { captureException } from '@sentry/react'
import { isString } from 'lodash-es'

import { FetchError } from '@/fetcher'
import { isInvalidUser, useAuth } from '@/modules/session/auth.store'

const handleFetchError = (
	err: unknown,
	navigateFn: NavigateFunction,
	logoutFn: () => void,
	client: QueryClient,
) => {
	if (isInvalidUser(err)) {
		navigateFn('/invalid-user')
		return
	}
	if (err instanceof FetchError) {
		if (err.statusCode === 401) {
			const message =
				(isString(err.payload) ? err.payload : err.payload?.detail) || err.message
			navigateFn('/login')
			logoutFn()
			client.clear()
			toast.error(`Your session is unauthorized, please login again. Reasons: ${message}}`)
		}
	}
}

const handleToastNotification = (err: unknown) => {
	if (isInvalidUser(err)) {
		return
	}
	if (err instanceof FetchError) {
		toast.error(err.status || err.message)
		return
	}

	if (err instanceof Error) {
		toast.error(err.message)
		return
	}

	toast.error('Something went wrong')
	throw err
}

const createQueryClient = () => {
	const client = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 3000,
				retry: (failureCount, err) => {
					if (err instanceof FetchError) {
						if (err.statusCode === 401) {
							return false
						}
					}
					return failureCount < 2
				},
			},
		},
	})
	return client
}

const QueryClientErrorHandle = ({ children }: PropsWithChildren) => {
	const navigate = useNavigate()
	const { logout } = useAuth()
	const queryClient = useQueryClient()

	// update errors handlers
	// FIXME: navigate currently wastes some rerenders
	// it will be excluded from deps array until a better solution is found
	useEffect(() => {
		const queryCache = queryClient.getQueryCache()
		const mutationCache = queryClient.getMutationCache()

		queryCache.config.onError = (err) => {
			handleFetchError(err, navigate, logout, queryClient)
			handleToastNotification(err)
		}

		mutationCache.config.onError = (err) => {
			handleFetchError(err, navigate, logout, queryClient)
			if (!(err instanceof FetchError)) {
				captureException(err)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryClient, logout])

	return children
}

export const QueryProvider = ({ children }: PropsWithChildren) => {
	const [client] = useState(() => createQueryClient())

	return (
		<QueryClientProvider client={client}>
			<QueryClientErrorHandle>{children}</QueryClientErrorHandle>
		</QueryClientProvider>
	)
}
