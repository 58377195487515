import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

export const ReactivateVideobotModal = ({ onClose, onSubmit, videobot, isLoading }) => {
	const { t } = useTranslation(['errors', 'common', 'videobot'])

	return (
		<Modal
			header={<h3>{t('videobot:reactivateVideobot')}</h3>}
			onClose={onClose}
			variant="confirmation"
		>
			<p>
				<Trans
					i18nKey="videobot:reactivateVideobotDescription"
					values={{ name: videobot?.name }}
				>
					Are you sure you want to deactivate videobot <strong>{videobot?.name}</strong>?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="primary" onClick={() => onSubmit()} isLoading={isLoading} wide>
				{t('reactivate')}
			</Button>
		</Modal>
	)
}
