import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { cloneDeep } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Badge from '@/components/Badge'
import Button from '@/components/Button'
import FieldMedia from '@/components/FieldMedia'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Icon from '@/components/Icon'
import ListTable from '@/components/ListTable/ListTable'
import More, { MoreButton } from '@/components/More'
import Panel from '@/components/Panel'
import { breakpoints } from '@/lib/theme'
import { getNextId } from '@/lib/utils'
import FieldSelect from '@/components/FieldSelect'
import { LANGS } from '@/lib/constants'
import { Col } from '@/components/Grid'
import { useVideobotEditor } from '@/features/Videobot/hooks/videobot_editor'
import { SlideCategoryType } from '@/api/videobot.schemas'
import { ControlledFieldToggle } from '@/components/field_toggle/field_toggle.component'

import useEditorFormPrompt from '../../hooks/useEditorPrompt'
import ExitEditorModal from '../ExitEditorModal'
import EditButtonPanel from './EditButtonPanel'

const SlideOptionMenu = ({ row, onEditClick, onDeleteClick }) => {
	const { t } = useTranslation(['user'])

	return (
		<More>
			<MoreButton onClick={() => onEditClick(row)}>{t('edit')}</MoreButton>
			<MoreButton onClick={() => onDeleteClick(row)} variant="red">
				{t('remove')}
			</MoreButton>
		</More>
	)
}

const EditSlideActions = ({ onSave, onDelete, onDuplicate, saveDisabled }) => {
	const { t } = useTranslation()
	return (
		<React.Fragment>
			<div className="ml-0 mr-auto">
				<Button onClick={onDelete} variant="red">
					{t('delete')}
				</Button>
				<Button onClick={onDuplicate} variant="secondary">
					{t('duplicate')}
				</Button>
			</div>
			<div>
				<Button onClick={onSave} disabled={saveDisabled}>
					{t('save')}
				</Button>
			</div>
		</React.Fragment>
	)
}

const EditSlidePanel = () => {
	const { slideId } = useParams()

	const { t } = useTranslation()
	const {
		watch: watchEditor,
		actions: { addSlide, updateSlide, deleteSlide },
	} = useVideobotEditor()
	const navigate = useNavigate()
	const [slides, actions] = watchEditor(['slides', 'actions'])

	const slideData = slides.find((o) => o.id.toString() === slideId.toString())

	const {
		register,
		control,
		formState: { errors, isValid, isDirty },
		reset,
		handleSubmit,
		setValue,
		watch,
	} = useForm({
		mode: 'onBlur',
		values: slideData,
	})
	const currentSlide = watch()
	const [optionToEdit, setOptionToEdit] = useState(null)
	const watchIsCaptionEnabled = watch('isCaptionEnabled')

	const handleDeleteOption = (option) => {
		setValue(
			'options',
			currentSlide.options.filter((o) => o.id !== option.id),
			{ shouldDirty: true },
		)
	}

	const handleSaveOptions = (options) => {
		setValue('options', options, { shouldDirty: true })
	}

	const handleEditOption = (option) => {
		setOptionToEdit(option)
	}

	const optionsColumns = [
		{
			name: t('videobot:slideFields.type.label'),
			selector: (row) => (
				<React.Fragment>
					{row.type === 'EXTERNAL_LINK' && (
						<React.Fragment>
							<Icon
								variant="square"
								size="small"
								color="purple"
								name="external-link"
							/>
							<span>
								{' '}
								{actions.find((o) => o.id === row.targetId)?.name || row.name}
							</span>
						</React.Fragment>
					)}
					{row.type === 'POST_MESSAGE' && (
						<React.Fragment>
							<Icon variant="square" size="small" color="purple" name="mail-solid" />
							<span>
								{' '}
								{actions.find((o) => o.id === row.targetId)?.name || row.name}
							</span>
						</React.Fragment>
					)}
					{row.type === 'CLOSE_VIDEOBOT' && (
						<React.Fragment>
							<Icon variant="square" size="small" color="purple" name="cross" />
							<span>
								{' '}
								{actions.find((o) => o.id === row.targetId)?.name || row.name}
							</span>
						</React.Fragment>
					)}
					{row.type === 'REGULAR_FORM' && (
						<React.Fragment>
							<Icon
								variant="square"
								size="small"
								color="primary"
								inverted
								name="mail-solid"
							/>
							<span>
								{' '}
								{actions.find((o) => o.id === row.targetId)?.name || row.name}
							</span>
						</React.Fragment>
					)}
					{row.type === 'SLIDE' && (
						<React.Fragment>
							<Icon
								variant="square"
								size="small"
								color="dark"
								inverted
								name="video-solid"
							/>
							<span>
								{' '}
								{(slides &&
									actions &&
									[...slides, ...actions].find((o) => o.id === row.targetId)
										?.name) ||
									row.name}
							</span>
						</React.Fragment>
					)}
				</React.Fragment>
			),
		},
		{
			name: t('videobot:slideFields.name.label'),
			selector: (row) => row.name,
		},
		{
			name: t('videobot:slideFields.style.label'),
			selector: (row) => row.featured,
			cell: (row) => {
				return row.featured ? (
					<Badge variant="primary">{t('highlight')}</Badge>
				) : (
					<Badge variant="primary-inverted">{t('standard')}</Badge>
				)
			},
		},
		{
			hide: breakpoints.lg,
			button: true,
			width: '2.75rem',
			cell: (row) => {
				return (
					<SlideOptionMenu
						row={row}
						onDeleteClick={() => handleDeleteOption(row)}
						onEditClick={() => handleEditOption(row)}
					/>
				)
			},
		},
	]
	const formRef = useRef(null)

	const { isSubmitted, setIsSubmitted, showPrompt, acceptPrompt, cancelPrompt } =
		useEditorFormPrompt({
			when: isDirty,
			condition: (url) => url.match(/.*videobots\/\d.\/content\//g),
		})

	const handleMediaChange = (media) => {
		const videoUrl = media && media.url ? media.url : null
		const posterUrl = media && media.thumbnailUrl ? media.thumbnailUrl : null
		const previewUrl = media && media.previewUrl ? media.previewUrl : null
		const filename = media && media.name ? media.name : null

		setValue('videoId', media && media.id ? Number.parseInt(media.id, 10) : null, {
			shouldDirty: true,
		})
		setValue('videoUrl', videoUrl, { shouldDirty: true })
		setValue('posterUrl', posterUrl, { shouldDirty: true })
		setValue('previewUrl', previewUrl, { shouldDirty: true })
		setValue('filename', filename, { shouldDirty: true })
	}

	const handleSave = (data) => {
		reset({ keepValues: true })
		updateSlide(slideId, {
			...data,
			isNew: false,
			language: data.isCaptionEnabled ? data.language : null,
		})
		setIsSubmitted(true)
	}

	const handleDelete = () => {
		deleteSlide(slideId)
		navigate('../')
	}

	const handleAddOption = () => {
		const optionPrototype = { id: getNextId(currentSlide.options), featured: false }
		setOptionToEdit(optionPrototype)
	}

	const handleOptionReorder = (payload) => {
		setValue('options', payload, { shouldDirty: true })
	}

	useEffect(() => {
		if (isSubmitted) {
			navigate('../')
		}
	}, [isSubmitted, navigate])

	const handleDuplicate = (e) => {
		e.preventDefault()
		const duplicateSlide = cloneDeep(currentSlide)
		duplicateSlide.id = getNextId(slides)
		duplicateSlide.category = SlideCategoryType.SLIDE // do not duplicate intro
		addSlide(duplicateSlide)
		navigate('../')
	}

	const onSave = (event) => {
		event.preventDefault()
		formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
	}

	return (
		<Panel
			title={t('videobot:editSlide')}
			onBack={() => navigate('../')}
			footer={
				<EditSlideActions
					onDelete={() => handleDelete()}
					onSave={onSave}
					onDuplicate={handleDuplicate}
					saveDisabled={(!isValid || !isDirty) && !currentSlide?.isNew}
				/>
			}
		>
			{currentSlide && (
				<React.Fragment>
					<form ref={formRef} onSubmit={handleSubmit((data) => handleSave(data))}>
						{/* Media */}
						<FormField>
							<FieldMedia
								onChange={handleMediaChange}
								defaultValue={
									currentSlide?.videoUrl
										? {
												thumbnailUrl: currentSlide?.posterUrl,
												previewUrl: currentSlide?.previewUrl,
											}
										: null
								}
							/>
						</FormField>
						{/* Title */}
						<FormField label={t('videobot:slideFields.name.label')}>
							<FieldText
								register={register}
								placeholder={t('videobot:slideFields.name.placeholder')}
								name="name"
								error={errors.name}
								defaultValue={currentSlide?.name}
								required
							/>
						</FormField>
					</form>
					{/* Slides List */}
					<ListTable
						dragEnabled
						columns={optionsColumns}
						data={currentSlide.options}
						addAction={t('videobot:addButton')}
						onAdd={handleAddOption}
						onReorder={handleOptionReorder}
						onRowClicked={handleEditOption}
					/>
					<div className="pt-4">
						<Col sm={6} xxs={12}>
							<FormField label={t('videobot:slideFields.overrideCaptionLanguage')}>
								<ControlledFieldToggle
									control={control}
									name="isCaptionEnabled"
									defaultSelected={Boolean(currentSlide?.language)}
								/>
								{(watchIsCaptionEnabled || Boolean(currentSlide?.language)) && (
									<FieldSelect
										register={register}
										placeholder={t('videobot:settingsPage.captionLanguage')}
										name="language"
										type="text"
										error={errors.language}
										options={LANGS.map((item) => ({
											value: item.code,
											label: item.name,
											item,
										}))}
										setValue={setValue}
										defaultValue={currentSlide?.language}
									/>
								)}
							</FormField>
						</Col>
					</div>
					{/* Edit Option */}
					<AnimatePresence>
						{optionToEdit && (
							<EditButtonPanel
								buttonData={optionToEdit}
								onClose={() => setOptionToEdit(null)}
								onDelete={handleDeleteOption}
								onSave={handleSaveOptions}
								currentSlide={currentSlide}
							/>
						)}
					</AnimatePresence>
				</React.Fragment>
			)}
			{showPrompt && (
				<ExitEditorModal
					onSave={() => {
						formRef.current.dispatchEvent(
							new Event('submit', { cancelable: true, bubbles: true }),
						)
						acceptPrompt()
					}}
					onExit={acceptPrompt}
					onClose={cancelPrompt}
				/>
			)}
		</Panel>
	)
}

export default EditSlidePanel
