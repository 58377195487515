import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const defaultLanguage = 'en'

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		fallbackLng: defaultLanguage,
		lng: localStorage.getItem('language')
			? JSON.parse(localStorage.getItem('language'))
			: defaultLanguage,
		defaultNS: 'common',
		fallbackNS: 'common',
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	})

export default i18n
