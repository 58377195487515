import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'

import Button from '@/components/Button'
import MediaList from '@/features/MediaLibrary/components/MediaList'
import MediaUploadList from '@/features/MediaLibrary/components/MediaUploadList'
import MediaUploadModal from '@/features/MediaLibrary/components/MediaUploadModal'
import View from '@/layout/View'
import FieldText from '@/components/FieldText'
import { useGetAllMedia } from '@/api/videobot'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { MEDIA_LIST_INITIAL_PARAMS } from '@/lib/constants'
import { allMediasKey } from '@/api/videobot.keys'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'

const MediaLibraryContents = () => {
	const [searchParams, setSearchParams] = useSearchParams(MEDIA_LIST_INITIAL_PARAMS)
	const { register, handleSubmit } = useForm({
		defaultValues: { name: searchParams.get('name') },
	})
	const activeAccount = useCurrentAccount()
	const { t } = useTranslation(['media'])
	const [showUploadModal, setShowUploadModal] = useState(false)
	const params = Object.fromEntries(searchParams)
	const queryClient = useQueryClient()

	const handleCreateNewClick = () => {
		setShowUploadModal(true)
	}

	const { data, isLoading } = useGetAllMedia(
		{ account_id: activeAccount?.id, ...params },
		{
			query: {
				enabled: Boolean(activeAccount),
				refetchInterval: 5000,
				keepPreviousData: true,
			},
		},
	)

	const invalidateQueries = () => {
		queryClient.invalidateQueries({
			queryKey: allMediasKey(),
		})
	}

	const handleUploaded = () => {
		invalidateQueries()
	}

	const onSearch = (data) => {
		setSearchParams((prev) => ({ ...Object.fromEntries(prev), page: 1, ...data }))
	}

	return (
		<View
			header
			title={t('videoLibrary')}
			actions={<Button onClick={handleCreateNewClick}>{t('media:addMedia')}</Button>}
			mobileTopBar
			isLoading={isLoading && !data}
		>
			<form onSubmit={handleSubmit(onSearch)} className="mb-2 flex gap-4">
				<label className="flex items-center gap-2">
					<FieldText
						type="text"
						className="rounded"
						placeholder="Search videos..."
						name="name"
						register={register}
					/>
				</label>

				<button
					className="rounded bg-primary px-8 text-body transition hover:bg-dark"
					type="submit"
				>
					Search
				</button>
			</form>
			{/* Uploader */}
			<MediaUploadList />
			{/* List */}
			<MediaList data={data} invalidateMediaList={invalidateQueries} />
			<AnimatePresence>
				{/* Upload Modal */}
				{showUploadModal && (
					<MediaUploadModal
						onUploaded={handleUploaded}
						onClose={() => setShowUploadModal(false)}
					/>
				)}
			</AnimatePresence>
		</View>
	)
}

const MediaLibrary = () => {
	return (
		<RoleCheck
			roles={[
				RoleType.Super_Admin,
				RoleType.Admin,
				RoleType.Owner,
				RoleType.Member,
				RoleType.Reseller,
			]}
		>
			<MediaLibraryContents />
		</RoleCheck>
	)
}

export default MediaLibrary
