import styled, { css } from 'styled-components'

import { ButtonWrapper } from '@/components/Button/Button.styles'
import { IconSquare } from '@/components/Icon/Icon.styles'

import media from '../../lib/media'
import { LastMb0, Reset, TransitionPrimary, TransitionSecondary } from '../../lib/mixins'

export const ViewContent = styled.div`
	max-width: 90rem;
`

export const ViewHeader = styled.div`
	display: flex;
	align-items: center;
	${TransitionPrimary('opacity, visibility, transform')}

	${(props) =>
		props.hide &&
		css`
			opacity: 0;
			transform: translateY(-0.25rem);
			visibility: hidden;
		`};

	${media('>md')} {
		margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
	}

	${media('<=md')} {
		margin-bottom: ${(props) => props.theme.spacing.mobile.sm};
	}

	${(props) =>
		!props.noBorder &&
		css`
			padding-bottom: ${(props) => props.theme.spacing.desktop.sm};
			border-bottom: 1px solid ${(props) => props.theme.color.line};

			${media('<=md')} {
				padding-bottom: ${(props) => props.theme.spacing.mobile.sm};
			}
		`};
`

export const ViewHeaderBack = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 1.5rem;
`

export const ViewWrapper = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.color.body};

	${(props) =>
		props.cover &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #fff;
		`};
`

export const ViewScroller = styled.div`
	max-height: 100%;
	overflow: auto;
	${media('>lg')} {
		padding: ${(props) => props.theme.spacing.desktop.sm} 2.5rem 2.5rem;

		${(props) =>
			props.showTopBarOnDesktop &&
			css`
				padding-top: calc(
					${(props) => props.theme.size.topBar.desktop} +
						${(props) => props.theme.spacing.desktop.sm}
				);
			`};
	}

	${media('<=lg')} {
		padding: calc(
				${(props) => props.theme.size.topBar.mobile} +
					${(props) => props.theme.spacing.desktop.sm}
			)
			${(props) => props.theme.grid.outerMargin} ${(props) => props.theme.size.mobileNavBar}
			${(props) => props.theme.grid.outerMargin};

		${(props) =>
			props.hasBottomBar &&
			css`
				padding-bottom: ${(props) => props.theme.size.topBar.desktop};
			`};

		${(props) =>
			props.hasTabs &&
			css`
				padding-top: calc(
					${(props) => props.theme.size.topBar.mobile} +
						${(props) => props.theme.spacing.mobile.sm} +
						${(props) => props.theme.size.tabsBar.mobile}
				);
			`};
	}

	${media('<=md')} {
		padding: calc(
				${(props) => props.theme.size.topBar.mobile} +
					${(props) => props.theme.spacing.mobile.sm}
			)
			${(props) => props.theme.grid.outerMargin}
			calc(
				${(props) => props.theme.size.mobileNavBar} +
					${(props) => props.theme.spacing.mobile.sm}
			)
			${(props) => props.theme.grid.outerMargin};

		${(props) =>
			props.hasTabs &&
			css`
				padding-top: calc(
					${(props) => props.theme.size.topBar.mobile} +
						${(props) => props.theme.spacing.mobile.sm} +
						${(props) => props.theme.size.tabsBar.mobile}
				);
			`};

		${(props) =>
			props.hasBottomBar &&
			css`
				padding-bottom: ${(props) => props.theme.size.topBar.mobile};
			`};
	}
`

export const ViewHeaderBody = styled.div`
	flex: 1 1 auto;
	${LastMb0()}

	h1 {
		margin-bottom: 0.25rem;

		${media('<=lg')} {
			font-size: 1.25rem;
		}
	}

	p {
		color: ${(props) => props.theme.color.muted};
	}
`
export const ViewHeaderActions = styled.div`
	margin-left: auto;
`

export const ViewTopBarWrapper = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 140;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);

	${(props) =>
		!props.showOnDesktop &&
		css`
			${media('>lg')} {
				display: none;
			}
		`};
`

export const ViewTopBarBody = styled.div`
	display: flex;
	align-items: center;
	padding: 0 ${(props) => props.theme.grid.outerMargin};

	${media('>lg')} {
		padding: 0 2.5rem;
		height: ${(props) => props.theme.size.topBar.desktop};
	}

	${media('<=lg')} {
		padding: 0 ${(props) => props.theme.grid.outerMargin};
		height: ${(props) => props.theme.size.topBar.mobile};
	}
`

export const ViewTopBarBack = styled.div`
	margin-right: 1rem;
`
export const ViewBack = styled.button`
	${Reset('button')}
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.4rem;
	height: 2.4rem;
	margin-left: -0.25rem;
	border-radius: ${(props) => props.theme.radius.sm};
	background-color: ${(props) => props.theme.color.light};
	${TransitionPrimary('background')}

	&:hover {
		background-color: ${(props) => props.theme.color.line};
	}

	svg {
		width: 0.8rem;
		height: 0.8rem;
	}
`

export const ViewTopBarTitle = styled.h1`
	flex: ${(props) => (props.hasMiddleDecor ? '0 1 auto' : '1 1 auto')};
	margin-bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	padding-right: 1rem;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}

	${media('>lg')} {
		font-size: 1.6rem;
	}

	${media('<=lg')} {
		font-size: 1.1rem;
	}

	${IconSquare} {
		margin-left: 0.5em;
		flex-shrink: 0;
	}
`

export const ViewTopBarInput = styled.input`
	${Reset('all')}
	width: 16rem;
	border: 1px solid ${(props) => props.theme.color.line};
	border-radius: 0.5rem;
	font-weight: 800;
	line-height: 1.1;
	padding: 0.75rem;
	font-size: 1.15rem;
	font-family: ${(props) => props.theme.font.secondary};

	&:focus {
		border: 1px solid ${(props) => props.theme.color.line};
		box-shadow: none;
		outline: 0;
	}

	${media('<=sm')} {
		font-size: 1rem;
	}
`

export const ViewTopBarActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;
`

export const ViewRouterView = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	height: 100%;
	display: flex;
	justify-content: start;
	background-color: ${(props) => props.theme.color.light};

	${media('>lg')} {
		top: 0;
		bottom: 0;
		height: auto;

		${(props) =>
			props.showTopBarOnDesktop &&
			css`
				top: ${(props) => props.theme.size.topBar.desktop};

				${(props) =>
					props.hasTabs &&
					css`
						top: calc(
							${(props) => props.theme.size.topBar.desktop} +
								${(props) => props.theme.size.tabsBar.desktop}
						);
					`};
			`};
	}
`

export const ViewTabs = styled.nav`
	position: relative;
	display: flex;

	${media('>lg')} {
		padding: 0 2.5rem;
	}

	${media('<=lg')} {
		padding: 0 ${(props) => props.theme.grid.outerMargin};
	}
`

export const TabsIndicator = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: ${(props) => props.theme.color.primary};
	${TransitionSecondary('transform,width')}
`

export const ViewTabsButton = styled.button`
	${Reset('button')}
	font-family: ${(props) => props.theme.font.secondary};
	font-weight: 600;
	font-size: 0.9rem;
	display: flex;
	padding-top: 0.25em;

	${(props) =>
		props.active &&
		css`
			color: ${(props) => props.theme.color.primary};
		`};

	${media('>lg')} {
		height: ${(props) => props.theme.size.tabsBar.desktop};
		&:not(:last-child) {
			margin-right: 2.5rem;
		}
	}
	${media('<=lg')} {
		align-items: center;
		text-align: center;
		justify-content: center;
		flex: 1 0 0;
		height: ${(props) => props.theme.size.tabsBar.mobile};
	}
`

export const ViewBottomBar = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: ${(props) => props.theme.size.topBar.desktop};
	border-top: 1px solid ${(props) => props.theme.color.line};
	flex-shrink: 0;
	padding: 0 ${(props) => props.theme.grid.outerMargin};
	background-color: ${(props) => props.theme.color.body};

	${media('>lg')} {
		display: none;
	}
	${media('<=md')} {
		height: ${(props) => props.theme.size.topBar.mobile};
	}
	${media('<=sm')} {
		${ButtonWrapper} {
			flex: 1 0 0;
		}
	}
`

export const ViewHeaderTabsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	${TransitionPrimary('opacity, visibility, transform')}

	${(props) =>
		props.hide &&
		css`
			opacity: 0;
			transform: translateY(-0.25rem);
			visibility: hidden;
		`};

	${(props) =>
		!props.noBorder &&
		css`
			position: relative; /* Required for absolute positioning of the pseudo-element */
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 2.5rem;
				right: 2.5rem;
				height: 1px;
				background-color: ${(props) => props.theme.color.line};
			}
		`};
`

export const ViewHeaderTabsBody = styled.div`
	display: flex;
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 ${(props) => props.theme.grid.outerMargin};

	${media('>lg')} {
		padding: 0 2.5rem;
		height: ${(props) => props.theme.size.topBar.desktop};
	}

	${media('<=lg')} {
		padding: 0 ${(props) => props.theme.grid.outerMargin};
		height: ${(props) => props.theme.size.topBar.mobile};
	}
`
export const ViewHeaderTabsActions = styled.div`
	margin-left: auto;
`
export const ViewHeaderTabsTitle = styled.h1`
	flex: 1 1 auto;
	margin-bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	padding-right: 1rem;
	flex-shrink: 0;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}

	${media('>lg')} {
		font-size: 1.6rem;
	}

	${media('<=lg')} {
		font-size: 1.1rem;
	}
`
