import styled from 'styled-components'

import { TransitionSecondary } from '@/lib/mixins'

export const ProgressBarWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`

export const ProgressBarIndicator = styled.div`
	position: relative;
	flex: 1 1 auto;
	height: 0.6rem;
	border-radius: 1rem;
	background-color: ${(props) => props.theme.color.light};
	overflow: hidden;
`

export const ProgressBarFill = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background-color: ${(props) => props.theme.color.primary};
	width: ${(props) => props.width}%;
	${TransitionSecondary('width')}
`

export const ProgressBarValue = styled.span`
	flex: 0 0 3.5rem;
	text-align: center;
	font-size: 0.9rem;
	margin-bottom: 0;
	font-family: ${(props) => props.theme.font.secondary};
`
