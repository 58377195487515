import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import FieldSelect from '@/components/FieldSelect'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const AddExternalLink = ({ onClose, show, isLoading, onSubmit }) => {
	const { t } = useTranslation(['videobot'])

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: { externalLinkType: 'url' },
	})

	const externalLinkType = watch('externalLinkType')

	const handleFormSubmit = (data) => {
		if (onSubmit) {
			onSubmit({
				...data,
				type: 'EXTERNAL_LINK',
			})
		}
	}

	const externalLinkTypeOptions = [
		{ value: 'tel', label: t('videobot:linkFields.tel.label') },
		{ value: 'mailto', label: t('videobot:linkFields.mailto.label') },
		{ value: 'url', label: t('videobot:linkFields.url.label') },
		{ value: 'deep_link', label: t('videobot:linkFields.deepLink.label') },
		{ value: 'relative_path', label: t('videobot:linkFields.relativePath.label') },
	]

	return (
		<Modal
			header={<h3>{t('videobot:createNewExternalLink')}</h3>}
			show={show}
			onClose={onClose}
		>
			<form autoComplete="off" onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
				<FormField label={t('videobot:linkFields.name.label')} required>
					<FieldText
						register={register}
						placeholder={t('videobot:linkFields.name.placeholder')}
						name="name"
						type="text"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>

				<FormField label={t('videobot:linkFields.type.label')} required>
					<FieldSelect
						register={register}
						placeholder={t('videobot:linkFields.type.placeholder')}
						name="externalLinkType"
						error={errors.externalLinkType}
						options={externalLinkTypeOptions}
						setValue={setValue}
						defaultValue="url"
						size="large"
					/>
				</FormField>

				{externalLinkType === 'tel' && (
					<FormField label={t('videobot:linkFields.tel.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.tel.placeholder')}
							name="url"
							type="tel"
							error={errors.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'url' && (
					<FormField label={t('videobot:linkFields.url.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.url.placeholder')}
							name="url"
							type="url"
							error={errors.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'mailto' && (
					<FormField label={t('videobot:linkFields.mailto.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.mailto.placeholder')}
							name="url"
							type="mailto"
							error={errors.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'deep_link' && (
					<FormField label={t('videobot:linkFields.deepLink.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.deepLink.placeholder')}
							name="url"
							type="deep_link"
							error={errors.url}
							required
							size="large"
						/>
					</FormField>
				)}

				{externalLinkType === 'relative_path' && (
					<FormField label={t('videobot:linkFields.relativePath.label')} required>
						<FieldText
							register={register}
							placeholder={t('videobot:linkFields.relativePath.placeholder')}
							name="url"
							type="relative_path"
							error={errors.url}
							required
							size="large"
						/>
					</FormField>
				)}

				<Button disabled={!isValid} block isLoading={isLoading}>
					{t('videobot:createExternalLink')}
				</Button>
			</form>
		</Modal>
	)
}

AddExternalLink.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default AddExternalLink
