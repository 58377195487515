import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { useInviteUserToAccountById } from '@/api/videobot'

export const ResendInviteModal = ({ onClose, show, user, accountId }) => {
	const { t } = useTranslation(['common', 'user'])
	const navigate = useNavigate()
	const { mutate, isLoading: isInviteLoading } = useInviteUserToAccountById()

	const ModalHeader = <h3>{t('user:resendInviteEmail')}</h3>

	const sendInviteEmail = () => {
		mutate(
			{ accountId, data: { role: user.role.role, userEmail: user.email } },
			{
				onSuccess: () => {
					toast.success(t('user:invitationHasBeenSent'))
					navigate('/dashboard/users')
					onClose()
				},
				onError: (err) => {
					toast.error(t(err.message))
					onClose()
				},
			},
		)
	}

	const getUserDisplayName = (user) => {
		if (user.firstName || user.lastName) {
			return `${user.firstName || ''} ${user.lastName || ''}`.trim()
		}
		return user.email
	}

	return (
		<Modal header={ModalHeader} show={show} onClose={onClose} variant="confirmation">
			<p className="text-balance">
				<Trans
					i18nKey="user:resendInviteEmailDescription"
					values={{ displayName: getUserDisplayName(user) }}
				>
					Do you want to resend an invitation email to &nbsp;
					<strong>{getUserDisplayName(user)}</strong>?
				</Trans>
			</p>
			<Button variant="secondary" onClick={onClose} wide>
				{t('cancel')}
			</Button>
			<Button variant="primary" onClick={sendInviteEmail} isLoading={isInviteLoading} wide>
				{t('send')}
			</Button>
		</Modal>
	)
}
