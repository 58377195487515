import styled from 'styled-components'

import { AlertWrapper } from '@/components/Alert/Alert.styles'
import media from '@/lib/media'

export const AnalyticsDetailsWrapper = styled.div`
	position: relative;
	background-color: ${(props) => props.theme.color.light};
	border-bottom: 1px solid ${(props) => props.theme.color.line};
	padding: 1rem;
	display: grid;
	min-height: 4rem;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	overflow-wrap: anywhere;

	@media screen {
		${media('<=md')} {
			grid-template-columns: 1fr;
		}
	}
	${AlertWrapper} {
		margin-bottom: 0;
		flex: 1 1 auto;
	}
`

export const AnalyticsDetailsBox = styled.div`
	background-color: ${(props) => props.theme.color.body};
	border-radius: ${(props) => props.theme.radius.sm};
	padding: 1rem;

	@media screen {
		${media('>md')} {
			flex: 1 0 0;
		}
	}

	@media print {
		flex: 1 0 0;
	}
`

export const AnalyticsDetailsHeader = styled.div`
	display: flex;
	margin-bottom: 0.75rem;
	color: ${(props) => props.theme.color.mutedLight};
	font-size: 0.85rem;
	font-weight: 600;

	span {
		&:last-child {
			margin-left: auto;
		}
	}
`

export const AnalyticsDetailsBar = styled.div`
	display: flex;
	align-items: center;
`

export const AnalyticsDetailsBarIndicator = styled.span`
	margin-right: 0.75rem;
	height: 0.4rem;
	background-color: ${(props) => props.theme.color.primary};
	border-radius: 0.2rem;
	min-width: 0.4rem;
	flex: ${(props) => props.grow} 0 auto;
`

export const AnalyticsDetailsBarValue = styled.div`
	color: ${(props) => props.theme.color.mutedLight};
	font-size: 0.85rem;
	font-weight: 600;
`

export const AnalyticsDetailsContent = styled.div`
	dl {
		border-top: 1px solid ${(props) => props.theme.color.line};
		padding-top: 0.75rem;
		display: flex;
		align-items: center;
		margin-bottom: 0;

		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}

		dt {
			flex: 1 1 auto;
			margin-right: 1rem;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: normal;
		}

		dd {
			margin-left: auto;
			font-size: 0.85rem;
			color: ${(props) => props.theme.color.mutedLight};
			font-weight: 600;
			margin-bottom: 0;
		}
	}
`

export const AnalyticsDetailsEntry = styled.div`
	@media screen {
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}

	> span {
		display: block;
		margin-bottom: 0.1rem;
	}

	@media print {
		border-top: 1px solid ${(props) => props.theme.color.line};
		padding-top: 0.75rem;

		display: flex;
		/* align-items: center; */
		&:not(:last-child) {
			margin-bottom: 0.75rem;
		}

		${AnalyticsDetailsBarIndicator} {
			display: none;
		}

		${AnalyticsDetailsBar} {
			margin-left: auto;
		}
	}
`
