import { useFieldArray, useFormContext } from 'react-hook-form'
import { arrayMove } from '@dnd-kit/sortable'
import { produce } from 'immer'
import { sortBy } from 'lodash-es'

import { getNextId } from '@/lib/utils'
import { SlideCategoryType } from '@/api/videobot.schemas'

// TODO: Group state machine and stuffs
export const useVideobotEditor = () => {
	// forms
	const form = useFormContext()
	const slidesField = useFieldArray({ control: form.control, name: 'slides' })
	const actionsField = useFieldArray({ control: form.control, name: 'actions' })

	// slides
	const addSlide = (data) => {
		const newSlide = {
			options: [],
			...data,
			id: getNextId(form.getValues('slides')),
			isNew: true,
		}
		slidesField.append(newSlide)
		return newSlide
	}

	const updateSlide = (id, data) => {
		const slides = form.getValues('slides')
		form.setValue(
			'slides',
			produce(slides, (draft) => {
				const index = draft.findIndex((o) => `${o.id}` === `${id}`)
				if (index >= 0) {
					draft[index] = { ...data }
				}
			}),
			{ shouldDirty: true },
		)
	}

	const deleteSlide = (id) =>
		form.setValue(
			'slides',
			form.getValues('slides').filter((o) => `${o.id}` !== `${id}`),
			{ shouldDirty: true },
		)

	const moveSlide = (from, to) =>
		form.setValue('slides', arrayMove(form.getValues('slides'), from, to), {
			shouldDirty: true,
		})

	const setIntroSlide = (index) => {
		const slides = form.getValues('slides')
		form.setValue(
			'slides',
			sortBy(
				produce(slides, (draft) => {
					if (index >= 0) {
						for (const slide of draft) {
							slide.category = SlideCategoryType.SLIDE
						}
						draft[index].category = SlideCategoryType.INTRO
					}
				}),
				(o) => o.category !== 'INTRO',
			),
			{ shouldDirty: true },
		)
	}

	const setNormalSlide = (index) => {
		const slides = form.getValues('slides')
		form.setValue(
			'slides',
			produce(slides, (draft) => {
				if (index >= 0) {
					draft[index].category = SlideCategoryType.SLIDE
				}
			}),
			{ shouldDirty: true },
		)
	}

	// actions
	const addAction = (data) => {
		const newAction = {
			...data,
			id: getNextId(form.getValues('actions')),
			isNew: true,
		}
		actionsField.append(newAction)
		return newAction
	}

	const updateAction = (id, data) => {
		const actions = form.getValues('actions')
		form.setValue(
			'actions',
			produce(actions, (draft) => {
				const index = draft.findIndex((o) => `${o.id}` === `${id}`)
				if (index >= 0) {
					draft[index] = { ...data }
				}
			}),
			{ shouldDirty: true },
		)
	}

	const deleteAction = (id) =>
		form.setValue(
			'actions',
			form.getValues('actions').filter((o) => `${o.id}` !== `${id}`),
			{ shouldDirty: true },
		)

	const actions = {
		addSlide,
		updateSlide,
		deleteSlide,
		moveSlide,
		setIntroSlide,
		setNormalSlide,
		addAction,
		updateAction,
		deleteAction,
	}

	// return
	return { ...form, actions }
}
