import { AnimatePresence, motion } from 'framer-motion'
import { PropTypes } from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import Icon from '../Icon'
import { InfoIconTooltip, InfoIconWrapper } from './InfoIcon.styles'

const InfoIcon = (props) => {
	const { name = 'info', children, wide, variant = 'default', noWrap, color } = props
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [isOpen, setIsOpen] = useState(false)

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		strategy: 'absolute',
		placement: 'top',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 5],
				},
			},
		],
	})

	const openTooltip = () => {
		setIsOpen(true)
	}

	const closeTooltip = () => {
		setIsOpen(false)
	}

	useEffect(() => {
		referenceElement?.addEventListener('mouseenter', openTooltip)
		referenceElement?.addEventListener('mouseleave', closeTooltip)

		return () => {
			referenceElement?.removeEventListener('mouseenter', openTooltip)
			referenceElement?.removeEventListener('mouseleave', closeTooltip)
		}
	}, [referenceElement])

	return (
		<InfoIconWrapper open={isOpen} ref={setReferenceElement} variant={variant} color={color}>
			<Icon name={name} />

			{createPortal(
				<AnimatePresence mode="wait">
					{isOpen && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.15 }}
						>
							<InfoIconTooltip
								style={{ ...styles.popper }}
								{...attributes.popper}
								ref={setPopperElement}
								wide={wide}
								noWrap={noWrap}
							>
								{children}
							</InfoIconTooltip>
						</motion.div>
					)}
				</AnimatePresence>,
				document.querySelector('#tooltips'),
			)}
		</InfoIconWrapper>
	)
}

InfoIcon.propTypes = {
	name: PropTypes.string,
	variant: PropTypes.string,
	wide: PropTypes.bool,
	noWrap: PropTypes.bool,
	children: PropTypes.node,
}

export default InfoIcon
