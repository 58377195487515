import { Navigate, Outlet } from 'react-router'

import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FeatureType } from '@/api/videobot.schemas'

export const WidgetBase = () => {
	const account = useCurrentAccount()
	const { data: featureFlags } = useFeatureFlags({ account_id: account?.id })
	if (!account) {
		return <Navigate to="/" />
	}
	if (!featureFlags?.includes(`dashboard:${FeatureType.new_widgets}`)) {
		return <Navigate to="/" />
	}
	return <Outlet />
}
