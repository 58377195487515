import styled, { css } from 'styled-components'

import { ButtonWrapper } from '@/components/Button/Button.styles'
import { IconSquare } from '@/components/Icon/Icon.styles'
import media from '@/lib/media'
import { TransitionPrimary } from '@/lib/mixins'

export const MediaUploadListWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing.desktop.sm};
	white-space: nowrap;
`
export const MediaUploadItemName = styled.div`
	flex: 1 1 auto;
	padding-right: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${IconSquare} {
		margin-left: -0.25rem;
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
		margin-right: 1rem;

		${media('<=xs')} {
			display: none;
		}
	}
`

export const MediaUploadItemProgress = styled.div`
	flex: 0 0 25%;

	${media('<=xs')} {
		order: 4;
		flex: 0 0 100%;
		margin-top: 0.75rem;
	}
`

export const MediaUploadItemActions = styled.div`
	position: relative;
	flex-shrink: 0;
	margin-left: 2rem;
	${ButtonWrapper} {
		${TransitionPrimary('opacity,visibility')}
	}
`

export const MediaUploadItemWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0.85rem;
	border: 1px solid ${(props) => props.theme.color.line};
	border-radius: ${(props) => props.theme.radius.sm};

	${media('<=xs')} {
		flex-wrap: wrap;
	}

	${(props) =>
		props.uploaded &&
		css`
			${MediaUploadItemActions} {
				${ButtonWrapper} {
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
				}
			}
		`};

	&:not(:last-child) {
		margin-bottom: 0.35rem;
	}
`

export const MediaUploadItemCheck = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: 1.25rem;
	height: 1.25rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;

		path {
			stroke-dasharray: 36;
			stroke-width: 4;
			transition: stroke-dashoffset 0.2s ease-out;
			stroke: ${(props) => props.theme.color.green};
		}
	}
`
