import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import Button from '@/components/Button'
import View from '@/layout/View'
import { WidgetList } from '@/features/widget/widget_list.component'
import { CreateWidgetModal } from '@/features/widget/create_widget_modal.component'

export const WidgetListView = () => {
	const [t] = useTranslation(['widget'])
	const [isCreateModalShow, setIsCreateModalShow] = useState(false)

	return (
		<View
			header
			title={t('widgets')}
			actions={
				<Button onClick={() => setIsCreateModalShow(true)}>{t('widget:create_new')}</Button>
			}
			mobileTopBar
		>
			{isCreateModalShow && (
				<CreateWidgetModal
					onClose={() => setIsCreateModalShow(false)}
					show={isCreateModalShow}
				/>
			)}
			<WidgetList />
		</View>
	)
}
