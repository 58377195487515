import styled, { css } from 'styled-components'

import media from '@/lib/media'
import { Reset, TransitionPrimary } from '@/lib/mixins'

import { FieldSelectWrapper } from '../FieldSelect/FieldSelect.styles'
import { IconSquare } from '../Icon/Icon.styles'
import { InfoIconWrapper } from '../InfoIcon/InfoIcon.styles'

export const ListTableCell = styled.div`
	flex: 1 0 0;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 0.5rem;
	min-width: 8rem;
	text-overflow: ellipsis;

	* {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	${(props) =>
		props.isThumbnail &&
		css`
			${media('>md')} {
				flex: 0 0 calc(${(props) => props.theme.size.thumbnail.desktop} + 1rem);
				min-width: calc(${(props) => props.theme.size.thumbnail.desktop} + 1rem);
			}

			${media('<=md')} {
				flex: 0 0 calc(${(props) => props.theme.size.thumbnail.mobile} + 1rem);
				min-width: calc(${(props) => props.theme.size.thumbnail.mobile} + 1rem);
			}
		`};

	${(props) =>
		props.isButton &&
		css`
			flex: 0 0 2.75rem;
			max-width: 2.75rem;
			min-width: 2.75rem;
			margin-left: auto;
			text-align: center;
			justify-content: center;

			&:last-child {
				flex: 0 0 3.5rem;
				max-width: 3.5rem;
			}
		`};

	${IconSquare} {
		&:not(:last-child) {
			margin-right: 0.5rem;
		}
	}

	${InfoIconWrapper} {
		margin-left: 0.5rem;
	}
`
export const ListTableRow = styled.div`
	display: flex;
	width: 100%;
	display: flex;
	height: 4.5rem;
	user-select: none;
	overflow: auto;
	background-color: ${(props) => props.theme.color.body};
	touch-action: none;

	${(props) =>
		props.head &&
		css`
			height: 2rem;

			${ListTableCell} {
				font-size: 0.8rem;
				font-weight: 600;
				color: ${(props) => props.theme.color.mutedLight};
			}
		`};

	${(props) =>
		props.footer &&
		css`
			position: relative;
			border-top: 1px solid ${(props) => props.theme.color.line};
			margin-top: -1px;
			border-bottom: 0 !important;
			background-color: ${(props) => props.theme.color.body};
			z-index: 2;
		`};

	${(props) =>
		props.clickable &&
		!props.isDragging &&
		css`
			cursor: pointer;
			${TransitionPrimary('background')}
		`};

	${(props) =>
		!props.isDragging &&
		css`
			&:hover {
				background-color: ${(props) => props.theme.color.light};
			}
		`};
`

export const ListTableHead = styled.div``

export const ListTableBody = styled.div``

export const ListTableDrag = styled.button`
	${Reset('button')};
	position: relative;
	height: 1.5rem;
	width: 1.5rem;
	margin-right: -0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	touch-action: none;

	&:before,
	&:after {
		width: 65%;
		height: 2px;
		background-color: currentColor;
		content: ' ';
	}

	&:before {
		margin-bottom: 2px;
	}
`

export const ListTableAction = styled.button`
	${Reset('button')}
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 0.5rem;
	font-weight: 600;
	font-family: ${(props) => props.theme.font.secondary};

	${IconSquare} {
		margin-right: 1rem;
	}

	${FieldSelectWrapper} {
		min-width: 15rem;
		max-width: 100%;
	}

	&:hover {
		${IconSquare} {
			&:before {
				background-color: ${(props) => props.theme.color.primary};
			}
			svg {
				fill: ${(props) => props.theme.color.inverted};
			}
		}
	}
`

export const ListTableWrapper = styled.div`
	position: relative;

	border: 1px solid ${(props) => props.theme.color.line};
	border-radius: ${(props) => props.theme.radius.md};
	overflow: hidden;

	${ListTableRow} {
		${(props) =>
			!props.hasFooter &&
			css`
				&:not(:last-child) {
					border-bottom: 1px solid ${(props) => props.theme.color.line};
				}
			`};
		${(props) =>
			props.hasFooter &&
			css`
				border-bottom: 1px solid ${(props) => props.theme.color.line};
			`};
	}
`
