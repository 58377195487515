import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'

import LazyImage from '@/components/LazyImage'
import View from '@/layout/View'

import { AuthBackground, AuthBody, AuthContent, AuthLogo } from './AuthLayout.styles'

const AuthLayout = ({ children, variant = 'login', theme = 'light' }) => {
	return (
		<View fullwidth>
			<AuthBody>
				<AuthBackground scheme={theme}>
					<LazyImage src={`/assets/img/bg-${variant}.jpg`} alt="" />
				</AuthBackground>
				<AuthContent>
					<AuthLogo scheme={theme}>
						<Link to="/login">
							<SVG src={'/assets/img/logo-full.svg'}></SVG>
						</Link>
					</AuthLogo>
					{children}
				</AuthContent>
			</AuthBody>
		</View>
	)
}

AuthLayout.propTypes = {
	children: PropTypes.node,
	theme: PropTypes.oneOf(['light', 'dark']),
	variant: PropTypes.oneOf(['login', 'signup', 'password', 'join-team']),
}

export default AuthLayout
