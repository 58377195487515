import PropTypes from 'prop-types'

import { FormSectionHeader, FormSectionWrapper } from './FormSection.styles'

const FormSection = ({ title, description, children }) => {
	return (
		<FormSectionWrapper>
			<FormSectionHeader>
				{title && <h4>{title}</h4>}
				{description && <p>{description}</p>}
			</FormSectionHeader>
			{children}
		</FormSectionWrapper>
	)
}

FormSection.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node,
}

export default FormSection
