import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useCurrentAccount } from '@/modules/session/auth.store'
import { useFeatureFlags } from '@/modules/session/global.hook'
import { FeatureType } from '@/api/videobot.schemas'
import media from '@/lib/media'
import { Cover, Font, Reset, TransitionPrimary } from '@/lib/mixins'

const ShareVideobotWrapper = styled.div`
	display: flex;
	justify-content: center;
	grid-auto-flow: column;
	align-items: center;

	background: #fff;
	height: 56px;
	flex-shrink: 0;

	border-bottom: 1px solid ${(props) => props.theme.color.line};

	${media('<=lg')} {
		margin-top: calc(
			${(props) => props.theme.size.topBar.mobile} +
				${(props) => props.theme.size.tabsBar.mobile}
		);
	}
`

const ShareVideobotTabsRow = styled.ul`
	${Reset('list')}
	display: flex;
	flex-direction: row;

	padding: 4.5px;
	height: 42px;

	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.color.light};
	background-color: ${(props) => props.theme.color.light};

	align-items: center;
`

const ShareVideobotTab = styled.li`
	& a {
		position: relative;
		${Reset('link')}
		height: 32px;
		${Font('1rem', 'medium')}

		display: flex;
		padding: 0 16px;

		border-radius: 5px;
		justify-content: center;
		align-items: center;

		font-weight: 600;
		font-family: ${(props) => props.theme.font.secondary};

		${media('<=sm')} {
			font-size: 0.9rem;
		}

		span {
			position: relative;
		}

		&:before {
			${Cover()}
			${TransitionPrimary()}
			border-radius: 6px;
			background-color: ${(props) => props.theme.color.body};
			opacity: 0;
			transform: scale(0.6);
		}
	}

	${(props) =>
		props.active &&
		css`
			a {
				&:before {
					opacity: 1;
					transform: scale(1);
				}
			}
		`};
`

export const ShareVideobotTabs = () => {
	const { id } = useParams()
	const [t] = useTranslation(['videobot'])
	const location = useLocation()
	const currentAccount = useCurrentAccount()
	const { data: featureFlags } = useFeatureFlags({ account_id: currentAccount?.id })
	const isVideobotAdsEnabled = featureFlags?.includes(`dashboard:${FeatureType.videobot_as_ads}`)
	const tabs = [
		{
			path: `/dashboard/videobots/${id}/share`,
			name: t('videobot:sharePage.widget'),
		},
		{
			path: `/dashboard/videobots/${id}/share/iframe`,
			name: t('videobot:sharePage.iframe'),
		},
		{
			path: `/dashboard/videobots/${id}/share/link`,
			name: t('videobot:sharePage.link'),
		},
		{
			path: `/dashboard/videobots/${id}/share/display-ads`,
			name: t('videobot:sharePage.displayAds'),
		},
	]

	const filteredTabs = tabs.filter((tab) => {
		if (tab.path.includes('display-ads')) {
			return isVideobotAdsEnabled
		}
		return true
	})

	return (
		<ShareVideobotWrapper>
			<ShareVideobotTabsRow>
				{filteredTabs.map((tab) => (
					<ShareVideobotTab key={tab.name} active={tab.path === location.pathname}>
						<Link to={tab.path}>
							<span>{tab.name}</span>
						</Link>
					</ShareVideobotTab>
				))}
			</ShareVideobotTabsRow>
		</ShareVideobotWrapper>
	)
}
