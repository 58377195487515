import styled from 'styled-components'

import media from '../../lib/media'
import { FormFieldWrapper } from '../FormField/FormField.styles'

export const FormGroupWrapper = styled.div`
	position: relative;
	border-top: 1px solid ${(props) => props.theme.color.line};
	padding-top: 1.25rem;

	&:last-child {
		border-bottom: 1px solid ${(props) => props.theme.color.line};
		margin-bottom: 1.25rem;
	}

	${FormFieldWrapper} {
		&:last-child {
			padding-bottom: 0;
		}
	}
`

export const FormGroupHeader = styled.div`
	margin-bottom: 1rem;
	display: flex;
	align-items: flex-start;

	h6 {
		font-size: 0.95rem;
		margin-bottom: 0;
		font-weight: 600;
		color: ${(props) => props.theme.color.global};
		opacity: 1;
	}

	p {
		margin-bottom: 0;
		margin-top: 0.25rem;
		font-size: 0.95rem;
		color: ${(props) => props.theme.color.muted};
	}
`

export const FormGroupHeaderMain = styled.div`
	flex: 1 1 auto;
	padding-right: 1.5rem;
`

export const FormGroupHeaderUtils = styled.div`
	margin-left: auto;
`

export const FormGroupContent = styled.div`
	flex: 1 1 auto;
	margin-bottom: 1.25rem;
	${media('<=md')} {
		margin-bottom: 1rem;
	}
`
