import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import DataTable from '@/components/DataTable'
import { FeatureFlagEditModal } from '@/features/FeatureFlag/feature_flag_edit_modal'
import More, { MoreButton } from '@/components/More'
import InfoIcon from '@/components/InfoIcon'

export const FeatureFlagsList = ({ featureFlagsList, accountNameMap, onUpdateFlag }) => {
	const { t } = useTranslation('feature')

	const [selectedFlag, setSelectedFlag] = useState(null)

	const handleEditClick = (row) => {
		setSelectedFlag(row)
	}

	const handleCloseModal = () => {
		setSelectedFlag(null)
	}

	const columns = [
		{
			id: 'name',
			width: '15rem',
			name: t('name'),
			sortable: true,
			selector: (row) => row.name,
			cell: (row) => (
				<React.Fragment>
					{row.name}
					{row.description && (
						<span style={{ marginLeft: '3px', marginBottom: '3px' }}>
							<InfoIcon>{row.description}</InfoIcon>{' '}
						</span>
					)}
				</React.Fragment>
			),
		},
		{
			id: 'flag_type',
			width: '15rem',
			name: t('flagType'),
			selector: (row) => row.flag_type,
			sortable: true,
		},
		{
			id: 'is_everyone',
			width: '15rem',
			name: t('isEveryone'),
			selector: (row) => (row.is_everyone === null ? 'N/A' : row.is_everyone.toString()),
			sortable: true,
		},
		{
			id: 'is_superadmin',
			width: '15rem',
			name: t('isSuperadmin'),
			selector: (row) => (row.is_superadmin === null ? 'N/A' : row.is_superadmin.toString()),
			sortable: true,
		},
		{
			id: 'account_ids',
			width: '15rem',
			name: t('accountNames'),
			selector: (row) => row.account_ids.map((id) => accountNameMap[id] || id).join(', '),
			sortable: true,
		},
		{
			id: 'user_ids',
			width: '15rem',
			name: t('userIds'),
			selector: (row) => row.user_ids.join(', '),
			sortable: true,
		},
		{
			id: 'languages',
			width: '15rem',
			name: t('languages'),
			selector: (row) => row.languages.join(', '),
			sortable: true,
		},
		{
			width: '2.75rem',
			center: true,
			button: true,
			cell: (row) => {
				return (
					<More>
						<MoreButton onClick={() => handleEditClick(row)}>
							{t('common:edit')}
						</MoreButton>
					</More>
				)
			},
		},
	]

	return (
		<React.Fragment>
			<DataTable columns={columns} data={featureFlagsList} />
			{selectedFlag && (
				<AnimatePresence>
					<FeatureFlagEditModal
						flag={selectedFlag}
						onClose={handleCloseModal}
						onUpdateFlag={onUpdateFlag}
					/>
				</AnimatePresence>
			)}
		</React.Fragment>
	)
}

FeatureFlagsList.propTypes = {
	featureFlagsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	accountNameMap: PropTypes.object.isRequired,
	onUpdateFlag: PropTypes.func.isRequired,
}
