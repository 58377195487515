import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import Loader from '@/components/Loader'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import Error from '@/layout/Error'
import PropTypes from '@/lib/propTypes'
import {
	getReadAccountDetailByIdQueryKey,
	getReadCurrentUserAccountsQueryKey,
	useReadAccountDetailById,
	useReadCurrentUserAccounts,
	useUpdateAccountById,
} from '@/api/videobot'
import { useAuth } from '@/modules/session/auth.store'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'

import Button from '../../components/Button'
import AccountProfileForm from '../../features/Account/components/AccountProfileForm'
import View from '../../layout/View'

const SingleAccountActions = ({ handleLogInToAccount }) => {
	const { t } = useTranslation(['account'])
	return (
		useMediaQuery('<=lg') && (
			<Button variant="secondary" onClick={() => handleLogInToAccount()}>
				{t('account:loginToAccount')}
			</Button>
		)
	)
}

SingleAccountActions.propTypes = {
	handleLogInToAccount: PropTypes.func,
}

const SingleAccountTopBar = ({ handleFormSubmit, handleLogInToAccount }) => {
	const { t } = useTranslation(['account'])
	return (
		<React.Fragment>
			{useMediaQuery('>lg') && (
				<Button variant="secondary" onClick={() => handleLogInToAccount()}>
					{t('account:loginToAccount')}
				</Button>
			)}
			<Button onClick={handleFormSubmit} variant={useMediaQuery('<=lg') ? 'link' : 'primary'}>
				{useMediaQuery('<=lg') ? t('save') : t('saveChanges')}
			</Button>
		</React.Fragment>
	)
}

SingleAccountTopBar.propTypes = {
	handleFormSubmit: PropTypes.func,
	handleLogInToAccount: PropTypes.func,
}

const SingleAccountContents = () => {
	const { t } = useTranslation(['account', 'errors'])
	const { id } = useParams()
	const { setActiveAccount } = useAuth()
	const { data: accounts } = useReadCurrentUserAccounts()
	const [showActions, setShowActions] = useState(false)
	const { search: urlQuery } = useLocation()
	const formRef = useRef(null)
	const navigate = useNavigate()
	const {
		data: account,
		isLoading: isAccountLoading,
		isError: isAccountError,
	} = useReadAccountDetailById(id)
	const queryClient = useQueryClient()
	const updateAccount = useUpdateAccountById({
		mutation: {
			onSuccess: async () => {
				toast.success(t('account:accountUpdated'))
				queryClient.invalidateQueries(getReadAccountDetailByIdQueryKey(id))
				queryClient.invalidateQueries(getReadCurrentUserAccountsQueryKey())
			},
			onError: async () => {
				toast.error(t('errors:errorOccured'))
			},
		},
	})

	const handleLogInToAccount = () => {
		if (account) {
			setActiveAccount(accounts.find((o) => o.id === account.id)?.id)
			navigate('/dashboard')
		}
	}

	useEffect(() => {
		if (account) {
			setShowActions(true)
		}
	}, [account])

	if (!accounts) {
		return <Loader cover isLoading={updateAccount?.isLoading} />
	}

	return (
		<Fragment>
			{account && (
				<View
					header
					title={t('account:singleAccount.title')}
					caption={t('account:singleAccount.description')}
					shortTitle={account && account.businessName}
					actions={
						showActions && (
							<SingleAccountActions handleLogInToAccount={handleLogInToAccount} />
						)
					}
					topBarActions={
						showActions && (
							<SingleAccountTopBar
								handleLogInToAccount={handleLogInToAccount}
								handleFormSubmit={() => formRef?.current?.submit()}
							/>
						)
					}
					showTopBar
					isLoading={updateAccount?.isLoading}
					showTopBarOnDesktop
					hide={!account}
					cover
					backRoute={`/dashboard/accounts${urlQuery}`}
				>
					<AccountProfileForm
						ref={formRef}
						account={account}
						onSubmit={(data) => {
							updateAccount.mutate({ accountId: id, data })
						}}
						variant="UPDATE"
						isLoading={isAccountLoading}
					/>
				</View>
			)}
			{isAccountLoading && <Loader cover isLoading={updateAccount?.isLoading} />}
			{isAccountError && <Error background="body" />}
		</Fragment>
	)
}

export const SingleAccount = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin, RoleType.Reseller]}>
			<SingleAccountContents />
		</RoleCheck>
	)
}
