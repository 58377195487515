import { forwardRef, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import PropTypes from '@/lib/propTypes'

import {
	FieldCheckboxIndicator,
	FieldCheckboxInput,
	FieldCheckboxWrapper,
} from './FieldCheckbox.styles'

const FieldCheckboxSimpleContents = (props, ref) => {
	const { error, name, value, checked, onClick } = props

	const wrapperRef = useRef(null)

	const id = uuidv4()

	const handleChange = () => {}

	const handleIndicatorClick = () => {
		wrapperRef.current.querySelector('input').click()
	}

	return (
		<FieldCheckboxWrapper ref={wrapperRef} error={error} selected={checked}>
			<FieldCheckboxInput
				ref={ref}
				value={value}
				id={id}
				name={name}
				type="checkbox"
				onChange={handleChange}
				onClick={onClick}
				{...props}
			/>
			<FieldCheckboxIndicator onClick={handleIndicatorClick}>
				<svg x="0" y="0" viewBox="0 0 32 32">
					<path fill="none" strokeLinecap="round" d="M6,16l7,8L27,8" />
				</svg>
			</FieldCheckboxIndicator>
		</FieldCheckboxWrapper>
	)
}

const FieldCheckboxSimple = forwardRef(FieldCheckboxSimpleContents)
FieldCheckboxSimple.displayName = 'FieldCheckboxSimple'

FieldCheckboxSimple.propTypes = {
	error: PropTypes.bool,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	children: PropTypes.node,
	defaultChecked: PropTypes.bool,
	required: PropTypes.bool,
	checked: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	onClick: PropTypes.func,
}

export default FieldCheckboxSimple
