import { useRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router'
import { Outlet } from 'react-router-dom'

import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { useCurrentAccount } from '@/modules/session/auth.store'
import { AccountSettingsContext } from '@/pages/Account/account_settings.context'

import Button from '../../components/Button'
import View from '../../layout/View'

const AccountSettingsContents = () => {
	const { t } = useTranslation(['common', 'account', 'user', 'errors'])
	const activeAccount = useCurrentAccount()

	const formRef = useRef(null)
	const [isFormSubmitting, setIsFormSubmitting] = useState(false)

	const contextValue = useMemo(
		() => ({
			formRef,
			setIsFormSubmitting,
		}),
		[formRef, setIsFormSubmitting],
	)

	const tabs = useMemo(() => {
		if (!activeAccount) {
			return []
		}

		if (
			[RoleType.Super_Admin, RoleType.Reseller, RoleType.Owner].includes(activeAccount.role)
		) {
			return [
				{ path: 'profile', name: t('profile') },
				{ path: 'account', name: t('account') },
				{ path: 'integrations', name: t('integrations') },
			]
		}

		return [{ path: 'profile', name: t('profile') }]
	}, [t, activeAccount])

	if (!activeAccount) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<AccountSettingsContext.Provider value={contextValue}>
			<View
				headerTabs
				title={t('settings')}
				shortTitle={t('settings')}
				actions={
					<Button onClick={() => formRef?.current?.submit()} isLoading={isFormSubmitting}>
						{t('saveChanges')}
					</Button>
				}
				hideHeaderOnMobile
				showTopBar
				topBarActions={
					<Button
						onClick={() => formRef?.current?.submit()}
						variant="link"
						isLoading={isFormSubmitting}
					>
						{t('save')}
					</Button>
				}
				tabs={tabs}
			>
				<Outlet />
			</View>
		</AccountSettingsContext.Provider>
	)
}

const AccountSettings = () => {
	return (
		<RoleCheck
			roles={[RoleType.Super_Admin, RoleType.Reseller, RoleType.Owner, RoleType.Admin]}
		>
			<AccountSettingsContents />
		</RoleCheck>
	)
}

export default AccountSettings
