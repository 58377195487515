import { normalize } from 'polished'
import styled, { createGlobalStyle } from 'styled-components'

import media from '../lib/media'
import { Reset } from '../lib/mixins'
import theme from '../lib/theme'

const Style = createGlobalStyle`
  ${normalize()}

  @page { size: auto;  margin: 0mm; }

  html {
	  font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.35;
    font-family: ${theme.font.primary};
    color: ${theme.color.global};
    font-weight: 300;


    @media print {
      font-size: 10px;
    }
  }

  .rs-picker-menu.rs-anim-in {
    z-index: 2000;
}

.rs-picker-toggle-placeholder {
    font-size: 1rem;
    padding-left: 0.2rem;
    color: rgba(0, 3, 41, 0.55) !important;
}

  *::selection {
    background-color: rgba(53,29,218,0.2);
  }

  #popups {
    position: fixed;
    z-index: 1000;
  }

  #tooltips {
    position: fixed;
    z-index: 1100;
  }

  body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    font-size: 1rem;
    background-color: transparent;
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-weight: 800;
    line-height: 1.1;
    font-family: ${theme.font.secondary};
  }

  h1 {
    font-size: 1.7rem;

    ${media('<=md')} {
      font-size: 1.75rem;
    }
    ${media('<=sm')} {
      font-size: 1.65rem;
    }
  }

  h2 {
    font-size: 1.5rem;

    ${media('<=md')} {
      font-size: 1.4rem;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 1.5em;

    ${media('<=md')} {
      font-size: 1.2rem;
    }
  }

  h4 {
    font-size: 1.2rem;

    ${media('<=md')} {
      font-size: 1.1rem;
    }
  }

  h5 {
    font-size: 0.95rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.75rem;
    opacity: 0.5;
    font-weight: 600;
    font-family: ${theme.font.primary};
    margin-bottom: 0.75em;
  }

  a {
    ${Reset('link')}
    color: ${theme.color.primary};

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 1.5;

    strong {
      font-weight: 700;
    }
  }

  dl {
    font-size: 1em;
  }

  .text-sm {
    font-size: 0.85rem;
  }

  .text-muted {
    color: ${theme.color.muted};
  }

  .text-centered {
    display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: -1.3rem;
  }

  :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #000329;
    --toastify-toast-min-height: 1rem;
    --toastify-color-success:  ${theme.color.green};
    --toastify-color-success-light:  ${theme.color.greenLight};
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: ${theme.color.red};
    --toastify-color-error-light: ${theme.color.redLight};
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  }

  //Velaris styles

  @media (max-width: 768px) {
    #widget-velaris_survey {
      display: none;
    }
  }

  // Toastify styles

  .Toastify__toast {
    align-items: flex-start;
    text-align: left;
    border-radius: 0.5rem;
    font-weight: 400;
    padding: 0.75rem;
    box-shadow: none;
    border: 1px solid transparent;

    ${media('<=xs')} {
      border-radius: 0;
      min-height: ${theme.size.mobileNavBar};
    }
  }

  .Toastify__toast-body {
    font-size: 0.95rem;
    width: 100%;
    padding: 0;
    margin-right: 0.25rem;
    align-items: flex-start;
    padding-bottom: 0.15rem;
  }

  .Toastify__toast-icon {
    margin-top: 0.1rem;
    svg {
      fill: currentColor;
    }
  }

  .Toastify__progress-bar {
    bottom: 3px;
    left: 6px;
    width: calc(100% - 12px);
    height: 1px;
    border-radius: 0;
  }

  .Toastify__toast-container--bottom-left {
    bottom: 0;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    color: var(--toastify-color-success);
    background: var(--toastify-color-success-light);
    border-color: rgba(10,181,0,0.30);

    .Toastify__close-button {
      color: var(--toastify-color-success);
    }
    .Toastify__progress-bar {
      background-color: rgba(10,181,0,0.35);

    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: var(--toastify-color-error);
    background: var(--toastify-color-error-light);
    border-color: rgba(240,36,42,0.35);

    .Toastify__close-button {
      color: var(--toastify-color-error);
    }
    .Toastify__progress-bar {
      background-color: rgba(240,36,42,0.35);

    }
  }

  .Toastify__close-button--light {
    display: none;
  }


`

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
`

export default Style
