import { createContext, useReducer, type PropsWithChildren, useContext } from 'react'
import { Authenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'

Auth.configure({
	region: import.meta.env.VITE_AWS_REGION,
	userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
	userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
	authenticationFlowType: 'USER_SRP_AUTH',
	oauth: {
		domain: import.meta.env.VITE_AWS_COGNITO_DOMAIN,
		scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: `${import.meta.env.VITE_BASE_URL}/oauth-check`,
		redirectSignOut: `${import.meta.env.VITE_BASE_URL}/login`,
		responseType: 'code',
	},
})

const CognitoContext = createContext<() => void>(() => undefined)

export const Cognito = ({ children }: PropsWithChildren) => {
	const [key, reAuth] = useReducer(() => Date.now(), Date.now())

	return (
		<CognitoContext.Provider value={reAuth}>
			<Authenticator.Provider key={key}>{children}</Authenticator.Provider>
		</CognitoContext.Provider>
	)
}

export const useReAuth = () => {
	const reAuth = useContext(CognitoContext)
	return reAuth
}
