import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'
import { padStart } from 'lodash-es'

import Icon from '@/components/Icon'
import InfoIcon from '@/components/InfoIcon'
import { AnalyticsFieldType, EventChannelType } from '@/api/videobot.schemas'
import { usePowerUserMode } from '@/modules/session/auth.store'

import {
	IconContainer,
	Metric,
	MetricTitle,
	SummaryItem,
	SummaryWrapper,
} from './AnalyticsSummary.styles'

const formatDurationToHours = (durationSeconds) => {
	const hours = Math.floor(durationSeconds / 3600)
	const minutes = Math.floor((durationSeconds % 3600) / 60)
	const seconds = Math.floor((durationSeconds % 3600) % 60)

	return `${padStart(hours, 2, '0')}:${padStart(minutes, 2, '0')}:${padStart(seconds, 2, '0')}`
}

const fieldEnabled = (analyticsDisplayFields, fieldName) => {
	// FIXME: check field properly, which should be enabled by default
	return (
		analyticsDisplayFields[fieldName] === true ||
		(!(fieldName in analyticsDisplayFields) &&
			fieldName !== AnalyticsFieldType.watermark_clicked)
	)
}

export const AnalyticsSummary = ({
	data,
	report,
	channel,
	analyticsDisplayFields,
	hasSessions,
	newCtrFormula,
}) => {
	const { t } = useTranslation(['analytics'])
	const {
		contacted,
		cta,
		watched,
		directIntroViewed,
		iframeIntroViewed,
		iframeViewed,
		widgetIntroViewed,
		sliderIntroViewed,
		widgetViewed,
		session,
		watermarkClicked,
		introActions,
		sliderPlayed,
		sliderSession,
		liked,
	} = data
	const duration = report ? 0 : 1
	const numberUpDelay = report ? 0 : 1
	const ctrDirect = (directIntroViewed / (hasSessions ? session : iframeViewed)) * 100 || 0
	const ctrIframe = (iframeIntroViewed / (hasSessions ? session : iframeViewed)) * 100 || 0
	const ctrWidget = (widgetIntroViewed / (hasSessions ? session : widgetViewed)) * 100 || 0
	const ctrSlider = (sliderPlayed / (sliderSession || 1)) * 100 || 0
	const engagingDirect = (introActions / directIntroViewed) * 100 || 0
	const engagingIframe = (introActions / iframeIntroViewed) * 100 || 0
	const engagingWidget = (introActions / widgetIntroViewed) * 100 || 0
	const { powerUserMode } = usePowerUserMode()

	const isSliderAnalytics = channel === EventChannelType.slider

	let opened = 0
	let loaded = 0
	let ctr = 0
	let engaging = 0
	let sliderOpened = 0

	if (channel === EventChannelType.direct) {
		opened = directIntroViewed
	}
	if (channel === EventChannelType.iframe) {
		opened = iframeIntroViewed
	}
	if (channel === EventChannelType.widget) {
		opened = widgetIntroViewed
	}

	if (channel === EventChannelType.direct) {
		loaded = iframeViewed
	}
	if (channel === EventChannelType.iframe) {
		loaded = iframeViewed
	}
	if (channel === EventChannelType.widget) {
		loaded = widgetViewed
	}

	if (channel === EventChannelType.direct) {
		ctr = ctrDirect
	}
	if (channel === EventChannelType.iframe) {
		ctr = ctrIframe
	}
	if (channel === EventChannelType.widget) {
		ctr = ctrWidget
	}

	if (channel === EventChannelType.direct) {
		engaging = engagingDirect
	}
	if (channel === EventChannelType.iframe) {
		engaging = engagingIframe
	}
	if (channel === EventChannelType.widget) {
		engaging = engagingWidget
	}

	// Slider analytics
	if (isSliderAnalytics) {
		sliderOpened = sliderPlayed
		opened = sliderIntroViewed
	}

	// New CTR/Engagement formula, since 04-09-2024
	if (newCtrFormula) {
		opened = data.opened
		engaging = (data.engaged / data.opened) * 100 || 0

		if (isSliderAnalytics) {
			ctr = ctrSlider
		} else {
			ctr = (opened / session) * 100 || 0
		}
	}

	const totalSession = isSliderAnalytics ? sliderSession : session

	return (
		<SummaryWrapper>
			{hasSessions && (
				<SummaryItem highlighted>
					<MetricTitle>
						<IconContainer color="primary">
							<Icon name="external-link" />
						</IconContainer>
						{t('session')}
						<InfoIcon>{t('analytics:sessionDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={totalSession}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			{!hasSessions && (
				<SummaryItem highlighted>
					<MetricTitle>
						<IconContainer color="primary">
							<Icon name="loaded" />
						</IconContainer>
						{t('timesLoaded')}
						<InfoIcon>{t('analytics:timesLoadedDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={loaded}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			{powerUserMode && (
				<SummaryItem highlighted>
					<MetricTitle>
						<IconContainer color="primary">
							<Icon name="click" />
						</IconContainer>
						{t('watermark')}
						<InfoIcon>{t('analytics:watermarkDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={watermarkClicked}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			{isSliderAnalytics && (
				<SummaryItem highlighted>
					<MetricTitle>
						<IconContainer color="primary">
							<Icon name="external-link" />
						</IconContainer>
						{t('sliderTimesOpened')}
						<InfoIcon>{t('analytics:sliderTimesOpenedDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={sliderOpened}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			<SummaryItem highlighted>
				<MetricTitle>
					<IconContainer color="purpleDark">
						<Icon name="click" />
					</IconContainer>
					{t(isSliderAnalytics ? 'timesPlayed' : 'timesOpened')}
					<InfoIcon>
						{t(
							isSliderAnalytics
								? 'analytics:timesPlayedDescription'
								: 'analytics:timesOpenedDescription',
						)}
					</InfoIcon>
				</MetricTitle>
				<Metric>
					<CountUp end={opened} separator="," duration={duration} delay={numberUpDelay} />
				</Metric>
			</SummaryItem>
			<SummaryItem highlighted>
				<MetricTitle>
					<IconContainer>
						<Icon name="ctr" />
					</IconContainer>
					{t('ctr')}
					<InfoIcon>
						{t(
							isSliderAnalytics
								? 'analytics:sliderCtrDescription'
								: 'analytics:ctrDescription',
						)}
					</InfoIcon>
				</MetricTitle>
				<Metric>
					<CountUp
						end={ctr}
						separator=","
						decimals={1}
						duration={duration}
						delay={numberUpDelay}
						suffix="%"
					/>
				</Metric>
			</SummaryItem>
			{!isSliderAnalytics && (
				<SummaryItem>
					<MetricTitle>
						<IconContainer>
							<Icon name="star" />
						</IconContainer>
						{t('engaging')}
						<InfoIcon>{t('analytics:engagingDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={engaging}
							separator=","
							decimals={1}
							duration={duration}
							delay={numberUpDelay}
							suffix="%"
						/>
					</Metric>
				</SummaryItem>
			)}
			<SummaryItem>
				<MetricTitle>
					<IconContainer>
						<Icon name="time" />
					</IconContainer>
					{t('minutesViewed')}
					<InfoIcon>{t('analytics:minutesViewedDescription')}</InfoIcon>
				</MetricTitle>
				<Metric>{formatDurationToHours(watched) || '00:00:00'}</Metric>
			</SummaryItem>
			{fieldEnabled(analyticsDisplayFields, AnalyticsFieldType.cta) && (
				<SummaryItem>
					<MetricTitle>
						<IconContainer>
							<Icon name="star" />
						</IconContainer>
						{t('ctaClicks')}
						<InfoIcon>{t('analytics:ctaClicksDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={cta}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			{fieldEnabled(analyticsDisplayFields, AnalyticsFieldType.email) && (
				<SummaryItem>
					<MetricTitle>
						<IconContainer>
							<Icon name="mail" />
						</IconContainer>
						{t('newContacts')}
						<InfoIcon>{t('analytics:newContactsDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={contacted}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
			{powerUserMode && (
				<SummaryItem>
					<MetricTitle>
						<IconContainer>
							<Icon name="like" />
						</IconContainer>
						{t('likes')}
						<InfoIcon>{t('analytics:likesDescription')}</InfoIcon>
					</MetricTitle>
					<Metric>
						<CountUp
							end={liked}
							separator=","
							duration={duration}
							delay={numberUpDelay}
						/>
					</Metric>
				</SummaryItem>
			)}
		</SummaryWrapper>
	)
}
