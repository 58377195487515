import styled from 'styled-components'

import { IconSquare } from '@/components/Icon/Icon.styles'

export const MediaThumbnail = styled.div`
	min-width: ${(props) => props.theme.size.thumbnail.desktop};
	width: ${(props) => props.theme.size.thumbnail.desktop};
	height: ${(props) => props.theme.size.thumbnail.desktop};
	border-radius: ${(props) => props.theme.radius.sm};
	background-color: ${(props) => props.theme.color.light};

	img {
		width: 100%;
		height: 100%;
		border-radius: ${(props) => props.theme.radius.sm};
		object-fit: cover;
		display: block;
	}
`

export const MediaListWrapper = styled.div`
	.rdt_TableCell {
		${MediaThumbnail},
		${IconSquare} {
			margin: 0.5rem 0.5rem 0.5rem 0;
		}
	}
`
