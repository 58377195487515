import styled, { css, keyframes } from 'styled-components'

import { Cover, LinkUnderlineOnHover, Reset, TransitionPrimary } from '../../lib/mixins'
import { BadgeWrapper } from '../Badge/Badge.styles'
import { FieldCheckboxIndicator, FieldCheckboxWrapper } from '../FieldCheckbox/FieldCheckbox.styles'
import { FieldRadioWrapper } from '../FieldRadio/FieldRadio.styles'
import { IconSquare } from '../Icon/Icon.styles'
import { InfoIconWrapper } from '../InfoIcon/InfoIcon.styles'
import { MoreWrapper } from '../More/More.styles'
import { ThumbnailWrapper } from '../Thumbnail/Thumbnail.styles'

export const DataTableWrapper = styled.div`
	position: relative;

	${(props) =>
		props.pagination &&
		css`
			padding-bottom: 4rem;
		`};
`

export const DataTableBody = styled.div`
	position: relative;
	border: 1px solid ${(props) => props.theme.color.line};
	border-radius: ${(props) => props.theme.radius.md};

	@media print {
		> div > div {
			display: block;
		}
	}

	.rdt_Table {
		${FieldCheckboxWrapper} {
			margin-top: 0;
			margin-bottom: 0;
		}
		${FieldCheckboxIndicator} {
			margin-right: 0;
		}
	}

	.rdt_TableHead {
		font-size: 0.8rem;

		.rdt_TableHeadRow {
			min-height: 36px;
			.rdt_TableCol {
				font-weight: 600;
				color: ${(props) => props.theme.color.mutedLight};
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				&:first-child {
					padding-left: 1rem;
				}

				.__rdt_custom_sort_icon__ {
					margin-left: 0.5rem;
					svg {
						position: relative;
						top: 0.1em;
						width: 0.6rem;
						fill: ${(props) => props.theme.color.mutedLight};
					}
				}

				.rdt_TableCol_Sortable {
					&:hover {
						.__rdt_custom_sort_icon__ {
							svg {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	.rdt_TableBody {
		.rdt_TableRow {
			position: relative;
			font-size: 0.9rem;
			${TransitionPrimary('background')}
			min-height: 4rem;

			&.selected {
				background-color: ${(props) => props.theme.color.purpleTransparent};

				&:hover {
					background-color: ${(props) => props.theme.color.purpleTransparent};
				}
			}

			.rdt_TableCell {
				white-space: nowrap;
				padding-left: 0.5rem;
				padding-right: 0.5rem;

				${ThumbnailWrapper} {
					margin-top: 0.5rem;
					margin-bottom: 0.5rem;
					pointer-events: none;
				}
				&:first-child {
					padding-left: 0.75rem;

					${IconSquare},
					${ThumbnailWrapper} {
						margin-left: -0.25rem;
					}
				}
				&:nth-child(2) {
					${MoreWrapper} {
						margin-left: -0.3rem;
					}
				}

				${BadgeWrapper},
				${InfoIconWrapper} {
					&:not(:first-child) {
						margin-left: 0.5rem;
					}
				}

				> a {
					color: inherit;
					${LinkUnderlineOnHover()}
				}

				> span,
				> a,
				> div {
					&:not(${FieldRadioWrapper}),
					&:not(${MoreWrapper}) {
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				div${MoreWrapper} {
					overflow: visible;
				}
			}

			&:hover {
				background-color: ${(props) => props.theme.color.light};
			}
		}
	}

	${(props) =>
		props.selectable &&
		css`
			.rdt_TableCol,
			.rdt_TableCell {
				&:nth-child(2) {
					padding-left: 0.15rem;
				}
			}
		`};
`

export const DataTablePaginationWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	user-select: none;
`

export const DataTablePaginationArrow = styled.button`
	${Reset('button')}
	width: ${(props) => props.theme.control.sm};
	height: ${(props) => props.theme.control.sm};
	display: inline-flex;
	align-items: center;
	justify-content: center;

	svg {
		fill: ${(props) => props.theme.color.mutedLight};
		display: block;
		height: 0.75rem;
		width: 0.75rem;
	}

	${(props) =>
		props.disabled &&
		css`
			opacity: 0.35;
			cursor: not-allowed;
		`};
	${(props) =>
		!props.disabled &&
		css`
			&:hover {
				svg {
					fill: ${(props) => props.theme.color.global};
				}
			}
		`}
`

export const DataTablePaginationButton = styled.button`
	position: relative;
	${Reset('button')}
	width: ${(props) => props.theme.control.sm};
	height: ${(props) => props.theme.control.sm};
	color: ${(props) => props.theme.color.mutedLight};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	margin: 0 0.1rem;
	border-radius: ${(props) => props.theme.radius.sm};
	${TransitionPrimary('color')}
	font-size: 0.9rem;
	&:before {
		${Cover()}
		background-color: ${(props) => props.theme.color.global};
		${TransitionPrimary('transform,scale,opacity')}
		opacity: 0;
		visibility: hidden;
		transform: scale(0.9);
		border-radius: ${(props) => props.theme.radius.sm};
	}
	${(props) =>
		!props.active &&
		css`
			&:hover {
				color: ${(props) => props.theme.color.global};
			}
		`}

	> span {
		position: relative;
	}

	${(props) =>
		props.active &&
		css`
			color: ${(props) => props.theme.color.inverted};
			&:before {
				visibility: visible;
				transform: scale(1);
				opacity: 1;
			}
		`};
`

export const DataTablePaginationCounter = styled.div`
	margin-right: 1rem;
	font-size: 0.9rem;
`

export const DataTablePaginationControls = styled.div`
	display: flex;
	margin-left: auto;
`

const searchAnimation = keyframes`
  0% { transform: translateX(-25%); }
  50% { transform: translateX(25%); }
  100% { transform: translateX(-25%); }
`

export const DataTableEmptyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem;
	align-items: center;

	svg {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		margin-bottom: 1.5rem;
		fill: ${(props) => props.theme.color.line};
		animation: ${searchAnimation} 2s infinite;
	}
`
