import { atomWithStorage } from 'jotai/utils'

export const adminAccountsFilter = atomWithStorage('admin_accounts', '')

export const viewFeatureAccountsFilter = atomWithStorage('view_feature_accounts', [])
export const createFeatureAccountsFilter = atomWithStorage('create_feature_accounts', [])
export const editFeatureAccountsFilter = atomWithStorage('edit_feature_accounts', [])
export const viewFeatureUsersFilter = atomWithStorage('view_feature_users', [])
export const createFeatureUsersFilter = atomWithStorage('create_feature_users', [])
export const editFeatureUsersFilter = atomWithStorage('edit_feature_users', [])
