import styled, { css } from 'styled-components'

import media from '../../lib/media'
import { ButtonWrapper } from '../Button/Button.styles'
import { FieldTextWrapper } from '../FieldText/FieldText.styles'
import { InfoIconWrapper } from '../InfoIcon/InfoIcon.styles'

export const FormFieldWrapper = styled.div`
	position: relative;
	display: block;

	> label {
		font-size: 0.9rem;
		font-weight: 600;
		margin-bottom: 0.25rem;
		display: flex;
		color: ${(props) => props.theme.color.muted};

		${media('<=md')} {
			font-size: 0.8rem;
		}
		${InfoIconWrapper} {
			margin-left: 0.25rem;
		}
	}

	${(props) =>
		!props.noMargin &&
		css`
			${media('>md')} {
				padding-bottom: 1.25rem;
			}

			${media('<=md')} {
				padding-bottom: 1rem;
			}
		`};

	/* + ${ButtonWrapper} {
    margin-top: 1rem;
  } */

	${FieldTextWrapper} {
		margin-bottom: 0.25rem;
	}
`
