// reset css should be on top
import './index.css'

import ReactDOM from 'react-dom/client'
import {
	unstable_HistoryRouter as Router,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'
import type { Theme, ToastPosition } from 'react-toastify'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'
import { BrowserTracing, Replay } from '@sentry/react'
import { useEffect, StrictMode, Fragment } from 'react'
import { createBrowserHistory } from 'history'

import { StoreProvider } from '@/modules/session/store.provider'
import { QueryProvider } from '@/modules/session/query.provider'
import { Startup } from '@/modules/session/startup'
import { BaseStyles } from '@/styles/base_styles'
import { Cognito } from '@/modules/session/cognito.component'

import App from './App'
import Icon from './components/Icon'
import './lib/i18n'
import theme from './lib/theme'
import reportWebVitals from './reportWebVitals'
import Style from './styles/global'

import './styles/rsuite.less'

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_ENVIRONMENT,
	enabled: !import.meta.env.VITE_E2E,
	integrations: [
		new BrowserTracing({
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Replay(),
	],
	tracesSampleRate: 0.5,
	tracePropagationTargets: ['localhost', /^https:\/\/(api|api-stg).videobot\.com/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	denyUrls: [/^blob:https:\/\/(app|app-stg).videobot.com/],
})

const toastSettings = {
	position: 'bottom-left' as ToastPosition,
	autoClose: 2500,
	icon: ({ type }: { type: string }) => <Icon name={type} />,
	transition: Slide,
	theme: 'colored' as Theme,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const history = createBrowserHistory()
root.render(
	<Fragment>
		<Style />
		<StrictMode>
			<Router history={history as any}>
				<Cognito>
					<StoreProvider>
						<ThemeProvider theme={theme}>
							<QueryProvider>
								<BaseStyles>
									<Startup>
										<App />
									</Startup>
								</BaseStyles>
								<ToastContainer {...toastSettings} />
							</QueryProvider>
						</ThemeProvider>
					</StoreProvider>
				</Cognito>
			</Router>
		</StrictMode>
	</Fragment>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
