import { useTranslation } from 'react-i18next'

import Panel from '@/components/Panel'

import { VideobotIframe } from '../ShareVideobot/VideobotIframe'

const VideobotPreviewPanel = ({ bot, onClose, videobot }) => {
	const { t } = useTranslation()
	const styles = { borderRadius: '10px', border: 'none', width: `340px`, height: `650px` }

	return (
		<Panel
			title={`${t('preview')}: ${videobot?.name || bot?.name}`}
			onClose={onClose}
			centerContent
			addBackdrop
		>
			<VideobotIframe
				src={bot ? `/preview/${bot.id}?preview=true` : `/preview/current?preview=true`}
				style={styles}
				videobot={videobot}
			/>
		</Panel>
	)
}

export default VideobotPreviewPanel
