import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

export const TextArea = styled.textarea`
	${Reset('all')}
	display: block;
	border-radius: 6px;
	text-align: left;
	overflow-x: auto;
	resize: none;

	white-space: pre-wrap;
	width: 100%;
	padding: 1rem;
	margin-bottom: 1.75rem;

	font-size: 1.1rem;
	line-height: 1.65;
	text-size-adjust: 100%;

	background-color: ${(props) => props.theme.color.line};

	&:focus,
	&:active {
		outline: 0;
		box-shadow: 0;
		border: 0;
		background-color: ${(props) => props.theme.color.light};
	}
`
