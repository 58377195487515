import AuthLayout from '@/features/Auth/components/AuthLayout'
import { UnauthCheck } from '@/modules/session/auth.component'
import LoginForm from '@/features/Auth/components/LoginForm'

export const Login = () => {
	return (
		<UnauthCheck>
			<AuthLayout variant="login">
				<LoginForm />
			</AuthLayout>
		</UnauthCheck>
	)
}
