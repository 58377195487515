import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'

import type { AccountCurrentUserRead, UserWithRolesRead } from '@/api/videobot.schemas'
import { useResetStore } from '@/modules/session/store.provider'
import { useReAuth } from '@/modules/session/cognito.component'
import { FetchError } from '@/fetcher'

const ACTIVE_ACCOUNT_KEY = 'active_account'
const activeAccountAtom = atomWithStorage(ACTIVE_ACCOUNT_KEY, '')
const activeAccountIdAtom = atom((get) => Number.parseInt(get(activeAccountAtom), 10) || undefined)

export const ON_LOGIN_EMAIL_KEY = 'on_login_email'
const powerUserModeAtom = atom(false)

export const isBypassAuth = () => import.meta.env.VITE_DANGEROUS_DISABLE_AUTH_CHECK === 'true'

export const useAuth = () => {
	const ___bypassAuth___ = isBypassAuth()

	const setActiveAccount = useSetAtom(activeAccountAtom)
	const activeAccountId = useAtomValue(activeAccountIdAtom)
	const resetStore = useResetStore()
	const queryClient = useQueryClient()
	const { route } = useAuthenticator((context) => [context.route])
	const isAuthenticated = ___bypassAuth___ || route === 'authenticated'
	const reAuth = useReAuth()

	const login = useCallback(
		async (email: string, password: string) => {
			const user = await Auth.signIn(email, password)
			// TODO: handle other challenge types
			if (user.challengeName !== 'SOFTWARE_TOKEN_MFA') {
				reAuth()
			}
			sessionStorage.removeItem(ON_LOGIN_EMAIL_KEY)
			return user
		},
		[reAuth],
	)

	const mfaLogin = useCallback(
		async (user: any, code: string) => {
			await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
			reAuth()
		},
		[reAuth],
	)

	const logout = useCallback(async () => {
		resetStore()
		setActiveAccount('')
		queryClient.clear()
		await Auth.signOut()
		reAuth()
		sessionStorage.removeItem(ON_LOGIN_EMAIL_KEY)
	}, [resetStore, setActiveAccount, queryClient, reAuth])

	const clearActiveAccount = useCallback(() => {
		setActiveAccount('')
	}, [setActiveAccount])

	return {
		isAuthenticated,
		activeAccountId,
		login,
		logout,
		setActiveAccount,
		clearActiveAccount,
		mfaLogin,
	}
}

export const usePreferredMFA = () => {
	const [preferredMFA, setPreferredMFA] = useState('INIT')
	const { user } = useAuthenticator((context) => [context.user])

	useEffect(() => {
		const getPreferredMFA = async () => {
			if (user) {
				const authPreferredMFA = await Auth.getPreferredMFA(user)
				setPreferredMFA(authPreferredMFA)
			}
		}
		getPreferredMFA()
	}, [user])

	return preferredMFA
}

// Get the current user
export const CurrentUserContext = createContext<UserWithRolesRead | null>(null)
export const useCurrentUser = () => {
	const currentUser = useContext(CurrentUserContext)
	const { isAuthenticated } = useAuth()
	if (!currentUser) {
		throw new Error('[CurrentUserContext]: No current user found')
	}
	if (!isAuthenticated) {
		throw new Error('[CurrentUserContext]: Currently not authenticated')
	}
	return currentUser
}

// Get the current account
export const CurrentAccountContext = createContext<AccountCurrentUserRead | null>(null)
export const useCurrentAccount = () => {
	const currentAccount = useContext(CurrentAccountContext)
	const { isAuthenticated } = useAuth()
	if (!isAuthenticated) {
		throw new Error('[CurrentAccountContext]: Currently not authenticated')
	}
	return currentAccount
}

export const useCurrentAccountStrict = () => {
	const currentAccount = useCurrentAccount()
	if (!currentAccount) {
		throw new Error('[CurrentAccountContext]: No account selected')
	}
	return currentAccount
}

export const usePowerUserMode = () => {
	const [powerUserMode, setPowerUserMode] = useAtom(powerUserModeAtom)
	const currentUser = useCurrentUser()

	const isPowerUser = Boolean(currentUser.isCustomerSuccess || currentUser.isSuperuser)

	const setPowerUserModeIfAllowed = (newMode: boolean) => {
		if (isPowerUser) {
			setPowerUserMode(newMode)
		} else {
			// do nothing
		}
	}

	return { powerUserMode, setPowerUserMode: setPowerUserModeIfAllowed, isPowerUser }
}

// TODO: Maybe use some kind of error code instead of string comparison
export const isInvalidUser = (err: unknown) => {
	if (err instanceof FetchError) {
		if (err.statusCode === 401 && err.payload?.detail === 'User not found.') {
			return true
		}
	}
	return false
}
