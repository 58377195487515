import { useAuthenticator } from '@aws-amplify/ui-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { getUsersCurrentUserQueryKey, useFinalizeUser } from '@/api/videobot'
import { ON_LOGIN_EMAIL_KEY, useAuth, useCurrentUser } from '@/modules/session/auth.store'
import { AuthCheck } from '@/modules/session/auth.component'

const OauthCheck = () => {
	const { mutate } = useFinalizeUser()
	const { user: cognitoUser } = useAuthenticator((context) => [context.user])
	const user = useCurrentUser()
	const { logout } = useAuth()
	const navigate = useNavigate()
	const [t] = useTranslation()
	const queryClient = useQueryClient()

	useEffect(() => {
		if (!user) {
			return navigate('/login')
		}
		if (!cognitoUser) {
			return navigate('/login')
		}
		mutate(
			{
				data: {
					firstName: user.firstName || cognitoUser.attributes?.given_name,
					lastName: user.lastName || cognitoUser.attributes?.family_name,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(getUsersCurrentUserQueryKey())
					navigate('/')
					sessionStorage.removeItem(ON_LOGIN_EMAIL_KEY)
				},
				onError: () => {
					toast.error(t('errors:errorOccured'))
					navigate('/login')
				},
			},
		)
	}, [navigate, user, mutate, cognitoUser, t, queryClient, logout])

	return null
}

export const OauthCheckView = () => (
	<AuthCheck>
		<OauthCheck />
	</AuthCheck>
)
