import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import FieldText from '@/components/FieldText'
import FormField from '@/components/FormField'
import Modal from '@/components/Modal'
import PropTypes from '@/lib/propTypes'

const AddSlideModal = ({ onClose, show, isLoading, onSubmit }, _ref) => {
	const { t } = useTranslation(['videobot'])

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
	})

	return (
		<Modal header={<h3>{t('videobot:createNewSlide')}</h3>} show={show} onClose={onClose}>
			<form autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data))}>
				<FormField label={t('videobot:slideFields.name.label')}>
					<FieldText
						register={register}
						placeholder={t('videobot:slideFields.name.placeholder')}
						name="name"
						type="name"
						error={errors.name}
						required
						size="large"
					/>
				</FormField>
				<Button disabled={!isValid} block isLoading={isLoading}>
					{t('videobot:createSlide')}
				</Button>
			</form>
		</Modal>
	)
}

AddSlideModal.propTypes = {
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	show: PropTypes.bool,
	isLoading: PropTypes.bool,
}

export default AddSlideModal
