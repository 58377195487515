import styled from 'styled-components'

export const ButtonPreview = styled.span`
	position: relative;
	cursor: crosshair;
	display: inline-flex;
	align-items: center;
	width: 100%;
	background-color: ${(props) => props.bgColor};
	color: ${(props) => props.textColor};
	justify-content: center;
	border-radius: 3rem;
	border: 1px solid transparent;
	font-weight: 600;
	height: 3.25rem;
`
