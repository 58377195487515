import * as React from 'react'

import { useGetCfBotAnalytics } from '@/api/videobot'

interface Props {
	botId: string
	fromDate: string
	toDate: string
	iconId?: string
}

export const CFAnalyticsTable = (props: Props) => {
	const { data, error } = useGetCfBotAnalytics(props.botId, {
		from_date: props.fromDate,
		to_date: props.toDate,
		icon_identifier: props.iconId,
	})

	if (data) {
		return (
			<div>
				<table className="w-full table-auto">
					<thead className="bg-slate-900 text-slate-50 border-b font-bold">
						<tr className="[&>td]:p-2">
							<td>Screen</td>
							<td>ID</td>
							<td className="w-32 text-right">Minuted delivered</td>
							<td className="w-32 text-right">Views</td>
							<td className="w-32 text-right">CTR %</td>
						</tr>
					</thead>

					<tbody className="divide-y">
						{data.videos.map((video, index) => (
							<tr key={video.identifier} className="[&>td]:p-2">
								<td className="text-neutral-700 font-bold">{video.name}</td>
								<td>{video.identifier}</td>
								<td className="text-right">{video.watched}</td>
								<td className="text-right">{video.views}</td>
								<td className="text-right">
									{index > 0 &&
										`${(
											(video.views / data.videos[index - 1].views) *
											100
										).toFixed(2)} %`}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}

	if (error) {
		return <div>Unexpected error...</div>
	}

	return <div>Loading, please wait</div>
}
