import { clamp } from 'lodash-es'

export const mapStr2bool = (obj) => {
	const str2bool = (value) => {
		if (value && typeof value === 'string') {
			if (value.toLowerCase() === 'true') return true
			if (value.toLowerCase() === 'false') return false
		}
		return value
	}
	Object.keys(obj).forEach((key) => {
		obj[key] = str2bool(obj[key])
	})

	return obj
}

export const parseCss = async (cssRaw) => {
	const styleElement = document.createElement('style')
	const head = document.getElementsByTagName('head')[0]
	head.append(styleElement)

	// disable style
	styleElement.disabled = true
	styleElement.sheet.disabled = true

	// add style
	styleElement.textContent = cssRaw

	const sheet = styleElement.sheet
	const styles = {}

	const cssRules = sheet.cssRules
	for (let i = 0; i < cssRules.length; i++) {
		const rule = cssRules[i]
		if (!rule) {
			continue
		}
		const styleSet = {}
		if (rule instanceof CSSStyleRule) {
			styles[rule.selectorText] = styleSet
			for (const style of rule.style) {
				styleSet[style] = rule.style.getPropertyValue(style)
			}
		}
		if (rule instanceof CSSFontFaceRule) {
			styles[`@font-face ${i}`] = styleSet
			for (const style of rule.style) {
				styleSet[style] = rule.style.getPropertyValue(style)
			}
		}
	}

	head.removeChild(styleElement)

	return styles
}

export const getNextId = (list) => {
	let latestId = 1
	for (const obj of list) {
		if (Number(obj.id) > latestId) {
			latestId = obj.id
		}
	}
	return latestId + 1
}

export const urlToPathname = (url) => new URL(url, location).pathname.replace(/\/$/, '') || '/'

export const unescapeLink = (link) => {
	const doc = new DOMParser().parseFromString(link, 'text/html')
	return doc.documentElement.textContent
}

export const hexWithAlpha = (hex, alpha) => {
	const alphaHex = Math.floor(clamp(alpha, 0, 1) * 255).toString(16)
	return hex + alphaHex
}
