import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import { useMediaQuery } from '@/hooks/useMediaQuery'
import { RoleCheck } from '@/modules/session/auth.component'
import { RoleType } from '@/api/videobot.schemas'
import { getReadAccountsQueryKey, useCreateAccount } from '@/api/videobot'

import Button from '../../components/Button'
import AccountProfileForm from '../../features/Account/components/AccountProfileForm'
import View from '../../layout/View'

const CreateAccountContents = () => {
	const { t } = useTranslation(['account', 'errors'])
	const formRef = useRef(null)
	const navigate = useNavigate()
	const isMobile = useMediaQuery('<=')
	const { mutate, isLoading } = useCreateAccount()
	const queryClient = useQueryClient()

	const Actions = (
		<Button onClick={() => formRef?.current?.submit()}>{t('account:createAccount')}</Button>
	)

	const ActionsTopBar = (
		<Button variant={isMobile ? 'link' : 'primary'} onClick={() => formRef?.current?.submit()}>
			{t('submit')}
		</Button>
	)

	const createAccount = (data) => {
		mutate(
			{ data },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(getReadAccountsQueryKey())
					toast.success(t('account:accountCreated'))
					navigate('/dashboard')
				},
				onError: (error) => {
					const detail = error.response?.data?.detail ?? error.message
					toast.error(
						t(`${detail}`, t('errorOccured', { ns: 'errors' }), { ns: 'errors' }),
					)
				},
			},
		)
	}

	return (
		<View
			title={t('account:createAccount')}
			shortTitle={t('account:createAccount')}
			actions={Actions}
			topBarActions={ActionsTopBar}
			hideHeaderActionsOnMobile
			showTopBar
			showTopBarOnDesktop
			isLoading={isLoading}
			cover
			backRoute="/dashboard/accounts"
		>
			<AccountProfileForm
				ref={formRef}
				variant="CREATE"
				onSubmit={(data) => createAccount(data)}
			/>
		</View>
	)
}

const CreateAccount = () => {
	return (
		<RoleCheck roles={[RoleType.Super_Admin, RoleType.Reseller]} skipAccountRole>
			<CreateAccountContents />
		</RoleCheck>
	)
}

export default CreateAccount
