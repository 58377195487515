import { NavLink } from 'react-router-dom'

import PropTypes from '@/lib/propTypes'

import Icon from '../../components/Icon'
import { NavigationLink } from './Navigation.styles'

const NavigationItem = ({ title, icon, to }) => {
	return (
		<NavigationLink as={NavLink} to={to}>
			<Icon name={icon} className="aspect-square w-5" />
			<span>{title}</span>
		</NavigationLink>
	)
}

NavigationItem.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	to: PropTypes.string,
}

export default NavigationItem
