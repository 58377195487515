import { motion } from 'framer-motion'
import { Outlet, useNavigate } from 'react-router'
import styled from 'styled-components'
import { useAtomValue } from 'jotai'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Fragment, useEffect } from 'react'
import { toast } from 'react-toastify'

import { isNavigationCollapsedAtom } from '@/common/layout'
import { useAuth, useCurrentAccount, useCurrentUser } from '@/modules/session/auth.store'
import { AuthCheck } from '@/modules/session/auth.component'
import { RoleStatusType } from '@/api/videobot.schemas'

import { useVelarisSurvey } from './modules/velaris/velaris.hook'
import { useMediaQuery } from './hooks/useMediaQuery'
import Navigation from './layout/Navigation'
import TopBar from './layout/TopBar/TopBar'
import { Wrapper } from './styles/global'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'

const Body = styled(motion.div)`
	position: relative;
	top: 0;
	flex: 1;
	display: flex;
`

export const Dashboard = () => {
	const isNavigationCollapsed = useAtomValue(isNavigationCollapsedAtom)
	const { activeAccountId } = useAuth()
	const currentUser = useCurrentUser()
	const currentAccount = useCurrentAccount()

	useVelarisSurvey(currentUser, currentAccount)

	const variants = {
		extended: {
			marginLeft: '17rem',
			width: `calc(100% - 17rem)`,
		},
		collapsed: {
			marginLeft: '5rem',
			width: `calc(100% - 5rem)`,
		},
		mobile: {
			marginLeft: '0',
			width: '100%',
		},
	}

	const isLargerThanLg = useMediaQuery('>lg')
	let bodyProps = {}

	if (isLargerThanLg) {
		bodyProps = {
			transition: { duration: 0.2 },
			initial: isNavigationCollapsed ? 'collapsed' : 'extended',
			animate: isNavigationCollapsed ? 'collapsed' : 'extended',
			variants,
		}
	} else {
		bodyProps = {
			initial: 'mobile',
			animate: 'mobile',
			variants,
		}
	}

	return (
		<Fragment>
			<ReactQueryDevtools
				initialIsOpen={false}
				position="bottom-right"
				panelPosition="right"
			/>
			<Wrapper>
				<ErrorBoundary>
					<Navigation />
					<TopBar />
					<Body {...bodyProps}>
						<Outlet key={activeAccountId ?? 0} />
					</Body>
				</ErrorBoundary>
			</Wrapper>
		</Fragment>
	)
}

export const AuthDashboard = () => (
	<AuthCheck>
		<Dashboard />
	</AuthCheck>
)

export const DashboardDefaultRoute = () => {
	const { setActiveAccount, logout } = useAuth()
	const currentUser = useCurrentUser()
	const currentAccount = useCurrentAccount()
	const navigate = useNavigate()

	useEffect(() => {
		const activeRoles = currentUser.roles.filter(
			(role) => role.status === RoleStatusType.Active,
		)

		if (currentAccount) {
			return navigate('./videobots', { replace: true })
		}

		if (currentUser.isSuperuser || currentUser.isCustomerSuccess || currentUser.isReseller) {
			return navigate('./accounts', { replace: true })
		}

		if (activeRoles.length > 2) {
			return navigate('/team-select', { replace: true })
		}

		if (activeRoles[0]) {
			setActiveAccount(activeRoles[0].accountId)
			return navigate('./videobots', { replace: true })
		}

		const pendingRoles = currentUser.roles.filter(
			(role) => role.status === RoleStatusType.Invited,
		)

		if (pendingRoles.length > 0) {
			return navigate('/team-select', { replace: true })
		}

		logout()
		navigate('/login')
		toast.error('You do not have access to any accounts')
	}, [currentAccount, currentUser, navigate, setActiveAccount, logout])

	return null
}
